import React from 'react'
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";
import FirstAccess from "../Entitlement/FirstAccess/FirstAccess";
import Login from "../Entitlement/Login/Login";
import BaseLayout from "../pages/BaseLayout";
import DashBoard from "../pages/Dashboard/dashboard";
import ActionsPlansRoutes from "./ActionsPlans/ActionsPlans.routes";
import AdministrationUsers from "./AdministrationUsers/AdministrationUsers.routes";
import AgentsRoutes from "./Agents/Agents.routes";
import CalendarRoutes from "./Calendar/Calendar.routes";
import ChecklistRoutes from "./Checklists/Checklist.routes";
import FarmersRoutes from "./Farmers/Farmers.routes";
import MessagesRoutes from "./Message/Message.routes";
import NotificationsRoutes from "./Notifications/Notifications.routes";
import Private from "./Private";
import ProgramAreaRoutes from "./ProgramArea/ProgramArea.routes";
import ProgramMapRoutes from "./ProgramMap/ProgramMap.routes";
import ReportRoutes from "./Reports/Report.routes";
import ServicesRoutes from "./Services/Services.routes";
import SuppliersRoutes from "./Suppliers/Suppliers.routes";
import UserRoutes from "./Users/Users.routes";
import RecoverPass from "../Entitlement/RecoverPass/RecoverPass";

function RoutesApp() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/first-access" element={<FirstAccess />} />
      <Route path="/recover-password" element={<RecoverPass />} />

      <Route
        path="/dashboard"
        element={
          <Private>
            <BaseLayout breadcrumb={"DashBoard"}>
              <DashBoard />
            </BaseLayout>
          </Private>
        }
      />
      <Route path="/program-map/*" element={<ProgramMapRoutes />} />
      <Route path="/program-area/*" element={<ProgramAreaRoutes />} />
      <Route path="agents/*" element={<AgentsRoutes />} />
      <Route path="administration-users/*" element={<AdministrationUsers />} />
      <Route path="checklists/*" element={<ChecklistRoutes />} />
      <Route path="action_plans/*" element={<ActionsPlansRoutes />} />
      <Route path="calendar/*" element={<CalendarRoutes />} />
      <Route path="farmers/*" element={<FarmersRoutes />} />
      <Route path="user/*" element={<UserRoutes />} />
      <Route path="notifications/*" element={<NotificationsRoutes />} />
      <Route path="messages/*" element={<MessagesRoutes />} />
      <Route path="reports/*" element={<ReportRoutes />} />
      <Route path="suppliers/*" element={<SuppliersRoutes />} />
      <Route path="services/*" element={<ServicesRoutes />} />

      {/* Dim das Áreas de Notificatções */}

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}

export default RoutesApp;
