import axios from "../../apis/axiosInterceptorCarbon";

export const list = async (
  pageSize,
  currentPage,
  search,
  sortOrder,
  sortField
) => {
  const result = await axios.get(
    `/v1/vendor?limit=${pageSize}&page=${currentPage}&name=${search}`
  );
  return result.data.data;
};

export const createSuppliers = async (
  name,
  name_of_responsible,
  email_of_responsible,
  phone_of_responsible,
  cpf_cnpj
) => {
  const payload = {
    name,
    name_of_responsible,
    email_of_responsible,
    phone_of_responsible,
    cpf_cnpj,
  };
  await axios.post(`/v1/vendor`, payload);
};

export const viewSuppliers = async ({ id }) => {
  const result = await axios.get(`v1/vendor/${id}`);
  return result.data.data;
};

export const createSalesman = async (
  vendorId,
  name,
  email,
  phone,
  hasFullControl,
  cpf_cnpj
) => {
  const payload = {
    vendorId,
    name,
    email,
    phone,
    hasFullControl,
    cpf_cnpj,
  };
  console.log("payload", payload);
  await axios.post(`/v1/salesman`, payload);
};

export const listSalesman = async (pageSize, currentPage, search, vendorId) => {
  const result = await axios.get(
    `v1/salesman?limit=${pageSize}&page=${currentPage}&name=${search}&vendorId=${vendorId}`
  );
  return result.data;
};

export const getWallet = async (id) => {
  const result = await axios.get(`v1/wallet/${id}`);
  return result.data.data;
};

export const editSupplier = async (vendorId, name, salesmanId) => {
  const payload = {
    name,
    salesmanId,
  };
  await axios.put(`v1/vendor/${vendorId}`, payload);
};

export const findSalesman = async (id) => {
  const result = await axios.get(`v1/salesman/${id}`);
  return result.data.data;
};

export const editSalesman = async (id, values) => {
  const payload = {
    name: values.salesmanName,
    email: values.email,
    phone: values.phone,
  };
  await axios.put(`v1/salesman/${id}`, payload);
};
