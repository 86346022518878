import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Form, Input, Row, Select } from "antd";
import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import BCButtonConfirm from "../../components/BCButtonConfirm";
import BCFlagSelector from "../../components/BCFlagSelector";
import { phoneMaskBR } from "../../utils/mask";
import { createSuppliers } from "./service";
const { Option } = Select;
const { Meta } = Card;

function SuppliersCreateSuppliers() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [organizations, setOrganizations] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("Name_is_Required")),
    responsible_of_name: Yup.string().required(
      t("Responsible_name_is_Required")
    ),
    responsible_of_email: Yup.string()
      .email(t("Invalid_Email"))
      .required(t("Responsible_email_Is_Required")),
    responsible_of_phone: Yup.string().required(
      t("Responsible_phone_Is_Required")
    ),
    phoneCode: Yup.string().required(t("Responsible_phone_Is_Required")),
    cpf_cnpj: Yup.string(),
  });

  async function create(values) {
    await createSuppliers(
      values.name,
      values.responsible_of_name,
      values.responsible_of_email,
      `${values.phoneCode} ${values.responsible_of_phone}`,
      values.cpf_cnpj
    );
    navigate("/vendor");
  }

  return (
    <Formik
      initialValues={{
        name: "",
        responsible_of_name: "",
        responsible_of_email: "",
        responsible_of_phone: "",
        cpf_cnpj: "",
        phoneCode: "+55",
      }}
      validationSchema={validationSchema}
      onSubmit={create}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
      }) => (
        <Form layout="vertical" onFinish={handleSubmit}>
          <Meta
            avatar={
              <Avatar
                style={{ backgroundColor: "green", color: "white" }}
                icon={<UserOutlined />}
              />
            }
            title={t("Invite_a_new_suppliers")}
            description={t("Text_new_suppliers")}
          />
          <Row style={{ marginTop: "30px" }} gutter={16}>
            <Col span={7}>
              <Row gutter={16}>
                <Col xs={24} sm={24}>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={touched.name && errors.name ? errors.name : ""}
                        validateStatus={
                          touched.name && errors.name ? "error" : ""
                        }
                      >
                        <Input
                          name="name"
                          className="input"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Name")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={
                          touched.responsible_of_name &&
                          errors.responsible_of_name
                            ? errors.responsible_of_name
                            : ""
                        }
                        validateStatus={
                          touched.responsible_of_name &&
                          errors.responsible_of_name
                            ? "error"
                            : ""
                        }
                      >
                        <Input
                          name="responsible_of_name"
                          className="input"
                          value={values.responsible_of_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Responsible_name")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={
                          touched.responsible_of_email &&
                          errors.responsible_of_email
                            ? errors.responsible_of_email
                            : ""
                        }
                        validateStatus={
                          touched.responsible_of_email &&
                          errors.responsible_of_email
                            ? "error"
                            : ""
                        }
                      >
                        <Input
                          prefix={<MailOutlined />}
                          name="responsible_of_email"
                          className="input"
                          value={values.responsible_of_email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Responsible_email")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={
                          touched.cpf_cnpj && errors.cpf_cnpj
                            ? errors.cpf_cnpj
                            : ""
                        }
                        validateStatus={
                          touched.cpf_cnpj && errors.cpf_cnpj ? "error" : ""
                        }
                      >
                        <Input
                          name="cpf_cnpj"
                          className="input"
                          value={values.cpf_cnpj}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("cpf_cnpj")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={
                          touched.responsible_of_phone &&
                          errors.responsible_of_phone
                            ? errors.responsible_of_phone
                            : ""
                        }
                        validateStatus={
                          touched.responsible_of_phone &&
                          errors.responsible_of_phone
                            ? "error"
                            : ""
                        }
                      >
                        <Input
                          addonBefore={
                            <BCFlagSelector
                              selected={values.phoneCode}
                              onSelect={(value) =>
                                handleChange({
                                  target: { name: "phoneCode", value },
                                })
                              }
                            />
                          }
                          name="responsible_of_phone"
                          className="input"
                          value={values.responsible_of_phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Responsible_phone")}
                          onKeyUp={(event) => phoneMaskBR(event)}
                          prefix={<PhoneOutlined />}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Form.Item label={t("Text_important_new_suppliers")}></Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <BCButtonConfirm
              style={{ width: "100%", maxWidth: "200px", marginTop: "20px" }}
              buttonText={t("Send")}
            ></BCButtonConfirm>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}

export default SuppliersCreateSuppliers;
