import { Button, Modal, Select, Skeleton, Progress } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import * as S from "./UploadDocumentModalStyles";
import {
  fechAddDocument,
  fetchListTypeDocument,
  fetchUploadDocumentFarm,
} from "../../../service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const UploadDocumentModal = (props, ref) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingListTypeDocument, setLoadingListTypeDocument] = useState(false);
  const [listTypeDocuments, setListTypeDocuments] = useState([]);

  const [typeDocumentSelected, setTypeDocumentSelected] = useState();
  const [documentSelected, setDocumentSelected] = useState();

  const [progress, setProgress] = useState(0);

  const onCancel = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const handleSubmitDocument = async () => {
    try {
      setLoading(true);
      const data = new FormData();
      data.append("file", documentSelected);

      const response = await fetchUploadDocumentFarm(data, (e) => {
        const progress = e.progress * 100;
        setProgress(Number.parseInt(progress));
      });

      const body = {
        file: response?.message,
        type_document: typeDocumentSelected,
        farm_id: props?.farmId,
      };

      await fechAddDocument(body);
      setOpen(false);
      setLoading(false);
      toast.success(t("message_send_document"));
      setDocumentSelected();
      setTypeDocumentSelected();
      props?.onLoad();
      window.location.reload()
    } catch (error) {
      toast.error(t("error_message_upload_document"));
      console.log("erro upload document", error);
    }
  };

  useImperativeHandle(ref, () => ({
    handleOpen: () => handleOpen(),
  }));

  const handleLoadListTypeDocument = async () => {
    try {
      setLoadingListTypeDocument(true);
      const response = await fetchListTypeDocument();
      if (response?.data) {
        const adapter = response?.data.map((item) => ({
          value: item?.name,
          label: item?.name,
        }));
        setListTypeDocuments(adapter);
      }
      setLoadingListTypeDocument(false);
    } catch (error) {
      console.log("error list type documents", error);
      setLoadingListTypeDocument(false);
    }
  };

  useEffect(() => {
    if (!open) return;
    handleLoadListTypeDocument();
  }, [open]);

  return (
    <Modal
      maskClosable={false}
      open={open}
      onCancel={onCancel}
      onOk={handleSubmitDocument}
      title={t("label_add_document")}
      footer={[
        <Button
          shape="round"
          className="confirm_button"
          type="dashed"
          loading={loading}
          disabled={!(documentSelected && typeDocumentSelected)}
          icon={<CloudUploadOutlined />}
          onClick={handleSubmitDocument}
        >
          Enviar
        </Button>,
      ]}
    >
      {loadingListTypeDocument && <Skeleton title active />}
      {!loadingListTypeDocument && (
        <S.Container>
          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder={t("label_input_type_document")}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            onChange={setTypeDocumentSelected}
            options={listTypeDocuments}
          />
          <S.WrapperInput>
            {loading ? (
              <Progress type="circle" percent={progress} size={50} />
            ) : (
              <S.IconUpload />
            )}

            <S.LabelInput>
              {documentSelected
                ? documentSelected?.name
                : t('label_input_file_document')}
            </S.LabelInput>
            <S.InputUpload
              type="file"
              onChange={(e) => setDocumentSelected(e.target.files[0])}
            />
          </S.WrapperInput>
        </S.Container>
      )}
    </Modal>
  );
};

export default forwardRef(UploadDocumentModal);
