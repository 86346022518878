import { Switch, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TYPEUSER } from "../../Enums/Users.enum";
import BCTable from "../../components/BCTable";
import { listUsers, setAvailableForAgent } from "./service";

export default function AgentsListAvailable() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [sortField, setSortField] = useState("name");
  const [loading, setLoading] = useState(true);
  const [agents, setAgents] = useState([]);

  const { t } = useTranslation();

  const findAgents = useCallback(async () => {
    setLoading(true);
    const result = await listUsers({
      limit: Number.MAX_SAFE_INTEGER,
      page: 1,
      type: TYPEUSER.AGENT,
      sort: sortOrder,
      field: sortField,
      search,
    });
    setAgents(result.result);
    result.result.map((x) => (x.key = x._id));
    setTotal(result.count);
    setLoading(false);
    setLoading(false);
  }, [sortOrder, sortField, search]);

  useEffect(() => {
    findAgents();
  }, [findAgents]);

  async function handleChange(id, value) {
    await setAvailableForAgent(id, value);
    await findAgents();
  }

  const columns = [
    {
      title: t("Name"),
      dataIndex: "name",
      align: "center",
      sorter: true,
      width: "20%",
    },
    {
      title: t("Organization"),
      dataIndex: "organization",
      align: "center",
      sorter: false,
      width: "10%",
      render: (text, data) => (
        <Tag color="success" key={data.organization.name}>
          {data.organization.name}
        </Tag>
      ),
    },
    {
      title: t("Regions"),
      dataIndex: "regions",
      align: "center",
      sorter: false,
      render: (text, data) => (
        <>
          {data.regions.map((r) => (
            <Tag color="processing" key={r.name}>
              {r.name}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: t("Active"),
      dataIndex: "on_duty",
      align: "center",
      sorter: false,
      width: "10%",
      render: (text, data) => (
        <Switch
          style={{ backgroundColor: data.on_duty ? "rgb(31, 150, 82)" : "" }}
          checked={data.on_duty}
          onChange={(value) => {
            handleChange(data._id, value);
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <BCTable
        data={agents}
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        placeholderSearch={`${t("Search")} ${t("Agents")}`}
        setSearch={setSearch}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        loading={loading}
        columns={columns}
        actionsColumn={[]}
        showCreateButton={false}
      />
    </div>
  );
}
