import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Button, Col, Row, Typography, Form } from "antd";
import {
  UserOutlined,
  CheckOutlined,
  EyeFilled,
  EditFilled,
} from "@ant-design/icons";

import { RecipientCard, Name, Input } from "./AdministrationEditStyles";
import { useNavigate, useParams } from "react-router-dom";

import { getUserById, getPermissions, editAdminUser } from "../service";

import {
  phoneMaskBR,
  insertMask,
  avoidSpaces,
  toLowerCase,
} from "../../../utils/mask";
import { useLanguage } from "../../../contexts/languageContext";

const { Title, Paragraph } = Typography;

export const AdministrationEdit = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { language } = useLanguage();
  const navigate = useNavigate();
  
  const [ loading, setLoading ] = useState(false);
  const [usersPermission, setUserPermission] = useState({});
  const [readOnly, setReadOnly] = useState(true);
  const [userView, setUserView] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 400)
  }, [language])

  const handleSelectRecipient = ({ key, value }) => {
    const draft_user_permissions = { ...usersPermission };

    const permissionIsSelected = draft_user_permissions[key].selected;

    if (permissionIsSelected) {
      draft_user_permissions[key].selected = false;
    } else {
      draft_user_permissions[key].selected = true;
    }

    setUserPermission(draft_user_permissions);
  };

  const permissions = () =>
    Object.entries(usersPermission).map(([key, value], i) => {
      return (
        <RecipientCard
          key={i}
          onClick={() => handleSelectRecipient({ key, value })}
          selected={value.selected}
        >
          <CheckOutlined className="selected_icon" />
          <Name className="selected_recipient">{value.name}</Name>
        </RecipientCard>
      );
    });

  const populatePermissions = async () => {
    const [result, systemPermissions] = await Promise.all([
      getUserById(id),
      getPermissions(),
    ]);

    const data = {
      name: result.name,
      email: result.email,
      phone: insertMask(result.phone, "phone"),
      id: result._id,
    };

    form.setFieldsValue(data);

    const userPermissions = result.permissions.reduce(
      (acc, user_perm) => ({
        ...acc,
        [user_perm.permission]: {
          id: user_perm._id,
          name: user_perm.name,
          slug: user_perm.permission,
          selected: true,
        },
      }),
      {}
    );

    const draft_permissions = systemPermissions.result.reduce(
      (acc, item) => ({
        ...acc,
        [item.permission]: userPermissions[item.permission]
          ? userPermissions[item.permission]
          : {
              id: item._id,
              name: item.name,
              slug: item.permission,
              selected: false,
            },
      }),
      {}
    );

    setUserPermission(draft_permissions);
  };

  const onFinish = async (data) => {
    let permissions = [];

    for (const value of Object.values(usersPermission)) {
      if (value.selected) {
        permissions.push(value.id);
      }
    }
    const dataMain = {
      user_id: id,
      name: data.name,
      email: data.email,
      phone: `+55${data.phone.replace(/[()-]/g, "").replaceAll(/\s/g, "")}`,
      permissions: permissions,
    };

    await editAdminUser(dataMain);

    navigate(`/administration-users/list-admins`);
  };

  useEffect(() => {
    populatePermissions();
  }, []);

  if(loading){
    return <></>
  }

  return (
    <>
      <Row>
        <Title level={3} style={{ color: "#47342e" }}>
          {t("admin_edit_page_title")}
        </Title>
      </Row>
      <Row
        style={{
          minHeight: "70%",
          overflow: "hidden",
        }}
      >
        <Col
          style={{
            backgroundColor: "#f8f2e9",
            padding: 30,

            borderTopLeftRadius: 25,
            borderBottomLeftRadius: 25,
          }}
          xl={6}
          md={24}
        >
          <Row justify={"space-between"}>
            <Row>
              <UserOutlined
                style={{ fontSize: 18, color: "#1f9652" }}
              ></UserOutlined>
              <Paragraph
                style={{ margin: 0, marginLeft: 10, color: "#1f9652" }}
              >
                {t("User_Profile")}
              </Paragraph>
            </Row>

            <Row>
              <span onClick={() => setReadOnly((prev) => !prev)}>
                {readOnly ? (
                  <EyeFilled style={{ fontSize: 20, color: "#b2bec3" }} />
                ) : (
                  <EditFilled style={{ fontSize: 20, color: "#b2bec3" }} />
                )}
              </span>
            </Row>
          </Row>

          <Row style={{ marginBottom: 30 }}></Row>

          <Row style={{ height: "100%" }}>
            <Form form={form} onFinish={onFinish} initialValues={userView}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("invalid_field_name"),
                  },
                ]}
              >
                <Input
                  readOnly={readOnly ? readOnly : ""}
                  placeholder={t("name")}
                ></Input>
              </Form.Item>
              <Row></Row>

              <Row>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: t("invalid_field_email"),
                    },
                  ]}
                  onKeyPress={(event) => {
                    avoidSpaces(event);
                    toLowerCase(event);
                  }}
                >
                  <Input
                    placeholder={t("email")}
                    readOnly={readOnly ? readOnly : ""}
                  ></Input>
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  name="phone"
                  onKeyPress={(event) => {
                    avoidSpaces(event);
                    phoneMaskBR(event);
                  }}
                  rules={[
                    {
                      required: true,
                      message: t("invalid_field_phone"),
                    },
                    () => ({
                      validator(_, value) {
                        if (value.length < 14) {
                          return Promise.reject(t("minimum_of_8_characters"));
                        }
                        if (value.length > 15) {
                          return Promise.reject(t("maximum_of_11_characters"));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder={t("phone")}
                    readOnly={readOnly ? readOnly : ""}
                  />
                </Form.Item>
              </Row>

              <Row style={{ flex: 1 }}>
                <Button
                  shape="round"
                  style={{
                    bottom: 0,
                    width: "83%",
                    position: "absolute",
                    left: 0,
                    margin: 30,
                    color: "white",
                  }}
                  className="confirm_button"
                  type="primary"
                  htmlType="submit"
                >
                  {t("btn_save")}
                </Button>
              </Row>
            </Form>
          </Row>
        </Col>
        <Col
          style={{
            backgroundColor: "white",
            padding: 30,

            borderTopRightRadius: 25,
            borderBottomRightRadius: 25,
          }}
          xl={6}
          md={24}
        >
          <Row>
            <Paragraph>{t("user_permission")}</Paragraph>
          </Row>

          <Row>{permissions()}</Row>
        </Col>
      </Row>
    </>
  );
};
