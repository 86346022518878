import React from 'react'
import jwtDecode from "jwt-decode";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/auth";

// Edu, não se preocupe ainda com as permissoes, eu faço isso depois
export default function Private({ children }) {
  const { signed, loading } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  if (loading) {
    return <div></div>;
  }

  if (!signed || token === null || Date.now() >= jwtDecode(token).exp * 1000) {
    return <Navigate to="/" />;
  }

  return children;
}
