import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Col, Row, Space, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { CHECKLIST_STATUS } from "../../Enums/Checklist.enum";
import BCTable from "../../components/BCTable";
import { getAgent } from "../AdministrationUsers/service";
import { listChecklist } from "../Checklist/service";

export default function AgentsViewChecklists() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [checklist, setChecklists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [sortField, setSortField] = useState("checklist_name");
  const [loading, setLoading] = useState(true);
  const { type } = useParams();
  const [agent, setAgent] = useState({});
  const { t } = useTranslation();

  const findAgents = useCallback(async () => {
    setLoading(true);
    const result = await getAgent(id);
    setAgent(result);
  }, [id]);
  useEffect(() => {
    findAgents();
  }, [findAgents]);

  const findChecklist = useCallback(async () => {
    setLoading(true);

    const result = await listChecklist({
      limit: pageSize,
      page: currentPage,
      search: search,
      sortOrder: sortOrder,
      sortField: sortField,
      checklistType: type,
      agentId: id,
    });

    result.result.map((x) => (x.key = x._id));
    setChecklists(result.result);
    setTotal(result.count);
    setLoading(false);
  }, [pageSize, currentPage, search, sortOrder, sortField, type, id]);

  useEffect(() => {
    findChecklist();
  }, [findChecklist]);

  const onView = (key) => {
    navigate(`/checklists/${key}/view`);
  };

  const columns = [
    {
      title: t("Date"),
      dataIndex: "createdAt",
      align: "center",
      sorter: true,
      width: 150,
      render: (text, record) => (
        <span key={record.createdAt}>
          {record.createdAt
            ? new Date(record.createdAt).toLocaleDateString()
            : t("Never_accessed")}
        </span>
      ),
    },
    {
      title: t("Farmer"),
      dataIndex: "farmer.name",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return (
          <span>
            {data.farmer_name}
            <br />
            <Tag>{data.farmer_identifier}</Tag>
          </span>
        );
      },
    },
    {
      title: t("Farm"),
      dataIndex: "name",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return (
          <span>
            {data.farm_name}
            <br />
            <Tag>{data.farm_identifier}</Tag>
          </span>
        );
      },
    },
    {
      title: "Checklist",
      dataIndex: "checklist_name",
      align: "start",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "start",
      render: (text, data) => {
        let statusTag;
        switch (data.status) {
          case CHECKLIST_STATUS.COMPLETED:
            statusTag = (
              <Tag icon={<CheckCircleOutlined />} color="success">
                {t("COMPLETED")}
              </Tag>
            );
            break;
          case CHECKLIST_STATUS.EXPIRED:
            statusTag = (
              <Tag icon={<CloseCircleOutlined />} color="error">
                {t("EXPIRED")}
              </Tag>
            );
            break;
          case CHECKLIST_STATUS.PENDING:
            statusTag = (
              <Tag icon={<ClockCircleOutlined spin />} color="warning">
                {t("PENDING")}
              </Tag>
            );
            break;
          default:
            statusTag = <span>Status Desconhecido</span>;
        }
        return statusTag;
      },
    },
  ];

  const actionsColumn = [
    {
      title: t("Actions"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={() => onView(record.key)}
            icon={<EyeOutlined />}
          >
            {t("View")}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Row style={{ marginTop: "20px" }} gutter={[16, 16]} justify="start">
        <Col xs={12} sm={12} md={21}>
          <Col
            style={{ display: "flex", justifyContent: "space-between" }}
            className="custom-card"
          >
            <div style={{ display: "flex", alignItems: "start" }}>
              <h1
                style={{
                  fontSize: "2rem",
                  marginTop: "1px",
                }}
              >
                <UserOutlined size={48} />
              </h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <h1
                  style={{
                    fontSize: "25px",
                    marginBottom: "4px",
                    marginTop: "10px",
                  }}
                >
                  {agent.name}
                </h1>
                <div
                  style={{
                    fontSize: "20px",
                    display: "flex",
                    gridTemplateColumns: "repeat(3)",
                    gap: "16px",
                    alignItems: "start",
                    justifyContent: "flex-start",
                    color: "rgb(79, 175, 73)",
                    marginLeft: "-30px",
                  }}
                >
                  {agent.regions
                    ? agent.regions.map((item) => (
                        <div key={item.name}>
                          <FaMapMarkerAlt style={{ marginRight: "6px" }} />
                          {item.name}
                        </div>
                      ))
                    : ""}
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  <MailOutlined size={24} />
                </h1>

                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  {agent.email}
                </h1>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  <PhoneOutlined size={24} />
                </h1>
                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  {agent.phone}
                </h1>
              </div>
            </div>
          </Col>
        </Col>
      </Row>
      <BCTable
        data={checklist}
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        placeholderSearch={`${t("Search")} Checklists`}
        setSearch={setSearch}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        loading={loading}
        columns={columns}
        actionsColumn={actionsColumn}
        showCreateButton={false}
      />
    </div>
  );
}
