import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Input, List, Modal, Row, Upload } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  PoweroffOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ReactAudioPlayer from "react-audio-player";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { changeStatus, getChat, listFarmers, sendPost } from "./service";
import { chat as chatData } from "../../models/chat";
import { farm as farmData } from "../../models/farm";
import { isSafeURL } from "../../utils/isSafeURL";
import DOMPurify from "dompurify";
import { signatureLink } from "../Farmers/service";
import { MessageFile } from "./MessageItem";
import { MessageFileAudio } from "./MessageItemAudio";
import { MessageFileVideo } from "./MessageItemVideo";
import { toast } from "react-toastify";

const mapperType = [
  "image/webp",
  "image/tiff",
  "image/png",
  "image/jpg",
  "image/gif",
  "image/jpeg",
  "video/wmv",
  "video/mov",
  "video/avi",
  "video/mp4",
  "audio/aac",
  "audio/flac",
  "audio/mp3",
  "audio/mpeg",
  "application/octet-stream",
  "application/pdf",
  "video/quicktime"
];

export default function MessageViewChatFarmer() {
  const { id, chatId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [mimetype, setMimetype] = useState("");
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [message, setMessage] = useState("");
  const [disableInputs, setDisableInputs] = useState(true);

  const playerRef = useRef(null);
  const audioPlayerRef = useRef(null);
  const lastChatItemRef = useRef(null);

  const [chat, setChat] = useState(chatData);

  const [farm, setFarm] = useState(farmData);

  useEffect(() => {
    handleLinkClick(window.location.origin);
  }, []);

  const handleLinkClick = (url) => {
    if (!isSafeURL(url)) navigate("/messages", { replace: true });
  };

  const findChat = useCallback(async () => {
    const result = await getChat({
      id: chatId,
    });

    setDisableInputs(result.status === "OPEN");
    setChat(result);
  }, [chatId]);

  const findFarmer = useCallback(async () => {
    const result = await listFarmers({
      id: id,
    });

    setFarm(result.result[0]);
  }, [id]);

  useEffect(() => {
    findChat();
    findFarmer();
  }, [findChat, findFarmer]);

  useEffect(() => {
    if (lastChatItemRef.current) {
      lastChatItemRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chat]);

  const closeChat = async () => {
    await changeStatus({ chatId, status: "CLOSED" });
    navigate(`/messages/${id}/farmer`);
  };

  const handlePreview = async (url, mimetype) => {
    const response = await signatureLink(url);
    setPreviewUrl(response);
    setPreviewVisible(true);
    setPlaying(true);
    setAudioPlaying(true);
    setMimetype(mimetype);
  };

  const isImageFile = () => {
    return mimetype.startsWith("image/");
  };

  const handleModalClose = () => {
    setPreviewVisible(false);
    setPreviewUrl("");
    setPlaying(false);
    setAudioPlaying(false);
    if (playerRef?.current) {
      const playerVideo = playerRef.current;
      playerVideo.getInternalPlayer().pause();
    }

    if (audioPlayerRef?.current) {
      const player = audioPlayerRef.current;
      player.audioEl.current.pause();
    }
  };

  const getType = (link) => {
    if (!link) return "";
    const extractParam = link.split(".");
    const type = extractParam.at(-1);
    if (type) return type;
    return "mp4";
  };

  const handleDownload = async (location, originalname) => {
    const link = document.createElement("a");
    const response = await signatureLink(location);

    const sanitizedLocation = DOMPurify.sanitize(response);

    link.href = sanitizedLocation;
    link.download = originalname;
    link.rel = "noopener noreferrer";
    link.target = "_blank";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderListMeta = ({ item }) => {
    if (!item.location) {
      return (
        <List.Item.Meta
          title={
            <span
              style={{
                color: item.user_type === "farmer" ? "white" : "",
                wordWrap: "break-word",
              }}
            >
              {item.message}
            </span>
          }
          description={
            <span style={{ color: item.user_type === "farmer" ? "white" : "" }}>
              {new Date(item.createdAt).toLocaleString()}{" "}
              {item.sent_by_user_name}
            </span>
          }
        />
      );
    } else {
      return (
        <List.Item.Meta
          title={
            <span
              style={{
                color: "black",
                cursor: "pointer",
              }}
            >
              <Button
                type="link"
                onClick={() => handlePreview(item.url, item.mimetype)}
              >
                {item.location.endsWith(".caf") ? (
                  <MessageFileAudio url={item.url} />
                ) : getType(item.url) == "mp4" ? (
                  <MessageFileVideo
                    ref={playerRef}
                    url={item.url}
                    controls={true}
                    loop={false}
                    playing={playing}
                    width={300}
                  />
                ) : (
                  <MessageFile url={item.url} />
                )}
              </Button>

              <Button
                type="link"
                icon={<DownloadOutlined />}
                onClick={() => handleDownload(item.url, item.originalname)}
              />
            </span>
          }
          description={
            <span style={{ color: "black" }}>
              {new Date(item.createdAt).toLocaleString()}{" "}
              {item.sent_by_user_name}
            </span>
          }
        />
      );
    }
  };

  const FileUploadButton = ({ onFileChange }) => {
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    const dummyRequest = async ({ file, onSuccess, onProgress }) => {
      
      const isTypeAccepted = mapperType.includes(file.type);

      if (!isTypeAccepted) {
        toast.info(t("file_message_type"));
        setUploading(false);
        return;
      }
      const formData = new FormData();
      formData.append("file", file);

      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      };

      try {
        await sendPost({ chatId, formData, config });
        setProgress(0);
        setUploading(false);
        await findChat();
      } catch (error) {
        setProgress(0);
        setUploading(false);
      }
    };

    const handleChange = (info) => {
      if (info.file.status === "uploading") {
        setUploading(true);
        return;
      }
      if (info.file.status === "done") {
        setUploading(false);
        setProgress(0);
        onFileChange(info.file.originFileObj);
      }
    };

    return (
      <Upload
        customRequest={dummyRequest}
        onChange={handleChange}
        showUploadList={false}
      >
        <Button icon={<UploadOutlined />}>
          {uploading ? `${t("Uploading")}... ${progress}%` : "Upload"}
        </Button>
      </Upload>
    );
  };

  const handlePressEnter = async () => {
    if (message !== "" && message.length > 0) {
      const formData = new FormData();
      formData.append("post", message);
      await sendPost({ chatId, formData, config: {} });
      setMessage("");
      await findChat();
    }
  };

  const handleFileChange = (file) => {
    const formData = new FormData();
    formData.append("file", file);
  };

  return (
    <div>
      <Row justify="center">
        <Col span={16}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "400px",
            }}
          >
            <h1>{farm.farmer.name}</h1>
            <h1 style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              {farm.farmer.identifier}
            </h1>
            <h4 style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              {t("Farm")} {farm.name} {farm.identifier}
            </h4>
          </div>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          {disableInputs && (
            <Button
              type="danger"
              icon={<PoweroffOutlined />}
              onClick={() => closeChat()}
            >
              {t("End_Chat")}
            </Button>
          )}
          <Link to={`/messages/${id}/farmer`}>
            <Button type="dashed" icon={<ArrowLeftOutlined />} shape="round">
              {t("Gotoback")}
            </Button>
          </Link>
        </Col>
      </Row>
      <Card style={{ height: "700px", overflowY: "auto" }}>
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <List
            itemLayout="horizontal"
            split={false}
            dataSource={chat.posts}
            renderItem={(item, index) => (
              <List.Item
                ref={index === chat.posts.length - 1 ? lastChatItemRef : null}
                style={{
                  display: "flex",
                  justifyContent:
                    item.user_type === "farmer" ? "flex-start" : "flex-end",
                }}
              >
                <Card
                  style={{
                    width: "50%",
                    height: "10%",
                    margin:
                      item.user_type === "farmer" ? "0 auto 0 0" : "0 0 0 auto",
                    backgroundColor:
                      item.user_type === "farmer" ? "#47342eb8" : "#e5e1da",
                  }}
                >
                  <div
                    style={{
                      textAlign: item.user_type === "farmer" ? "left" : "right",
                    }}
                  >
                    {renderListMeta({ item })}
                  </div>
                </Card>
              </List.Item>
            )}
          />
        </div>

        <Modal open={previewVisible} onCancel={handleModalClose} footer={null}>
          {previewUrl && (
            <div style={{ textAlign: "center" }}>
              {isImageFile() ? (
                <img src={previewUrl} alt="" style={{ maxWidth: "100%" }} />
              ) : (
                <ReactPlayer
                  ref={playerRef}
                  url={previewUrl}
                  controls={true}
                  loop={false}
                  playing={playing}
                  width="100%"
                  height="100%"
                />
              )}
            </div>
          )}
        </Modal>
      </Card>
      {disableInputs && (
        <Row align="middle" gutter={16} style={{ marginTop: "10px" }}>
          <Col span={20}>
            <Input
              placeholder={t("Write_your_message_here")}
              size="large"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onPressEnter={handlePressEnter}
            />
          </Col>
          <Col span={4}>
            <FileUploadButton onFileChange={handleFileChange} />
          </Col>
        </Row>
      )}
    </div>
  );
}
