export const statusWithYellowColor = ["CREATED", "SENT", "WAITING_TO_CALL"];
export const statusWithRedColor = ["DELETED", "REFUSED"];
export const statusWithGreenColor = [
  "VERIFIED",
  "ACCEPTED",
  "RECEIVED_THE_CALL",
];
export const statusWithGrayColor = [
  "EXPIRED",
  "EXPIRED",
  "DIDNT_RECEIVE_THE_CALL",
];
export const statusWithBlueColor = ["NEWPROPOSAL"];
