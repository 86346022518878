import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Col, Row, Space, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { ACTION_PLANS } from "../../Enums/ActionsPlans.enum";
import BCTable from "../../components/BCTable";
import { listActionsPlans } from "../ActionPlans/service";
import { getAgent } from "../AdministrationUsers/service";
import BCListFarms from "../../components/utils/BCListFarms";

export default function AgentsViewFarmers() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const actionsColumn = [
    {
      title: t("Actions"),
      _id: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={() => navigate(`/farmers/detail/${record._id}/farmer`)}
            icon={<EyeOutlined />}
          >
            {t("View")}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h1>{`${t("AgentField")} : ${t("FarmerTitle")}`}</h1>
      <Row style={{ marginTop: "20px" }} gutter={[16, 16]} justify="start">
        <Col xs={12} sm={12} md={21}>
        </Col>
      </Row>
      <BCListFarms  actionsColumn={actionsColumn} agentId={id}/>
    </div>
  );
}
