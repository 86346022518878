import React from 'react'
import { ArrowRightOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";

import { useNavigate } from "react-router-dom";
import { Status, StatusContainer } from "./PendingActivityStyles";

import { useTranslation } from "react-i18next";

export const ListPendingActivity = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const columns = [
    {
      title: t("Category"),
      dataIndex: "category",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return <span>{data.category}</span>;
      },
    },
    {
      title: t("Description"),
      dataIndex: "description",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return <span>{data.description}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "start",
      sorter: true,
      render: (text, { status, statusClient, url }) => {
        console.log({ url });

        return (
          <StatusContainer onClick={() => navigate(url)}>
            <Status status={status}>{statusClient}</Status>
            <ArrowRightOutlined
              style={{ fontSize: "1.25rem", color: "#e5e1da" }}
            ></ArrowRightOutlined>
          </StatusContainer>
        );
      },
    },
  ];

  const actions = [
    {
      title: t("Actions"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={
              () => {}
              // navigate(
              //     `/program-area/${record._id}/farmer/${record.identifier}/area`
              // )
            }
            icon={<EyeOutlined />}
          >
            {t("View")}
          </Button>
        </Space>
      ),
    },
  ];

  return {
    columns,
    actions,
  };
};
