import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BCCard } from "../../../components/BCCard/BCCard";
import BCTable from "../../../components/BCTable";
import { getOverviewAreasService, listFarmsService } from "../service";
import { AdapterData } from "./AdapterData";
import { ProgramAreaCollunmGrid } from "./ProgramAreaCollunmGrid";
import * as S from "./ProgramAreaStyles";

export const ProgramAreaPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { actions, columns } = ProgramAreaCollunmGrid(t, navigate);

  const [farms, setFarms] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [sortField, setSortField] = useState("name");
  const [loading, setLoading] = useState(true);

  const [overviewArea, setOverviewArea] = useState({
    agroforestry: {
      total: "0",
      total_pending: "0",
      total_rejected: "0",
      total_reissue: "0",
      total_review: "0",
      total_signed: "0",
      total_approved: "0",
    },
    reflorestation: {
      total: "0",
      total_pending: "0",
      total_rejected: "0",
      total_reissue: "0",
      total_review: "0",
      total_signed: "0",
      total_approved: "0",
    },
  });

  const loadOverview = useCallback(async () => {
    try {
      const responseOverview = await getOverviewAreasService();
      setOverviewArea(responseOverview);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const loadFarms = useCallback(async () => {
    try {
      setLoading(true);
      const _farms = await listFarmsService({
        page: search ? 1 : currentPage,
        search,
        sortOrder,
        sortField,
      });
      const farmerAdapted = AdapterData(_farms.result).listFarms();
      console.log(farmerAdapted);
      setTotal(_farms.count);
      setFarms(farmerAdapted);
      setLoading(false);
    } catch (error) {
      console.log(error);
      console.log("error load farmes");
    }
  }, [currentPage, search, sortOrder, sortField]);

  useEffect(() => {
    loadFarms();
  }, [currentPage, search, sortOrder, sortField]);

  useEffect(() => {
    loadOverview();
  }, []);

  return (
    <S.Container>
      <S.Title>{t("Areas_Program")}</S.Title>
      <S.WrapperBox>
        <BCCard title={t("AGROFORESTRY")} data={overviewArea.agroforestry} />
        <BCCard title={t("REFORESTATION")} data={overviewArea.reflorestation} />
      </S.WrapperBox>
      <S.Title>{t("Property_areas")}</S.Title>
      <S.WrapperTable>
        <BCTable
          data={farms}
          total={total}
          pageSize={pageSize}
          currentPage={currentPage}
          placeholderSearch={`${t("Search")} ${t("Farms")}`}
          setSearch={setSearch}
          setCurrentPage={setCurrentPage}
          setPageSize={setPageSize}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
          loading={loading}
          columns={columns}
          actionsColumn={actions}
          showCreateButton={false}
        />
      </S.WrapperTable>
    </S.Container>
  );
};
