import React from 'react'
import { useTranslation } from "react-i18next";
import * as S from "./CardTotalAreaAgroforestryStyles";

export const CardTotalAreaAgroforestry = ({
  totalAgroforestryEnrolled,
  totalAgroforestryImplemented,
}) => {
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.WrapperCountArea>
        <S.TitleCountArea>
          {Math.round(totalAgroforestryEnrolled)} ha
        </S.TitleCountArea>
      </S.WrapperCountArea>
      <S.LabelDescription>{t("Agroforestry_area")}</S.LabelDescription>
      <S.TotalAreaImplemented>
        vs {Math.round(totalAgroforestryImplemented)} {t("ha_implemented")}
      </S.TotalAreaImplemented>
    </S.Container>
  );
};
