import styled, { css } from "styled-components";

import { Input as AntInput, Col } from "antd";

export const RecipientCard = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  padding: 0.5rem 4px;
  padding-right: 12px;
  margin-bottom: 0.5rem;

  border-radius: 4px;

  transition: all 0.25s;

  &:hover {
    cursor: pointer;
    background-color: rgba(71, 52, 46, 0.2);
  }

  ${(props) =>
    props.selected &&
    css`
      background-color: rgba(80, 175, 73, 0.15);

      .selected_icon {
        color: #1f9652;
      }

      .selected_recipient {
        color: #1f9652;
      }
    `};
`;

export const Name = styled.span`
  margin-left: 1rem;

  font-size: 14px;
  color: #47342e;
`;

export const Input = styled(AntInput)`
  background-color: transparent !important;

  border-top: 0px;
  border-left: 0px;
  border-right: 0px;

  border-radius: 0px;
`;

export const Container = styled.div`
  opacity: 0.5;
  cursor: not-allowed;

  display: flex;

  p {
    margin-left: 1rem;
  }

  input {
    cursor: not-allowed;

    accent-color: #1f9652;

    width: 20px;
    height: 20px;
    padding: 1rem;
  }

  margin-bottom: 1rem;
`;

export const RightSide = styled(Col)`
  background-color: white;
  padding: 30px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
`;

export const CheckboxContainer = styled.div``;
