import { Space } from "antd";
import styled from "styled-components";

export const Container = styled.div``;

export const WrapperCard = styled.div`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
`;
