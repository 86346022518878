import React from "react";
import Base from "./Base/base.js";

const BaseLayout = ({ children, breadcrumb, gotoback = null }) => {
  return (
    <Base breadcrumb={breadcrumb} gotoback={gotoback}>
      {children}
    </Base>
  );
};

export default BaseLayout;
