import styled from "styled-components";
import { FaCloudUploadAlt } from "react-icons/fa";

export const Container = styled.div`
  width: 100%;
  border: 1px solid #3331;
  background-color: #f6f6f6;
  border-radius: 8px;
  box-shadow: 1px 1px 10px #3332;
  margin-top: 20px;
  padding: 20px;
`;

export const ListDocuments = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
`;

export const Title = styled.h3`
  color: #47342e;
  font-size: 18px;
  font-family: sans-serif;
  font-weight: 500;
`;

export const WrapperTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;

export const InputFile = styled.input``;

export const ButtonUpload = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 20px;
  border-color: transparent;
  margin-left: 20px;
  background-color: #1f9652;
  color: #ffffff;
  padding: 0 10px;
`;

export const Icon = styled(FaCloudUploadAlt)`
    font-size: 16px;
`;
