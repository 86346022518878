import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BCGraphic } from "../../../components/BCGraphic";
import { getEnrolledVsImplementedTop10 } from "../service";
import * as S from "./WrapperGraphicStyles";

export const WrapperGraphic = () => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    loadDataGraphic();
  }, []);

  const loadDataGraphic = async () => {
    try {
      const response = await getEnrolledVsImplementedTop10();

      if (!response) return;

      const serializerDataGraphic = [];

      response.forEach((item) => {
        serializerDataGraphic.push({
          period: item.name,
          value: item.farmers_enrolled,
          name: t("Enrolled_farmers"),
        });
        serializerDataGraphic.push({
          period: item.name,
          value: item.total_implemented_ha,
          name: t("Implemented_farmers"),
        });
      });

      setData(serializerDataGraphic);
    } catch (error) {}
  };
  return (
    <S.Container>
      <BCGraphic data={data} title={t("rate_of_top_10_field_agents")} />
    </S.Container>
  );
};
