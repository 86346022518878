import { useCallback, useEffect, useState } from "react";
import { FieldsTemplateAdditionalInformation } from "./FieldsTemplateAdditionalInformation";
import { findByIdFarm } from "../service";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const StatusProgramType = Object.freeze({
  APPROVED: "APPROVED",
  PENDING: "PENDING",
});

export const useAdditionalInformation = () => {
  const { id } = useParams();
  const {t} = useTranslation()
  const { InitialFields, InitialSteps } = FieldsTemplateAdditionalInformation();
  const [additionalInformation, setAdditionalInformation] =
    useState(InitialFields);
  const [steps, setSteps] = useState(InitialSteps);
  const [farm, setFarm] = useState();

  useEffect(() => {
    loadFarm();
  }, []);
  const getFieldUpdatedValue = (field, farm) => {
    const { field: fieldName } = field;

    if (fieldName === "birth_date") {
      const formattedDate = new Date(
        farm.farmer?.birth_date
      ).toLocaleDateString();
      return formattedDate || "";
    }

    if (fieldName === "total_area") {
      return `${parseFloat(farm[fieldName]).toFixed(2)} Ha`;
    }

    if (
      ["forest_area", "agriculture_area", "pasture_area"].includes(fieldName)
    ) {
      if (!farm.production_information[fieldName]) return "0.00 Ha";
      return `${parseFloat(farm.production_information[fieldName]).toFixed(
        2
      )} Ha`;
    }

    if (typeof farm[fieldName] === "number") {
      return String(farm[fieldName]);
    }

    if (typeof farm.production_information[fieldName] === "number") {
      return String(farm.production_information[fieldName]);
    }

    if(fieldName == 'farm_name'){
      return farm?.name;
    }

    if(fieldName == 'name') return farm?.farmer?.name

    if(fieldName == 'marital_status'){
      return t(farm?.farmer?.marital_status)
    }

    return (
      farm?.[fieldName]||
      farm.farmer?.[fieldName] ||
      farm.production_information[fieldName] ||
      ""
    );
  };

  const loadFarm = useCallback(async () => {
    try {
      if (!id) {
        return;
      }

      const farmResponse = await findByIdFarm({ id });
      
      const { result: [farm = {}] = [] } = farmResponse;
      
      if (!Object.keys(farm).length) {
        return;
      }
      setFarm(farm)

      const updatedFields = additionalInformation.map((session) => {
        const { title, fields } = session;

        if (title === 'title_information_personal') {
          return {
            ...session,
            fields: fields.map((field) => ({
              ...field,
              value: getFieldUpdatedValue(field, farm),
            })),
          };
        }

        if (title === 'title_information_property') {
          return {
            ...session,
            fields: fields.map((field) => {
              const { field: fieldName } = field;

              if (fieldName === "main_field_agent") {
                const mainAgent = farm.agents?.find(
                  (agent) => agent.ordering === 1
                );
                return {
                  ...field,
                  value: mainAgent?.info?.name || "",
                };
              }

              return {
                ...field,
                value: getFieldUpdatedValue(field, farm),
              };
            }),
          };
        }

        if (title === "title_information_production") {
          return {
            ...session,
            fields: fields.map((field) => ({
              ...field,
              value: getFieldUpdatedValue(field, farm),
            })),
          };
        }

        return session;
      });

      setAdditionalInformation(updatedFields);
      handleStageStep(farm);
    } catch (error) {
      console.error("Erro ao carregar a fazenda:", error);
    }
  }, [id, additionalInformation]);

  const handleStageStep = (farm) => {
    if (farm.program.length === 0) return;
    const myProgram = farm.program[0];

    const {
      program: { rules },
    } = myProgram;

    const draft_steps = steps.map((step) => {
      switch (step.id) {
        case 1:
          step.status = StatusProgramType.APPROVED;
          return step;
        case 2:
          step.status = rules[1].status;
          return step;
        case 3:
          step.status = rules[2].rules[0].status;
          return step;
        case 4:
          step.status = rules[2].rules[2].status;
          return step;
        case 5:
          step.status = rules[3].status;
          return step;
        case 6:
          step.status = farm.farmer.has_package
            ? StatusProgramType.APPROVED
            : StatusProgramType.PENDING;
          return step;
        default:
          return step;
      }
    });

    setSteps(draft_steps);
  };

  return {
    steps,
    additionalInformation,
    farm,
    loadFarm
  };
};
