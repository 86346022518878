import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  Row,
  Select,
} from "antd";
import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import BCButtonConfirm from "../../components/BCButtonConfirm";
import BCFlagSelector from "../../components/BCFlagSelector";
import { phoneMaskBR } from "../../utils/mask";
import { createAgent, getOrganizations, getRegions } from "./service";
const { Option } = Select;
const { Meta } = Card;

function AdministrationCreateAgent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);
  const [regions, setPermissions] = useState([]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("Invalid_Email"))
      .required(t("Email_is_required")),
    phone: Yup.string().required(t("Phone_Is_Required")),
    name: Yup.string().required(t("Name_is_Required")),
    organizationId: Yup.string().required(t("OrganizationId_Is_Required")),
    regions: Yup.array()
      .required(t("Regions_is_required"))
      .min(1, t("Regions_is_required")),
  });

  const findOrganizations = useCallback(async () => {
    const result = await getOrganizations();
    setOrganizations(result);
  }, []);

  const findRegions = useCallback(async () => {
    const result = await getRegions();
    setPermissions(result.result);
  }, []);

  useEffect(() => {
    findOrganizations();
    findRegions();
  }, [findOrganizations, findRegions]);

  async function createUser(values) {
    await createAgent(
      values.name,
      values.email,
      `${values.phoneCode} ${values.phone}`,
      values.organizationId,
      values.regions
    );
    navigate("/administration-users/list-agents");
  }

  return (
    <Formik
      initialValues={{
        email: "",
        name: "",
        phone: "",
        organizationId: "",
        regions: [],
        phoneCode: "+55",
      }}
      validationSchema={validationSchema}
      onSubmit={createUser}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
      }) => (
        <Form layout="vertical" onFinish={handleSubmit}>
          {/* <h1 style={{ marginTop: "20px" }}>{t("Text_new_admin")}</h1> */}
          <Meta
            avatar={
              <Avatar
                style={{ backgroundColor: "green", color: "white" }}
                icon={<UserOutlined />}
              />
            }
            title={t("Invite_a_new_agent")}
            description={t("Text_new_agent")}
          />
          <Row style={{ marginTop: "30px" }} gutter={16}>
            <Col xs={24} sm={6}>
              <Row gutter={16}>
                <Col xs={24} sm={24}>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={touched.name && errors.name ? errors.name : ""}
                        validateStatus={
                          touched.name && errors.name ? "error" : ""
                        }
                      >
                        <Input
                          name="name"
                          className="input"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Name")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={touched.email && errors.email ? errors.email : ""}
                        validateStatus={
                          touched.email && errors.email ? "error" : ""
                        }
                      >
                        <Input
                          prefix={<MailOutlined />}
                          name="email"
                          className="input"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Email")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={touched.phone && errors.phone ? errors.phone : ""}
                        validateStatus={
                          touched.phone && errors.phone ? "error" : ""
                        }
                      >
                        <Input
                          addonBefore={
                            <BCFlagSelector
                              selected={values.phoneCode}
                              onSelect={(value) =>
                                handleChange({
                                  target: { name: "phoneCode", value },
                                })
                              }
                            />
                          }
                          name="phone"
                          className="input"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Phone")}
                          onKeyUp={(event) => phoneMaskBR(event)}
                          prefix={<PhoneOutlined />}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} md={24}>
                      <Form.Item
                        help={
                          touched.organizationId &&
                          errors.organizationId &&
                          errors.organizationId
                            ? errors.organizationId
                            : ""
                        }
                        validateStatus={
                          touched.organizationId && errors.organizationId
                            ? "error"
                            : ""
                        }
                      >
                        <Select
                          style={{ width: "100%" }}
                          name="organizationId"
                          value={values.organizationId}
                          onChange={(value) =>
                            handleChange({
                              target: { name: "organizationId", value },
                            })
                          }
                          onBlur={handleBlur}
                          placeholder={t("Organizations")}
                        >
                          <Option value="" disabled>
                            {t("Organizations")}
                          </Option>
                          {organizations.map((x) => (
                            <Option value={x._id} key={x._id}>
                              {x.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={6}>
              <Form.Item
                label={t("Choose_regions")}
                help={touched.regions && errors.regions ? errors.regions : ""}
                validateStatus={
                  touched.regions && errors.regions ? "error" : ""
                }
              >
                <Checkbox.Group
                  name="regions"
                  value={values.regions}
                  onChange={(checkedValues) =>
                    handleChange({
                      target: { name: "regions", value: checkedValues },
                    })
                  }
                >
                  <List
                    dataSource={regions}
                    renderItem={(region) => (
                      <List.Item>
                        <Checkbox
                          value={region._id}
                          style={{
                            "--checkbox-border-color": values.regions.includes(
                              region._id
                            )
                              ? "green"
                              : "",
                          }}
                        >
                          {region.name}
                        </Checkbox>
                      </List.Item>
                    )}
                  />
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <BCButtonConfirm
              style={{ width: "100%", maxWidth: "200px", marginTop: "20px" }}
              buttonText={t("Send")}
            ></BCButtonConfirm>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}

export default AdministrationCreateAgent;
