import styled, { css } from "styled-components";

import {
  statusWithBlueColor,
  statusWithGrayColor,
  statusWithGreenColor,
  statusWithRedColor,
  statusWithYellowColor,
} from "../../utils/statusWithColor";

export const WrapperTable = styled.div`
  background-color: #fff;
  padding: 18px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
`;

export const Status = styled.p`
  margin: 0 !important;

  ${(props) =>
    statusWithYellowColor.includes(props.status) &&
    css`
      color: #ffbc00;
    `}

  ${(props) =>
    statusWithRedColor.includes(props.status) &&
    css`
      color: #e74c3c;
    `}

  ${(props) =>
    statusWithGreenColor.includes(props.status) &&
    css`
      color: #1f9652;
    `}

  ${(props) =>
    statusWithGrayColor.includes(props.status) &&
    css`
      color: #b2bec3;
    `}

  ${(props) =>
    statusWithBlueColor.includes(props.status) &&
    css`
      color: #399ed8;
    `}
`;
