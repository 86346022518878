import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  margin-top: 10px;
  flex-wrap: wrap;
`;

export const WrapperInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  min-width: 467px;
  padding: 10px 10px 10px 0px;

  @media screen and (max-width: 1503px) {
    width: none;
    max-width: none;
    flex:1;
  }
`;

export const WrapperBoxCountArea = styled.div`
  display: flex;
  flex: 1;
`;

export const WrapperGraphic = styled.div`
  width: 60%;
  margin: 10px 0px;

  @media screen and (max-width: 1503px) {
    width: none;
    max-width: none;
    flex:1;
  }
  
`;
