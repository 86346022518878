import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

import { Button, Card, Col, Collapse, Row, Tag, Timeline } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ITEMS_HISTORY_STATUS,
  ITEMS_STATUS,
} from "../../Enums/ActionsPlans.enum";
import { listActionsPlans } from "./service";
import { isSafeURL } from "../../utils/isSafeURL";

const { Panel } = Collapse;

export default function ActionPlanViewItem() {
  const navigate = useNavigate();
  const { id, item_id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    handleLinkClick(window.location.origin);
  }, []);

  const [actionsPlan, setActionPlan] = useState({
    farmer: {
      name: "",
      identifier: "",
    },
    farm: {
      city: "",
      state: "",
    },
    agent_name: "",
    checklist_name: "",
    items: [
      {
        actions: [],
      },
    ],
  });

  const [item, setItem] = useState({
    acceptedAt: new Date(),
    item_title: "",
    status: "",
    history: [
      {
        user_type: "",
        createdAt: new Date(),
        message: "",
        status: "",
        evidence: "",
      },
    ],
    childrens: [],
  });

  const handleLinkClick = (url) => {
    if (!isSafeURL(url)) navigate("/action_plans", { replace: true });
  };

  const findActionPlans = useCallback(async () => {
    const result = await listActionsPlans({
      id: id,
    });
    setActionPlan(result.result[0]);
    await Promise.all(
      result.result[0].items.map(async (a) => {
        a.actions.map(async (ai) => {
          if (ai.id === parseInt(item_id)) {
            const childrens = [];
            ai.history.map(async (h) => {
              let color =
                h.status === ITEMS_HISTORY_STATUS.APPROVED ? "green" : "blue";
              childrens.push({
                color: color,
                children: (
                  <>
                    <div>
                      {h.user_type === "farmer" ? (
                        <span style={{ fontSize: "15px" }}>
                          {t("FarmerResponse")}
                        </span>
                      ) : (
                        <>
                          {h.status === ITEMS_HISTORY_STATUS.APPROVED && (
                            <span style={{ fontSize: "15px" }}>
                              {t("Approved_Per_Agent")}
                            </span>
                          )}
                          {h.status === ITEMS_HISTORY_STATUS.REVIEW && (
                            <span style={{ fontSize: "15px" }}>
                              {t("REVIEW")}
                            </span>
                          )}
                          {h.status === ITEMS_HISTORY_STATUS.REJECTED && (
                            <span style={{ fontSize: "15px" }}>
                              {t("Rejected__Per_Agent")}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                    <span>{new Date(h.createdAt).toLocaleDateString()}</span>
                    <br />
                    <span>{h.message}</span>
                    <br />
                    {h.evidence && (
                      <div>
                        <a
                          href={h.evidence}
                          title="Link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("View_Document")} <FileTextOutlined />
                        </a>
                      </div>
                    )}
                  </>
                ),
              });
            });
            ai.childrens = childrens;
            setItem(ai);
          }
        });
      })
    );
  }, [id, item_id, t]);

  useEffect(() => {
    findActionPlans();
  }, [findActionPlans]);

  const responseStatus = () => {
    const style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "160px",
      height: "40px",
      borderRadius: "20px",
      fontSize: "20px",
    };
    if (item.status === ITEMS_STATUS.PENDING) {
      return (
        <Tag style={style} icon={<ClockCircleOutlined spin />} color="warning">
          {t("PENDING")}
        </Tag>
      );
    }
    if (item.status === ITEMS_STATUS.REVIEW) {
      return <Tag>{t("REVIEW")}</Tag>;
    }

    if (item.status === ITEMS_STATUS.RESOLVED) {
      return (
        <Tag style={style} icon={<CheckCircleOutlined />} color="success">
          {t("RESOLVED")}
        </Tag>
      );
    }

    if (item.status === ITEMS_STATUS.REJECTED) {
      return (
        <Tag style={style} icon={<CloseCircleOutlined />} color="error">
          {t("REJECTED")}
        </Tag>
      );
    }
  };

  return (
    <div>
      <Link to={`/action_plans/${id}/view`}>
        <Button
          type="dashed"
          icon={<ArrowLeftOutlined />} // Adicione o ícone aqui
          shape="round"
          style={{
            width: "100%",
            maxWidth: "200px",
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1,
          }}
        >
          {t("Gotoback")}
        </Button>
      </Link>
      <Row style={{ marginTop: "40px", width: "100%", height: "100vh" }}>
        <Col span={6}>
          <Card
            style={{
              backgroundColor: "#f6f6f6",
              height: "100%",
              borderRadius: 0,
            }}
          >
            <Row style={{ marginTop: "10px" }}>
              <div>{responseStatus()}</div>
            </Row>
            <Row style={{ marginTop: "40px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ color: "black", fontSize: "20px" }}>
                  {item.item_title}
                </div>
              </div>
            </Row>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: "15px", marginTop: "20px" }}>
                {t("Created_in")}{" "}
                {new Date(item.acceptedAt).toLocaleDateString()}
              </div>
            </Row>
          </Card>
        </Col>
        <Col span={18}>
          <Card style={{ height: "100%", borderRadius: 0 }}>
            <Timeline items={item.childrens.reverse()}></Timeline>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
