import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Form, Input, Row, Select, Switch } from "antd";
import { Formik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import BCButtonConfirm from "../../components/BCButtonConfirm";
import BCFlagSelector from "../../components/BCFlagSelector";
import { AuthContext } from "../../contexts/auth";
import { phoneMaskBR } from "../../utils/mask";
import { createSalesman, viewSuppliers } from "./service";

const { Option } = Select;
const { Meta } = Card;

function SuppliersCreateSalesman() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [suppliers, setSuppliers] = useState({});

  const findSuppliers = useCallback(async () => {
    const result = await viewSuppliers({
      id: id,
    });

    setSuppliers(result);
  }, [id]);

  useEffect(() => {
    findSuppliers();
  }, [findSuppliers]);

  const validationSchema = Yup.object().shape({
    vendor_name: Yup.string(),
    name: Yup.string().required(t("Name_is_Required")),
    email: Yup.string()
      .email(t("Invalid_Email"))
      .required(t("Email_is_required")),
    phone: Yup.string().required(t("Phone_Is_Required")),
    hasFullControl: Yup.boolean().default(false),
    cpf_cnpj: Yup.string(),
  });

  async function createUser(values) {
    await createSalesman(
      id,
      values.name,
      values.email,
      values.phone,
      values.hasFullControl,
      values.cpf_cnpj
    );
    navigate("/suppliers");
  }

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        phone: "",
        phoneCode: "+55",
        hasFullControl: false,
        cpf_cnpj: "",
      }}
      validationSchema={validationSchema}
      onSubmit={createUser}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
      }) => (
        <Form layout="vertical" onFinish={handleSubmit}>
          <Meta
            avatar={
              <Avatar
                style={{ backgroundColor: "green", color: "white" }}
                icon={<UserOutlined />}
              />
            }
            title={t("Invite_a_new_salesman")}
            description={t("Text_new_salesman")}
          />
          <Row style={{ marginTop: "30px" }} gutter={16}>
            <Col span={7}>
              <Row gutter={16}>
                <Col xs={24} sm={24}>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={
                          touched.vendor_name && errors.vendor_name
                            ? errors.vendor_name
                            : ""
                        }
                        validateStatus={
                          touched.vendor_name && errors.vendor_name
                            ? "error"
                            : ""
                        }
                      >
                        <Input
                          name="name"
                          className="input"
                          value={suppliers.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Name")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={touched.name && errors.name ? errors.name : ""}
                        validateStatus={
                          touched.name && errors.name ? "error" : ""
                        }
                      >
                        <Input
                          name="name"
                          className="input"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Employee_name")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={touched.email && errors.email ? errors.email : ""}
                        validateStatus={
                          touched.email && errors.email ? "error" : ""
                        }
                      >
                        <Input
                          prefix={<MailOutlined />}
                          name="email"
                          className="input"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Employee_email")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={
                          touched.cpf_cnpj && errors.cpf_cnpj
                            ? errors.cpf_cnpj
                            : ""
                        }
                        validateStatus={
                          touched.cpf_cnpj && errors.cpf_cnpj ? "error" : ""
                        }
                      >
                        <Input
                          name="cpf_cnpj"
                          className="input"
                          value={values.cpf_cnpj}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("cpf_cnpj")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={touched.phone && errors.phone ? errors.phone : ""}
                        validateStatus={
                          touched.phone && errors.phone ? "error" : ""
                        }
                      >
                        <Input
                          addonBefore={
                            <BCFlagSelector
                              selected={values.phoneCode}
                              onSelect={(value) =>
                                handleChange({
                                  target: { name: "phoneCode", value },
                                })
                              }
                            />
                          }
                          name="phone"
                          className="input"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Employee_phone")}
                          onKeyUp={(event) => phoneMaskBR(event)}
                          prefix={<PhoneOutlined />}
                        />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <span>{t("has_Full_Control")}</span>
                          <span style={{ marginLeft: "8px" }}></span>
                          <Switch
                            name="hasFullControl"
                            style={{
                              backgroundColor: values.hasFullControl
                                ? "rgb(31, 150, 82)"
                                : "",
                            }}
                            checked={values.hasFullControl}
                            onChange={(checked) =>
                              handleChange({
                                target: {
                                  name: "hasFullControl",
                                  value: checked,
                                },
                              })
                            }
                          />
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Form.Item>
            <BCButtonConfirm
              style={{ width: "100%", maxWidth: "200px", marginTop: "20px" }}
              buttonText={t("Send")}
            ></BCButtonConfirm>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}

export default SuppliersCreateSalesman;
