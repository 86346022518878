import { LockOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import BCButtonConfirm from "../../components/BCButtonConfirm";
import { AuthContext } from "../../contexts/auth";
import { ChangePassService } from "./service";

export default function UserChangePass() {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    if (id !== user.user._id) {
      logout();
    }
  }, [id, user, logout]);

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(t("Pass_is_required")),
    password: Yup.string()
      .min(8, t("Pass_Least8"))
      .matches(/[A-Z]/, t("Pass_Should_Contain_At_Least_1_Uppercase"))
      .matches(/[a-z]/, t("Pass_Should_Contain_At_Least_1_Lowercase"))
      .matches(/[0-9]/, t("Pass_Should_Contain_At_Least_1_Number"))
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        t("Pass_Should_Contain_At_Least_1_Special_Character")
      )
      .required(t("Pass_is_required")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("Pass_must_match"))
      .required(t("Confirm_Pass_is_required")),
  });

  async function ChangePassPerfil(values) {
    try {
      await ChangePassService(
        values.currentPassword,
        values.password,
        values.confirmPassword
      );
      navigate("/dashboard");
    } catch (error) {
      console.log("AQUI", error);
    }
  }

  return (
    <div>
      <Formik
        initialValues={{}}
        validationSchema={validationSchema}
        onSubmit={ChangePassPerfil}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
        }) => (
          <Form layout="vertical" onSubmitCapture={handleSubmit}>
            <Form.Item
              help={touched.currentPassword && errors.currentPassword ? errors.currentPassword : ""}
              validateStatus={
                touched.currentPassword && errors.currentPassword ? "error" : ""
              }
            >
              <Input.Password
                prefix={<LockOutlined />}
                name="currentPassword"
                value={values.currentPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                className="input"
                placeholder={t("Current_Pass")}
                style={{ width: "25%" }}
              />
            </Form.Item>
            <Form.Item
              help={touched.password && errors.password ? errors.password : ""}
              validateStatus={
                touched.password && errors.password ? "error" : ""
              }
            >
              <Input.Password
                prefix={<LockOutlined />}
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                className="input"
                placeholder={t("Pass")}
                style={{ width: "25%" }}
              />
            </Form.Item>
            <Form.Item
              help={
                touched.confirmPassword && errors.confirmPassword
                  ? errors.confirmPassword
                  : ""
              }
              validateStatus={
                touched.confirmPassword && errors.confirmPassword ? "error" : ""
              }
            >
              <Input.Password
                prefix={<LockOutlined />}
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                className="input"
                placeholder={t("Confirm_Pass")}
                style={{ width: "25%" }}
              />
            </Form.Item>
            <Form.Item>
              <BCButtonConfirm
                style={{ width: "100%", maxWidth: "200px" }}
                buttonText={t("Change")}
              ></BCButtonConfirm>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
}
