import {
  CheckCircleOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Calendar,
  Card,
  Col,
  Collapse,
  Descriptions,
  List,
  Modal,
  Row,
  Tag,
} from "antd";
import {
  endOfMonth,
  format,
  getDate,
  getMonth,
  parse,
  parseISO,
  startOfMonth,
} from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { list } from "./service";
import "./styles.css";
const { Meta } = Card;
const { Panel } = Collapse;

export default function CalendarDashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { farmerId } = useParams();

  const [startDay, setStartDay] = useState(
    format(startOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [endDay, setEndDay] = useState(
    format(endOfMonth(new Date()), "yyyy-MM-dd")
  );

  const [openModal, setOpenModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [dataSelected, setDataSelect] = useState(
    new Date().toLocaleDateString()
  );
  const [eventsForModal, setEventsForModal] = useState([
    {
      id: "",
      title: "",
      type: "",
      day: "",
      status: "",
      destinations: [
        {
          farmer: {},
        },
      ],
      description: "",
      owner: {},
      image: "",
      createdAt: new Date(),
      hour: "",
      period: "",
      address: "",
      city: "",
      state: "",
      date: new Date(),
    },
  ]);

  const [eventsDay, setEventsDay] = useState([
    {
      id: "",
      title: "",
      type: "",
      day: "",
      status: "",
      destinations: [
        {
          farmer: {},
        },
      ],
      description: "",
      owner: {},
      image: "",
      createdAt: new Date(),
      hour: "",
      period: "",
      address: "",
      city: "",
      state: "",
      date: new Date(),
    },
  ]);

  const findEvents = useCallback(async () => {
    let result = await list({ dateFinal: endDay, dateInitial: startDay });

    if (farmerId) {
      result = result.filter(
        (e) =>
          e.event_owner_user_id === farmerId ||
          e.destinations.find((d) => d.destination_user_id === farmerId)
      );
    }

    setEvents(result);

    const format = "dd/MM/yyyy";
    const dateObject = parse(dataSelected, format, new Date());

    const eventsDay = [];
    result.forEach((e) => {
      if (getDate(parseISO(e.date)) === getDate(dateObject)) {
        eventsDay.push({
          id: e._id,
          day: getDate(dateObject),
          title: e.title,
          type: e.type_event.name,
          status: e.status,
          destinations: e.destinations,
          description: e.description,
          owner: e.owner,
          image: e.location,
          createdAt: e.createdAt,
          hour: e.hour,
          period: e.period,
          address: e.address,
          city: e.city,
          state: e.state,
          date: e.date,
        });
      }
    });

    setEventsDay(eventsDay);
  }, [endDay, startDay, dataSelected, farmerId]);

  useEffect(() => {
    findEvents();
  }, [findEvents]);

  const onPanelChange = async (value, mode) => {
    const month = value.month() + 1;
    const start = format(new Date(`${value.year()}-${month}-01`), "yyyy-MM-dd");
    const end = format(
      endOfMonth(new Date(`${value.year()}-${month}-15`)),
      "yyyy-MM-dd"
    );
    setStartDay(start);
    setEndDay(end);
  };

  const getListData = (value) => {
    let listData = [];
    const eventNow = events.filter(
      (e) =>
        getDate(parseISO(e.date)) === value.date() &&
        getMonth(parseISO(e.date)) === value.month()
    );
    if (eventNow.length > 0) {
      eventNow.forEach((e) => {
        const color = e.type_event.name === "WORKSHOP" ? "success" : "warning";
        listData.push({
          color: color,
          content: e.title,
        });
      });
    }

    return listData || [];
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Tag color={item.color}>{item.content}</Tag>
          </li>
        ))}
      </ul>
    );
  };

  const onSelect = (value) => {
    setDataSelect(
      new Date(
        `${value.year()}-${value.month() + 1}-${value.date()}`
      ).toLocaleDateString()
    );

    const daySelected = getDate(
      new Date(`${value.year()}-${value.month() + 1}-${value.date()}`)
    );
    const eventsDay = [];
    events.forEach((e) => {
      if (getDate(parseISO(e.date)) === daySelected) {
        eventsDay.push({
          id: e._id,
          day: daySelected,
          title: e.title,
          type: e.type_event.name,
        });
      }
    });

    setEventsDay(eventsDay);
  };

  const cellRender = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
  };

  const createEvent = () => {
    navigate("/calendar/create");
  };

  const editEvent = (id) => {
    navigate(`/calendar/${id}/edit`);
  };

  const verifyEvent = () => {
    navigate(`/calendar/verify`);
  };

  const moreInfos = (event) => {
    setOpenModal(true);
    setEventsForModal(event);
  };

  return (
    <div>
      <h1>{t("Calendar")}</h1>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Row gutter={[16, 16]}>
          <Col>
            <Button
              onClick={createEvent}
              shape="round"
              className="confirm_button"
              type="dashed"
              icon={<PlusOutlined />}
            >
              {t("Add_event")}
            </Button>
          </Col>
          <Col>
            <Button
              onClick={verifyEvent}
              shape="round"
              type="dashed"
              style={{ color: "white", backgroundColor: "#4682B4" }}
              icon={<CheckCircleOutlined />}
            >
              {t("Verify_Event")}
            </Button>
          </Col>
        </Row>
      </div>
      <Row style={{ marginTop: "15px", width: "100%", height: "100vh" }}>
        <Col span={18}>
          <Card style={{ height: "100%", borderRadius: 0 }}>
            <Calendar
              onSelect={onSelect}
              onPanelChange={onPanelChange}
              cellRender={cellRender}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <div style={{ marginRight: 10, color: "red" }}>
                <Tag color="success">{t("WORKSHOP")}</Tag>
              </div>
              <div style={{ color: "green" }}>
                <Tag color="warning">{t("TRAINING")}</Tag>
              </div>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            style={{
              backgroundColor: "#f6f6f6",
              height: "100%",
              borderRadius: 0,
            }}
          >
            <Meta
              title={
                <span style={{ fontSize: "24px" }}>{t("Day_Events")}</span>
              }
              description={<Tag color="blue">{dataSelected}</Tag>}
            />

            <List
              style={{
                marginTop: "20px",
                maxHeight: "750px",
                overflowY: "auto",
              }}
              dataSource={eventsDay}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        onClick={() => moreInfos(item)}
                        style={{
                          backgroundColor:
                            item.type === "WORKSHOP" ? "#52c41a" : "#faad14",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "24px",
                          width: "64px",
                          height: "64px",
                          cursor: "pointer",
                        }}
                      >
                        {item.day}
                      </Avatar>
                    }
                    title={
                      <div>
                        <a onClick={() => moreInfos(item)}>{item.title}</a>
                        {item.status === "CREATED" && (
                          <EditOutlined
                            onClick={() => editEvent(item.id)}
                            style={{
                              fontSize: "16px",
                              marginLeft: "10px",
                              verticalAlign: "middle",
                            }}
                          />
                        )}
                      </div>
                    }
                    description={
                      item.type === "WORKSHOP" ? "WORKSHOP" : "TRAINING"
                    }
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        centered
        open={openModal}
        width={1000}
        closable={false}
        footer={null}
        style={{ maxWidth: "100%", width: 1000 }}
      >
        <Card
          bordered={false}
          hoverable
          style={{ width: "100%" }}
          cover={
            eventsForModal.image ? (
              <img
                alt="example"
                src={eventsForModal.image}
                style={{ height: 300, objectFit: "cover" }}
              />
            ) : (
              <></>
            )
          }
        >
          <Descriptions title={t("Event_Data")}>
            <Descriptions.Item label={t("Title")}>
              {eventsForModal.title}
            </Descriptions.Item>
            <Descriptions.Item label={t("Description")}>
              {eventsForModal.description}
            </Descriptions.Item>
            <Descriptions.Item label={t("Type_event")}>
              {t(eventsForModal.type)}
            </Descriptions.Item>
            <Descriptions.Item label={`${t("Hour")} | ${t("Period")}`}>
              {eventsForModal.hour} {`${t(eventsForModal.period)}`}
            </Descriptions.Item>
            <Descriptions.Item label={t("Address")}>
              {eventsForModal.address}
            </Descriptions.Item>
            <Descriptions.Item label={`${t("City")} | ${t("State")}`}>
              {eventsForModal.city} - {eventsForModal.state}
            </Descriptions.Item>
            <Descriptions.Item label={t("Date")}>
              {new Date(eventsForModal.date).toLocaleDateString()}
            </Descriptions.Item>
          </Descriptions>
          <Collapse>
            <Panel header={t("Subscribers_to_the_Event")} key="1">
              <List
                itemLayout="horizontal"
                dataSource={eventsForModal.destinations}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      title={item?.farmer?.name}
                      description={item?.farmer?.identifier}
                    />
                  </List.Item>
                )}
              />
            </Panel>
          </Collapse>
          <div style={{ textAlign: "right", marginTop: "20px" }}>
            <Button onClick={() => setOpenModal(false)} key="back">
              {t("Close")}
            </Button>
          </div>
        </Card>
      </Modal>
    </div>
  );
}
