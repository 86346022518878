import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import * as S from "./BCBoxFilterAreaStyles";
import { BCFilterParams } from "./BCFiltersParams";
import { useLanguage } from "../../../contexts/languageContext";

export const BCBoxFilterArea = ({ onFilter }, ref) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  

  const [paramsFilter, setParamsFilter] = useState(BCFilterParams(t));

  useEffect(() => {
    setParamsFilter(BCFilterParams(t))
  }, [language])

  useEffect(() => {
    handleApplyFilter();
  }, [paramsFilter]);

  const handleApplyFilter = useCallback(() => {
    const convertedData = paramsFilter.map(({ field, options }) => ({
      field,
      value: options
        .filter((option) => option.checked)
        .map((option) => option.value),
    }));

    if (onFilter) onFilter(convertedData);
  }, []);

  const onChange = useCallback((filter) => {
    const params = paramsFilter.map((param) => {
      param.options = param.options.map((option) => {
        if (option.value === filter.value) {
          return { ...option, ...filter };
        }
        return option;
      });
      return param;
    });

    setParamsFilter(params);
  }, []);

  const Legend = {
    title: t("LengentTitle"),
    lengends: [
      { title: t("LengentAreaMapTitle"), color: "#ffbc00" },
      { title: t("LengentAreaMapTitle1"), color: "#00efd7" },
      { title: t("LengentAreaMapTitle2"), color: "#59ff4e" },
      { title: t("LengentAreaMapTitle4"), color: "#b264fc" },
      { title: t("LengentAreaMapTitle5"), color: "#3bd626" },
    ],
  };

  return (
    <S.Container>
      <S.Content>
        <S.Title>{t("see_on_map")}</S.Title>
        <S.ContentFilters>
          {paramsFilter.map((filter) => (
            <S.WrapperFilter>
              <S.TitleFilter>{filter.title}</S.TitleFilter>
              <S.WrapperCheckBox style={{ flexDirection: "column" }}>
                {filter.options.map((option) => (
                  <S.CheckboxCustom
                    style={{
                      "--checkbox-border-color": option.checked ? "green" : "",
                    }}
                    checked={option.checked}
                    onChange={(e) =>
                      onChange({
                        field: filter.field,
                        value: option.value,
                        checked: e.target.checked,
                      })
                    }
                  >
                    {option.label}
                  </S.CheckboxCustom>
                ))}
              </S.WrapperCheckBox>
            </S.WrapperFilter>
          ))}
        </S.ContentFilters>
        <S.ContentLegends>
          <S.TitleFilter>{Legend.title}</S.TitleFilter>
          {Legend.lengends.map((legend) => (
            <S.WrapperLegend>
              <S.BoxIndicator color={legend.color} />
              <S.TitleLegend>{legend.title}</S.TitleLegend>
            </S.WrapperLegend>
          ))}
        </S.ContentLegends>
      </S.Content>
    </S.Container>
  );
};
