import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Card, Col, Descriptions, Form, Input, Row, Tag } from "antd";
import { Formik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import BCButtonConfirm from "../../components/BCButtonConfirm";
import { AuthContext } from "../../contexts/auth";
import { phoneMaskBR, phoneMaskBRDefault } from "../../utils/mask";
import { editUserService, getUser } from "./service";

export default function UserPerfil() {
  const { user, logout } = useContext(AuthContext);
  const { t } = useTranslation();
  const { id } = useParams();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("Invalid_Email"))
      .required(t("Email_is_required")),
    phone: Yup.string().required(t("Phone_Is_Required")),
    name: Yup.string().required(t("Name_is_Required")),
  });

  const [initalValues, setInitialValues] = useState({
    email: user.user.email ?? "",
    phone: user.user.phone ? phoneMaskBRDefault(user.user.phone) : "",
    name: user.user.name ?? "",
  });

  const [infosHeader, setInfosHeader] = useState([
    {
      label: t("Name"),
      value: "",
      type: "label",
    },
    {
      label: t("Email"),
      value: "",
      type: "label",
    },
    {
      label: t("Phone"),
      value: "",
      type: "label",
    },
    {
      label: t("Organization"),
      value: "",
      type: "tag",
    },
    {
      label: t("Permissions"),
      value: "",
      type: "array",
    },
  ]);

  useEffect(() => {
    if (id !== user.user._id) {
      logout();
    }
  }, [id, user, logout]);

  async function editUser(values) {
    await editUserService(values.name, values.email, values.phone);
  }

  const findUser = useCallback(async () => {
    const data = await getUser();
    setInitialValues({
      email: data.data.email ?? "",
      phone: data.data.phone ? phoneMaskBRDefault(user.user.phone) : "",
      name: data.data.name ?? "",
    });
    setInfosHeader([
      {
        label: t("Name"),
        value: `${data.data.name}`,
        type: "label",
      },
      {
        label: t("Email"),
        value: data.data.email,
        type: "label",
      },
      {
        label: t("Phone"),
        value: phoneMaskBRDefault(data.data.phone),
        type: "label",
      },
      {
        label: t("Organization"),
        value: data.data.organization_name,
        type: "tag",
      },
      {
        label: t("Permissions"),
        value: data.data.permissions.map((obj) => obj.name).join(" | "),
        type: "label",
      },
    ]);
  }, [t, user.user.phone]);

  useEffect(() => {
    findUser();
  }, [findUser]);

  return (
    <div style={{ position: "relative" }}>
      <Row style={{ marginBottom: "20px" }}>
        <Col xs={24}>
          <Card className="card-no-border">
            <Row gutter={[16, 16]}>
              {/* <Col xs={24} md={6}> */}
                {/* <img
                  src="https://faro.edu.br/wp-content/uploads/2018/11/253206-saiba-aqui-o-que-voce-precisa-para-ser-um-administrador-de-empresas.jpg"
                  alt="Imagem"
                  style={{ width: "100%", height: "auto" }}
                /> */}
              {/* </Col> */}
              <Col xs={24} md={18}>
                <Descriptions title="Dados" layout="vertical">
                  {infosHeader.map((x) => (
                    <Descriptions.Item label={x.label}>
                      {(() => {
                        switch (x.type) {
                          case "tag":
                            return <Tag>{x.value}</Tag>;
                          case "outra opção":
                            return <span>Valor para outra opção</span>;
                          default:
                            return <span>{x.value}</span>;
                        }
                      })()}
                    </Descriptions.Item>
                  ))}
                </Descriptions>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Formik
        initialValues={initalValues}
        validationSchema={validationSchema}
        onSubmit={editUser}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
        }) => (
          <Form layout="vertical" onSubmitCapture={handleSubmit}>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  help={touched.name && errors.name ? errors.name : ""}
                  validateStatus={touched.name && errors.name ? "error" : ""}
                >
                  <Input
                    className="input"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("Name")}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  help={touched.email && errors.email ? errors.email : ""}
                  validateStatus={touched.email && errors.email ? "error" : ""}
                >
                  <Input
                    className="input"
                    prefix={<MailOutlined />}
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("Email")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  help={touched.phone && errors.phone ? errors.phone : ""}
                  validateStatus={touched.phone && errors.phone ? "error" : ""}
                >
                  <Input
                    className="input"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("Phone")}
                    onKeyUp={(event) => phoneMaskBR(event)}
                    prefix={<PhoneOutlined />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <BCButtonConfirm
                    style={{ width: "100%", maxWidth: "200px" }}
                    buttonText={t("Change")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}
