import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import { Button, Space, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CHECKLIST_STATUS } from "../../Enums/Checklist.enum";
import BCTable from "../../components/BCTable";
import { listChecklist } from "./service";

export default function ChecklistList() {
  const navigate = useNavigate();
  const [checklist, setChecklists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [sortField, setSortField] = useState("checklist_name");
  const [loading, setLoading] = useState(true);
  const { type } = useParams();
  const { t } = useTranslation();

  const findChecklist = useCallback(async () => {
    setLoading(true);

    const result = await listChecklist({
      limit: pageSize,
      page: currentPage,
      search: search,
      sortOrder: sortOrder,
      sortField: sortField,
      checklistType: type,
    });

    result.result.map((x) => (x.key = x._id));
    setChecklists(result.result);
    setTotal(result.count);
    setLoading(false);
  }, [pageSize, currentPage, search, sortOrder, sortField, type]);

  useEffect(() => {
    findChecklist();
  }, [findChecklist]);

  const onView = (key) => {
    navigate(`/checklists/${key}/view`);
  };

  const columns = [
    {
      title: t("Date"),
      dataIndex: "createdAt",
      align: "center",
      sorter: true,
      width: 150,
      render: (text, record) => (
        <span key={record.createdAt}>
          {record.createdAt
            ? new Date(record.createdAt).toLocaleDateString()
            : t("Never_accessed")}
        </span>
      ),
    },
    {
      title: t("Farmer"),
      dataIndex: "farmer.name",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return (
          <span>
            {data.farmer.name}
            <br />
            <Tag>{data.farmer.identifier}</Tag>
          </span>
        );
      },
    },
    {
      title: t("Farm"),
      dataIndex: "name",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return (
          <span>
            {data.farms.name}
            <br />
            <Tag>{data.farms.identifier}</Tag>
          </span>
        );
      },
    },
    {
      title: t("Agents"),
      dataIndex: "agent_name",
      align: "start",
    },
    {
      title: "Checklist",
      dataIndex: "checklist_name",
      align: "start",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "start",
      render: (text, data) => {
        let statusTag;
        switch (data.status) {
          case CHECKLIST_STATUS.COMPLETED:
            statusTag = (
              <Tag icon={<CheckCircleOutlined />} color="success">
                {t("COMPLETED")}
              </Tag>
            );
            break;
          case CHECKLIST_STATUS.EXPIRED:
            statusTag = (
              <Tag icon={<CloseCircleOutlined />} color="error">
                {t("EXPIRED")}
              </Tag>
            );
            break;
          case CHECKLIST_STATUS.PENDING:
            statusTag = (
              <Tag icon={<ClockCircleOutlined spin />} color="warning">
                {t("PENDING")}
              </Tag>
            );
            break;
          default:
            statusTag = <span>Status Desconhecido</span>;
        }
        return statusTag;
      },
    },
  ];

  const actionsColumn = [
    {
      title: t("Actions"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={() => onView(record.key)}
            icon={<EyeOutlined />}
          >
            {t("View")}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <BCTable
        data={checklist}
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        placeholderSearch={`${t("Search")} Checklists`}
        setSearch={setSearch}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        loading={loading}
        columns={columns}
        actionsColumn={actionsColumn}
        showCreateButton={false}
      />
    </div>
  );
}
