export const AdapterData = (data) => {
  const listFarms = () => {
    const transformedFarmAreas = data.map((farm) => {
      let totalAreaAgroforest = 0;
      let totalAreaReforestation = 0;

      farm.maps.program_area.forEach((programArea) => {
        if (programArea.area_type === "AGROFORESTRY") {
          totalAreaAgroforest += parseFloat(programArea.area_size);
        } else if (programArea.area_type === "REFORESTATION") {
          totalAreaReforestation += parseFloat(programArea.area_size);
        }
      });

      return {
        _id: farm._id,
        name: farm.farmer.name,
        identifier: farm.farmer.identifier,
        total_area_farm: `${parseFloat(farm.maps.farm_area.farm_size).toFixed(
          2
        )} Ha`,
        total_area_agroforest: `${totalAreaAgroforest.toFixed(2)} Ha`,
        total_area_reforestation: `${totalAreaReforestation.toFixed(2)} Ha`,
        status: farm.program[0].status,
      };
    });

    return transformedFarmAreas;
  };

  return {
    listFarms,
  };
};
