import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Form, Input, Row, Select } from "antd";
import { Formik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import BCButtonConfirm from "../../components/BCButtonConfirm";
import { AuthContext } from "../../contexts/auth";
import { phoneMaskBR } from "../../utils/mask";
import { editSupplier, listSalesman, viewSuppliers } from "./service";

const { Option } = Select;
const { Meta } = Card;

function SuppliersEdit() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [suppliers, setSuppliers] = useState({});
  const [salesmans, setSalesmans] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [selectedSalesman, setSelectedSalesman] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const validationSchema = console.log("a");
  Yup.object().shape({
    name: Yup.string().required(t("Name_is_Required")),
  });

  const findSuppliers = useCallback(async () => {
    const result = await viewSuppliers({
      id: id,
    });
    setSuppliers(result);
  }, [id]);

  useEffect(() => {
    findSuppliers();
  }, [findSuppliers]);

  const findSalesmans = useCallback(async () => {
    const result = await listSalesman(pageSize, currentPage, search, id);
    setSalesmans(result.data.result);
  }, [id, pageSize, currentPage, search]);

  useEffect(() => {
    findSalesmans();
  }, [findSalesmans]);

  const handleSalesmanChange = (value) => {
    const selectedSalesman = salesmans.find(
      (salesman) => salesman._id === value
    );
    setSelectedSalesman(selectedSalesman);
  };
  console.log("b");
  async function editSuppliers(values) {
    await editSupplier(id, values.name, selectedSalesman._id);
    navigate("/suppliers");
  }

  return (
    <Formik
      initialValues={{
        name: suppliers.name,
        salesmanName: "",
        email: "",
        phone: "",
      }}
      validationSchema={validationSchema}
      onSubmit={editSuppliers}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
      }) => (
        <Form layout="vertical" onFinish={handleSubmit}>
          <Meta
            avatar={
              <Avatar
                style={{ backgroundColor: "green", color: "white" }}
                icon={<UserOutlined />}
              />
            }
            title={t("Edit_suppliers")}
            description={t("Text_edit_suppliers")}
          />
          <Row style={{ marginTop: "30px" }} gutter={16}>
            <Col span={7}>
              <Row gutter={16}>
                <Col xs={24} sm={24}>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={touched.name && errors.name ? errors.name : ""}
                        validateStatus={
                          touched.name && errors.name ? "error" : ""
                        }
                      >
                        <Input
                          name="name"
                          className="input"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Name")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={
                          touched.salesmanName && errors.salesmanName
                            ? errors.salesmanName
                            : ""
                        }
                        validateStatus={
                          touched.salesmanName && errors.salesmanName
                            ? "error"
                            : ""
                        }
                      >
                        <Select
                          style={{ width: "100%" }}
                          name="salesmanName"
                          value={values.salesmanName}
                          onChange={(value) => {
                            handleChange("salesmanName")(value);
                            handleSalesmanChange(value);
                          }}
                          onBlur={handleBlur}
                          placeholder={t("Employee_name")}
                        >
                          <Option value="" disabled>
                            {t("Employee_name")}
                          </Option>
                          {salesmans.map((x) => (
                            <Option value={x._id} key={x._id}>
                              {x.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={touched.email && errors.email ? errors.email : ""}
                        validateStatus={
                          touched.email && errors.email ? "error" : ""
                        }
                      >
                        <Input
                          prefix={<MailOutlined />}
                          name="email"
                          className="input"
                          value={selectedSalesman.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Employee_email")}
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}></Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={touched.phone && errors.phone ? errors.phone : ""}
                        validateStatus={
                          touched.phone && errors.phone ? "error" : ""
                        }
                      >
                        <Input
                          name="phone"
                          className="input"
                          value={selectedSalesman.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Employee_phone")}
                          onKeyUp={(event) => phoneMaskBR(event)}
                          prefix={<PhoneOutlined />}
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Form.Item
                label={t(
                  "Importante! Ao alterar o e-mail do responsável, será enviado um novo convite para criação de senha, invalidando o e-mail utilizado anteriormente."
                )}
              ></Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <BCButtonConfirm
              style={{ width: "100%", maxWidth: "200px", marginTop: "20px" }}
              buttonText={t("Save_Updates")}
            ></BCButtonConfirm>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}

export default SuppliersEdit;
