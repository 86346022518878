import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { AREA_MAP } from "../../../../../Enums/AreaMap.enum";
import { PROGRAM_AREA_MAP_STATUS } from "../../../../../Enums/ProgramAreaMapStatus";
import { getFarmMapsById, signatureLink } from "../../../service";
import * as S from "./CadFarmAreaStyles";

const farm = {
  myAgent: {
    name: "",
  },
};

export const CardFarmArea = ({ farm }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [linkFarmArea, setLinkFarmArea] = useState("");

  useEffect(() => {
    handleSignatureLink();
  }, [farm]);

  const handleSignatureLink = async () => {
    try {
      if (!farm?.maps?.farm_area?.image_link) return;
      const response = await signatureLink(farm?.maps?.farm_area?.image_link);
      setLinkFarmArea(response);
    } catch (error) {
      console.warn("error handleSignatureLink", error);
    }
  };

  const [typesAres, setTypesAres] = useState({});

  const calculateAgroforestry = async (data = []) => {
    const result = data.reduce((previousValue, currentValue) => {
      const program = currentValue.programAreas.map((item) => ({
        area_size: item.area_size || 0,
        area_type: item.area_type,
        implemented_area: item.implemented_area || 0,
        status: item.status,
      }));
      const dataInitialReduce = {
        agroforest: {
          area_size: 0,
          implemented_area: 0,
        },
        reforestation: {
          area_size: 0,
          implemented_area: 0,
        },
      };
      program.forEach((element) => {
        if (
          element.area_type === AREA_MAP.AGROFORESTRY &&
          element.status === PROGRAM_AREA_MAP_STATUS.SIGNED
        ) {
          dataInitialReduce.agroforest.area_size =
            parseInt(previousValue?.agroforest?.area_size || 0) +
            parseInt(element.area_size);
          dataInitialReduce.agroforest.implemented_area =
            parseInt(previousValue?.agroforest?.implemented_area || 0) +
            parseInt(element.implemented_area);
        }
        if (
          element.area_type === AREA_MAP.REFORESTATION &&
          element.status === PROGRAM_AREA_MAP_STATUS.SIGNED
        ) {
          dataInitialReduce.reforestation.area_size =
            parseInt(previousValue?.reforestation?.area_size || 0) +
            parseInt(element.area_size);
          dataInitialReduce.reforestation.implemented_area =
            parseInt(previousValue?.reforestation?.implemented_area || 0) +
            parseInt(element.implemented_area);
        }
      });
      return dataInitialReduce;
    }, {});
    setTypesAres(result);
  };

  const loadMapsDetail = async () => {
    try {
      const result = await getFarmMapsById(farm._id);
      if (result.length > 0) {
        calculateAgroforestry(result);
      }
    } catch (error) {
      console.info("erro load maps detail", error);
    }
  };

  useEffect(() => {
    loadMapsDetail();
  }, [farm]);

  return (
    <S.Container
      hoverable
      onClick={() =>
        navigate(`/program-area/${farm._id ?? 123}/list-program-area-by-farm`)
      }
      loading={!linkFarmArea}
      cover={<S.ImageArea src={linkFarmArea} loading={true} />}
    >
      <S.BodyCar>
        <S.LabelDescriptionProgram>
          {t("AGROFORESTRY")}
        </S.LabelDescriptionProgram>
        <S.WrapperTotalInfoAreaProgram>
          <S.LabelInfoAreaProgram status="enrolled">
            <S.TotalAreaProgram status="enrolled">
              {typesAres?.agroforest?.implemented_area || 0} ha
            </S.TotalAreaProgram>{" "}
            {t("ENROLLED")}
          </S.LabelInfoAreaProgram>
          <S.LabelInfoAreaProgram status="implemented">
            <S.TotalAreaProgram status="implemented">
              {typesAres?.agroforest?.area_size || 0}
            </S.TotalAreaProgram>{" "}
            {t("ha_implemented")}
          </S.LabelInfoAreaProgram>
        </S.WrapperTotalInfoAreaProgram>

        <S.LabelDescriptionProgram>
          {t("REFORESTATION")}
        </S.LabelDescriptionProgram>
        <S.WrapperTotalInfoAreaProgram>
          <S.LabelInfoAreaProgram status="enrolled">
            <S.TotalAreaProgram status="enrolled">
              {typesAres?.reforestation?.implemented_area || 0} ha
            </S.TotalAreaProgram>{" "}
            {t("ENROLLED")}
          </S.LabelInfoAreaProgram>
          <S.LabelInfoAreaProgram status="implemented">
            <S.TotalAreaProgram status="implemented">
              {typesAres?.agroforest?.area_size || 0}
            </S.TotalAreaProgram>{" "}
            {t("ha_implemented")}
          </S.LabelInfoAreaProgram>
        </S.WrapperTotalInfoAreaProgram>
      </S.BodyCar>
    </S.Container>
  );
};
