import {
  CheckCircleOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Divider, List, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams,  } from "react-router-dom";
import { getUsers } from "./service";

export default function AdministrationViewAdmins() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [admin, setAdmin] = useState({});
  const navigate = useNavigate();

  const findUsers = useCallback(async () => {
    try{
      const result = await getUsers(id);
      setAdmin(result);
    }catch(error){
      navigate(-1)
    }
  }, [id]);

  useEffect(() => {
    findUsers();
  }, [findUsers]);


  return (
    <div>
      <div>
        <Button
          onClick={() => navigate(`/administration-users/${id}/edit`)}
          shape="round"
          className="confirm_button"
          type="dashed"
          icon={<PlusOutlined />}
        >
          {t("Edit_User")}
        </Button>
      </div>
      <Row style={{ marginTop: "40px", width: "100%", height: "100vh" }}>
        <Col span={6}>
          <Card
            style={{
              backgroundColor: "#f6f6f6",
              height: "100%",
              borderRadius: 0,
            }}
          >
            <Row></Row>

            <Row style={{ marginTop: "1px" }}>
              <div></div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <UserOutlined
                  style={{
                    color: "rgb(79, 175, 73)",
                    fontSize: "25px",
                    marginRight: "5px",
                    marginTop: "13px",
                  }}
                />
                <div
                  style={{
                    color: "rgb(79, 175, 73)",
                    fontSize: "25px",
                    marginTop: "10px",
                  }}
                >
                  {t("User_Profile")}
                </div>
              </div>
            </Row>

            <Row
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: "15px" }}></div>
              <div style={{ fontSize: "15px" }}></div>
              <div></div>
            </Row>
            <Divider />
            <Row>
              <div
                style={{
                  fontSize: "25px",
                  marginRight: "5px",
                  marginTop: "15px",
                }}
              >
                {admin.name}
              </div>
            </Row>

            <Row>
              <Col span={12} style={{ textAlign: "start" }}>
                <h3>{admin.organization_name}</h3>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={12} style={{ textAlign: "start" }}>
                <h3>{admin.email}</h3>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ textAlign: "start" }}>
                <h3>{admin.phone}</h3>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={18}>
          <Card style={{ height: "100%", borderRadius: 0 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ fontSize: "25px" }}>{t("Permissions")}</div>
            </div>
            <Divider />
            <Row>
              <Col span={12} style={{ textAlign: "start" }}>
                <List
                  dataSource={admin.permissions}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <div>
                            <CheckCircleOutlined
                              style={{ color: "green", marginRight: "5px" }}
                            />
                            {item.name}
                          </div>
                        }
                      />
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
