import { Card, Col, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OverviewGraphic } from "./OverviewGraphic";
import { OverviewOfProgramArea } from "./OverviewOfProgramAreas/OverviewOfProgramArea";
import { WrapperGraphic } from "./WrapperGraphic";
import { infosDash, overview } from "./service";

const Dashboard = () => {
  const navigate = useNavigate();

  const data = [
    { name: "Item 1", quantidade: 400 },
    { name: "Item 2", quantidade: 300 },
    { name: "Item 3", quantidade: 200 },
    { name: "Item 4", quantidade: 100 },
    { name: "Item 5", quantidade: 150 },
  ];

  const { t } = useTranslation();

  const [initialOverview, setInitialOverview] = useState({
    agentsPerRegions: [],
    countAgentsPerOrganizations: [],
  });

  const [infoDash, setInfoDash] = useState([
    {
      NAME: t("IN_PROGRESS"),
      COUNT: 0,
      COLOR: "white",
      TEXT_COLOR: "##ff9900",
    },
    {
      NAME: t("FLAGGED_FOR_REVIEW"),
      COUNT: 0,
      COLOR: "white",
      TEXT_COLOR: "black",
    },
    {
      NAME: t("APPLICATION_DENIED"),
      COUNT: 0,
      COLOR: "white",
      TEXT_COLOR: "black",
    },
    {
      NAME: t("ENROLLED_TO_THE_PROGRAM"),
      COUNT: 0,
      COLOR: "green",
      TEXT_COLOR: "white",
    },
  ]);

  const findRegionsAndOrganizations = useCallback(async () => {
    const result = await overview();
    setInitialOverview({
      agentsPerRegions: result.agentsPerRegions,
      countAgentsPerOrganizations: result.countAgentsPerOrganizations,
    });
  }, []);

  const findInfoDash = useCallback(async () => {
    const result = await infosDash();
    const infos = [
      {
        NAME: t("IN_PROGRESS"),
        COUNT: result.IN_PROGRESS,
        COLOR: "#fff",
        TEXT_LABE_COLOR: "#47342e",
        TEXT_COLOR: "#ff9900",
        ONCLICK: "/farmers/farmer/in-progress",
      },
      {
        NAME: t("FLAGGED_FOR_REVIEW"),
        COUNT: result.FLAGGED_FOR_REVIEW,
        COLOR: "#fff",
        TEXT_LABE_COLOR: "#47342e",
        TEXT_COLOR: "#49afaf",
        ONCLICK: "/farmers/farmer/flagged-for-review",
      },
      {
        NAME: t("APPLICATION_DENIED"),
        COUNT: result.APPLICATION_DENIED,
        COLOR: "#fff",
        TEXT_LABE_COLOR: "#47342e",
        TEXT_COLOR: "#f24343",
        ONCLICK: "/farmers/farmer/application-denied",
      },
      {
        NAME: t("ENROLLED_TO_THE_PROGRAM"),
        COUNT: result.ENROLLED_TO_THE_PROGRAM,
        COLOR: "rgb(79, 175, 73)",
        TEXT_LABE_COLOR: "#fff",
        TEXT_COLOR: "white",
        ONCLICK: "/farmers/farmer/enrolled-to-the-program",
      },
    ];
    setInfoDash(infos);
  }, [t]);

  useEffect(() => {
    findRegionsAndOrganizations();
    findInfoDash();
  }, [findRegionsAndOrganizations, findInfoDash]);

  return (
    <div>
      <h1>{t("FarmersView")}</h1>

      <Row style={{ marginTop: "10px" }} gutter={[16, 16]} justify="start">
        {infoDash.map((a, index) => {
          return (
            <Col key={index}>
              <Card
                onClick={() => navigate(a.ONCLICK)}
                style={{
                  cursor: "pointer",
                  height: "150px",
                  width: "280px",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: a.COLOR,
                  boxShadow: "1px 1px 10px #3332",
                  color: a.TEXT_COLOR,
                }}
              >
                <div className="card-content">
                  <h1
                    style={{
                      fontSize: "46px",
                      fontWeight: 300,
                      color: a.TEXT_COLOR,
                      display: "flex",
                    }}
                  >
                    {a.COUNT}
                  </h1>
                  <div
                    className="centered-content"
                    style={{ fontSize: "1.0rem", color: a.TEXT_LABE_COLOR }}
                  >
                    {a.NAME}
                  </div>
                </div>
              </Card>
            </Col>
          );
        })}
        {/* <Col xs={24} sm={12} md={6}>
          <Card className="custom-card">
            <div className="card-content">
              <div className="centered-content">Conteúdo do Card 1</div>
            </div>
          </Card>
        </Col> */}
      </Row>
      <h1 style={{ marginTop: 30 }}>{t("Overview_of_program_areas")}</h1>
      <OverviewOfProgramArea />

      <h1 style={{ marginTop: "30px" }}>{t("Overview_of_field_agents")}</h1>
      <Row style={{ marginTop: "20px" }} justify="start" gutter={[16, 16]}>
        <Row style={{ marginTop: "20px" }} gutter={[16, 16]}>
          {initialOverview.agentsPerRegions.map((a, index) => {
            return (
              <Col key={index}>
                <Card
                  style={{
                    cursor: "default",
                    height: "150px",
                    width: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#ffffff",
                    color: "#47342e",
                    border: "none",
                    boxShadow: "1px 1px 10px #3332",
                  }}
                >
                  <div className="card-content">
                    <h1
                      style={{
                        fontSize: "3rem",
                        fontWeight: 400,
                        display: "flex",
                      }}
                    >
                      {a.countAgentsPerRegion}
                    </h1>
                    <div
                      className="centered-content"
                      style={{ fontSize: "1.0rem", color: "#555555" }}
                    >
                      {a.region}
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
          {initialOverview.countAgentsPerOrganizations.map((a, index) => {
            return (
              <Col key={index}>
                <Card
                  style={{
                    cursor: "default",
                    height: "150px",
                    width: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#47342eb8",
                    color: "#fffaf0",
                    boxShadow: "1px 1px 10px #3332",
                  }}
                >
                  <div className="card-content">
                    <h1 style={{ fontSize: "3rem", fontWeight: 400 }}>
                      {a.countAgentsPerOrganization}
                    </h1>
                    <div
                      style={{ fontSize: "1rem" }}
                      className="centered-content"
                    >
                      {a.organization}
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
        <WrapperGraphic />
        <OverviewGraphic />
      </Row>
    </div>
  );
};

export default Dashboard;
