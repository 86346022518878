import { UserOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import BCListFarms from "../../components/utils/BCListFarms";

export default function AgentsList() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [editAlocation, setEditAlocation] = useState(false);

  useEffect(() => {
    setEditAlocation(!location.pathname.includes("/agents/define-list"));
  }, [location.pathname]);

  const definiAgent = (key) => {
    if (editAlocation) navigate(`/agents/${key}/edit-field-agents`);
    else navigate(`/agents/${key}/define-field-agents`);
  };

  const actionsColumn = [
    {
      title: t("Actions"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            className="confirm_button"
            type="dashed"
            onClick={() => definiAgent(record.key)}
            icon={<UserOutlined />}
          >
            {editAlocation ? t("Reset_field_agent") : t("Define_field_agents")}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <BCListFarms
        actionsColumn={actionsColumn}
        needAgents={true}
        delete_items={[]}
        withProgram={false}
        no_agents={location.pathname.includes("/agents/define-list")}
        only_with_agents={!location.pathname.includes("/agents/define-list")}
      ></BCListFarms>
    </div>
  );
}
