import React from 'react'
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import BaseLayout from "../../pages/BaseLayout";
import CalendarCreateOrEditEvent from "../../pages/Calendar/CalendarCreateOrEditEvent";
import CalendarDashboard from "../../pages/Calendar/CalendarDashboard";
import CalendarVerify from "../../pages/Calendar/CalendarVerify";
import Private from "../Private";

function CalendarRoutes() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path="/:farmerId?"
        element={
          <Private>
            <BaseLayout breadcrumb={t("Calendar")}>
              <CalendarDashboard />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/create"
        element={
          <Private>
            <BaseLayout
              gotoback={"/calendar"}
              breadcrumb={`${t("Calendar")} / ${t("Creation")}`}
            >
              <CalendarCreateOrEditEvent />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/:id/edit"
        element={
          <Private>
            <BaseLayout
              gotoback={"/calendar"}
              breadcrumb={`${t("Calendar")} / ${t("Edit")}`}
            >
              <CalendarCreateOrEditEvent />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/verify"
        element={
          <Private>
            <BaseLayout
              gotoback={"/calendar"}
              breadcrumb={`${t("Calendar")} / ${t("Verify")}`}
            >
              <CalendarVerify />
            </BaseLayout>
          </Private>
        }
      />
    </Routes>
  );
}

export default CalendarRoutes;
