import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import App from "./App";
import GlobalStyles from "./GlobalStyles";
import "./index.css";
import lightBlueTheme from "./theme";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={lightBlueTheme}>
    <GlobalStyles />
    <App />
  </ThemeProvider>
);
