import {
  AreaChartOutlined,
  ArrowLeftOutlined,
  BellOutlined,
  CalendarOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  FileDoneOutlined,
  FormOutlined,
  LockOutlined,
  LogoutOutlined,
  MailOutlined,
  SettingOutlined,
  SolutionOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

import { Avatar, Breadcrumb, Button, Card, Dropdown, Layout, Menu } from "antd";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo2.png";
import BCLanguageSelector from "../../components/BCLanguageSelector";
import { AuthContext } from "../../contexts/auth";

import BellIcon from "./Components/BellIcon/BellIcon";
import * as S from "./styles";

const { Header, Content, Footer, Sider } = Layout;

function Base({ children, breadcrumb, gotoback }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, user } = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 790 });

  const getSelectedKey = () => {
    if (location.pathname.includes("/dashboard")) return "1";
    if (location.pathname.includes("/program-map")) return "2";
    if (location.pathname.includes("/program-area")) return "3";
    if (location.pathname.includes("/farmers")) return "4";
    if (location.pathname.includes("/agents")) return "5";
    if (location.pathname.includes("/suppliers")) return "6";
    if (location.pathname.includes("/administration-users")) return "7";
    if (location.pathname.includes("/services")) return "8";
    if (location.pathname.includes("/calendar")) return "9";
    if (location.pathname.includes("/action_plans")) return "10";
    if (location.pathname.includes("/checklist")) return "11";
    if (location.pathname.includes("/reports")) return "12";
    if (location.pathname.includes("/messages")) return "13";
    if (location.pathname.includes("/notifications")) return "14";
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        icon={<UserOutlined />}
        onClick={() => navigate(`/user/${user.user._id}/perfil`)}
      >
        {t("MyPerfil")}
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<LockOutlined />}
        onClick={() =>
          navigate(`/user/${user.user._id}/perfil/change_password`)
        }
      >
        {t("ChangePass")}
      </Menu.Item>
      <Menu.Item key="3" icon={<LogoutOutlined />} onClick={() => logout()}>
        {t("Exit")}
      </Menu.Item>
    </Menu>
  );

  const avatarMenu = (
    <Dropdown overlay={menu} placement="bottomRight">
      <Avatar icon={<UserOutlined />} />
    </Dropdown>
  );

  function getItem(label, key, icon, onClick, children) {
    return {
      key,
      icon,
      children,
      onClick,
      label,
    };
  }

  const items = [
    getItem(t("Dashboard"), "1", <DashboardOutlined />, () =>
      navigate("/dashboard")
    ),
    getItem(t("Mapa_Program"), "2", <EnvironmentOutlined />, () =>
      navigate("/program-map")
    ),
    getItem(t("Areas_Program"), "3", <AreaChartOutlined />, () =>
      navigate("/program-area")
    ),
    getItem(t("Farmers"), "4", <UsergroupAddOutlined />, () =>
      navigate("/farmers")
    ),
    getItem(t("Agents"), "5", <UserOutlined />, () => navigate("/agents")),
    getItem(t("Suppliers"), "6", <SolutionOutlined />, () =>
      navigate("/suppliers")
    ),
    getItem(t("Administration_and_users"), "7", <SettingOutlined />, () =>
      navigate("/administration-users")
    ),
    getItem(t("Services"), "8", <SettingOutlined />, () =>
      navigate("/services")
    ),
    getItem(t("Calendar"), "9", <CalendarOutlined />, () =>
      navigate("/calendar")
    ),
    getItem(t("Action_Plan"), "10", <FormOutlined />, () =>
      navigate("/action_plans")
    ),
    getItem(t("Checklists"), "11", <FormOutlined />, () =>
      navigate("/checklists")
    ),
    getItem(t("Reports"), "12", <FileDoneOutlined />, () =>
      navigate("/reports")
    ),
    getItem(t("Messages"), "13", <MailOutlined />, () => navigate("/messages")),
    getItem(t("Notification_Center"), "14", <BellOutlined />, () =>
      navigate("/notifications")
    ),
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <S.MenuSider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        trigger={null}
        collapsedWidth={80}
        width={240}
      >
        <div
          style={{
            width: "100%",
            height: 64,
            background: "#5c4c45",
            position: "relative",
          }}
        >
          <div
            onClick={() => setCollapsed(!collapsed)}
            style={{
              cursor: "pointer",
              position: "absolute",
              display: "flex",
              flex: 1,
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={logo}
              alt="Descrição da Imagem"
              style={{
                width: "128px",
                height: "40px",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
        <S.CustomMenu
          className="custom-menu"
          selectedKeys={[getSelectedKey()]}
          mode="inline"
          items={items}
          // onClick={(e) => {
          //   if (e.key === "2") {
          //     items[1].onClick();
          //   } else if (e.key === "4") {
          //     items[3].onClick();
          //   }
          // }}
          style={{
            backgroundColor: "transparent",
            color: "white",
            marginTop: 20,
          }}
        />
      </S.MenuSider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: collapsed ? 80 : 240,
          width: "80%",
          backgroundColor: "#fffaf0",
          zIndex: 1
        }}
      >
        <S.MenuHeader>
          <React.Fragment>
            {!isMobile ? (
              <React.Fragment>
                <h1 style={{ margin: 0 }}>{t("Name_APP")}</h1>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BellIcon></BellIcon>

                  <BCLanguageSelector />
                  <div style={{ marginLeft: "8px" }}>{avatarMenu}</div>
                  <div style={{ marginLeft: "8px" }}>{user.user.name}</div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BellIcon></BellIcon>

                  <BCLanguageSelector />
                  <div style={{ marginLeft: "8px" }}>{avatarMenu}</div>
                  <div style={{ marginLeft: "8px" }}>{user.user.name}</div>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </S.MenuHeader>

        <Content style={{ margin: "0 16px" }}>
          <Breadcrumb
            style={{
              margin: "16px 0",
              height: 0,
              backgroundColor: "#ffff",
              display: "flex",
              alignItems: "center",
              paddingLeft: 10,
            }}
          >
            {/* <Breadcrumb.Item>{breadcrumb}</Breadcrumb.Item> */}
          </Breadcrumb>
          <Card
            style={{
              position: "relative",
              height: "calc(100% - 32px)",
              width: "100%",
              backgroundColor: "transparent",
              borderWidth: 0,
            }}
            bodyStyle={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
              backgroundColor: "transparent",
            }}
          >
            {gotoback && (
              <Link to={gotoback}>
                <Button
                  type="dashed"
                  icon={<ArrowLeftOutlined />} // Adicione o ícone aqui
                  shape="round"
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    position: "absolute",
                    top: 8,
                    right: 8,
                    zIndex: 1,
                  }}
                >
                  {t("Gotoback")}
                </Button>
              </Link>
            )}
            {children}
          </Card>
        </Content>
        <Footer style={{ textAlign: "center", backgroundColor: "#fffaf0" }}>
          BovControl ©2023
        </Footer>
      </Layout>
    </Layout>
  );
}

export default Base;
