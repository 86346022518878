
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  listAreaIndigenous,
  listAreaPresevartion,
} from "../pages/ProgramMap/service";
import { AdapterAreas } from "../components/Maps/BCPolygon/AdapterMapArea";
import { AREA_MAP } from "../Enums/AreaMap.enum";
import { AuthContext } from "../contexts/auth";
import jwtDecode from "jwt-decode";

const AreaReservationContext = createContext({});

const AreaReservationProvider = ({ children }) => {
  const { signed, loading } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const [areaIndigenous, setAreaIndigenous] = useState([]);
  const [areaPreservation, setAreaPreservation] = useState([]);

  useEffect(() => {
    if (!signed || token === null || Date.now() >= jwtDecode(token).exp * 1000)
      return;
    loadAreaIndigenous();
    loadAreaPreservation();
  }, [signed]);

  const loadAreaIndigenous = async () => {
    const responseAreaIndigenous = await listAreaIndigenous();
    const areaReservation = AdapterAreas(
      responseAreaIndigenous
    ).areaReservation(AREA_MAP.INDIGENOUS_AREA);
    setAreaIndigenous(areaReservation);
  };

  const loadAreaPreservation = async () => {
    const responseAreaPreservation = await listAreaPresevartion();
    const areaReservation = AdapterAreas(
      responseAreaPreservation
    ).areaReservation(AREA_MAP.PRESERVATION_AREA);
    setAreaPreservation(areaReservation);
  };

  return (
    <AreaReservationContext.Provider
      value={{
        areaIndigenous,
        areaPreservation,
        loadAreaPreservation,
        loadAreaIndigenous,
      }}
    >
      {children}
    </AreaReservationContext.Provider>
  );
};

const useReservationArea = () => {
  const context = useContext(AreaReservationContext);

  if (!context) throw new Error("");

  return context;
};

export { AreaReservationProvider, useReservationArea };
