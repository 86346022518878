import React from 'react'
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import BaseLayout from "../../pages/BaseLayout";
import ReportDashboard from "../../pages/Reports/ReportDashboard";
import Private from "../Private";

function ReportRoutes() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Private>
            <BaseLayout breadcrumb={`${t("Reports")}`}>
              <ReportDashboard />
            </BaseLayout>
          </Private>
        }
      />
    </Routes>
  );
}

export default ReportRoutes;
