import axios from "../../apis/axiosInterceptorFarmer";

export const requestReport = async (payload) => {
  const result = await axios.post(`v1/farm/report`, payload);
  return result.data.data;
};

export const list = async ({ limit, page }) => {
  const result = await axios.get(
    `v1/farm/report/list?limit=${limit}&page=${page}`
  );
  return result.data.data;
};
