import styled from "styled-components";

export const Container = styled.div``;

export const Title = styled.h2`
  margin: 10px 2px 10px 0px;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #47342e;
`;

export const WrapperBox = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  min-width: 800px;
  height: 200px;
  justify-content: center;
`;

export const WrapperTable = styled.div`
  background-color: #fff;
  padding: 18px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
`;