import React from 'react'
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import Private from "../Private";
import BaseLayout from "../../pages/BaseLayout";
import { ProgramAreaPage } from "../../pages/ProgramAreas/ProgramAreaPage";
import { ListAreaByFarmPage } from "../../pages/ProgramAreas/ListAreaByProperties";
import { AreaDetailPage } from "../../pages/ProgramAreas/AreaDetail";

function ProgramAreaRoutes() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Private>
            <BaseLayout breadcrumb={"Program Area"}>
              <ProgramAreaPage />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/:id/list-program-area-by-farm"
        element={
          <Private>
            <BaseLayout
              gotoback="/program-area"
              breadcrumb={"Program Area / Áreas da Propriedade "}
            >
              <ListAreaByFarmPage />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/:id/farmer/:identifier/area"
        element={
          <Private>
            <BaseLayout
              breadcrumb={
                "Program Area / Áreas da Propriedade / Detalhes da Área"
              }
            >
              <AreaDetailPage />
            </BaseLayout>
          </Private>
        }
      />
    </Routes>
  );
}

export default ProgramAreaRoutes;
