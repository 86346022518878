import React, { useEffect, useState } from "react";

import * as S from "./ReviewIntersectionsStyles";

import { Row, Image, Space } from "antd";

import { useTranslation } from "react-i18next";

import IntersectionItem from "../IntersectionItem/IntersectionItem";
import overlapIcon from "../../../../../assets/IconsSabia/overlap.svg";

function ReviewIntersections({
  intersections,
  onIntersectionIdVisiblesChange,
}) {
  const { t } = useTranslation();

  const [intersectionsIdVisibles, setIntersectionsIdVisibles] = useState(
    intersections.map((i) => i._id)
  );

  const handleOnCheck = ({ visible, intersectionId }) => {
    if (visible) {
      setIntersectionsIdVisibles((prev) => [...prev, intersectionId]);
    } else {
      setIntersectionsIdVisibles((prev) =>
        prev.filter((i) => i !== intersectionId)
      );
    }
  };

  useEffect(() => {
    onIntersectionIdVisiblesChange(intersectionsIdVisibles);
  }, [intersectionsIdVisibles]);

  return (
    <S.ListIntersections>
      <S.Header>
        <Image src={overlapIcon}></Image>
        <S.Title level={5}>{t("areas_overlap")}</S.Title>
      </S.Header>

      {intersections.map((intersection, index) => {
        return (
          <IntersectionItem
            key={index}
            intersection={intersection}
            index={index}
            visible={
              !!intersectionsIdVisibles.find((i) => i === intersection._id)
            }
            onCheck={handleOnCheck}
          ></IntersectionItem>
        );
      })}
    </S.ListIntersections>
  );
}

export default ReviewIntersections;
