import { Card, Col, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AdministrationListAgents from "../AdministrationUsers/AdministrationListAgents";
import CustomBarChart from "./Graphics/FarmesVsFieldAgentsPerRegion";
import FarmesVsImplementation from "./Graphics/FarmesVsImplementation";
import {
  graphicFarmersVsFieldAgentsRegion,
  graphicFarmsVcImplementationPerVisits,
  overview,
} from "./service";

export default function AgentsDashboard() {
  const { t } = useTranslation();
  const [dataGraphic, setDataGraphic] = useState([]);
  const [dataGraphic2, setDataGraphic2] = useState({});
  const [initialOverview, setInitialOverview] = useState({
    agentsPerRegions: [],
    countAgentsPerOrganizations: [],
  });

  const findAgents = useCallback(async () => {
    const result = await overview();
    setInitialOverview({
      agentsPerRegions: result.agentsPerRegions,
      countAgentsPerOrganizations: result.countAgentsPerOrganizations,
    });
  }, []);

  const findGraphic1 = useCallback(async () => {
    const result = await graphicFarmersVsFieldAgentsRegion();
    setDataGraphic(result);
  }, []);

  const findGraphic2 = useCallback(async () => {
    try {
      const result = await graphicFarmsVcImplementationPerVisits();
      setDataGraphic2(result);
    } catch (error) {
      alert(
        "Se der erro nessa tela, é pq a variavel e ambiente está incorreta ao obter os dados do grafico"
      );
    }
  }, []);

  useEffect(() => {
    findAgents();
    findGraphic1();
    findGraphic2();
  }, [findAgents, findGraphic1, findGraphic2]);

  // Fica Evidente que fazer uma map para gerar os cards seria uma boa opção e economizaria codigo
  // Mas devido a simplicidade da Tela acho melhor manter a replica do codigo para maior visibilidade
  return (
    <div>
      <h1>{t("Dashboard")}</h1>
      <Card>
        <Row style={{ marginTop: "20px" }} gutter={[16, 16]}>
          {initialOverview.agentsPerRegions.map((a, index) => {
            return (
              <Col key={index}>
                <Card
                  style={{
                    cursor: "default",
                    height: "150px",
                    width: "170px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#fffaf0",
                  }}
                >
                  <div className="card-content">
                    <h1 style={{ fontSize: "3rem" }}>
                      {a.countAgentsPerRegion}
                    </h1>
                    <div
                      className="centered-content"
                      style={{ fontSize: "1.0rem", color: "#555555" }}
                    >
                      {a.region}
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
          {initialOverview.countAgentsPerOrganizations.map((a, index) => {
            return (
              <Col key={index}>
                <Card
                  style={{
                    cursor: "default",
                    height: "150px",
                    width: "180px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#47342eb8",
                  }}
                >
                  <div className="card-content">
                    <h1 style={{ fontSize: "3rem" }}>
                      {a.countAgentsPerOrganization}
                    </h1>
                    <div
                      style={{ fontSize: "1rem" }}
                      className="centered-content"
                    >
                      {a.organization}
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Card>
      <Card style={{ marginTop: "20px" }}>
        <h1>{t('title_graphic_area')}</h1>
        <Row gutter={[16, 16]}>
          <Col xxl={12} style={{ marginTop: "40px" }}>
            <div style={{ marginLeft: "120px", fontSize: "1.2rem" }}>
              {t("Graphic_1")}
            </div>
            <CustomBarChart data={dataGraphic} />
          </Col>
          <Col xxl={12} style={{ marginTop: "40px" }}>
            <div style={{ marginLeft: "120px", fontSize: "1.2rem" }}>
              {t("Graphic_2")}
            </div>
            <FarmesVsImplementation data={dataGraphic2} />
          </Col>
        </Row>
      </Card>
      <Card style={{ marginTop: "20px" }}>
        <Row gutter={[16, 16]}>
          <h1>{t("Agents")}</h1>
          <div style={{ marginTop: "20px" }}>
            <AdministrationListAgents resendFirstAccessCode={false} />
          </div>
        </Row>
      </Card>
    </div>
  );
}
