import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Col, Row, Space, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { ACTION_PLANS } from "../../Enums/ActionsPlans.enum";
import BCTable from "../../components/BCTable";
import { listActionsPlans } from "../ActionPlans/service";
import { getAgent } from "../AdministrationUsers/service";

export default function AgentsViewActionsPlans() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [actionsPlans, setActionsPlans] = useState([
    {
      createdAt: new Date(),
      farmer: {
        name: "",
        identifier: "",
      },
      farm: {
        name: "",
        identifier: "",
      },
      status: "",
      agent_name: "",
      items: [
        {
          actions: [],
        },
      ],
    },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [agent, setAgent] = useState({});
  const { t } = useTranslation();

  const findAgents = useCallback(async () => {
    setLoading(true);
    const result = await getAgent(id);
    setAgent(result);
  }, [id]);
  useEffect(() => {
    findAgents();
  }, [findAgents]);

  const findActionPlans = useCallback(async () => {
    setLoading(true);
    const result = await listActionsPlans({
      limit: pageSize,
      page: currentPage,
      status: search,
      agentId: id,
    });
    setActionsPlans(result.result);
    setLoading(false);
  }, [pageSize, currentPage, search, id]);

  useEffect(() => {
    findActionPlans();
  }, [findActionPlans]);

  const onView = (key) => {
    navigate(`/action_plans/${key}/view`);
  };

  const columns = [
    {
      title: t("Date"),
      dataIndex: "createdAt",
      align: "center",
      sorter: false,
      width: 150,
      render: (text, record) => (
        <span key={record.createdAt}>
          {new Date(record.createdAt).toLocaleDateString()}
        </span>
      ),
    },
    {
      title: t("Farmer"),
      dataIndex: "farmer.name",
      align: "start",
      sorter: false,
      render: (text, data) => {
        return (
          <span>
            {data.farmer_name}
            <br />
            <Tag>{data.farmer.identifier}</Tag>
          </span>
        );
      },
    },
    {
      title: t("Farm"),
      dataIndex: "name",
      align: "start",
      sorter: false,
      render: (text, data) => {
        return (
          <span>
            {data.farm_name}
            <br />
            <Tag>{data.farm.identifier}</Tag>
          </span>
        );
      },
    },
    {
      title: t("Checklist"),
      dataIndex: "checklist",
      align: "start",
      sorter: false,
      render: (text, data) => {
        return <span>{data.checklist_name}</span>;
      },
    },
    {
      title: t("Total_Itens"),
      dataIndex: "total",
      align: "start",
      sorter: false,
      render: (text, data) => {
        let total = 0;
        data.items.forEach((m) => {
          total += m.actions.length;
        });
        return <div>{total}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "start",
      render: (text, data) => {
        let statusTag;
        switch (data.status) {
          case ACTION_PLANS.COMPLETED:
            statusTag = (
              <Tag icon={<CheckCircleOutlined />} color="success">
                {t("COMPLETED")}
              </Tag>
            );
            break;
          case ACTION_PLANS.EXPIRED:
            statusTag = (
              <Tag icon={<CloseCircleOutlined />} color="error">
                {t("EXPIRED")}
              </Tag>
            );
            break;
          case ACTION_PLANS.PENDING:
            statusTag = (
              <Tag icon={<ClockCircleOutlined spin />} color="warning">
                {t("PENDING")}
              </Tag>
            );
            break;
          case ACTION_PLANS.PENDING_APPROVAL:
            statusTag = (
              <Tag icon={<ClockCircleOutlined spin />} color="warning">
                {t("Pending_Approval")}
              </Tag>
            );
            break;
          default:
            statusTag = <span>Status Desconhecido</span>;
        }
        return statusTag;
      },
    },
  ];

  const actionsColumn = [
    {
      title: t("Actions"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={() => onView(record._id)}
            icon={<EyeOutlined />}
          >
            {t("View")}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h1>{`${t("Agent")} / ${t("ActionsPlans")}`}</h1>
      <Row style={{ marginTop: "20px" }} gutter={[16, 16]} justify="start">
        <Col xs={12} sm={12} md={21}>
          <Col
            style={{ display: "flex", justifyContent: "space-between" }}
            className="custom-card"
          >
            <div style={{ display: "flex", alignItems: "start" }}>
              <h1
                style={{
                  fontSize: "2rem",
                  marginTop: "1px",
                }}
              >
                <UserOutlined size={48} />
              </h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <h1
                  style={{
                    fontSize: "25px",
                    marginBottom: "4px",
                    marginTop: "10px",
                  }}
                >
                  {agent.name}
                </h1>
                <div
                  style={{
                    fontSize: "20px",
                    display: "flex",
                    gridTemplateColumns: "repeat(3)",
                    gap: "16px",
                    alignItems: "start",
                    justifyContent: "flex-start",
                    color: "rgb(79, 175, 73)",
                    marginLeft: "-30px",
                  }}
                >
                  {agent.regions
                    ? agent.regions.map((item) => (
                        <div key={item.name}>
                          <FaMapMarkerAlt style={{ marginRight: "6px" }} />
                          {item.name}
                        </div>
                      ))
                    : ""}
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  <MailOutlined size={24} />
                </h1>

                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  {agent.email}
                </h1>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  <PhoneOutlined size={24} />
                </h1>
                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  {agent.phone}
                </h1>
              </div>
            </div>
          </Col>
        </Col>
      </Row>
      <BCTable
        data={actionsPlans}
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        setSearch={setSearch}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        loading={loading}
        columns={columns}
        actionsColumn={actionsColumn}
        showCreateButton={false}
        showPlaceholderSearch={false}
      />
    </div>
  );
}
