import axiosE from "../../apis/axiosInterceptorEntitlement";
import axios from "../../apis/axiosInterceptorFarmer";
import calendarApi from "../../apis/axiosInterceptorCalendar";

export const listFarms = async ({
  currentPage = 1,
  pageSize = 10,
  search = "",
  sortOrder,
  sortField,
  id = "",
  withProgram = false,
  no_agents,
  only_with_agents,
}) => {
  const result = await axios.get(
    `/v3/farm_admin?limit=${pageSize}&page=${currentPage}&search=${search}&sort=${sortOrder}&field=${sortField}&_id=${id}&withProgram=${withProgram}&no_agents=${no_agents}&only_with_agents=${only_with_agents}`
  );
  return result.data.data;
};

export const linkAgents = async (farmId, ids) => {
  const payload = {
    ids,
  };
  const result = await axios.put(`/v2/farm/${farmId}/link-agents`, payload);
  return result.data;
};

export const graphicFarmersVsFieldAgentsRegion = async () => {
  const result = await axios.get("/v1/graphics/farmers-vs-fiel-agents-region");
  return result.data.data;
};

export const graphicFarmsVcImplementationPerVisits = async () => {
  const result = await axios.get(
    "v1/graphics/farmers-vs-implementation-per-visits"
  );
  return result.data.data;
};

export const listUsers = async ({ limit, page, type, sort, field, search }) => {
  const result = await axiosE.get(
    `/v2/user?limit=${limit}&page=${page}&type=${type}&sort=${sort}&field=${field}&search=${search}`
  );
  return result.data.data;
};

export const setAvailableForAgent = async (id, onduty) => {
  const payload = {
    on_duty: onduty,
  };
  await axiosE.patch(`/v1/agent/set-available-for-agent/${id}`, payload);
};

export const overview = async () => {
  const result = await axiosE.get("/v1/agent/for-regions-and-organizations");
  return result.data.data;
};

export const nextEvents = async (_idAgent) => {
  let params = {
    limit: 400,
    destination_user_id: _idAgent,
  };
  const result = await calendarApi.post("/v1/schedule_request/list", params);
  return result.data.data;
};
