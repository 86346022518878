import { EyeOutlined } from "@ant-design/icons";
import { Button, Space, Tag, Tooltip } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BCTable from "../../components/BCTable";
import { list } from "./service";

export default function CenterNotificationList() {
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortField, setSortField] = useState("createdAt");
  const [loading, setLoading] = useState(true);
  const [onlyMy, setOnlyMy] = useState("false");
  const [selectedOriginNotifications, setSelectedOriginNotifications] =
    useState(1);

  const { t } = useTranslation();

  const findNotifications = useCallback(async () => {
    setLoading(true);
    const result = await list({
      limit: pageSize,
      page: currentPage,
      search: search,
      sort: sortOrder,
      field: sortField,
      onlyMy: onlyMy,
    });
    result.result.map((x) => (x.key = x._id));
    setNotifications(result.result);
    setTotal(result.count);
    setLoading(false);
  }, [pageSize, currentPage, search, sortOrder, sortField, onlyMy]);

  useEffect(() => {
    findNotifications();
  }, [findNotifications]);

  const onView = (key) => {
    navigate(`${key}/view`);
  };

  const onAdd = () => {
    navigate("/notifications/create");
  };

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (text, record) => {
        if (
          record.documents.find(
            (x) =>
              x.user_destination_type === "admin" &&
              record.documents.every((x) => x.status_admin === "READ")
          )
        ) {
          return <Tag color="success">{t("Read")}</Tag>;
        } else {
          return record.documents.every((x) => x.status === "READ") ? (
            <Tag color="success">{t("Read")}</Tag>
          ) : (
            <Tooltip
              placement="topLeft"
              title={t("It_will_only_be_read_when_all_of_the_batch_are_read")}
            >
              <Tag color="warning">{t("NotRead")}</Tag>
            </Tooltip>
          );
        }
      },
    },

    {
      title: t("Message"),
      dataIndex: "description",
      align: "center",
      sorter: true,
      render: (text, record) => (
        <span key={record.documents[0]}>{record.documents[0].description}</span>
      ),
    },
    {
      title: t("Author"),
      dataIndex: "name",
      align: "center",
      render: (text, record) =>
        record.documents[0].origin_user ? (
          <span key={record.documents[0]._id}>
            {record.documents[0].origin_user.name}
          </span>
        ) : (
          <span key={record.documents[0]._id}>{t("Sent_by_the_system")}</span>
        ),
    },
    {
      title: t("Date"),
      dataIndex: "createdAt",
      align: "center",
      sorter: true,
      render: (text, record) => (
        <span key={record.documents[0]}>
          {new Date(record.documents[0].createdAt).toLocaleDateString()}
        </span>
      ),
    },
    {
      title: t("Total_Sent"),
      dataIndex: "total",
      align: "center",
      render: (text, record) => (
        <span key={record.documents[0]}>{record.documents.length}</span>
      ),
    },
  ];

  const actionsColumn = [
    {
      title: t("Actions"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={() => onView(record.key)}
            icon={<EyeOutlined />}
          >
            {t("View")}
          </Button>
        </Space>
      ),
    },
  ];

  const searchOriginNotifications = (key) => {
    switch (key) {
      case 1:
        setSelectedOriginNotifications(1);
        setOnlyMy("false");
        break;
      case 2:
        setSelectedOriginNotifications(2);
        setOnlyMy("true");
        break;
      default:
        break;
    }
  };

  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "180px",
    height: "32px",
    borderRadius: "20px",
    fontSize: "20px",
    cursor: "pointer",
  };

  return (
    <div>
      <BCTable
        data={notifications}
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        placeholderSearch={`${t("Search")} ${t("Message")}`}
        setSearch={setSearch}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        loading={loading}
        columns={columns}
        actionsColumn={actionsColumn}
        showCreateButton={true}
        textButtonAdd={t("New_Message")}
        onAdd={onAdd}
        children={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Tag
              style={style}
              onClick={() => searchOriginNotifications(1)}
              color={selectedOriginNotifications === 1 ? "success" : ""}
            >
              {t("All")}
            </Tag>
            <Tag
              style={style}
              onClick={() => searchOriginNotifications(2)}
              color={selectedOriginNotifications === 2 ? "success" : ""}
            >
              {t("Just_mine")}
            </Tag>
          </div>
        }
      />
    </div>
  );
}
