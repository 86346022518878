import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import { Button, Space, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ACTION_PLANS } from "../../Enums/ActionsPlans.enum";
import BCTable from "../../components/BCTable";
import { listActionsPlans } from "./service";

export default function ActionsPlansList() {
  const navigate = useNavigate();
  const [selected, setSelect] = useState(1);
  const [actionsPlans, setActionsPlans] = useState([
    {
      createdAt: new Date(),
      farmer: {
        name: "",
        identifier: "",
      },
      farm: {
        name: "",
        identifier: "",
      },
      status: "",
      agent_name: "",
      items: [
        {
          actions: [],
        },
      ],
    },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const { type, farmerId } = useParams();
  const { t } = useTranslation();

  const findActionsPlans = useCallback(async () => {
    setLoading(true);
    const result = await listActionsPlans({
      limit: pageSize,
      page: currentPage,
      status: search,
      checklistName: type,
      farmerId,
    });

    result.result.map((x) => (x.key = x._id));

    if (farmerId) {
      setActionsPlans(result.result.filter((i) => i.farmer_id === farmerId));
    } else setActionsPlans(result.result);
    setTotal(result.count);
    setLoading(false);
  }, [pageSize, currentPage, search, type, farmerId]);

  useEffect(() => {
    findActionsPlans();
  }, [findActionsPlans]);

  const onView = (key) => {
    navigate(`/action_plans/${key}/view`);
  };

  const columns = [
    {
      title: t("Date"),
      dataIndex: "createdAt",
      align: "center",
      sorter: true,
      width: 150,
      render: (text, record) => (
        <span key={record.createdAt}>
          {new Date(record.createdAt).toLocaleDateString()}
        </span>
      ),
    },
    {
      title: t("Farmer"),
      dataIndex: "farmer.name",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return (
          <span>
            {data.farmer.name}
            <br />
            <Tag>{data.farmer.identifier}</Tag>
          </span>
        );
      },
    },
    {
      title: t("Farm"),
      dataIndex: "name",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return (
          <span>
            {data.farm.name}
            <br />
            <Tag>{data.farm.identifier}</Tag>
          </span>
        );
      },
    },
    {
      title: t("Agents"),
      dataIndex: "agent_name",
      align: "start",
    },
    {
      title: t("Total_Itens"),
      dataIndex: "total",
      align: "start",
      sorter: true,
      render: (text, data) => {
        let total = 0;
        data.items.forEach((m) => {
          total += m.actions.length;
        });
        return <div>{total}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "start",
      render: (text, data) => {
        let statusTag;
        switch (data.status) {
          case ACTION_PLANS.COMPLETED:
            statusTag = (
              <Tag icon={<CheckCircleOutlined />} color="success">
                {t("COMPLETED")}
              </Tag>
            );
            break;
          case ACTION_PLANS.EXPIRED:
            statusTag = (
              <Tag icon={<CloseCircleOutlined />} color="error">
                {t("EXPIRED")}
              </Tag>
            );
            break;
          case ACTION_PLANS.PENDING:
            statusTag = (
              <Tag icon={<ClockCircleOutlined spin />} color="warning">
                {t("PENDING")}
              </Tag>
            );
            break;
          case ACTION_PLANS.PENDING_APPROVAL:
            statusTag = (
              <Tag icon={<ClockCircleOutlined spin />} color="warning">
                {t("Pending_Approval")}
              </Tag>
            );
            break;
          default:
            statusTag = <span>Status Desconhecido</span>;
        }
        return statusTag;
      },
    },
  ];

  const actionsColumn = [
    {
      title: t("Actions"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={() => onView(record.key)}
            icon={<EyeOutlined />}
          >
            {t("View")}
          </Button>
        </Space>
      ),
    },
  ];

  const searchActionsPlans = (key) => {
    setSelect(key);
    switch (key) {
      case 1:
        setSearch("");
        break;
      case 2:
        setSearch(ACTION_PLANS.PENDING);
        break;
      case 3:
        setSearch(ACTION_PLANS.EXPIRED);
        break;
      case 4:
        setSearch(ACTION_PLANS.COMPLETED);
        break;
      default:
        break;
    }
  };

  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "140px",
    height: "40px",
    borderRadius: "20px",
    fontSize: "20px",
    cursor: "pointer",
  };

  return (
    <div>
      <h1>{`${t("ActionsPlans")}`}</h1>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "30px",
        }}
      >
        <Tag
          style={style}
          onClick={() => searchActionsPlans(1)}
          color={selected === 1 ? "success" : ""}
        >
          {t("All")}
        </Tag>
        <Tag
          style={style}
          onClick={() => searchActionsPlans(2)}
          color={selected === 2 ? "success" : ""}
        >
          {t("In_Progress")}
        </Tag>
        <Tag
          style={style}
          onClick={() => searchActionsPlans(3)}
          color={selected === 3 ? "success" : ""}
        >
          {t("EXPIRED")}
        </Tag>
        <Tag
          style={style}
          onClick={() => searchActionsPlans(4)}
          color={selected === 4 ? "success" : ""}
        >
          {t("Finalized")}
        </Tag>
      </div>
      <BCTable
        data={actionsPlans}
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        setSearch={setSearch}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        loading={loading}
        columns={columns}
        actionsColumn={actionsColumn}
        showCreateButton={false}
        showPlaceholderSearch={false}
      />
    </div>
  );
}
