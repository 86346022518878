import { Card } from "antd";
import styled from "styled-components";

export const Container = styled(Card)`
  display: flex;
  flex: 1;
  align-items: center;
  box-shadow: 1px 1px 10px #3332;
  background-color: #ffffff;
  margin-left: 5px;
`;

export const WrapperCountArea = styled.div`
  display: flex;
  width: 100%;
`;

export const TitleCountArea = styled.h2`
  color: #1f9652;
  font-size: 36px;
  font-weight: 300;
`;

export const LabelDescription = styled.p`
  display: flex;
  width: 100%;
  color: #47342e;
  font-size: 16px;
  font-weight: normal;
`;

export const TotalAreaImplemented = styled.span`
  display: flex;
  width: 100%;
  color: rgba(71, 52, 46, 0.72);
  font-size: 14px;
  font-weight: normal;
`;
