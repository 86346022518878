import { LoadScript } from "@react-google-maps/api";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

import * as S from "./BCMapsStyles";

const DEFAULT_LOCATION = { lng: -51.158510341, lat: -6.751218522 };

const OPTION_MAP = {
  zoom: 10,
  mapTypeId: "hybrid",
  streetView: false,
  zoomControl: false,
  mapTypeControl: false,
  panControl: false,
  fullscreenControl: false,
  scaleControl: false,
  rotateControl: false,
  streetViewControl: false,
  minZoom: 5,
};

export const BCMaps = forwardRef(
  ({ center, polygonCoordinatesOnLoad, children }, ref) => {
    const [map, setMap] = useState();
    const [visibleZoomControl, setVisibleZoomControl] = useState(false);
    const [location, setLocation] = useState(DEFAULT_LOCATION);
    const [optionsMap, setOptionsMap] = useState(OPTION_MAP);

    const onMapLoad = (map) => {
      setMap(map);
    };

    const handleHabilitControlZoom = () => {
      applyThemeControlZoom();
      setTimeout(() => {
        setVisibleZoomControl(true);
      }, 1000);
    };

    const applyThemeControlZoom = () => S.StyleZoonControl();

    const zoomToPolygon = (polygonCoords) => {
      const bounds = new window.google.maps.LatLngBounds();
      for (let i = 0; i < polygonCoords.length; i++) {
        bounds.extend(polygonCoords[i]);
      }

      map.fitBounds(bounds);
    };

    useEffect(() => {
      applyThemeControlZoom();
    }, [visibleZoomControl]);

    useEffect(() => {
      if (!center) {
        setOptionsMap(OPTION_MAP);
        setLocation(DEFAULT_LOCATION);
        return;
      }
      setLocation(center);
      setOptionsMap({
        ...OPTION_MAP,
        zoom: 15,
      });
    }, [center]);

    useEffect(() => {
      if (polygonCoordinatesOnLoad && map) {
        zoomToPolygon(polygonCoordinatesOnLoad);
      }
    }, [polygonCoordinatesOnLoad, map, zoomToPolygon]);

    useImperativeHandle(ref, () => {
      return {
        zoomToPolygon,
      };
    });

    return (
      <S.ContainerProgramMap>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          libraries={["places"]}
        >
          <S.Maps
            onLoad={onMapLoad}
            center={location}
            options={{ ...optionsMap, zoomControl: visibleZoomControl }}
            onTilesLoaded={() => {
              handleHabilitControlZoom();
            }}
          >
            {children}
          </S.Maps>
        </LoadScript>
      </S.ContainerProgramMap>
    );
  }
);
