import axiosE from "../../apis/axiosInterceptorEntitlement";
import axios from "../../apis/axiosInterceptorFarmer";

export const listFarms = async ({
  currentPage = 1,
  pageSize = 10,
  search = "",
  sortOrder,
  sortField,
  id = "",
  withProgram = false,
  no_agents,
  only_with_agents,
  withChecklist = false,
  checklistType = "",
}) => {
  const result = await axios.get(
    `/v3/farm_admin?limit=${pageSize}&page=${currentPage}&search=${search}&sort=${sortOrder}&field=${sortField}&_id=${id}&withProgram=${withProgram}&no_agents=${no_agents}&only_with_agents=${only_with_agents}&withChecklist=${withChecklist}&checklist.checklist_type=${checklistType}`
  );
  return result.data.data;
};

export const farm = async (limit, page, id) => {
  const result = await axios.post(`/v3/farm`, {
    limit,
    page,
    withProgram: true,
    agent_id: id,
  });
  return result.data.data;
};

export const farmByAgent = async (limit, page, id) => {
  const result = await axios.get(
    `/v1/farm?limit=${limit}&page=${page}&agentId=${id}`
  );
  return result.data.data;
};

export const listUsers = async ({ limit, page, type, search, sort, field }) => {
  const result = await axiosE.get(
    `/v2/user?limit=${limit}&page=${page}&type=${type}&search=${search}&sort=${sort}&field=${field}`
  );
  return result.data.data;
};
