import { Col, Descriptions, Row, Skeleton, Space, Tag } from "antd";
import * as S from "./ListAreByFarmStyles";
import { useTranslation } from "react-i18next";
import { ListAreaByFarmCollunmGrid } from "./ListAreaByFarmCollunmGrid";
import { useNavigate, useParams } from "react-router-dom";
import BCTable from "../../../components/BCTable";
import { useCallback, useEffect, useState } from "react";
import { listFarmsService } from "../service";
import { STATUS_FARM } from "../../../Enums/Farm.enum";
import { CloseCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { AdapterListArea } from "./AdapterListArea";
import { BCProfilerFarmer } from "../../../components/BCProfileFarmer";

export const ListAreaByFarmPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { columns, actions } = ListAreaByFarmCollunmGrid(t, navigate);

  const [loading, setLoading] = useState(true);
  const [farm, setFarm] = useState({});
  const [areas, setAreas] = useState([])

  const [pageSize, setPageSize] = useState(0)

  const loadDetailFarm = useCallback(async () => {
    try {
      setLoading(true);
      const responseFarm = await listFarmsService({ id });
      if (responseFarm.result === 0) return;
      setFarm(responseFarm.result[0]);
      const _areas = AdapterListArea(responseFarm.result[0].maps, responseFarm.result[0]._id);
      setPageSize(_areas.length);
      setAreas(_areas);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  },[]);

  useEffect(() => {
    loadDetailFarm();
  }, []);

  if (loading) return <Skeleton paragraph={{ rows: 3 }} active={true} />;

  return (
    <S.Container>
      <BCProfilerFarmer farm={farm}/>
      <S.WrapperTable>
        <BCTable
          data={areas}
          total={1}
          pageSize={pageSize}
          showPlaceholderSearch={false}
          currentPage={1}
          loading={false}
          columns={columns}
          actionsColumn={actions}
          showCreateButton={false}
          setSortOrder={e => {}}
          setSortField={e => {}}
        />
      </S.WrapperTable>
    </S.Container>
  );
};
