import { Card, Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  FaChartBar,
  FaEdit,
  FaHistory,
  FaUserCog,
  FaUsers,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Agents = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Fica Evidente que fazer uma map para gerar os cards seria uma boa opção e economizaria codigo
  // Mas devido a simplicidade da Tela acho melhor manter a replica do codigo para maior visibilidade
  return (
    <div>
      <h1>{t("Agents")}</h1>
      <Row style={{ marginTop: "20px" }} justify="start" gutter={[16, 16]}>
        <Col xs={24} sm={12} md={4}>
          <Card
            onClick={() => navigate("/agents/overview")}
            style={{ cursor: "pointer" }}
            className="custom-card"
          >
            <div className="card-content">
              <FaChartBar size={48} />
              <div
                className="centered-content"
                style={{ fontSize: "1.0rem", color: "#555555" }}
              >
                {t("Overview")}
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={4}>
          <Card
            onClick={() => navigate("/agents/define-edit-list")}
            style={{ cursor: "pointer" }}
            className="custom-card"
          >
            <div className="card-content">
              <FaEdit size={48} />
              <div
                className="centered-content"
                style={{ fontSize: "1.0rem", color: "#555555" }}
              >
                {t("Edit_Allocation")}
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={4}>
          <Card
            onClick={() => navigate("/agents/list-available-field-agents")}
            style={{ cursor: "pointer" }}
            className="custom-card"
          >
            <div className="card-content">
              <FaUsers size={48} />
              <div
                className="centered-content"
                style={{ fontSize: "1.0rem", color: "#555555" }}
              >
                {t("List_of_Available_Agents")}
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={4}>
          <Card
            onClick={() => navigate("/agents/history-visits-events")}
            style={{ cursor: "pointer" }}
            className="custom-card"
          >
            <div className="card-content">
              <FaHistory size={48} />
              <div
                className="centered-content"
                style={{ fontSize: "1.0rem", color: "#555555" }}
              >
                {t("History_of_Visits_and_Events")}
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={4}>
          <Card
            onClick={() => navigate("/agents/define-list")}
            style={{ cursor: "pointer" }}
            className="custom-card"
          >
            <div className="card-content">
              <FaUserCog size={48} />
              <div
                className="centered-content"
                style={{ fontSize: "1.0rem", color: "#555555" }}
              >
                {t("Define_Field_Agent")}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Agents;
