import { Layout, Menu } from "antd";
import styled, { css } from "styled-components";

const { Sider, Header } = Layout;

export const CustomMenu = styled(Menu)`
  .ant-menu-item {
    width: 100% !important;
    margin-left: 0 !important;
    border-radius: 0 !important;
    color: #fff9 !important;

    :hover{
      color: #fff !important;
    }

    :active{
      background-color: #fff2 !important;;
    }
  }
  .ant-menu-item-selected {
    background-color: #5c4c45 !important;
    width: 100% !important;
    margin-left: 0 !important;
    border-radius: 0 !important;
    border-left: 5px solid #4faf49;
  }

  .ant-menu-item-selected > span {
    color: white !important;
  }
`;

export const MenuSider = styled(Sider).attrs({
  style: {
    padding: 0,
    background: "linear-gradient(#7a6b65, #47342e)",
    height: "100vh",
    position: "fixed",
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 20000,
  },
})``;

export const MenuHeader = styled(Header).attrs({
  style: {
    display: "flex",
    position: "sticky",
    justifyContent: "space-between",
    alignItems: "center",
    top: 0,
    zIndex: 10000,
    width: "100%",
    backgroundColor: "#ffffff",
    padding: "0 24px",
    height: 64,
    
  },
})`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
`;
