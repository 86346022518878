import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as S from "./AdditionalInformationStyles";
import {
  StatusProgramType,
  useAdditionalInformation,
} from "./useAdditionalInformation";
import { BoxDocuments } from "./Components/BoxDocuments";

export const AdditionalInformation = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { steps, additionalInformation, farm, loadFarm } = useAdditionalInformation();
  return (
    <>
      <S.Container>
        <S.ButtonBack
          shape="round"
          type="dashed"
          onClick={() => navigate(`/farmers/detail/${id}/farmer`)}
          icon={<ArrowLeftOutlined />}
        >
          Voltar
        </S.ButtonBack>
        <S.Content>
          <S.ContentSteps>
            <S.HeaderSteps>
              <S.Title>{t("Monitoring_of_enrollment_steps")}</S.Title>
            </S.HeaderSteps>
            <S.WrapperListStep>
              {steps.map((step, key) => (
                <S.ItemStep key={key}>
                  <S.WrapperIndicator>
                    <S.CircleStep status={step.status}>
                      {step.status === StatusProgramType.APPROVED ? (
                        <S.IconCheck />
                      ) : (
                        <S.IconHourglass />
                      )}
                    </S.CircleStep>
                    <S.LineStep status={step.status} />
                  </S.WrapperIndicator>
                  <S.WrapperInfoStep>
                    <S.TitleItemStep status={step.status}>
                      {t(step.title)}
                    </S.TitleItemStep>
                    <S.SubTitleItemSept>{t(step.subtitle)}</S.SubTitleItemSept>
                  </S.WrapperInfoStep>
                </S.ItemStep>
              ))}
            </S.WrapperListStep>
          </S.ContentSteps>
          <S.ContentInfo>
            <S.ContentInformation>
              <S.CustomCollapson>
                {additionalInformation.map((info, key) => (
                  <S.CustomPanel
                    key={key}
                    header={<S.TitlePanel>{t(info.title)}</S.TitlePanel>}
                  >
                    {info.fields.map((field, key) => (
                      <S.ItemInformation key={key}>
                        {field.type === "checkbox" && (
                          <Checkbox
                            checked={field.value}
                            style={{
                              "--checkbox-border-color": field.value
                                ? "green"
                                : "",
                            }}
                          >
                            {t(field.title)}
                          </Checkbox>
                        )}
                        {field.type === "text" && (
                          <S.ItemLabel>{t(field.title)}</S.ItemLabel>
                        )}
                        {field.type === "text" && (
                          <S.ItemInfo>{field.value}</S.ItemInfo>
                        )}
                      </S.ItemInformation>
                    ))}
                  </S.CustomPanel>
                ))}
              </S.CustomCollapson>
            </S.ContentInformation>
          </S.ContentInfo>
        </S.Content>
      </S.Container>
      <BoxDocuments farm={farm} onLoad={loadFarm}/>
    </>
  );
};
