import React from 'react'
import { useTranslation } from "react-i18next";
import * as S from "./PendingActivityStyles";
import { useNavigate } from "react-router-dom";
import { ListPendingActivity } from "./ListPendingActivityCollunmGrid";
import { useEffect, useState } from "react";
import { parseISO, format } from "date-fns";

import {
  getActionPlans,
  getAllEventsByFarmer,
  getVisitCallScheduling,
} from "../../../service";

import { ptBR, enUS } from "date-fns/locale";

import BCTable from "../../../../../components/BCTable";

export const PendingActivity = ({ farmerId, actionPlans }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { actions, columns } = ListPendingActivity(t, navigate);
  const [dataActivities, setDataActivities] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const translateStatusActionPlans = {
    ["PENDING"]: t("IN_PROGRESS"),
    ["PENDING_APPROVAL"]: t("pending_approval_action_plan"),
  };

  const translateStatusEvents = {
    ["WAITING_TO_CALL"]: t("PENDING"),
  };

  const translateStatusCalendar = {
    ["CREATED"]: t("IN_PROGRESS"),
  };

  const translateStatusScheduling = {
    ["SENT"]: t("PENDING"),
    ["NEWPROPOSAL"]: t("new_proposal_scheduling"),
  };

  const fetchPendingActivity = async (id, page = 1) => {
    setLoading(true);

    const { calls, scheduling, visits } = await getVisitCallScheduling({
      id,
      page,
      limit,
    });

    const actionPlansFilteredForTable = actionPlans?.filter(
      (item) => item.status === "PENDING_APPROVAL" || item.status === "PENDING"
    );

    const callsFilteredForTable = calls.filter(
      (item) => item.status === "WAITING_TO_CALL"
    );

    const visitsFilteredForTable = visits.filter(
      (item) => item.type_event_id === "VISIT" && item.status === "CREATED"
    );

    const schedulingFilteredForTable = scheduling.filter(
      (item) => item.status === "SENT" || item.status === "NEWPROPOSAL"
    );

    const actionPlansFormatted = actionPlansFilteredForTable?.map((item) => ({
      _id: item._id,
      category: t("Action_Plan"),
      description: item.checklist_name,
      status: item.status,
      statusClient: translateStatusActionPlans[item.status],
      url: `/action_plans/` + item._id + `/view`,
    }));

    const callsFormatted = callsFilteredForTable.map((item) => ({
      _id: item._id,
      category: t("calls"),
      description: t("PENDING"),
      status: item.status,
      statusClient: translateStatusEvents[item.status],
      url: `/agents/history-visits-events/` + farmerId,
    }));

    const calendarFormatted = visitsFilteredForTable.map((item) => ({
      _id: item._id,
      category: t("Calendar"),
      description: item.title,
      status: item.status,
      statusClient: translateStatusCalendar[item.status],
      url: `/calendar/` + farmerId,
    }));

    const schedulingFormatted = schedulingFilteredForTable.map((item) => ({
      _id: item._id,
      category: t("events"),
      description: item.title,
      status: item.status,
      statusClient: translateStatusScheduling[item.status],
      url: `/agents/history-visits-events/` + farmerId,
    }));
    if(!actionPlansFormatted){
      setDataActivities([
        ...callsFormatted,
        ...calendarFormatted,
        ...schedulingFormatted,
      ]);
    }else{
      setDataActivities([
        ...actionPlansFormatted,
        ...callsFormatted,
        ...calendarFormatted,
        ...schedulingFormatted,
      ]);
    }
  };

  useEffect(() => {
    if (farmerId) {
      fetchPendingActivity(farmerId, 1).finally(() => setLoading(false));
      setPage(1);
    }
  }, [farmerId]);

  return (
    <S.Container>
      <S.Title>
        {t("pending_activities") + " "}
        <S.CountPendingAction>({dataActivities.length})</S.CountPendingAction>
      </S.Title>
      <S.WrapperTable>
        <BCTable
          total={dataActivities.length}
          data={dataActivities}
          pageSize={limit}
          showPlaceholderSearch={false}
          currentPage={page}
          loading={loading}
          columns={columns}
          showCreateButton={false}
          setSortOrder={(e) => {}}
          setSortField={(e) => {}}
          setCurrentPage={(page) => {
            setPage(page);
            fetchPendingActivity(farmerId, page).finally(() =>
              setLoading(false)
            );
          }}
          setPageSize={(e) => {
            setLimit(e);
          }}
        />
      </S.WrapperTable>
    </S.Container>
  );
};
