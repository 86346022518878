import { useEffect, useRef, useState } from "react";
import { signatureLink } from "../Farmers/service";
import ReactPlayer from "react-player";

export const MessageFileVideo = ({
  url,
  playing,
  playerRef,
  controls,
  loop,
  width,
}) => {
  const [link, setLink] = useState("");

  useEffect(() => {
    handleSignatureLink();
  }, []);

  const handleSignatureLink = async () => {
    try {
      const response = await signatureLink(url);
      setLink(response);
    } catch (error) {
      console.log("handle signature error =>", error);
    }
  };
  return (
    <ReactPlayer   
      ref={playerRef}
      url={link}
      controls={controls}
      loop={loop}
      playing={playing}
      width={width}
    />
  );
};
