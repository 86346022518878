import { Card, Checkbox } from "antd";
import styled from "styled-components";

export const Container = styled(Card)`
  width: 300px;
  overflow: auto;
  position: absolute;
  background-color: #ffffff;
  right: 28px;
  top: 28px;
  bottom: 24px;
  border-radius: 12px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 700px;
`;

export const Title = styled.h1`
  font-size: 15px;
  color: #47342e;
`;

export const ContentFilters = styled.div`
  display: flex;
  flex-direction: column;
  height: 30%;
  margin-top: 10px;
`;

export const TitleFilter = styled.h1`
  font-size: 15px;
  color: #47342e;
  font-weight: 600;
`;

export const WrapperFilter = styled.div`
  margin-top: 30px;
`;

export const WrapperCheckBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-inline-start: 0px;
`;

export const CheckboxCustom = styled(Checkbox)`
  margin-bottom: 10px;
`;

export const ContentLegends = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 30px;
  border-top-width: 1px;
  border-top-color: #3332;
  border-top-style: solid;
  padding-top: 20px;
`;

export const WrapperLegend = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  align-items: center;
`;

export const BoxIndicator = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background-color: ${props => props.color ? props.color : '#399ed8'};
  margin-right: 10px;
`;

export const TitleLegend = styled.p`
  font-size: 14px;
  color: #47342e;
`;
