import styled from "styled-components";
import { BiCheck } from "react-icons/bi";
import { MdOutlineHourglassBottom } from "react-icons/md";
import { Button, Collapse } from "antd";
import { StatusProgramType } from "./useAdditionalInformation";

const { Panel } = Collapse;

export const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 1px 1px 10px #3332;
  overflow: hidden;
  border: 1px solid #3332;
  min-width: 800px;
  max-height: 705px;
  min-height: 705px;
  margin-top: 20px;
`;

export const ButtonBack = styled(Button)`
  position: absolute;
  right: 26px;
  top: 0;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  max-height: 735px;
`;

export const ContentSteps = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  width: 30%;
`;

export const ContentInfo = styled.div`
  display: flex;
  width: 70%;
  overflow-y: auto;
`;

/**content steps */
const hasColor = (status) => {
  switch (status) {
    case StatusProgramType.PENDING:
      return "#ff9900";
    case StatusProgramType.APPROVED:
      return "#1f9652";
    default:
      return "#ff9900";
  }
};
export const HeaderSteps = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #47342e;
`;

export const WrapperListStep = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 30px;
`;

export const ItemStep = styled.div`
  display: flex;
  flex: 1;
`;

export const WrapperIndicator = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 20px;
  justify-content: center;
  align-items: center;
`;

export const CircleStep = styled.div`
  display: flex;
  width: 20px;
  min-height: 20px;
  background-color: ${(props) => hasColor(props.status)};
  border-radius: 12px;
  justify-content: center;
  align-items: center;
`;

export const IconCheck = styled(BiCheck)`
  color: #ffffff;
`;

export const IconHourglass = styled(MdOutlineHourglassBottom)`
  color: #ffffff;
`;

export const LineStep = styled.div`
  display: flex;
  flex: 1;
  border-left: 2px solid ${(props) => hasColor(props.status)};
`;

export const WrapperInfoStep = styled.div`
  margin-left: 10px;
`;

export const TitleItemStep = styled.p`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => hasColor(props.status)};
`;

export const SubTitleItemSept = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #47342e;
`;

/** content additional information */
export const ContentInformation = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
`;

export const CustomCollapson = styled(Collapse).attrs({
  bordered: false,
})`
  width: 100%;
  border: 0px;
  background-color: #fff;
`;

export const CustomPanel = styled(Panel).attrs({})`
  background-color: #fff;
  border-radius: 0px;
`;

export const TitlePanel = styled.h4`
  color: green;
  font-weight: 500;
`;

export const ItemInformation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 50px;

  + div {
    border-top: 1px solid #e5e1da;
  }
`;

export const ItemLabel = styled.h5`
  opacity: 0.4;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #332522;
`;

export const ItemInfo = styled.p``;
