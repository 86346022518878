import { FaWallet } from "react-icons/fa";
import { SlGraph } from "react-icons/sl";
import { MdPriceChange } from "react-icons/md";
import { Button, Card } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  margin-right: 10px;
  height: 140px;
  min-width: 420px;
  max-width: 500px;
  box-shadow: 1px 1px 10px #3331;
  background: linear-gradient(0.25turn, #7a6b65, #7a6b65, #47342e);
  border-radius: 8px;
  padding: 0 18px;
  margin-top: 10px;

  :hover {
    box-shadow: 1px 1px 16px #3333;
    cursor: pointer;
  }

  @media screen and (max-width: 1506px) {
    width: none;
    max-width: none;
    flex:1;
  }

  @media screen and (max-width: 1006px) {
    width: 100%;
    max-width: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const IconWallet = styled(FaWallet)`
  margin-right: 10px;
  color: #ffff;
  font-size: 18px;
`;

export const TitleWalletCard = styled.h3`
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #ffff;
`;

export const ContentWallet = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const WrapperTotal = styled.div`
  width: 20%;
  margin-top: 8px;
`;

export const TotalText = styled.p`
  width: 57px;
  height: 32px;
  font-size: 32px;
  font-weight: 300;
  line-height: 0.7;
  color: #ffffff;
  text-align: center;
`;

export const TotalLabel = styled.span`
  width: 66px;
  height: 19px;
  margin: 5px 2px 0 0;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  color: ${(props) => (props.active ? "#ffffff" : "#fff8")};
`;

export const WrapperActions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const ButtonActionWallet = styled(Button).attrs({
  style: {
    color: "#ffbc00",
  },
  shape: "default",
  type: "text",
})`
  display: flex;
  margin-left: 16px;
`;

export const IconGraphic = styled(SlGraph)`
  margin-right: 6px;
  font-size: large;
`;

export const IconPriceChange = styled(MdPriceChange)`
  margin-right: 6px;
  font-size: large;
`;

export const TitleAction = styled.span``;
