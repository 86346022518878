import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: #fff;
  width: 50%;
  margin: 20px;
  max-width: 471px;
  min-width: 350px;
  max-height: 170px;
  padding: 0 30px;
  border: 1px solid #3331;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
`;

export const WrappeCollunm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TitleCountTotal = styled.h1`
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: rgba(71, 52, 46, 0.72);
  margin-top: -20px;
`;

export const Subtitle = styled.h3`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin-top: -10px;
`;

export const TitleStatus = styled.p`
  margin: 4px 0;
`;

export const InfoCountByStatus = styled.p``;
