import { Image, Space } from "antd";
import styled from "styled-components";

export const Container = styled.div``;

export const WrapperInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const TotalArea = styled.h2`
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: rgba(71, 52, 46, 0.72);
`;

export const DescriptionArea = styled.span`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: rgba(71, 52, 46, 0.72);
`;

export const NameAgent = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #47342e;
`;

export const DescriptionOrganization = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: left;
  color: rgba(71, 52, 46, 0.72);
`;

export const ImageArea = styled.img`
  width: 100%;
  max-width: 230px;
  min-width: 230px;
  height: 260px;
  border-radius: 8px;
  object-fit: cover;
`;

export const WrapperButtonNavigate = styled(Space)`
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

export const WrapperButtonBack = styled(Space)`
  position: absolute;
  right: 10px;
  top: 10px;
`;
