import styled, { css } from "styled-components";

import {
  statusWithBlueColor,
  statusWithGrayColor,
  statusWithGreenColor,
  statusWithRedColor,
  statusWithYellowColor,
} from "../../../../../utils/statusWithColor";

export const Title = styled.h3`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.68;
  letter-spacing: normal;
  text-align: left;
  color: #47342e;

  margin-top: 2.5rem;
  margin-left: 1.25rem;
  margin-bottom: 2.5rem;
`;

export const Container = styled.div`
  width: 100%;
  max-height: 100vh;

  height: 100vh;

  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const Content = styled.div`
  display: flex;

  width: 100%;
  background-color: #fffaf0;

  flex-direction: column;
`;

export const CloseButton = styled.button`
  background-color: black;
  border: none;
  width: 3rem;
  height: 3rem;
`;

export const StatusAssistanceFarmers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0.5rem 0;
  border-radius: 20px;
  font-size: 0.875rem;

  text-align: center;

  ${(props) =>
    statusWithYellowColor.includes(props.status) &&
    css`
      color: #ffbc00;
    `}

  ${(props) =>
    statusWithRedColor.includes(props.status) &&
    css`
      color: #e74c3c;
    `}

  ${(props) =>
    statusWithGreenColor.includes(props.status) &&
    css`
      color: #1f9652;
    `}

  ${(props) =>
    statusWithGrayColor.includes(props.status) &&
    css`
      color: #b2bec3;
    `}

  ${(props) =>
    statusWithBlueColor.includes(props.status) &&
    css`
      color: #399ed8;
    `}
`;
