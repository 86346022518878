import styled from "styled-components";
import { FaClipboardCheck } from "react-icons/fa";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  max-height: 140px;
  max-width: 224px;
  margin-right: 10px;
  min-width: 224px;
  box-shadow: 1px 1px 10px #3331;
  background-color: #fff;
  border-radius: 8px;
  padding: 22px;
  border: 1px solid #3332;

  margin-top: 10px;

  :hover {
    box-shadow: 1px 1px 16px #3333;
    cursor: pointer;
  }

  @media screen and (max-width: 1506px) {
    width: none;
    max-width: none;
    flex:1;
  }

  @media screen and (max-width: 1006px) {
    width: 100%;
    max-width: none;
  }
`;

export const HeaderCard = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: rgba(51, 37, 34, 0.8);
`;

export const IconClipboard = styled(FaClipboardCheck)`
  font-size: 22px;
  color: rgba(51, 37, 34, 0.8);
`;

export const ContentCard = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const WrapperTotal = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
`;

export const TotalText = styled.h2`
  font-size: 36px;
  font-weight: 400;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  text-align: left;
  color: #fd9927;
`;

export const LabelTotal = styled.span`
  font-size: 12px;
  color: #fd9927;
  margin-top: 10px;
`;
