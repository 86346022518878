import React, { useEffect } from "react";
import "./assistance-modal-styles.css";
import { Modal, Table } from "antd";
import { useTranslation } from "react-i18next";
import { StarOutlined, CloseOutlined, StarTwoTone } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";

import * as S from "./AssistanceModalStyles";
import translateStatus from "../../../../../utils/translateStatus";

function AssistanceModal({ isModalOpen, assistances }) {
  const modalRoot = document.getElementById("assistance-modal-container");
  const { innerHeight: height } = window;

  const isMobile = useMediaQuery({ maxWidth: 790 });

  const { t } = useTranslation();

  const columns = [
    {
      title: "Data",
      dataIndex: "created",
      key: "created",
      align: "center",
    },
    {
      title: "Tipo",
      dataIndex: "request_type",
      key: "request_type",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, data) => {
        return (
          <S.StatusAssistanceFarmers status={data.status}>
            {translateStatus(t)[data.request][data.status]}
          </S.StatusAssistanceFarmers>
        );
      },
    },
  ];

  return (
    <>
      <div id="assistance-modal-container"></div>
      <Modal
        getContainer={modalRoot}
        open={isModalOpen}
        closable={false}
        footer={null}
        bodyStyle={{
          width: "100%",
          padding: 0,

          border: "none",
        }}
        style={{
          width: "100%",

          position: "absolute",
          minHeight: height,
          maxHeight: height,
          right: 0,
          top: 58,
          padding: 0,
        }}
        maskStyle={{}}
      >
        <S.Container>
          <S.CloseButton>
            <CloseOutlined
              style={{ color: "#ffff", fontSize: "1.2rem" }}
            ></CloseOutlined>
          </S.CloseButton>
          <S.Content>
            <S.Title>
              {t("farmer_assistance")}({assistances.length})
            </S.Title>
            <Table
              className="sss"
              style={{
                overflowY: "scroll",
              }}
              columns={columns}
              dataSource={assistances ?? []}
              pagination={false}
            />
          </S.Content>
        </S.Container>
      </Modal>
    </>
  );
}

export default AssistanceModal;
