import React from 'react'
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import BaseLayout from "../../pages/BaseLayout";
import ChecklistDashboard from "../../pages/Checklist/ChecklistDashboard";
import ChecklistList from "../../pages/Checklist/ChecklistList";
import ChecklistView from "../../pages/Checklist/ChecklistView";
import Private from "../Private";

function ChecklistRoutes() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Private>
            <BaseLayout breadcrumb={`Checklists`}>
              <ChecklistDashboard />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/:type/list"
        element={
          <Private>
            <BaseLayout
              gotoback={"/checklists"}
              breadcrumb={`Checklists / ${t("List")}`}
            >
              <ChecklistList />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/:id/view"
        element={
          <Private>
            <BaseLayout
              gotoback={"/checklists"}
              breadcrumb={`Checklists / ${t("View")}`}
            >
              <ChecklistView />
            </BaseLayout>
          </Private>
        }
      />
    </Routes>
  );
}

export default ChecklistRoutes;
