import { EyeOutlined } from "@ant-design/icons";

import { Button, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BCTable from "../../components/BCTable";
import { overview } from "./service";

export default function ActionsPlansDashBoard() {
  const navigate = useNavigate();
  const [overviewData, setOverview] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const findOverview = useCallback(async () => {
    setLoading(true);
    const result = await overview();
    result.map((x) => (x.key = x.checklist_name));
    setOverview(result);

    setLoading(false);
  }, []);

  useEffect(() => {
    findOverview();
  }, [findOverview]);

  const onView = (key) => {
    navigate(`/action_plans/${key}/list`);
  };

  const columns = [
    {
      title: "Checklist",
      dataIndex: "checklist_name",
      align: "center",
    },
    {
      title: t("In_Progress"),
      dataIndex: "action_plan_in_progress",
      align: "center",
    },
    {
      title: t("Pending_Approval"),
      dataIndex: "action_plan_pending_approval",
      align: "center",
    },
    {
      title: t("Finalized"),
      dataIndex: "action_plan_completed",
      align: "center",
    },
  ];

  const actionsColumn = [
    {
      title: t("Actions"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={() => onView(record.key)}
            icon={<EyeOutlined />}
          >
            {t("View")}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h1>{t("Overview_Action_Plans")}</h1>
      <BCTable
        data={overviewData}
        columns={columns}
        actionsColumn={actionsColumn}
        showCreateButton={false}
        showPlaceholderSearch={false}
        disablePagination={true}
        loading={loading}
      />
    </div>
  );
}
