import styled from "styled-components";
import { SignatureIcon } from "../../../../../assets/customIcons";
import { TbCloudUpload } from "react-icons/tb";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  max-height: 140px;
  min-height: 140px;
  max-width: 254px;
  margin-right: 10px;
  min-width: 254px;
  box-shadow: 1px 1px 10px #3331;
  background: #fff;
  border-radius: 8px;
  padding: 18px;
  border: 1px solid #3332;

  position: relative;

  justify-content: space-between;
  margin-top: 10px;

  :hover {
    box-shadow: 1px 1px 16px #3333;
    cursor: pointer;
  }

  @media screen and (max-width: 1506px) {
    width: none;
    max-width: none;
    flex: 1;
  }

  @media screen and (max-width: 1006px) {
    width: 100%;
    max-width: none;
  }
`;

export const HeaderContract = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: rgba(51, 37, 34, 0.8);
`;

export const IconSinatureContract = styled.img.attrs({
  src: SignatureIcon,
})`
  width: 22px;
  color: rgba(51, 37, 34, 0.8);
`;

export const ContentContract = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: #ada8a7;
  font-size: 14px;
`;

export const ContractDescription = styled.span``;

export const ContractName = styled.p`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #ada8a7;
`;

export const ContractInfo = styled.p`
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #ada8a7;
`;

export const LoadingContainer = styled.div`
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  flex: 1;

  width: 100%;
  height: 100%;

  transform: translateX(-18px) translateY(-18px);

  margin: 5rem;

  background-color: rgba(255, 255, 255, 0.5);

  margin: 0;
  padding: 0;
`;

export const WrapperInput = styled.div`
  /* margin-top: 13px; */
  display: flex;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  border: 1px dashed rgba(51, 37, 34, 0.3);
  cursor: pointer;
`;

export const LabelInput = styled.label`
  text-align: center;
`;

export const ButtonInput = styled.button``;

export const InputUpload = styled.input`
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background-color: red;
  cursor: pointer;
`;

export const IconUpload = styled(TbCloudUpload)`
  width: 100%;
  font-size: 30px;
`;

export const WrapperLoading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CountPercentText = styled.span`
  margin-top: 10px;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #ada8a7;
`;
