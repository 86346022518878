export const phoneMaskBR = (event) => {
  if (event.target.value.length > 14) {
    const x = event.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
    event.target.value = !x[2]
      ? x[1]
      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
  } else {
    const x = event.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
    event.target.value = !x[2]
      ? x[1]
      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
  }
};

export const phoneMask = (phone) => {
  if (phone.length > 14) {
    var x = phone.replace(/\D/g, "").match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
    phone = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
  } else {
    var x = phone.replace(/\D/g, "").match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
    phone = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
  }

  return phone;
};

export const toLowerCase = (event) => {
  event.target.value = event.target.value.toLowerCase();
};

export const avoidSpaces = (event) => {
  const space = event.charCode;
  if (space === 32) {
    event.preventDefault();
  }
};

export const phoneMaskBRDefault = (value) => {
  const x = value.replace(/\D/g, "").match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
  return (value = !x[2]
    ? x[1]
    : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : ""));
};

export function formatCpf(event) {
  event.target.value = event.target.value.replace(/\D/g, "");
  event.target.value = event.target.value.replace(
    /(\d{3})(\d{3})(\d{3})(\d{2})/,
    "$1.$2.$3-$4"
  );

  return event.target.value;
}

export function formatCnpj(event) {
  event.target.value = event.target.value.replace(/\D/g, "");
  event.target.value = event.target.value.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5"
  );

  return event.target.value;
}

export function formatCpfDefault(value) {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

  return value;
}

export const insertMask = (value, mask) => {
  value = value.replace(/\D/g, "");

  switch (mask) {
    case "cpf":
      if (value.length <= 11) {
        value = value.replace(/(\d{3})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      }

      return value;
    case "cnpj":
      if (value.length <= 14) {
        value = value.replace(/^(\d{2})(\d)/, "$1.$2");
        value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
        value = value.replace(/(\d{4})(\d)/, "$1-$2");
      }

      return value;
    case "phone":
      value = value.split("55")[1] || "";
      if (value.length <= 11) {
        value = value.replace(/(\d{2})(\d)/, "($1) $2");
        value = value.replace(/(\d{5})(\d{4})/, "$1-$2");
      }

      return value;
    default:
      return value;
  }
};
