import axiosE from "../../apis/axiosInterceptorEntitlement";
import axios from "../../apis/axiosInterceptorFarmer";

export const overview = async () => {
  const result = await axiosE.get("/v1/agent/for-regions-and-organizations");
  return result.data.data;
};

export const infosDash = async () => {
  const result = await axios.get("/v2/program/infos-dash");
  return result.data.data;
};

export const getEnrolledVsImplemented = async () => {
  const { data } = await axios.get(
    `v1/graphics/enrolled-vs-implemented-regional-overview`
  );
  return data.data;
};

export const getEnrolledVsImplementedTop10 = async () => {
  const { data } = await axios.get(
    `/v1/graphics/enrolled-vs-implemented-top-10-field-agents-rate`
  );
  return data.data;
};

export const getEnrolledVsImplementedPerYear = async () => {
  const { data } = await axios.get(
    `/v1/graphics/farmers-enrolled-vs-implemented-rate-over-the-year`
  );
  return data.data;
};

export const getEnrolledVsImplementedPerYearID = async (agentId) => {
  const { data } = await axios.get(
    `/v1/graphics/farmers-enrolled-vs-implemented-rate-over-the-year?agentId=${agentId}`
  );
  return data.data;
};

export const getFarmersVsFieldAgentRegion = async () => {
  const { data } = await axios.get(`/v1/graphics/farmers-vs-fiel-agents-region`);
  return data.data;
};

export const getEnrolledImplementedAreas = async () => {
  const { data } = await axios.get(`/v1/graphics/enrolled-vs-implemented-areas`);
  return data.data;
};

export const getFarmersEnrolledFarmersImplemented = async () => {
  const { data } = await axios.get(
    `/v1/graphics/farmers-enrolled-vs-farmers-implemented`
  );
  return data.data;
};

export const getFarmersVsImplementation = async () => {
  const { data } = await axios.get(
    `/v1/graphics/farmers-vs-implementation-per-visits`
  );
  return data.data;
};

export const getListUsers = async (page) => {
  const { data } = await axiosE.get(`/v1/user/agents?limit=10&page=${page}`);
  return data.data;
}