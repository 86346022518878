import React, { useContext } from 'react'
import { Avatar, Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";
import brazilFlag from "../assets/br.svg";
import usaFlag from "../assets/us.svg";
import { useLanguage } from "../contexts/languageContext";

// Esse componente não necessidade de Propriedades, ele é responsavel em setar a linguagem do usuário em toda a aplicação
// TODO: Deve ser integrado com a troca de Lingua do Usuário quando o mesmo estiver Logado
function BCLanguageSelector() {
  const { t } = useTranslation();
  const { language, changeLanguage } = useLanguage();

  const languageMenu = (
    <Menu onClick={(e) => changeLanguage(e.key)}>
      <Menu.Item key="pt">
        <img
          src={brazilFlag}
          alt="Bandeira do Brasil"
          width="20"
          height="15"
          style={{ marginRight: "8px" }}
        />
        {t("Portuguese")}
      </Menu.Item>
      <Menu.Item key="en-us">
        <img
          src={usaFlag}
          alt="Bandeira dos Estados Unidos"
          width="20"
          height="15"
          style={{ marginRight: "8px" }}
        />
        {t("English")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={languageMenu} placement="bottomRight">
      <Avatar
        shape="square"
        src={language === "pt" ? brazilFlag : usaFlag}
        alt={language === "pt" ? t("Portuguese") : t("English")}
        style={{ cursor: "pointer" }}
      />
    </Dropdown>
  );
}

export default BCLanguageSelector;
