export const Colors = {
  FARM_BOUNDARIES_LINE: "#ffbc00",
  FARM_BOUNDARIES_AREA: "rgba(255, 188, 0, 0.8)",
  PROGRAM_REFORESTATION_LINE: "#59ff4e",
  PROGRAM_REFORESTATION_AREA: "rgba(89, 255, 78, 0.9)",
  PROGRAM_AGROFORESTRY_LINE: "#00efd7",
  PROGRAM_AGROFORESTRY_AREA: "rgba(0, 239, 215, 0.9)",
  INDIGENOUS_AREA_LINE: "#3bd626",
  INDIGENOUS_AREA: "rgba(30, 183, 31, 0.4)",
  PRESERVATION_AREA_LINE: "#b264fc",
  PRESERVATION_AREA: "rgba(104, 44, 251, 0.4)",
};
