import React from "react";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default function AreaEnrolledVsAreaImplemented({ data }) {
  const { t } = useTranslation();
  return (
    <BarChart
      width={600}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 10,
        left: 5,
        bottom: 5,
      }}
      barCategoryGap={50}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="description" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="implemented" name={t("Implemented")} fill="#fdbc2c" />
      <Bar dataKey="enrolled" name={t("Enrolled")} fill="#47342eb8" />
    </BarChart>
  );
}
