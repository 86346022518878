import {
  DeleteOutlined,
  PictureTwoTone,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Row,
  Select,
  Switch,
  TimePicker,
  Upload,
} from "antd";

import { format, isBefore, startOfDay } from "date-fns";
import dayjs from "dayjs";
import { Field, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import BCButtonConfirm from "../../components/BCButtonConfirm";
import states from "../../data-source/states";
import { create, edit, list } from "./service";
import "./styles.css";

const { Option } = Select;

export default function CalendarCreateOrEditEvent() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  const [isEdit, setIsEdit] = useState(id ? true : false);
  const [isPeriodSelected, setIsPeriodSelected] = useState(true);
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [initialValues, setInitialValues] = useState({
    description: "",
    title: "",
    type_event_id: "",
    date: null,
    state: "PA",
    city: "",
    address: "",
    hour: "",
    period: "MORNING",
    image: "",
    localization: "INPERSON",
  });

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("Title_is_Required")),
    type_event_id: Yup.string().required(t("Type_event_id_Is_Required")),
    localization: Yup.string().required(t("Localization_is_required")),
    description: Yup.string()
      .min(3, t("Description_min_3"))
      .required(t("Description_is_Required")),
    date: Yup.date().required(t("Date_is_required")),
    address: Yup.string()
      .min(3, t("Address_min_3"))
      .required(t("Address_is_Required")),
    state: Yup.string().required(t("State_is_Required")),
    city: Yup.string().required(t("City_is_Required")),
    period: Yup.string()
      .test("period", t("Period_is_Required"), function (value) {
        if (isPeriodSelected && !value) {
          return false;
        }
        return true;
      })
      .nullable(),
    hour: Yup.string()
      .test("hour", t("Hour_is_Required"), function (value) {
        if (!isPeriodSelected && !value) {
          return false;
        }
        return true;
      })
      .nullable(),
    image: Yup.string(),
  });

  const typesEvents = [
    {
      id: "61e40efab8b89763d0fc4c7b",
      name: t("TRAINING"),
    },
    {
      id: "61e40efab8b89763d0fc4c7a",
      name: t("WORKSHOP"),
    },
  ];

  const localizations = [
    {
      name: "INPERSON",
      description: t("INPERSON"),
    },
  ];

  const periods = [
    {
      type: "AFTERNOON",
      description: t("AFTERNOON"),
    },
    {
      type: "MORNING",
      description: t("MORNING"),
    },
  ];

  const findEvent = useCallback(async () => {
    if (isEdit) {
      const result = await list({ id: id });

      setInitialValues({
        description: result[0].description,
        title: result[0].title,
        type_event_id: result[0].type_event_id,
        date: result[0].date ? dayjs(result[0].date, "YYYY/MM/DD") : "",
        state: result[0].state,
        city: result[0].city,
        address: result[0].address,
        hour: result[0].hour ? dayjs(result[0].hour, "HH:mm") : "",
        period: result[0].period ? result[0].period : "",
        image: result[0].location ? `${result[0].location}***Editando` : "",
        localization: result[0].localization,
      });

      if (result[0].location)
        setImagePreview(`${result[0].location}***Editando`);

      if (result[0].period) {
        setIsPeriodSelected(true);
      } else {
        setIsPeriodSelected(false);
      }
    }
  }, [id, isEdit]);

  useEffect(() => {
    findEvent();
  }, [findEvent]);

  const handleSwitchChange = (checked) => {
    setIsPeriodSelected(checked);
  };
  const disabledHours = () => [0, 1, 2, 3, 4, 5, 6, 7, 19, 20, 21, 22, 23];

  async function createOrEdit(values) {
    try {
      setLoading(true);

      let formData = new FormData();
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("date", values.date);
      formData.append("address", values.address);
      formData.append("state", values.state);
      formData.append("city", values.city);

      if (!isEdit) {
        formData.append("type_event_id", values.type_event_id);
        formData.append("localization", values.localization);
      }

      if (isPeriodSelected) {
        if (values.period) formData.append("period", values.period);
      } else {
        if (values.hour)
          formData.append("hour", format(new Date(values.hour), "HH:mm"));
      }

      if (values.image && !values.image.toString().includes("***Editando"))
        formData.append("file", values.image);

      isEdit ? await edit(id, formData) : await create(formData);
      setLoading(false);
      navigate("/calendar");
    } catch (error) {
      setLoading(false);
    }
  }

  function disabledDate(current) {
    return isBefore(startOfDay(new Date(current)), startOfDay(new Date()));
  }

  return (
    <div>
      <h1>{`${t("Calendar")} / ${isEdit ? t("Edit") : t("Creation")}`}</h1>
      <Row style={{ marginTop: "15px", width: "100%", height: "100vh" }}>
        <Col span={12}>
          <Card style={{ height: "100%", borderRadius: 0 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={createOrEdit}
              enableReinitialize
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                setFieldValue,
              }) => (
                <Form layout="vertical" onFinish={handleSubmit}>
                  <Row gutter={16}>
                    <Col xs={24} sm={14}>
                      <Row gutter={16}>
                        <Col xs={24} sm={24}>
                          <Form.Item
                            help={
                              touched.type_event_id &&
                              errors.type_event_id &&
                              errors.type_event_id
                                ? errors.type_event_id
                                : ""
                            }
                            validateStatus={
                              touched.type_event_id && errors.type_event_id
                                ? "error"
                                : ""
                            }
                          >
                            <Select
                              style={{ width: "100%" }}
                              name="type_event_id"
                              value={values.type_event_id}
                              onChange={(value) =>
                                handleChange({
                                  target: { name: "type_event_id", value },
                                })
                              }
                              onBlur={handleBlur}
                              placeholder={t("Type_event")}
                              disabled={isEdit}
                              defaultValue=""
                            >
                              <Option value="" disabled>
                                {t("Type_event")}
                              </Option>
                              {typesEvents.map((x) => (
                                <Option value={x.id} key={x.id}>
                                  {x.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col xs={24} sm={24}>
                          <Form.Item
                            help={
                              touched.title && errors.title ? errors.title : ""
                            }
                            validateStatus={
                              touched.title && errors.title ? "error" : ""
                            }
                          >
                            <Input
                              name="title"
                              className="input"
                              value={values.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder={t("Title")}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} sm={24}>
                          <Form.Item
                            help={
                              touched.description && errors.description
                                ? errors.description
                                : ""
                            }
                            validateStatus={
                              touched.description && errors.description
                                ? "error"
                                : ""
                            }
                          >
                            <Input.TextArea
                              name="description"
                              className="input"
                              value={values.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder={t("Description")}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} sm={24}>
                          <Form.Item
                            help={
                              touched.date && errors.date ? errors.date : ""
                            }
                            validateStatus={
                              touched.date && errors.date ? "error" : ""
                            }
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              format="DD/MM/YYYY"
                              disabledTime={true}
                              value={values.date}
                              onChange={(value) =>
                                handleChange({
                                  target: { name: "date", value },
                                })
                              }
                              onBlur={handleBlur}
                              disabledDate={disabledDate}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col xs={24} md={24}>
                          <Form.Item
                            help={
                              touched.localization &&
                              errors.localization &&
                              errors.localization
                                ? errors.localization
                                : ""
                            }
                            validateStatus={
                              touched.localization && errors.localization
                                ? "error"
                                : ""
                            }
                          >
                            <Select
                              style={{ width: "100%" }}
                              name="localization"
                              value={values.localization}
                              onChange={(value) =>
                                handleChange({
                                  target: { name: "localization", value },
                                })
                              }
                              onBlur={handleBlur}
                              placeholder={t("Localization")}
                              defaultValue="INPERSON"
                              disabled={isEdit}
                            >
                              <Option value="" disabled>
                                {t("Localization")}
                              </Option>
                              {localizations.map((x) => (
                                <Option value={x.name} key={x.name}>
                                  {x.description}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col xs={24} md={24}>
                          <Form.Item
                            help={
                              touched.state && errors.state && errors.state
                                ? errors.state
                                : ""
                            }
                            validateStatus={
                              touched.state && errors.state ? "error" : ""
                            }
                          >
                            <Select
                              style={{ width: "100%" }}
                              name="state"
                              showSearch
                              value={values.state}
                              onChange={(value) =>
                                handleChange({
                                  target: { name: "state", value },
                                })
                              }
                              onBlur={(value) =>
                                handleBlur({
                                  target: { name: "state", value },
                                })
                              }
                              placeholder={t("States")}
                              defaultValue="PA"
                            >
                              <Option value="" disabled>
                                {t("States")}
                              </Option>
                              {states.map((x) => (
                                <Option value={x.initials} key={x.initials}>
                                  {x.initials}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col xs={24} md={24}>
                          <Form.Item
                            help={
                              touched.city && errors.city && errors.city
                                ? errors.city
                                : ""
                            }
                            validateStatus={
                              touched.city && errors.city ? "error" : ""
                            }
                          >
                            <Select
                              style={{ width: "100%" }}
                              name="city"
                              showSearch
                              value={values.city}
                              onChange={(value) =>
                                handleChange({
                                  target: { name: "city", value },
                                })
                              }
                              onBlur={handleBlur}
                              placeholder={t("Select_Cities")}
                            >
                              <Option value="" disabled>
                                {t("Select_Cities")}
                              </Option>
                              {states
                                .find((s) => s.initials === values.state)
                                .cities?.map((x) => (
                                  <Option value={x.name} key={x.name}>
                                    {x.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col xs={24} sm={24}>
                          <Form.Item
                            help={
                              touched.address && errors.address
                                ? errors.address
                                : ""
                            }
                            validateStatus={
                              touched.address && errors.address ? "error" : ""
                            }
                          >
                            <Input.TextArea
                              name="address"
                              className="input"
                              value={values.address}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder={t("Address")}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col xs={24} md={10}>
                          <Form.Item
                            help={
                              touched.period && errors.period && errors.period
                                ? errors.period
                                : ""
                            }
                            validateStatus={
                              touched.period && errors.period ? "error" : ""
                            }
                          >
                            <Select
                              style={{ width: "100%" }}
                              name="period"
                              showSearch
                              disabled={!isPeriodSelected}
                              value={values.period}
                              onChange={(value) =>
                                handleChange({
                                  target: { name: "period", value },
                                })
                              }
                              onBlur={handleBlur}
                              placeholder={t("Period")}
                              defaultValue="MORNING"
                            >
                              <Option value="" disabled>
                                {t("Period")}
                              </Option>
                              {periods.map((x) => (
                                <Option value={x.type} key={x.type}>
                                  {x.description}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={10}>
                          <Form.Item
                            help={
                              touched.hour && errors.hour && errors.hour
                                ? errors.hour
                                : ""
                            }
                            validateStatus={
                              touched.hour && errors.hour ? "error" : ""
                            }
                          >
                            <TimePicker
                              disabledHours={disabledHours}
                              style={{ width: "100%" }}
                              name="hour"
                              disabled={isPeriodSelected}
                              showSearch
                              value={values.hour}
                              onChange={(value) =>
                                handleChange({
                                  target: { name: "hour", value },
                                })
                              }
                              onBlur={handleBlur}
                              placeholder={t("Hour")}
                              format={"HH:mm"}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={4}>
                          <Switch
                            style={{
                              height: "25px",
                              backgroundColor: isPeriodSelected
                                ? "orange"
                                : "#4682B4",
                            }}
                            checked={isPeriodSelected}
                            onChange={handleSwitchChange}
                            checkedChildren="Período"
                            unCheckedChildren="Hora"
                          />
                        </Col>
                      </Row>

                      <Form.Item>
                        <BCButtonConfirm
                          style={{
                            width: "100%",
                            maxWidth: "200px",
                            marginTop: "20px",
                          }}
                          buttonText={!loading ? t("Send") : t("Sending")}
                        ></BCButtonConfirm>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={10}>
                      <Row gutter={16}>
                        <Col xs={24} md={24}>
                          <Form.Item>
                            <Field name="image">
                              {({ field }) => (
                                <>
                                  {values.image ? (
                                    <div style={{ marginBottom: "10px" }}>
                                      <Col
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Card
                                          style={{
                                            marginTop: "25px",
                                            display: "flex",
                                            width: "350px",
                                            height: "385px",
                                            overflow: "hidden", // adicionado para evitar que a imagem ultrapasse os limites do Card
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Image
                                            src={
                                              values.image
                                                .toString()
                                                .includes("***Editando")
                                                ? values.image.replace(
                                                    "***Editando",
                                                    ""
                                                  )
                                                : URL.createObjectURL(
                                                    values.image
                                                  )
                                            }
                                            alt="Imagem"
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "cover", // mantém a proporção da imagem
                                            }}
                                          />
                                        </Card>
                                        <Button
                                          danger
                                          style={{ marginTop: "20px" }}
                                          icon={<DeleteOutlined />}
                                          onClick={() => {
                                            setFieldValue("image", "");
                                            setImagePreview(null);
                                          }}
                                        >
                                          {t("RemoveImage")}
                                        </Button>
                                      </Col>
                                    </div>
                                  ) : (
                                    <Col
                                      style={{
                                        marginTop: "25px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Card
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          width: "300px",
                                          height: "330px",
                                        }}
                                      >
                                        <PictureTwoTone
                                          twoToneColor="#D3D3D3"
                                          style={{
                                            fontSize: "150px",
                                          }}
                                        />
                                      </Card>
                                      <Upload
                                        accept="image/*"
                                        beforeUpload={(file) => {
                                          setFieldValue("image", file);
                                          setImagePreview(file);
                                          return false;
                                        }}
                                        fileList={
                                          values.image ? [values.image] : []
                                        }
                                      >
                                        <Button
                                          style={{ marginTop: "20px" }}
                                          icon={<UploadOutlined />}
                                          block
                                        >
                                          {t("UploadImage")}
                                        </Button>
                                      </Upload>
                                    </Col>
                                  )}
                                  <input type="hidden" {...field} />
                                </>
                              )}
                            </Field>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Card>
        </Col>
        <Col span={12}>
          <Card
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f6f6f6",
              height: "100%",
              borderRadius: 0,
            }}
          >
            {imagePreview && (
              <Image
                src={
                  imagePreview.toString().includes("***Editando")
                    ? imagePreview.replace("***Editando", "")
                    : URL.createObjectURL(imagePreview)
                }
                alt="Imagem"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}
