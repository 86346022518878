import { EyeOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Space, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { TYPEUSER } from "../../Enums/Users.enum";
import BCTable from "../../components/BCTable";
import { listUsers, retrieveFirstAccessCodeService } from "./service";

export default function AdministrationListAdmins({
  resendFirstAccessCode = true,
}) {
  const navigate = useNavigate();
  const is1170 = useMediaQuery({ maxWidth: 1120 });
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [sortField, setSortField] = useState("name");
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  const findUsers = useCallback(async () => {
    setLoading(true);
    const result = await listUsers(
      pageSize,
      currentPage,
      TYPEUSER.AGENT,
      search,
      sortOrder,
      sortField
    );
    result.result.map((x) => (x.key = x._id));
    setUsers(result.result);
    setTotal(result.count);
    setLoading(false);
  }, [pageSize, currentPage, search, sortOrder, sortField]);

  useEffect(() => {
    findUsers();
  }, [findUsers]);

  const onView = (key) => {
    navigate(`/agents/${key}/view`);
  };

  const onAdd = () => {
    navigate("/administration-users/create/agent");
  };

  const retrieveFirstAccessCode = async (key) => {
    const user = users.find((x) => x._id === key);
    await retrieveFirstAccessCodeService(user.email);
  };

  const columns = [
    { 
      title: t("Name"),
      dataIndex: "name",
      align: "center",
      sorter: true,
      width: "10%",
    },
    {
      title: t("Organization"),
      dataIndex: "organization",
      align: "center",
      sorter: false,
      width: "10%",
      render: (text, data) => (
        <Tag color="success" key={data.organization.name}>
          {data.organization.name}
        </Tag>
      ),
    },
    {
      title: t("Regions"),
      dataIndex: "regions",
      align: "center",
      sorter: false,
      width: !is1170 ? "30%" : "40%",
      render: (text, data) => (
        <>
          {data.regions.map((r) => (
            <Tag color="processing" key={r.name}>
              {r.name}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: t("Last_access_date"),
      dataIndex: "last_access",
      align: "center",
      sorter: true,
      width: 150,
      render: (text, record) => (
        <span key={record.timeLastAccess}>
          {record.timeLastAccess
            ? new Date(record.timeLastAccess).toLocaleDateString()
            : t("Never_accessed")}
        </span>
      ),
    },
    {
      title: t("Added_in"),
      dataIndex: "createdAt",
      align: "center",
      sorter: true,
      width: 150,
      render: (text, record) => (
        <span key={record.createdAt}>
          {new Date(record.createdAt).toLocaleDateString()}
        </span>
      ),
    },
  ];

  const actionsColumn = [
    {
      title: t("Actions"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          {resendFirstAccessCode && (
            <Button
              shape="round"
              className="confirm_button"
              type="dashed"
              onClick={() => retrieveFirstAccessCode(record.key)}
              icon={<ReloadOutlined />}
            >
              {t("Resend_first_access_code")}
            </Button>
          )}

          <Button
            shape="round"
            type="dashed"
            onClick={() => onView(record.key)}
            icon={<EyeOutlined />}
          >
            {t("View")}
          </Button>
        </Space>
      ),
    },
  ];

  console.log({ users });

  return (
    <div>
      <BCTable
        data={users}
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        placeholderSearch={`${t("Search")} ${t("Agents")}`}
        setSearch={setSearch}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        loading={loading}
        columns={columns}
        actionsColumn={actionsColumn}
        showCreateButton={true}
        textButtonAdd={t("Add_new_user")}
        onView={onView}
        onAdd={onAdd}
      />
    </div>
  );
}
