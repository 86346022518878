import { Card, Col, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { countUsers } from "./service";

const AdministrationAndUsers = () => {
  const { t } = useTranslation();
  const [numberAgents, setNumberAgents] = useState(0);
  const [numberAdmins, setNumberAdmins] = useState(0);

  const findCount = useCallback(async () => {
    const data = await countUsers();
    setNumberAgents(data.find((x) => x._id === "agent").count);
    setNumberAdmins(data.find((x) => x._id === "admin").count);
  }, []);

  useEffect(() => {
    findCount();
  }, [findCount]);

  const navigate = useNavigate();

  return (
    <div>
      <h1>{t("Overview_Users")}</h1>
      <Row style={{ marginTop: "10px" }} gutter={[16, 16]} justify="start">
        <Col xs={24} sm={12} md={6}>
          <Card
            onClick={() => navigate("/administration-users/list-agents")}
            style={{ cursor: "pointer" }}
            className="custom-card"
          >
            <div className="card-content">
              <div
                className="centered-content"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h1 style={{ fontSize: "4rem" }}>{numberAgents}</h1>
                <h4 style={{ color: "#555555" }}>{t("Agents")}</h4>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card
            onClick={() => navigate("/administration-users/list-admins")}
            style={{ cursor: "pointer" }}
            className="custom-card"
          >
            <div className="card-content">
              <div
                className="centered-content"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h1 style={{ fontSize: "4rem" }}>{numberAdmins}</h1>
                <h4 style={{ color: "#555555" }}>{t("Administrators")}</h4>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AdministrationAndUsers;
