import styled from "styled-components";
import { Column } from "@ant-design/plots";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 80%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WrapperTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background-color: #3333;
  margin-top: 18px;
  padding: 0 15px;
  border-radius: 18px;
`;

export const Title = styled.h3`
    font-size: 14px;
    color: #47342e;
    font-weight: 400;

    span {
      font-weight: bold;
      color: #3336;
    }
`;

export const WrapperGraphic = styled.div`
`;

export const CustomColumn = styled(Column).attrs({
  color: ["#47342e", "#1f9652", "#64cfcf", "#ff9900"],
  xField: "period",
  yField: "value",
  seriesField: "name",
  isGroup: true,
  marginRatio: 0.1,
  dodgePadding: 10,
  intervalPadding: 50,
  minColumnWidth: 20,
  maxColumnWidth: 20,
  appendPadding: 40,
  legend: {
    layout: "horizontal",
    position: "bottom",
    maxRow: 1,
    offsetY: 0,
  },

  columnStyle: {
    radius: [20, 20, 20, 20],
  },

  label: {
    position: "top",
    color: ["#47342e", "#1f9652", "#64cfcf", "#ff9900"],
    layout: [
      {
        type: "interval-adjust-position",
      },
      {
        type: "interval-hide-overlap",
      },
      {
        type: 'adjust-color',
      },
    ],
  },
})`
  display: flex;
  flex: 1;
  width: 100%;
`;
