import { EyeOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { default as React, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BCTable from "../../components/BCTable";
import { list } from "./service"; 
import {format} from 'date-fns'

export default function SuppliersDashboard() {
  const navigate = useNavigate();

  const [suppliers, setSuppliers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [sortField, setSortField] = useState("name");
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const listSuppliers = useCallback(async () => {
    setLoading(true);
    const result = await list(
      pageSize,
      currentPage,
      search,
      sortOrder,
      sortField
    );
    result.result.map((x) => (x.key = x._id));
    setSuppliers(result.result);
    setTotal(result.count);
    setLoading(false);
  }, [pageSize, currentPage, search, sortOrder, sortField]);
  useEffect(() => {
    listSuppliers();
  }, [listSuppliers]);
  const onView = (key) => {
    navigate(`${key}/view`);
  };

  const columns = [
    {
      title: t("Name"),
      dataIndex: "name",
      align: "center",
      sorter: true,
      width: "10%",
    },
    {
      title: t("Responsible"),
      dataIndex: "Responsible",
      align: "center",
      sorter: false,
      width: "10%",
      render: (text, data) => data.responsible_name,
    },
    {
      title: t("Email"),
      dataIndex: "Email",
      align: "center",
      sorter: true,
      render: (text, data) => data.email,
    },
    {
      title: t("Added_in"),
      dataIndex: "createdAt",
      align: "center",
      sorter: true,
      render: (text, data) => format(new Date(data.createdAt), 'dd/MM/yyyy'),
    },
  ];

  const actionsColumn = [
    {
      title: t("Actions"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={() => onView(record.key)}
            icon={<EyeOutlined />}
          >
            {t("View")}
          </Button>
        </Space>
      ),
    },
  ];

  const onAdd = () => {
    navigate("create/suppliers");
  };
  return (
    <div>
      <BCTable
        data={suppliers}
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        placeholderSearch={`${t("Search")} ${t("Suppliers")}`}
        setSearch={setSearch}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        loading={loading}
        columns={columns}
        actionsColumn={actionsColumn}
        showCreateButton={true}
        textButtonAdd={t("Add_new_Suppliers")}
        onAdd={onAdd}
      />
    </div>
  );
}
