import { AREA_MAP } from "../../../Enums/AreaMap.enum";
export const AdapterListArea = (areas, _id) => {
  const farmArea = {
    ...areas.farm_area,
    _id,
    area_name: "Área da Propriedade",
    area_type: AREA_MAP.FARM_BOUNDARIES,
    area_size: areas.farm_area.farm_size,
  };

  const programAreas = areas.program_area.map(area => ({ ...area, _id}))

  return [farmArea, ...programAreas];
};
