import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./CardTotalEventStyles";

import { getCarbonPackage } from "../../../service";

export const CardTotalEvent = ({ farmerId }) => {
  const [calculatedProducts, setCalculatedProducts] = useState();
  const { t } = useTranslation();

  const fetchData = async () => {
    const data = await getCarbonPackage({ id: farmerId });

    if (data?.packages && data.packages.length > 0) {
      const calculatedProducts = calculateProductsStatement(data.packages[0]);

      setCalculatedProducts(calculatedProducts);
    }
  };

  useEffect(() => {
    fetchData();
  }, [farmerId]);

  const calculateProductsStatement = (pckg) => {
    let available = 0;
    let consumed = 0;

    if (pckg.can_do_module_1_traning) {
      available += pckg.amount_of_participation_module_1;
      consumed += pckg.amount_of_participation_in_module_1_used;
    }
    if (pckg.can_do_module_2_traning) {
      available += pckg.amount_of_participation_module_2;
      consumed += pckg.amount_of_participation_in_module_2_used;
    }
    if (pckg.can_do_module_3_traning) {
      available += pckg.amount_of_participation_module_3;
      consumed += pckg.amount_of_participation_in_module_3_used;
    }
    if (pckg.can_do_module_4_traning) {
      available += pckg.amount_of_participation_module_4;
      consumed += pckg.amount_of_participation_in_module_4_used;
    }
    if (pckg.can_do_module_5_traning) {
      available += pckg.amount_of_participation_module_5;
      consumed += pckg.amount_of_participation_in_module_5_used;
    }

    return {
      trainings: {
        total: available,
        used: consumed,
      },
      group_visits: {
        total: pckg.number_of_visits_in_group,
        used: pckg.number_to_visit_in_groups_used,
      },
      individual_visits: {
        total: pckg.number_of_individual_field_visits,
        used: pckg.number_of_visits_received,
      },
    };
  };

  return (
    <S.Container>
      <S.WrapperTotal>
        <S.Chip>
          <S.TitleChip>
            {calculatedProducts?.trainings?.used ?? 0} de{" "}
            {calculatedProducts?.trainings?.total ?? 0}
          </S.TitleChip>
        </S.Chip>
        <S.LabelTotal>{t("Attended_training")}</S.LabelTotal>
      </S.WrapperTotal>

      <S.WrapperTotal>
        <S.Chip>
          <S.TitleChip>
            {calculatedProducts?.individual_visits?.used ?? 0} de{" "}
            {calculatedProducts?.individual_visits?.total ?? 0}
          </S.TitleChip>
        </S.Chip>
        <S.LabelTotal>{t("Individual_field_visit")}</S.LabelTotal>
      </S.WrapperTotal>

      <S.WrapperTotal>
        <S.Chip>
          <S.TitleChip>
            {calculatedProducts?.group_visits?.used ?? 0} de{" "}
            {calculatedProducts?.group_visits?.total ?? 0}
          </S.TitleChip>
        </S.Chip>
        <S.LabelTotal>{t("Group_field_visit")}</S.LabelTotal>
      </S.WrapperTotal>
    </S.Container>
  );
};
