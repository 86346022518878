import styled from "styled-components";

export const NotificationCenterMessage = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;

  cursor: pointer;

  svg {
    width: 6%;
  }

  div {
    width: 85%;
    margin-left: 1rem;
  }

  h4 {
    color: var(--brown);
    font-size: 15px;
    font-weight: 600;
  }

  p {
    margin: 0 0 !important;
    font-size: 14px !important;
    color: var(--brown);
  }

  span {
    width: 7px;
    height: 7px;
    margin: 5px 5px 10px 5px;
    background-color: var(--erro);
    border-radius: 100%;
  }

  :not(:first-child) {
    border-top-color: rgba(51, 51, 51, 0.08);
    border-top-style: solid;
    border-top-width: 1px;
  }

  :hover {
    background-color: rgba(51, 51, 51, 0.08);
  }
`;

export const IconNotificationCenterOpen = () => (
  <svg
    width="18.546"
    height="17.619"
    viewBox="0 0 18.546 17.619"
    style={{ alignSelf: "flex-start" }}
  >
    <defs>
      <linearGradient
        id="7ecv6slbaa"
        x1="1"
        x2="0"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#47342e" />
        <stop offset="1" stopColor="#47342e" stopOpacity=".718" />
      </linearGradient>
    </defs>
    <path
      d="M20.537 7.491a1.838 1.838 0 0 0-.872-1.576L11.273 1 2.881 5.915A1.846 1.846 0 0 0 2 7.491v9.273a1.86 1.86 0 0 0 1.855 1.855h14.836a1.86 1.86 0 0 0 1.855-1.855zm-9.264 4.636-7.66-4.784 7.659-4.488 7.659 4.488z"
      transform="translate(-2 -1)"
      fill="url(#7ecv6slbaa)"
    />
  </svg>
);

export const RedDot = styled.div`
  background-color: red;
  border-radius: 360px;
  min-width: 0.5rem;
  min-height: 0.5rem;
  max-width: 0.5rem;
  max-height: 0.5rem;
`;
