import axios from "../../apis/axiosInterceptorFarmer";

export const overview = async () => {
  const result = await axios.get("v1/action_plan/overview");
  return result.data.data;
};

export const listActionsPlans = async ({
  limit = 10,
  page = 1,
  checklistName,
  status = "",
  id = "",
  farmerId,
  agentId = "",
}) => {
  let url = `v1/action_plan?limit=${limit}&page=${page}&status=${status}&id=${id}&agentId=${agentId}`;

  if (checklistName) url += `&checklistName=${checklistName}`;
  if (farmerId) url += `&farmerId=${farmerId}`;

  const result = await axios.get(url);
  return result.data.data;
};
