import axios from "../../apis/axiosInterceptorFarmer";

export const listProgramAreas = async ({farmId = ""}) => {
    let param = farmId ? `?farmId=${farmId}` : '';
    const result = await axios.get(`v1/farm-maps${param}`);
    return result.data.data;
}

export const listAreaIndigenous = async () => {
    const result = await axios.get(`v1/farm-maps/list-areas-indigenous-lands`);
    return result.data.data;
}

export const listAreaPresevartion = async () => {
    const result = await axios.get(`v1/farm-maps/list-areas-peservation-lands-para`);
    return result.data.data;
}