import { EyeOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BCTable from "../../components/BCTable";
import { overviewChecklist } from "./service";

export default function ChecklistDashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [infosChecklistCards, setInfosChecklistCards] = useState([]);
  const [checklistGrid, setChecklistGrid] = useState([]);

  const findInfo = useCallback(async () => {
    const result = await overviewChecklist();
    result.grid.map((x) => (x.key = x.checklistType));
    setChecklistGrid(result.grid);
    setInfosChecklistCards([
      {
        NAME: t("Performed"),
        COUNT: result.checklist_performed,
        COLOR: "#fffaf0",
        TEXT_COLOR: "black",
      },
      {
        NAME: t("In_Progress"),
        COUNT: result.checklist_in_progress,
        COLOR: "#fffaf0",
        TEXT_COLOR: "black",
      },
      {
        NAME: t("Finalized"),
        COUNT: result.checklist_completed,
        COLOR: "rgb(79, 175, 73)",
        TEXT_COLOR: "white",
      },
    ]);
  }, [t]);

  useEffect(() => {
    findInfo();
  }, [findInfo]);

  function onView(key) {
    navigate(`/checklists/${key}/list`);
  }

  const columns = [
    {
      title: "Checklist",
      dataIndex: "name",
      align: "start",
      render: (text, data) => <span>{data.checklist[0].name}</span>,
    },
    {
      title: t("Realized_Quantum"),
      dataIndex: "count",
      align: "start",
      width: 250,
    },
    {
      title: t("LastResponse"),
      dataIndex: "lastResponse",
      align: "start",
      width: 150,
      render: (text, data) => (
        <span key={data.lastResponse}>
          {new Date(data.lastResponse).toLocaleDateString()}
        </span>
      ),
    },
  ];

  const actionsColumn = [
    {
      title: t("Actions"),
      key: "action",
      align: "center",
      render: (text, data) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={() => onView(data.key)}
            icon={<EyeOutlined />}
          >
            {t("View")}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h1>{t("Overview_Checklist")}</h1>
      <Card>
        <Row style={{ marginTop: "20px" }} justify={"center"} gutter={[16, 16]}>
          {infosChecklistCards.map((c, index) => {
            return (
              <Col key={index}>
                <Card
                  style={{
                    cursor: "default",
                    height: "150px",
                    width: "250px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: c.COLOR,
                  }}
                  className="#fffaf0"
                >
                  <div className="card-content">
                    <h1 style={{ fontSize: "3rem", color: c.TEXT_COLOR }}>
                      {c.COUNT}
                    </h1>
                    <div
                      className="centered-content"
                      style={{ fontSize: "1.0rem", color: c.TEXT_COLOR }}
                    >
                      {c.NAME}
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Card>
      <Card style={{ marginTop: "20px" }}>
        <Row gutter={[16, 16]}>
          <h1>Checklists</h1>
          <div style={{ marginTop: "20px" }}>
            <BCTable
              data={checklistGrid}
              columns={columns}
              actionsColumn={actionsColumn}
              showCreateButton={false}
              showPlaceholderSearch={false}
              disablePagination={true}
            />
          </div>
        </Row>
      </Card>
    </div>
  );
}
