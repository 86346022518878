import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BCGraphic } from "../../../../../components/BCGraphic";
import { getEnrolledVsImplemented } from "../../../service";
import * as S from "./CardGraphicAreaStyles";

export const CardGraphicArea = () => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    loadDataGraphic();
  }, []);

  const loadDataGraphic = async () => {
    try {
      const response = await getEnrolledVsImplemented();

      const serializerDataGraphic = [];

      response.forEach((item) => {
        serializerDataGraphic.push({
          period: t(item.region),
          value: parseInt(item.total_afs_enrolled_brown),
          name: t("AFS_enrolled"),
        });
        serializerDataGraphic.push({
          period: t(item.region),
          value: parseInt(item.total_afs_implemented_green),
          name: t("AFS__implemented"),
        });
        serializerDataGraphic.push({
          period: t(item.region),
          value: parseInt(item.total_er_enrollled_blue),
          name: t("ER_enrolled"),
        });
        serializerDataGraphic.push({
          period: t(item.region),
          value: parseInt(item.total_er_implemented_orange),
          name: t("ER_implemented"),
        });
      });

      setData(serializerDataGraphic);
    } catch (error) {}
  };

  return (
    <S.Container>
      <BCGraphic
        data={data}
        title={t("Farmers_enrolled_and_deployed_regional_overview")}
      />
    </S.Container>
  );
};
