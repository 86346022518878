import * as S from "./BCGraphicStyles";

export const BCGraphic = ({ title, data = [] }) => {
  return (
    <S.Container>
      <S.Header>
        <S.WrapperTitle>
          <S.Title>{title}</S.Title>
        </S.WrapperTitle>
      </S.Header>
      <S.WrapperGraphic>
        <S.CustomColumn data={data} />
      </S.WrapperGraphic>
    </S.Container>
  );
};
