import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  FormOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  List,
  Row,
  Space,
  Tag,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GiFarmer } from "react-icons/gi";
import { MdSupportAgent } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { ITEMS_STATUS } from "../../Enums/ActionsPlans.enum";
import { listActionsPlans } from "./service";

const { Panel } = Collapse;

export default function ActionPlanView() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [actionsPlan, setActionPlan] = useState({
    farmer: {
      name: "",
      identifier: "",
    },
    farm: {
      city: "",
      state: "",
    },
    agent_name: "",
    checklist_name: "",
    items: [],
  });

  const findActionPlans = useCallback(async () => {
    const result = await listActionsPlans({
      id: id,
    });
    setActionPlan(result.result[0]);
  }, [id]);

  useEffect(() => {
    findActionPlans();
  }, [findActionPlans]);

  const onChange = (key) => {
    console.log(key);
  };

  const responseStatus = (item) => {
    console.log("item", item);
    if (item.status === ITEMS_STATUS.PENDING) {
      return (
        <Tag icon={<ClockCircleOutlined spin />} color="warning">
          {t("PENDING")}
        </Tag>
      );
    }
    if (item.status === ITEMS_STATUS.REVIEW) {
      return <Tag>{t("REVIEW")}</Tag>;
    }

    if (item.status === ITEMS_STATUS.RESOLVED) {
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          {t("RESOLVED")}
        </Tag>
      );
    }

    if (item.status === ITEMS_STATUS.REJECTED) {
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          {t("REJECTED")}
        </Tag>
      );
    }
  };
  const onView = (item) => {
    navigate(`/action_plans/${id}/view-item/${item.id}`);
  };

  return (
    <div>
      <Row style={{ marginTop: "40px", width: "100%", height: "100vh" }}>
        <Col span={6}>
          <Card
            style={{
              backgroundColor: "#f6f6f6",
              height: "100%",
              borderRadius: 0,
            }}
          >
            <Row style={{ marginTop: "10px" }}>
              <div>
                {t("Created_in")}{" "}
                {new Date(actionsPlan.createdAt).toLocaleDateString()}
              </div>
            </Row>
            <Row style={{ marginTop: "40px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <GiFarmer
                  style={{
                    color: "rgb(79, 175, 73)",
                    fontSize: "25px",
                    marginRight: "5px",
                  }}
                />
                <div style={{ color: "rgb(79, 175, 73)", fontSize: "20px" }}>
                  {t("Farmer")}
                </div>
              </div>
            </Row>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: "15px" }}>
                {actionsPlan.farmer.name.charAt(0).toUpperCase() +
                  actionsPlan.farmer.name.slice(1)}
              </div>
              <div style={{ fontSize: "15px" }}>
                {actionsPlan.farmer.identifier}
              </div>
              <div>
                {actionsPlan.farm.city} - {actionsPlan.farm.state}
              </div>
            </Row>

            <Row style={{ marginTop: "40px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <MdSupportAgent
                  style={{
                    color: "rgb(79, 175, 73)",
                    fontSize: "25px",
                    marginRight: "5px",
                  }}
                />
                <div style={{ color: "rgb(79, 175, 73)", fontSize: "20px" }}>
                  {t("Agent")}
                </div>
              </div>
            </Row>

            <Row
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "15px",
                }}
              >
                {actionsPlan.agent_name.charAt(0).toUpperCase() +
                  actionsPlan.agent_name.slice(1)}
              </div>
            </Row>
            <Divider />
            <Row style={{ marginTop: "40px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormOutlined
                  style={{
                    color: "rgb(79, 175, 73)",
                    fontSize: "25px",
                    marginRight: "5px",
                  }}
                />
                <div style={{ color: "rgb(79, 175, 73)", fontSize: "20px" }}>
                  {t("Completed_checklist")}
                </div>
              </div>
            </Row>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: "15px" }}>
                {new Date(actionsPlan.createdAt).toLocaleDateString()}
              </div>
              <div style={{ fontSize: "20px" }}>
                {actionsPlan.checklist_name}
              </div>
            </Row>
          </Card>
        </Col>
        <Col span={18}>
          <Card style={{ height: "100%", borderRadius: 0 }}>
            <Collapse
              style={{ backgroundColor: "white" }}
              bordered={false}
              onChange={onChange}
            >
              {actionsPlan.items.map((a, index) => {
                return (
                  <Panel header={a.actions[0].module_name} key={index}>
                    <List
                      dataSource={a.actions}
                      renderItem={(item) => (
                        <List.Item>
                          <Col
                            span={16}
                            style={{
                              display: "flex",
                              justifyContent: "start",
                            }}
                          >
                            {item.item_title}
                          </Col>

                          <Col span={16}>
                            <Row
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Col
                                span={8}
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                }}
                              >
                                {responseStatus(item)}
                              </Col>
                              <Col
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Space size="middle">
                                  <Button
                                    shape="round"
                                    type="dashed"
                                    onClick={() => onView(item)}
                                    icon={<EyeOutlined />}
                                  >
                                    {t("View")}
                                  </Button>
                                </Space>
                              </Col>
                            </Row>
                          </Col>
                        </List.Item>
                      )}
                    />
                  </Panel>
                );
              })}
            </Collapse>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
