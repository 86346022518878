import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "./CardTotalAreaRestorationStyles";

export const CardTotalAreaRestoration = ({
  totalRestorationEnrolled,
  totalRestorationImplemented,
}) => {
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.WrapperCountArea>
        <S.TitleCountArea>
          {Math.round(totalRestorationEnrolled)} ha
        </S.TitleCountArea>
      </S.WrapperCountArea>
      <S.LabelDescription>{t("Restauration_area")}</S.LabelDescription>
      <S.TotalAreaImplemented>
        vs {Math.round(totalRestorationImplemented)} {t("ha_implemented")}
      </S.TotalAreaImplemented>
    </S.Container>
  );
};
