import axios from "../../apis/axiosInterceptorEntitlement";
import apiClient from "../../apis/axiosInterceptorFarmer";

export const countUsers = async () => {
  const result = await axios.get(`v2/user/count_users`);
  return result.data.data;
};

export const listUsers = async (limit, page, type, search, sort, field) => {
  const result = await axios.get(
    `/v2/user?limit=${limit}&page=${page}&type=${type}&search=${search}&sort=${sort}&field=${field}`
  );
  return result.data.data;
};

export const getAgent = async (id) => {
  const result = await axios.get(`v1/user/agent/${id}`);
  return result.data.data;
};
export const getUsers = async (id) => {
  const result = await axios.get(`v1/user/${id}`);
  return result.data.data;
};

export const retrieveFirstAccessCodeService = async (email) => {
  await axios.post(`/v2/user/${email}/retrieve-first-access-code`);
};

export const getOrganizations = async () => {
  const result = await axios.get(`/v1/organization`);
  return result.data.data;
};

export const getPermissions = async () => {
  const result = await axios.get(`/v1/permission?limit=200`);
  return result.data.data;
};

export const getRegions = async () => {
  const result = await axios.get(`/v1/regions`);
  return result.data.data;
};

export const getUserById = async (id) => {
  const { data } = await axios.get(`/v1/user/${id}`);
  return data.data;
};

export const createAdmin = async (
  name,
  email,
  phone,
  organizationId,
  permissions
) => {
  const payload = {
    name,
    email,
    phone,
    organizationId,
    permissions,
  };
  await axios.post(`v2/user/register-user/admin`, payload);
};

export const createAgent = async (
  name,
  email,
  phone,
  organizationId,
  regions
) => {
  const payload = {
    name,
    email,
    phone,
    organizationId,
    regions,
  };
  await axios.post(`v2/user/register-user/agent`, payload);
};

export const editUser = async (values) => {
  const payload = {
    user_id: values.user_id,
    name: values.salesmanName,
    email: values.email,
    phone: values.phone,
  };
  await axios.put(`v2/user/edit`, payload);
};

export const editAdminUser = async (data) => {
  const dataResult = await axios.put("/v2/user/edit", data);
  return dataResult.data;
};

export const countFarmsPerAgent = async (limit, page, id) => {
  const result = await apiClient.get(
    `/v1/farm?limit=${limit}&page=${page}&agentId=${id}`
  );
  return result.data;
};

export const updateUser = async (agent) => {
  const { data } = await axios.put("/v2/user/edit", agent);
  return data;
};

export const getAgentById = async (id) => {
  const { data } = await axios.get(`/v1/user/agent/${id}`);
  return data.data;
};
