import { EyeOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Space, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BCListFarms from "../../components/utils/BCListFarms";
import AreaEnrolledVsAreaImplemented from "./Graphics/AreaEnrolledVsAreaImplemented";
import AreaEnrolledVsAreaImplementedPerHa from "./Graphics/AreaEnrolledVsAreaImplementedPerHa";
import {
  findAgentsPerRegions,
  graphicAreaEnrolledVsAreaImplemented,
  graphicAreaEnrolledVsAreaImplementedPerHa,
  infosDash,
} from "./service";

export default function FarmersDashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [dataGraphic, setDataGraphic] = useState([]);
  const [dataGraphic2, setDataGraphic2] = useState([]);
  const [agentsPerRegion, setagentsPerRegion] = useState([]);
  const [infoDash, setInfoDash] = useState([
    {
      NAME: t("IN_PROGRESS"),
      COUNT: 0,
      COLOR: "white",
      TEXT_COLOR: "black",
    },
    {
      NAME: t("FLAGGED_FOR_REVIEW"),
      COUNT: 0,
      COLOR: "white",
      TEXT_COLOR: "black",
    },
    {
      NAME: t("APPLICATION_DENIED"),
      COUNT: 0,
      COLOR: "white",
      TEXT_COLOR: "black",
    },
    {
      NAME: t("ENROLLED_TO_THE_PROGRAM"),
      COUNT: 0,
      COLOR: "green",
      TEXT_COLOR: "white",
    },
  ]);

  const listAgentsPerRegions = useCallback(async () => {
    const result = await findAgentsPerRegions();
    setagentsPerRegion(result.agentsPerRegions);
  }, []);
  useEffect(() => {
    listAgentsPerRegions();
  }, [listAgentsPerRegions]);

  const findInfoDash = useCallback(async () => {
    const result = await infosDash();
    const infos = [
      {
        NAME: t("IN_PROGRESS"),
        COUNT: result.IN_PROGRESS,
        COLOR: "#fffaf0",
        TEXT_COLOR: "black",
        ONCLICK: "farmer/in-progress",
      },
      {
        NAME: t("FLAGGED_FOR_REVIEW"),
        COUNT: result.FLAGGED_FOR_REVIEW,
        COLOR: "#fffaf0",
        TEXT_COLOR: "black",
        ONCLICK: "farmer/flagged-for-review",
      },
      {
        NAME: t("APPLICATION_DENIED"),
        COUNT: result.APPLICATION_DENIED,
        COLOR: "#fffaf0",
        TEXT_COLOR: "black",
        ONCLICK: "farmer/application-denied",
      },
      {
        NAME: t("ENROLLED_TO_THE_PROGRAM"),
        COUNT: result.ENROLLED_TO_THE_PROGRAM,
        COLOR: "rgb(79, 175, 73)",
        TEXT_COLOR: "white",
        AGENTS_PER_AREA: "",
        ONCLICK: "farmer/enrolled-to-the-program",
      },
    ];
    setInfoDash(infos);
  }, [t]);

  const findAreaEnrolledVsAreaImplemented = useCallback(async () => {
    const result = await graphicAreaEnrolledVsAreaImplemented();
    setDataGraphic([
      {
        enrolled: result.total_enrolled,
        implemented: result.total_implemented,
        description: "Total",
      },
      {
        enrolled: result.total_afs_enrolled,
        implemented: result.total_afs_implemented,
        description: "AFS",
      },
      {
        enrolled: result.total_er_enrollled,
        implemented: result.total_er_implemented,
        description: "ER",
      },
    ]);
  }, []);

  const findAreaEnrolledVsAreaImplementedPerHa = useCallback(async () => {
    const result = await graphicAreaEnrolledVsAreaImplementedPerHa();
    setDataGraphic2([
      {
        enrolled: result?.first_segment?.enrolled,
        implemented: result?.first_segment?.implemented,
        description: result?.first_segment?.name,
      },
      {
        enrolled: result?.second_segment?.enrolled,
        implemented: result?.second_segment?.implemented,
        description: result?.second_segment?.name,
      },
      {
        enrolled: result?.third_segment?.enrolled,
        implemented: result?.third_segment?.implemented,
        description: result?.third_segment?.name,
      },
      {
        enrolled: result?.fourth_segment?.enrolled,
        implemented: result?.fourth_segment?.implemented,
        description: result?.fourth_segment?.name,
      },
      {
        enrolled: result?.fifth_segment?.enrolled,
        implemented: result?.fifth_segment?.implemented,
        description: result?.fifth_segment?.name,
      },
    ]);
  }, []);

  useEffect(() => {
    findInfoDash();
    findAreaEnrolledVsAreaImplemented();
    findAreaEnrolledVsAreaImplementedPerHa();
  }, [
    findInfoDash,
    findAreaEnrolledVsAreaImplemented,
    findAreaEnrolledVsAreaImplementedPerHa,
  ]);

  const actionsColumn = [
    {
      title: t("user_history"),
      align: "center",
      render: (text, record) => record.farmer?.legacy_user && <Tag color="warning">{t("user_legacy")}</Tag>,
    },
    {
      title: t("Actions"),
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={() => navigate(`/farmers/detail/${record._id}/farmer`)}
            icon={<EyeOutlined />}
          >
            {t("View")}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h1>{t("Dashboard")}</h1>
      <Card>
        <Row style={{ marginTop: "20px" }} justify={"center"} gutter={[16, 16]}>
          {infoDash.map((a, index) => {
            return (
              <Col key={index}>
                <Card
                  onClick={() => navigate(a.ONCLICK)}
                  style={{
                    cursor: "pointer",
                    height: "150px",
                    width: "250px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: a.COLOR,
                  }}
                >
                  <div className="card-content">
                    <h1 style={{ fontSize: "3rem", color: a.TEXT_COLOR }}>
                      {a.COUNT}
                    </h1>
                    <div
                      className="centered-content"
                      style={{ fontSize: "1.0rem", color: a.TEXT_COLOR }}
                    >
                      {a.NAME}
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Card>
      <Card style={{ marginTop: "20px" }}>
        <Row gutter={[16, 16]}>
          <Col xxl={12} style={{ marginTop: "40px" }}>
            <div style={{ marginLeft: "190px", fontSize: "1.2rem" }}>
              {t("Graphic_3")}
            </div>
            <AreaEnrolledVsAreaImplemented data={dataGraphic} />
          </Col>
          <Col xxl={12} style={{ marginTop: "40px" }}>
            <div style={{ marginLeft: "120px", fontSize: "1.2rem" }}>
              {t("Graphic_4")}
            </div>
            <AreaEnrolledVsAreaImplementedPerHa data={dataGraphic2} />
          </Col>
        </Row>
      </Card>
      <Card style={{ marginTop: "20px" }}>
        <Row gutter={[16, 16]}>
          <h1>{t("Farmers")}</h1>
          <div style={{ marginTop: "20px" }}>
            <BCListFarms actionsColumn={actionsColumn} />
          </div>
        </Row>
      </Card>
    </div>
  );
}
