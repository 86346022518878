import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Row, Select, Space } from "antd";
import { format, subDays, subMonths, subYears } from "date-fns";
import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { REPORT_ENUM } from "../../Enums/Reports.enum";
import BCButtonConfirm from "../../components/BCButtonConfirm";
import BCTable from "../../components/BCTable";
import { list, requestReport } from "./service";
import { signatureLink } from "../Farmers/service";

const { Option } = Select;

export default function ReportDashboard() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    type: Yup.string().required(t("Type_report_is_required")),
    period: Yup.string().required(t("Period_is_Required")),
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [reports, setReports] = useState([
    {
      _id: "",
      identifier: "",
      type: "",
      status: "",
      format: "",
      user_id: "",
      file: "",
      period: "",
      sent_in: new Date(),
      generated_in: new Date(),
    },
  ]);

  const findReports = useCallback(async () => {
    setLoading(true);
    const result = await list({ limit: pageSize, page: currentPage });
    result.result.map((x) => (x.key = x._id));
    setReports(result.result);
    setTotal(result.count);
    setLoading(false);
  }, [pageSize, currentPage]);

  useEffect(() => {
    findReports();
    const intervalId = setInterval(findReports, 10000);
    return () => clearInterval(intervalId);
  }, [findReports]);

  const handleDownload = async  (file, type) => {
    const linkSignature = await signatureLink(file)
    let originalname = "";
    const link = document.createElement("a");
    link.href = linkSignature;
    switch (type) {
      case "INCOMPLETE_EXTRA_INFORMATION_CRITERIA":
        originalname = t("INCOMPLETE_EXTRA_INFORMATION_CRITERIA");
        break;
      case "SIGNATURE_OF_CONTRACT":
        originalname = t("SIGNATURE_OF_CONTRACT");
        break;
      default:
        return null;
    }

    link.download = originalname;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onAdd = () => {
    navigate("/notifications/create");
  };

  async function toGenerate(values) {
    let period = new Date();
    switch (values.period) {
      case "LAST_30_DAYS":
        period = subDays(period, 30);
        break;
      case "LAST_6_MONTHS":
        period = subMonths(period, 6);
        break;
      case "LAST_YEAR":
        period = subYears(period, 1);
        break;

      default:
        break;
    }
    const payload = {
      format: "pdf",
      type: values.type,
      period: values.period,
      greater_equal_to: format(period, "yyyy-MM-dd"),
    };
    await requestReport(payload);
    setOpenModal(false);
    await findReports();
  }

  const typesReport = [
    {
      type: "INCOMPLETE_EXTRA_INFORMATION_CRITERIA",
      name: t("INCOMPLETE_EXTRA_INFORMATION_CRITERIA"),
    },
    {
      type: "SIGNATURE_OF_CONTRACT",
      name: t("SIGNATURE_OF_CONTRACT"),
    },
  ];

  const periods = [
    {
      type: "LAST_30_DAYS",
      name: t("LAST_30_DAYS"),
    },
    {
      type: "LAST_6_MONTHS",
      name: t("LAST_6_MONTHS"),
    },
    {
      type: "LAST_YEAR",
      name: t("LAST_YEAR"),
    },
  ];

  const columns = [
    {
      title: t("Type_Report"),
      dataIndex: "type",
      align: "start",
      render: (text, data) => {
        switch (data.type) {
          case "INCOMPLETE_EXTRA_INFORMATION_CRITERIA":
            return (
              <span key={data.type}>
                {t("INCOMPLETE_EXTRA_INFORMATION_CRITERIA")}
              </span>
            );
          case "SIGNATURE_OF_CONTRACT":
            return <span key={data.type}>{t("SIGNATURE_OF_CONTRACT")}</span>;
          default:
            return null;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "start",
      render: (text, data) => {
        switch (data.status) {
          case REPORT_ENUM.GENERATED:
            return <span key={data.type}>{t("GENERATED")}</span>;
          case REPORT_ENUM.SENT:
            return <span key={data.type}>{t("SENT")}</span>;
          case REPORT_ENUM.GENERATING:
            return <span key={data.type}>{t("GENERATING")}</span>;
          default:
            return null;
        }
      },
    },
    {
      title: t("GeneratedIn"),
      dataIndex: "generated_in",
      align: "start",
      render: (text, data) => (
        <span key={data.generated_in}>
          {data.generated_in
            ? new Date(data.generated_in).toLocaleDateString()
            : ""}
        </span>
      ),
    },
  ];

  const actionsColumn = [
    {
      title: t("Actions"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            disabled={record.status !== REPORT_ENUM.GENERATED}
            onClick={() => handleDownload(record.file, record.type)}
            icon={<DownloadOutlined />}
          >
            Download
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <BCTable
        data={reports}
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        placeholderSearch={`${t("Search")} ${t("Message")}`}
        setSearch={() => {}}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        setSortField={() => {}}
        setSortOrder={() => {}}
        loading={loading}
        columns={columns}
        actionsColumn={actionsColumn}
        showCreateButton={false}
        showPlaceholderSearch={false}
        textButtonAdd={t("New_Message")}
        onAdd={onAdd}
        children={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => setOpenModal(true)}
              shape="round"
              className="confirm_button"
              type="dashed"
              style={{ marginBottom: "10px" }}
            >
              {t("New_report")}
            </Button>
          </div>
        }
      />

      <Modal
        title={t("New_report")}
        open={openModal}
        footer={null}
        onCancel={() => setOpenModal(false)}
      >
        <Formik
          initialValues={{
            period: "",
            type: "",
          }}
          validationSchema={validationSchema}
          onSubmit={toGenerate}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
          }) => (
            <Form layout="vertical" onFinish={handleSubmit}>
              <Row style={{ marginTop: "30px" }} gutter={16}>
                <Col xs={24} sm={24}>
                  <Row gutter={16}>
                    <Col xs={24} md={24}>
                      <Form.Item
                        help={
                          touched.period && errors.period && errors.period
                            ? errors.period
                            : ""
                        }
                        validateStatus={
                          touched.period && errors.period ? "error" : ""
                        }
                      >
                        <Select
                          style={{ width: "100%" }}
                          name="period"
                          value={values.period}
                          onChange={(value) =>
                            handleChange({
                              target: { name: "period", value },
                            })
                          }
                          onBlur={handleBlur}
                          placeholder={t("Period")}
                        >
                          <Option value="" disabled>
                            {t("Period")}
                          </Option>
                          {periods.map((x) => (
                            <Option value={x.type} key={x.type}>
                              {x.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={
                          touched.type && errors.type && errors.type
                            ? errors.type
                            : ""
                        }
                        validateStatus={
                          touched.type && errors.type ? "error" : ""
                        }
                      >
                        <Select
                          style={{ width: "100%" }}
                          name="type"
                          value={values.type}
                          onChange={(value) =>
                            handleChange({
                              target: { name: "type", value },
                            })
                          }
                          onBlur={handleBlur}
                          placeholder={t("Type_Report")}
                        >
                          <Option value="" disabled>
                            {t("Type_Report")}
                          </Option>
                          {typesReport.map((x) => (
                            <Option value={x.type} key={x.type}>
                              {x.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Form.Item>
                <BCButtonConfirm
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    marginTop: "20px",
                  }}
                  buttonText={t("To_generate")}
                ></BCButtonConfirm>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}
