import { HiDocument } from "react-icons/hi2";
import { MdDownload } from "react-icons/md";
import { IoMdClose } from "react-icons/io";

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  min-width: 220px;
  height: 70px;
  margin: 10px;
  padding: 0 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 1px 1px 10px #3332;
  justify-content: space-between;
  align-items: center;
  /* transition: 0.2s; */

  /* :hover {
    box-shadow: 1px 1px 10px #3338;
  } */
`;

export const Icon = styled(HiDocument)`
  font-size: 30px;
  color: #47342e;
`;

export const Content = styled.div`
  margin-left: 10px;
`;

export const Title = styled.h3`
  color: #47342e;
  font-size: 14px;
  font-family: sans-serif;
  font-weight: 400;
`;

export const Info = styled.p`
  color: #3338;
  font-size: 10px;
`;

export const WrapperIcons = styled.div`
  display: flex;
  padding: 0 4px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
`;

export const DownloadIdcon = styled(MdDownload)`
  color: #1f9652d1;
  font-size: 26px;
  cursor: pointer;
`;

export const WrapperIconDelete = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: #f109;
  margin-left: 10px;
  cursor: pointer;
`;

export const IconClose = styled(IoMdClose)`
  color: #ffffff;
  font-size: 16px;
`;
