import * as S from "./CardContractProgramStyles";
import { useTranslation } from "react-i18next";

import {
  fetchAssociatePackage,
  fetchCocoaContracts,
  fetchContractByProgramId,
  fetchUploadContract,
  signatureLink,
  uploadContract,
} from "../../../service";
import { useState } from "react";
import { useEffect } from "react";
import { Spin, Progress } from "antd";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
export const CardContractProgram = ({ contracts, farm, onLoadContract }) => {
  const navigate = useNavigate();
  const [contract] = contracts ? contracts : [];
  const [linkContract] = contract?.link_contract ? contract.link_contract : [];
  const [isContractApproved, setisContractApproved] = useState(false);
  const [program, setProgram] = useState();
  const [loadingUploadContract, setLoadingUploadContract] = useState(false);
  const [contractProgram, setContractProgram] = useState();
  const [showBoxContract, setShowBoxContract] = useState(false);
  const [percent, setPercent] = useState(0);

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  // const fetchContractInfo = async () => {
  //   setLoading(true);

  //   const response = await fetchCocoaContracts();

  //   const contracs = response.data.data;

  //   const info = contracs.find((c) => c._id === contract?.program_id);

  //   setContractInfo(info);
  // };

  useEffect(() => {
    setLoading(true);
    if (farm.program) {
      const rules = farm.program[0]?.program.rules;
      const status = checkCriteriaStatus(3, rules);
      const status_contract = rules[3]?.status;
      if (status_contract === "APPROVED") {
        setisContractApproved(true);
      } else {
        setisContractApproved(false);
      }

      if (status === "APPROVED") {
        setShowBoxContract(true);
      }
    }
    if (farm.program) {
      setProgram(farm.program[0]);
    }
    setLoading(false);
  }, [farm]);

  useEffect(() => {
    if (program) {
      loadContractByProgram(program?.program_id);
    }
  }, [program]);

  // useEffect(() => {
  //   if (contract) {
  //     fetchContractInfo().finally(() => setLoading(false));
  //   }
  // }, [contract]);

  const loadContractByProgram = async (programId) => {
    try {
      const response = await fetchContractByProgramId(programId);
      const contract = response?.data;
      setContractProgram(contract);
    } catch (error) {
      console.log("error load contract by program =>", error);
    }
  };

  const handleContract = async (data) => {
    try {
      if (!contractProgram) {
        return toast.error("message_2_error_import_contract");
      }

      const formData = new FormData();
      formData.append("file", data.target.files[0]);

      setLoadingUploadContract(true);
      const responseUploadContract = await fetchUploadContract(
        formData,
        (e) => {
          const progress = e.progress * 100;
          setPercent(Number.parseInt(progress));
        }
      );

      const body = {
        contract_id: contractProgram._id,
        farm_id: farm?._id,
        latitude: "0.000",
        longitude: "0.000",
        file: responseUploadContract?.message,
      };

      await uploadContract(body);

      await fetchAssociatePackage({
        package_id: "6654d48afc65dfbba80570ef",
        contracts_cocoa: [contractProgram._id],
        farmer_id: farm?._id,
      });

      toast.success(t("message_import_contract"));
      // onLoadContract?.();
      setisContractApproved(true);
      // setLoadingUploadContract(false)
    } catch (error) {
      toast.error(t("message_1_error_import_contract"));
      console.log("error send contrato", error);
      setLoadingUploadContract(false);
    }
  };

  const handleOpenContract = async () => {
    try {
      if (!isContractApproved) return;
      navigate(`/farmers/detail/${farm._id}/farmer/information-additional`);
    } catch (error) {
      console.log("error ao abrir contrato", error);
    }
  };

  function checkCriteriaStatus(criteriaKey, rules) {
    const criteria = rules.find((c) => c.key === criteriaKey);

    if (!criteria) return;

    const allApproved = criteria.rules.every(
      (rule) => rule.status === "APPROVED"
    );
    const anyRejected = criteria.rules.some(
      (rule) => rule.status === "REJECTED"
    );

    if (criteriaKey === 1) {
      if (allApproved) {
        return "APPROVED";
      } else if (anyRejected) {
        return "REJECTED";
      } else {
        return "PENDING";
      }
    } else if (criteriaKey === 2) {
      if (allApproved) {
        return "APPROVED";
      } else if (anyRejected) {
        return "REVIEW";
      } else {
        return "REVIEW";
      }
    } else if (criteriaKey === 3) {
      if (allApproved) {
        return "APPROVED";
      } else {
        return "PENDING";
      }
    } else {
      return "PENDING";
    }
  }

  if (!showBoxContract) return <></>;

  return (
    <S.Container onClick={handleOpenContract}>
      {isContractApproved ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Progress
            percent={100}
            type="circle"
            size="small"
            style={{ marginTop: 6 }}
          />
          <S.ContractName style={{ marginTop: 10 }}>
            {t("title_contract_signed")}
          </S.ContractName>
        </div>
      ) : (
        <S.ContentContract>
          {loadingUploadContract ? (
            <S.WrapperLoading style={{ display: "flex" }}>
              <Progress percent={percent} type="circle" size="small" />
            </S.WrapperLoading>
          ) : (
            <S.WrapperInput>
              <S.IconUpload />
              <S.LabelInput>{t("label_send_contract")}</S.LabelInput>
              <S.InputUpload type="file" onChange={handleContract} />
            </S.WrapperInput>
          )}
        </S.ContentContract>
      )}

      {loading && (
        <S.LoadingContainer>
          <Spin></Spin>
        </S.LoadingContainer>
      )}
    </S.Container>
  );
};
