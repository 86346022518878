import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  margin-top: 30px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
`;

export const IntersectedAreas = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
  padding: 0.25rem 0.5rem;
  background-image: linear-gradient(83deg, #ffbc00 5%, #ff9900 100%);
  border-radius: 14px;
  margin-top: 10px;

  > div {
    display: flex;

    p {
      margin: 0 !important;
      padding-left: 0.5rem;
      color: #fff;
    }
  }

  svg {
    width: 1.5rem;
    fill: #fff;
  }
`;

export const IconOverlay = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="17.8"
    viewBox="0 0 18 17.8"
  >
    <path
      d="M129,231.8l-9-7,1.25-.925L129,229.9l7.75-6.025,1.25.925Zm0-3.8-9-7,9-7,9,7ZM129,220.475Zm0,5.625,6.55-5.1L129,215.9l-6.55,5.1Z"
      transform="translate(-120 -214)"
    />
  </svg>
);
