import { MailOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import { Formik } from "formik";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import BCButtonConfirm from "../../components/BCButtonConfirm";
import { AuthContext } from "../../contexts/auth";
import BaseEntitlement from "../BaseEntitlement";

function RecoverPass() {
  const { t } = useTranslation();
  const { passwordRecoveryRequest, loadingAuth } = useContext(AuthContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("Invalid_Email"))
      .required(t("Email_is_required")),
  });

  async function passwordRecovery(values) {
    await passwordRecoveryRequest(values.email);
  }

  return (
    <BaseEntitlement
      title={t("RecoverPass")}
      subtitle={t("SubTitle_Recover_Pass")}
      to={"/login"}
    >
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={passwordRecovery}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
        }) => (
          <Form layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              help={touched.email && errors.email ? errors.email : ""}
              validateStatus={touched.email && errors.email ? "error" : ""}
            >
              <div style={{ marginTop: "20px" }}>
                <Input
                  prefix={<MailOutlined />}
                  name="email"
                  value={values.email}
                  className="input"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("Email")}
                />
              </div>
            </Form.Item>
            <Form.Item>
              <div style={{ marginTop: "20px" }}>
                <BCButtonConfirm
                  buttonText={!loadingAuth ? t("Send") : t("Sending")}
                ></BCButtonConfirm>
              </div>

              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <h4 style={{ fontWeight: "normal" }}>
                  {t("SubTitle_Recover_Pass_2")}
                </h4>
              </div>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </BaseEntitlement>
  );
}

export default RecoverPass;
