import { AREA_MAP } from "../../../Enums/AreaMap.enum";

export const AdapterAreas = (area_remote) => {
  const convertGeometryArrayInObject = (geometry) => {
    if (!geometry) return [];
    const coordinates = geometry.coordinates[0].map((coord) => ({
      lat: coord[1],
      lng: coord[0],
    }));
    const center_point = !geometry?.point
      ? null
      : {
          lat: geometry.point.coordinates[1],
          lng: geometry.point.coordinates[0],
        };
    return {
      coordinates,
      farm_id: geometry.farm_id,
      area_type: geometry.type,
      center_point,
      identifier: geometry.identifier,
      status: geometry.status,
    };
  };

  const execute = () => {
    const farmArea = convertGeometryArrayInObject({
      ...area_remote.geometry,
      farm_id: area_remote.farm_id,
      type: AREA_MAP.FARM_BOUNDARIES,
      point: area_remote.point,
      identifier: area_remote.identifier,
      status: area_remote.status,
    });

    farmArea.customColor = area_remote.customColor;

    const programAreas = area_remote.programAreas.map((program) =>
      convertGeometryArrayInObject({
        ...program.geometry,
        farm_id: area_remote.farm_id,
        type: program.area_type,
        point: program.point,
        identifier: program.identifier,
        status: program.status,
      })
    );

    const areas = [farmArea, ...programAreas];

    return areas;
  };

  const areaReservation = (area_type) => {
    const areas = area_remote.map((area) => {
      const coordinates = area.geometry.coordinates[0][0].map((coord) => ({
        lat: coord[1],
        lng: coord[0],
      }));

      return {
        coordinates,
        area_type,
      };
    });

    return areas;
  };

  return {
    execute,
    areaReservation,
  };
};
