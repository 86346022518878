import apiClient from "../../apis/axiosInterceptorFarmer";
import axiosE from "../../apis/axiosInterceptorEntitlement";

export const listFarmsService = async ({
  id="",
  page = 1,
  search = "",
  sortOrder = 1,
  sortField = "name",
}) => {
  console.log(page, search);
  const response = await apiClient.get(
    `/v3/farm_admin?limit=10&page=${page}&search=${search}&withProgram=true&sort=${sortOrder}&field=${sortField}&withMaps=true&_id=${id}`
  );
  return response.data.data;
};

export const getOverviewAreasService = async () => {
  const response = await apiClient.get("/v1/program/areas-overview");
  return response.data.data;
};


export const findByIdUser = async ({ _id }) => {
  const result = await axiosE.get(
    `/v2/user?_id=${_id}`
  );
  return result.data.data;
};