import axiosCalendar from "../../apis/axiosInterceptorCalendar";
import axiosCarbon from "../../apis/axiosInterceptorCarbon";
import axiosChat from "../../apis/axiosInterceptorChat";
import axiosE from "../../apis/axiosInterceptorEntitlement";
import {
  default as apiClient,
  default as axios,
} from "../../apis/axiosInterceptorFarmer";

export const infosDash = async () => {
  const result = await axios.get("/v2/program/infos-dash");
  return result.data.data;
};

export const graphicAreaEnrolledVsAreaImplemented = async () => {
  const result = await axios.get("/v1/graphics/enrolled-vs-implemented-areas");
  return result.data.data;
};

export const graphicAreaEnrolledVsAreaImplementedPerHa = async () => {
  const result = await axios.get(
    "/v1/graphics/farmers-enrolled-vs-farmers-implemented"
  );
  return result.data.data;
};

export const findByIdFarm = async ({ id = "" }) => {
  const response = await apiClient.get(
    `/v3/farm_admin?withProgram=true&withInfoAgents=true&withContract=true&withActionsPlan=true&withMaps=true&_id=${id}`
  );
  return response.data.data;
};

export const getFarmsPerStatus = async (
  pageSize,
  currentPage,
  search,
  sortOrder,
  sortField,
  status
) => {
  const result = await axios.get(
    `/v1/farm?limit=${pageSize}&page=${currentPage}&name=${search}&status=${status}&withProgram=true`
  );

  return result.data.data;
};

export const findAgentsPerRegions = async () => {
  const result = await axiosE.get(`v1/agent/for-regions-and-organizations`);
  return result.data.data;
};

export const findByIdUser = async ({ _id }) => {
  const result = await axiosE.get(`/v2/user?_id=${_id}`);
  return result.data.data;
};

export const getFarmMapsById = async (id) => {
  let param = id ? `?farmerId=${id}` : "";
  const { data } = await apiClient.get(`/v1/farm-maps${param}`);
  return data.data;
};

export const patchReviewStatus = async (
  typeStatus,
  programId,
  farmId,
  status
) => {
  const result = await axios.patch(`/v2/program/${programId}/farm/${farmId}`, {
    status: status,
    rule: typeStatus,
  });
  return result;
};

export const getChatsByFarmer = async ({
  farmerId,
  page = 1,
  limit = 1000,
}) => {
  let url = `/v1/chat?farmerId=${farmerId}&limit=${limit}&page=${page}`;
  return (await axiosChat.get(url)).data;
};

export const getVisitCallScheduling = async ({ id }) => {
  const { data } = await axiosCalendar.get(
    `v1/user_events/visit-call-sheduling/${id}`
  );

  return data.data;
};

export const getCarbonPackage = async ({ id }) => {
  const { data } = await axiosCarbon.get(`/v1/farmer-package/${id}`);

  return data.data;
};

export const getWallet = async ({ id }) => {
  const result = await axiosCarbon.get(`v1/wallet/${id}`);
  return result.data.data;
};

export const addOrRemoveCreditsApi = async (id, payload) => {
  const result = await axiosCarbon.patch(
    `v1/wallet/add-or-remove-credits/${id}`,
    payload
  );
  return result.data.data;
};

export const blockCredits = async (id, block_use_od_credits) => {
  const result = await apiClient.patch(`v1/farmer/block-use-of-credits/${id}`, {
    block_use_of_credits: block_use_od_credits,
  });
  return result.data.data;
};

export const getActionPlans = async ({
  page,
  limit,
  agentId,
  type,
  farmerId,
  status,
}) => {
  let url = `/v1/action_plan?page=${page}&limit=${limit}`;

  if (agentId) url += `&agentId=${agentId}`;
  if (type) url += `&checklistName=${type}`;
  if (farmerId) url += `&farmerId=${farmerId}`;
  if (status) url += `&status=${status}`;

  const { data } = await axios.get(url);

  return data.data;
};

export const getAllEventsByFarmer = async (farmerId) => {
  return axiosCalendar.get(`v1/user_events/${farmerId}`);
};

export const fetchCocoaContracts = async () => {
  return axiosCarbon.get("v1/cocoa_contract");
};

export const fetchIntersectedAreas = async (id) => {
  const { data } = await axios.get(`/v2/farm/intersected-areas?farmId=${id}`);
  return data.data;
};

export const signatureLink = async (url) => {
  const { data } = await axios.post("v2/file/download", { url });
  return data?.message;
};

export const uploadContract = async (props) => {
  const { data } = await axios.post("v2/contract/sending", props);
  return data;
};

export const fetchContractByProgramId = async (programId) => {
  const { data } = await axios.get(`v2/contract/list-per-program/${programId}`);
  return data;
};

export const fetchUploadContract = async (props, progress) => {
  const { data } = await axios.post(`v2/file/contract`, props, {
    onUploadProgress: (e) => progress(e),
  });
  return data;
};

export const fetchUploadDocumentFarm = async (props, progress) => {
  const { data } = await axios.post(`v2/file/farm`, props, {
    onUploadProgress: (e) => progress(e),
  });
  return data;
};

export const fetchAssociatePackage = async (props) => {
  const { data } = await axiosCarbon.post(
    `v1/farmer-package/admin-associate`,
    props
  );
  return data;
};

export const updateStatusIntersectedAreas = async ({
  a_id,
  a_status,
  a_description,
  b_id,
  b_status,
  b_description,
  identifier,
}) => {
  await axios.patch("/v2/farm/update-status-intersected-areas", {
    a_id,
    a_status,
    a_description,
    b_id,
    b_status,
    b_description,
    identifier,
  });
};

export const fetchListTypeDocument = async () => {
  const { data } = await axios.get("/v2/type_documents/list");
  return data;
};

export const fetchUploadDocument = async (props, onUploadProgress) => {
  const { data } = await axios.post("v2/farm/extra-documents", props, {
    onUploadProgress,
  });
  return data;
};

export const deleteDocument = async (farmerId, identifier) => {
  const { data } = await axios.delete(
    `v2/farm/${farmerId}/extra-documents/${identifier}`
  );
  return data;
};

export const fechAddDocument = async (props) => {
  const { data } = await axios.post(`v2/farm/extra-documents`, props);
  return data;
};
