import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { BCProfilerFarmer } from "../../../components/BCProfileFarmer";
import { findByIdFarm, getWallet } from "../service";
import * as S from "./DetailFarmerStyles";

import { CardCalendar } from "./Components/CardCalendar";
import { CardCarbonWallet } from "./Components/CardCarbonWallet";
import { CardContractProgram } from "./Components/CardContractProgram";
import { CardFarmArea } from "./Components/CardFarmArea";
import { CardFarmInformation } from "./Components/CardFarmInformation";
import { CardStatusesEligibilite } from "./Components/CardStatusesEligibilite";
import { CardTotalActionPlan } from "./Components/CardTotalActionPlan";
import { CardTotalAssistance } from "./Components/CardTotalAssistance";
import { CardTotalChat } from "./Components/CardTotalChat";
import { CardTotalEvent } from "./Components/CardTotalEvent/CardTotalEvent";
import { PendingActivity } from "./Components/PendingActivity";

export const DetailFarmerPage = () => {
  const { id } = useParams();
  const [farm, setFarm] = useState({
    farmer: {
      block_use_of_credits: false,
    },
  });
  const [wallet, setWallet] = useState({
    value_carbon_exchanged: 0,
    value_carbon_current_effect: 0,
    value_carbon_issue: 0,
    banck_statement: [],
  });

  const loadDetailFarm = useCallback(async () => {
    try {
      const responseFarm = await findByIdFarm({ id });

      if (responseFarm.result[0].farmer.has_package) {
        const walletFarmer = await getWallet({ id });
        setWallet(walletFarmer);
      }
      if (responseFarm.result === 0) return;
      setFarm(responseFarm.result[0]);
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  useEffect(() => {
    loadDetailFarm();
  }, []);

  return (
    <S.Container>
      <BCProfilerFarmer farm={farm} />
      <S.WrapperCard>
        <CardFarmInformation farm={farm} />
        <CardFarmArea farm={farm} />
        <CardStatusesEligibilite farm={farm} onLoad={() => loadDetailFarm()} />
      </S.WrapperCard>
      <S.WrapperCard>
        <CardCarbonWallet
          wallet={wallet}
          block_use_of_credits={farm.farmer.block_use_of_credits}
          isEnableBlock={false}
        />
        <CardTotalChat farm={farm} />
        <CardTotalAssistance farmerId={farm._id} />
        {farm?.farmer?.has_package && <CardTotalEvent farmerId={farm._id} />}
        <CardContractProgram
          contracts={farm?.contract}
          farm={farm}
          onLoadContract={loadDetailFarm}
        />
        <CardTotalActionPlan farm={farm} />
        <CardCalendar farmerId={farm._id} />
      </S.WrapperCard>
      <S.WrapperCard>
        <PendingActivity farmerId={farm._id} actionPlans={farm.actions_plan} />
      </S.WrapperCard>
    </S.Container>
  );
};
