import { AREA_MAP } from "../../../Enums/AreaMap.enum";
import { PROGRAM_AREA_MAP_STATUS } from "../../../Enums/ProgramAreaMapStatus";

export const BCFilterParams = (translation) => [
  {
    title: translation("TitleFilterMap"),
    field: "area_type",
    options: [
      {
        label: translation("OptionFilterAreaMap1"),
        value: AREA_MAP.FARM_BOUNDARIES,
        checked: true,
        is_revervation_area: false,
      },
      {
        label: translation("OptionFilterAreaMap2"),
        value: AREA_MAP.AGROFORESTRY,
        checked: true,
        is_revervation_area: false,
      },
      {
        label: translation("OptionFilterAreaMap3"),
        value: AREA_MAP.REFORESTATION,
        checked: true,
        is_revervation_area: false,
      },
      {
        label: translation("LengentAreaMapTitle5"),
        value: AREA_MAP.INDIGENOUS_AREA,
        checked: true,
        is_revervation_area: false,
      },
      {
        label: translation("LengentAreaMapTitle4"),
        value: AREA_MAP.PRESERVATION_AREA,
        checked: true,
        is_revervation_area: false,
      },
    ],
  },

  {
    title: translation("OptionFilterTItleStatusAreaMap"),
    field: "status",
    is_revervation_area: false,
    options: [
      {
        label: translation("OptionFilterStatusAreaMap1"),
        value: PROGRAM_AREA_MAP_STATUS.PENDING,
        checked: true,
        is_revervation_area: false,
      },
      {
        label: translation("OptionFilterStatusAreaMap2"),
        value: PROGRAM_AREA_MAP_STATUS.APPROVED,
        checked: true,
        is_revervation_area: false,
      },
      {
        label: translation("OptionFilterStatusAreaMap3"),
        value: PROGRAM_AREA_MAP_STATUS.SIGNED,
        checked: true,
        is_revervation_area: false,
      },
      {
        label: translation("OptionFilterStatusAreaMap4"),
        value: PROGRAM_AREA_MAP_STATUS.REJECTED,
        checked: true,
        is_revervation_area: false,
      },
    ],
  },
];