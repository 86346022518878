import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  box-shadow: 1px 1px 10px #3332;
  background-color: #ffff;
  border-radius: 8px;
  margin-top: 20px;
`;
