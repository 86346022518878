export const PROGRAM_AREA_MAP_STATUS = Object.freeze({
  NEW: "NEW",
  PENDING: "PENDING",
  REISSUE: "REISSUE",
  REVIEW: "REVIEW",
  REJECTED: "REJECTED",
  APPROVED: "APPROVED",
  NEW_PROPORSAL: "NEW_PROPOSAL",
  SIGNED: "SIGNED",
});
