import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, List, Modal, Row, Select } from "antd";
import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowRight } from "react-icons/ai";
import { AiOutlineWechat } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { TYPEUSER } from "../../Enums/Users.enum";
import BCButtonConfirm from "../../components/BCButtonConfirm";
import { createChat, getTitles, listChats, listFarmers } from "./service";

const { Option } = Select;

export default function MessageListChatsFarmer() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    title_id: Yup.string().required(t("Title_is_Required")),
    post: Yup.string().required(t("Message_is_Required")),
  });

  const [titlesChats, setTitleChats] = useState([
    {
      title: "",
      _id: "",
    },
  ]);
  const [chats, setChats] = useState([
    {
      closedIn: new Date(),
      createdAt: new Date(),
      destination_name: "",
      status: "",
      ticket_ref_number: "",
      title: "",
      user_origin_name: "",
      user_type: "",
      posts: [
        {
          message: "",
          file: "",
          createdAt: new Date(),
          read: false,
          sent_by_user_name: "",
          user_type: "",
          location: "",
          mimetype: "",
        },
      ],
    },
  ]);

  const [farm, setFarm] = useState({
    _id: "",
    name: "",
    identifier: "",
    farmer: {
      name: "",
      identifier: "",
      _id: "",
    },
  });

  const findChats = useCallback(async () => {
    const result = await listChats({
      currentPage: currentPage,
      pageSize: pageSize,
      farmerId: id,
    });

    setChats(result.result);
  }, [pageSize, currentPage, id]);

  const findFarmer = useCallback(async () => {
    const result = await listFarmers({
      id: id,
    });

    setFarm(result.result[0]);
  }, [id]);

  const findTitles = useCallback(async () => {
    const result = await getTitles();
    setTitleChats(result.result);
  }, []);

  useEffect(() => {
    findChats();
    findFarmer();
    findTitles();
  }, [findChats, findFarmer, findTitles]);

  const viewChat = (item) => {
    navigate(`/messages/${id}/farmer/${item._id}/chat`);
  };

  async function create(values) {
    setLoading(true);
    const formData = new FormData();
    formData.append("post", values.post);
    formData.append("destination_id", id);
    formData.append("title_id", values.title_id);
    formData.append("user_type", TYPEUSER.FARMER);
    try {
      const result = await createChat(formData);
      navigate(`/messages/${id}/farmer/${result}/chat`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <div>
      <Card>
        <Row justify="center">
          <Col span={16}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "400px",
              }}
            >
              <h1>{farm.farmer.name}</h1>
              <h1 style={{ color: "rgba(0, 0, 0, 0.5)" }}>
                {farm.farmer.identifier}
              </h1>
              <h4 style={{ color: "rgba(0, 0, 0, 0.5)" }}>
                {t("Farm")} {farm.name} {farm.identifier}
              </h4>
            </div>
          </Col>
          <Col span={8} style={{ textAlign: "right" }}>
            <Button
              onClick={() => setModalOpen(true)}
              shape="round"
              className="confirm_button"
              type="dashed"
              icon={<PlusOutlined />}
            >
              {t("New_chat")}
            </Button>
          </Col>
        </Row>
        <List
          style={{ width: "100%" }}
          itemLayout="horizontal"
          dataSource={chats}
          renderItem={(item, index) => (
            <List.Item onClick={() => viewChat(item)} style={{cursor: "pointer"}}>
              <List.Item.Meta
                avatar={<AiOutlineWechat size={24} />}
                title={<h3 style={{fontWeight: "normal", color: "#47342e"}}>{`${item.title} - ${item.ticket_ref_number}`}</h3>}
                description={
                  <div style={{color:"#7b6d69"}}>{item.posts && item.posts.length > 0
                    ? new Date(item.posts[0].createdAt).toLocaleString()
                    : ""}</div>
                }
              />
              <AiOutlineArrowRight
                size={24}
                color="#e5e1da"
              />
            </List.Item>
          )}
        />
      </Card>

      <Modal
        title={t("New_chat")}
        open={modalOpen}
        footer={null}
        onCancel={() => setModalOpen(false)}
      >
        <Formik
          initialValues={{
            title_id: "",
            post: "",
          }}
          validationSchema={validationSchema}
          onSubmit={create}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
          }) => (
            <Form layout="vertical" onFinish={handleSubmit}>
              <Row style={{ marginTop: "30px" }} gutter={16}>
                <Col xs={24} sm={24}>
                  <Row gutter={16}>
                    <Col xs={24} md={24}>
                      <Form.Item
                        help={
                          touched.title_id && errors.title_id && errors.title_id
                            ? errors.title_id
                            : ""
                        }
                        validateStatus={
                          touched.title_id && errors.title_id ? "error" : ""
                        }
                      >
                        <Select
                          style={{ width: "100%" }}
                          name="title_id"
                          value={values.title_id}
                          onChange={(value) =>
                            handleChange({
                              target: { name: "title_id", value },
                            })
                          }
                          onBlur={handleBlur}
                          placeholder={t("Title")}
                        >
                          <Option value="" disabled>
                            {t("Title")}
                          </Option>
                          {titlesChats.map((x) => (
                            <Option value={x._id} key={x._id}>
                              {x.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={touched.post && errors.post ? errors.post : ""}
                        validateStatus={
                          touched.post && errors.post ? "error" : ""
                        }
                      >
                        <Input
                          name="post"
                          className="input"
                          value={values.post}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Post")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Form.Item>
                <BCButtonConfirm
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    marginTop: "20px",
                  }}
                  buttonText={!loading ? t("Start") : `${t("Creating")} ...`}
                ></BCButtonConfirm>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}
