const localizations = [
  {
    name: "Pará",
    initials: "PA",
    country_id: "620eaadc9d696b21f29023ac",
    cities: [
      {
        name: "Abaetetuba",
        ibge_code: "1500107",
      },
      {
        name: "Abel Figueiredo",
        ibge_code: "1500131",
      },
      {
        name: "Acará",
        ibge_code: "1500206",
      },
      {
        name: "Afuá",
        ibge_code: "1500305",
      },
      {
        name: "Água Azul do Norte",
        ibge_code: "1500347",
      },
      {
        name: "Alenquer",
        ibge_code: "1500404",
      },
      {
        name: "Almeirim",
        ibge_code: "1500503",
      },
      {
        name: "Altamira",
        ibge_code: "1500602",
      },
      {
        name: "Anajás",
        ibge_code: "1500701",
      },
      {
        name: "Ananindeua",
        ibge_code: "1500800",
      },
      {
        name: "Anapu",
        ibge_code: "1500859",
      },
      {
        name: "Augusto Corrêa",
        ibge_code: "1500909",
      },
      {
        name: "Aurora do Pará",
        ibge_code: "1500958",
      },
      {
        name: "Aveiro",
        ibge_code: "1501006",
      },
      {
        name: "Bagre",
        ibge_code: "1501105",
      },
      {
        name: "Baião",
        ibge_code: "1501204",
      },
      {
        name: "Bannach",
        ibge_code: "1501253",
      },
      {
        name: "Barcarena",
        ibge_code: "1501303",
      },
      {
        name: "Belém",
        ibge_code: "1501402",
      },
      {
        name: "Belterra",
        ibge_code: "1501451",
      },
      {
        name: "Benevides",
        ibge_code: "1501501",
      },
      {
        name: "Bom Jesus do Tocantins",
        ibge_code: "1501576",
      },
      {
        name: "Bonito",
        ibge_code: "1501600",
      },
      {
        name: "Bragança",
        ibge_code: "1501709",
      },
      {
        name: "Brasil Novo",
        ibge_code: "1501725",
      },
      {
        name: "Brejo Grande do Araguaia",
        ibge_code: "1501758",
      },
      {
        name: "Breu Branco",
        ibge_code: "1501782",
      },
      {
        name: "Breves",
        ibge_code: "1501808",
      },
      {
        name: "Bujaru",
        ibge_code: "1501907",
      },
      {
        name: "Cachoeira do Arari",
        ibge_code: "1502004",
      },
      {
        name: "Cachoeira do Piriá",
        ibge_code: "1501956",
      },
      {
        name: "Cametá",
        ibge_code: "1502103",
      },
      {
        name: "Canaã dos Carajás",
        ibge_code: "1502152",
      },
      {
        name: "Capanema",
        ibge_code: "1502202",
      },
      {
        name: "Capitão Poço",
        ibge_code: "1502301",
      },
      {
        name: "Castanhal",
        ibge_code: "1502400",
      },
      {
        name: "Chaves",
        ibge_code: "1502509",
      },
      {
        name: "Colares",
        ibge_code: "1502608",
      },
      {
        name: "Conceição do Araguaia",
        ibge_code: "1502707",
      },
      {
        name: "Concórdia do Pará",
        ibge_code: "1502756",
      },
      {
        name: "Cumaru do Norte",
        ibge_code: "1502764",
      },
      {
        name: "Curionópolis",
        ibge_code: "1502772",
      },
      {
        name: "Curralinho",
        ibge_code: "1502806",
      },
      {
        name: "Curuá",
        ibge_code: "1502855",
      },
      {
        name: "Curuçá",
        ibge_code: "1502905",
      },
      {
        name: "Dom Eliseu",
        ibge_code: "1502939",
      },
      {
        name: "Eldorado dos Carajás",
        ibge_code: "1502954",
      },
      {
        name: "Faro",
        ibge_code: "1503002",
      },
      {
        name: "Floresta do Araguaia",
        ibge_code: "1503044",
      },
      {
        name: "Garrafão do Norte",
        ibge_code: "1503077",
      },
      {
        name: "Goianésia do Pará",
        ibge_code: "1503093",
      },
      {
        name: "Gurupá",
        ibge_code: "1503101",
      },
      {
        name: "Igarapé-Açu",
        ibge_code: "1503200",
      },
      {
        name: "Igarapé-Mirim",
        ibge_code: "1503309",
      },
      {
        name: "Inhangapi",
        ibge_code: "1503408",
      },
      {
        name: "Ipixuna do Pará",
        ibge_code: "1503457",
      },
      {
        name: "Irituia",
        ibge_code: "1503507",
      },
      {
        name: "Itaituba",
        ibge_code: "1503606",
      },
      {
        name: "Itupiranga",
        ibge_code: "1503705",
      },
      {
        name: "Jacareacanga",
        ibge_code: "1503754",
      },
      {
        name: "Jacundá",
        ibge_code: "1503804",
      },
      {
        name: "Juruti",
        ibge_code: "1503903",
      },
      {
        name: "Limoeiro do Ajuru",
        ibge_code: "1504000",
      },
      {
        name: "Mãe do Rio",
        ibge_code: "1504059",
      },
      {
        name: "Magalhães Barata",
        ibge_code: "1504109",
      },
      {
        name: "Marabá",
        ibge_code: "1504208",
      },
      {
        name: "Maracanã",
        ibge_code: "1504307",
      },
      {
        name: "Marapanim",
        ibge_code: "1504406",
      },
      {
        name: "Marituba",
        ibge_code: "1504422",
      },
      {
        name: "Medicilândia",
        ibge_code: "1504455",
      },
      {
        name: "Melgaço",
        ibge_code: "1504505",
      },
      {
        name: "Mocajuba",
        ibge_code: "1504604",
      },
      {
        name: "Moju",
        ibge_code: "1504703",
      },
      {
        name: "Monte Alegre",
        ibge_code: "1504802",
      },
      {
        name: "Muaná",
        ibge_code: "1504901",
      },
      {
        name: "Nova Esperança do Piriá",
        ibge_code: "1504950",
      },
      {
        name: "Nova Ipixuna",
        ibge_code: "1504976",
      },
      {
        name: "Nova Timboteua",
        ibge_code: "1505007",
      },
      {
        name: "Novo Progresso",
        ibge_code: "1505031",
      },
      {
        name: "Novo Repartimento",
        ibge_code: "1505064",
      },
      {
        name: "Óbidos",
        ibge_code: "1505106",
      },
      {
        name: "Oeiras do Pará",
        ibge_code: "1505205",
      },
      {
        name: "Oriximiná",
        ibge_code: "1505304",
      },
      {
        name: "Ourém",
        ibge_code: "1505403",
      },
      {
        name: "Ourilândia do Norte",
        ibge_code: "1505437",
      },
      {
        name: "Pacajá",
        ibge_code: "1505486",
      },
      {
        name: "Palestina do Pará",
        ibge_code: "1505494",
      },
      {
        name: "Paragominas",
        ibge_code: "1505502",
      },
      {
        name: "Parauapebas",
        ibge_code: "1505536",
      },
      {
        name: "Pau-d'Arco",
        ibge_code: "1505551",
      },
      {
        name: "Peixe-Boi",
        ibge_code: "1505601",
      },
      {
        name: "Piçarra",
        ibge_code: "1505635",
      },
      {
        name: "Placas",
        ibge_code: "1505650",
      },
      {
        name: "Ponta de Pedras",
        ibge_code: "1505700",
      },
      {
        name: "Portel",
        ibge_code: "1505809",
      },
      {
        name: "Porto de Moz",
        ibge_code: "1505908",
      },
      {
        name: "Prainha",
        ibge_code: "1506005",
      },
      {
        name: "Primavera",
        ibge_code: "1506104",
      },
      {
        name: "Quatipuru",
        ibge_code: "1506112",
      },
      {
        name: "Redenção",
        ibge_code: "1506138",
      },
      {
        name: "Rio Maria",
        ibge_code: "1506161",
      },
      {
        name: "Rondon do Pará",
        ibge_code: "1506187",
      },
      {
        name: "Rurópolis",
        ibge_code: "1506195",
      },
      {
        name: "Salinópolis",
        ibge_code: "1506203",
      },
      {
        name: "Salvaterra",
        ibge_code: "1506302",
      },
      {
        name: "Santa Bárbara do Pará",
        ibge_code: "1506351",
      },
      {
        name: "Santa Cruz do Arari",
        ibge_code: "1506401",
      },
      {
        name: "Santa Isabel do Pará",
        ibge_code: "1506500",
      },
      {
        name: "Santa Luzia do Pará",
        ibge_code: "1506559",
      },
      {
        name: "Santa Maria das Barreiras",
        ibge_code: "1506583",
      },
      {
        name: "Santa Maria do Pará",
        ibge_code: "1506609",
      },
      {
        name: "Santana do Araguaia",
        ibge_code: "1506708",
      },
      {
        name: "Santarém",
        ibge_code: "1506807",
      },
      {
        name: "Santarém Novo",
        ibge_code: "1506906",
      },
      {
        name: "Santo Antônio do Tauá",
        ibge_code: "1507003",
      },
      {
        name: "São Caetano de Odivelas",
        ibge_code: "1507102",
      },
      {
        name: "São Domingos do Araguaia",
        ibge_code: "1507151",
      },
      {
        name: "São Domingos do Capim",
        ibge_code: "1507201",
      },
      {
        name: "São Félix do Xingu",
        ibge_code: "1507300",
      },
      {
        name: "São Francisco do Pará",
        ibge_code: "1507409",
      },
      {
        name: "São Geraldo do Araguaia",
        ibge_code: "1507458",
      },
      {
        name: "São João da Ponta",
        ibge_code: "1507466",
      },
      {
        name: "São João de Pirabas",
        ibge_code: "1507474",
      },
      {
        name: "São João do Araguaia",
        ibge_code: "1507508",
      },
      {
        name: "São Miguel do Guamá",
        ibge_code: "1507607",
      },
      {
        name: "São Sebastião da Boa Vista",
        ibge_code: "1507706",
      },
      {
        name: "Sapucaia",
        ibge_code: "1507755",
      },
      {
        name: "Senador José Porfírio",
        ibge_code: "1507805",
      },
      {
        name: "Soure",
        ibge_code: "1507904",
      },
      {
        name: "Tailândia",
        ibge_code: "1507953",
      },
      {
        name: "Terra Alta",
        ibge_code: "1507961",
      },
      {
        name: "Terra Santa",
        ibge_code: "1507979",
      },
      {
        name: "Tomé-Açu",
        ibge_code: "1508001",
      },
      {
        name: "Tracuateua",
        ibge_code: "1508035",
      },
      {
        name: "Trairão",
        ibge_code: "1508050",
      },
      {
        name: "Tucumã",
        ibge_code: "1508084",
      },
      {
        name: "Tucuruí",
        ibge_code: "1508100",
      },
      {
        name: "Ulianópolis",
        ibge_code: "1508126",
      },
      {
        name: "Uruará",
        ibge_code: "1508159",
      },
      {
        name: "Vigia",
        ibge_code: "1508209",
      },
      {
        name: "Viseu",
        ibge_code: "1508308",
      },
      {
        name: "Vitória do Xingu",
        ibge_code: "1508357",
      },
      {
        name: "Xinguara",
        ibge_code: "1508407",
      },
    ],
  },
];

export default localizations;
