import React from 'react'
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import BaseLayout from "../../pages/BaseLayout";

import UserPerfil from "../../pages/User/UserPerfil";
import Private from "../Private";
import UserChangePass from "../../pages/User/UserChangePassword";

function UserRoutes() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path="/:id/perfil/change_password"
        element={
          <Private>
            <BaseLayout breadcrumb={t("User_Profile_Pass_Change")}>
              <UserChangePass />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/:id/perfil"
        element={
          <Private>
            <BaseLayout breadcrumb={t("User_Profile")}>
              <UserPerfil />
            </BaseLayout>
          </Private>
        }
      />
    </Routes>
  );
}

export default UserRoutes;
