import React from 'react'
import { t } from "i18next";
import * as S from "./BCCardStyles";

export const BCCard = ({
  data = {
    total: "0",
    total_pending: "0",
    total_rejected: "0",
    total_reissue: "0",
    total_review: "0",
    total_signed: "0",
    total_approved: "0",
  },
  title = "",
}) => {
  return (
    <S.Container>
      <S.WrappeCollunm>
        <S.TitleCountTotal>{data.total}</S.TitleCountTotal>
        <S.Subtitle>{title}</S.Subtitle>
      </S.WrappeCollunm>
      <S.WrappeCollunm>
        <S.TitleStatus style={{ color: "#ffbc00" }}>
          {t("PENDING")}
        </S.TitleStatus>
        <S.TitleStatus style={{ color: "#e74c3c" }}>
          {t("REJECTED")}
        </S.TitleStatus>
        <S.TitleStatus style={{ color: "#1f9652" }}>
          {t("APPROVED")}
        </S.TitleStatus>
        <S.TitleStatus style={{ color: "#1f9652" }}>
          {t("ENROLLED")}
        </S.TitleStatus>
      </S.WrappeCollunm>
      <S.WrappeCollunm style={{ alignItems: "end" }}>
        <S.TitleStatus style={{ color: "#ffbc00" }}>
          {data.total_pending}
        </S.TitleStatus>
        <S.TitleStatus style={{ color: "#e74c3c" }}>
          {data.total_rejected}
        </S.TitleStatus>
        <S.TitleStatus style={{ color: "#1f9652" }}>
          {data.total_approved}
        </S.TitleStatus>
        <S.TitleStatus style={{ color: "#1f9652" }}>
          {data.total_signed}
        </S.TitleStatus>
      </S.WrappeCollunm>
    </S.Container>
  );
};
