import { Card, Col, Space } from "antd";
import styled from "styled-components";

export const Container = styled(Card)`
  margin-right: 10px;
  max-width: 340px;
  min-width: 340px;
  max-height: 440px;
  height: 440px;
  border-radius: 8px;
  margin-bottom: 10px;

  @media screen and (max-width: 1506px) {
    flex:1;
    width: none;
    max-width: none;
  }

  @media screen and (max-width: 1006px) {
    width: 100%;
    max-width: none;
  }
`;

export const ImageArea = styled.img`
  width: 100%;
  height: 240px;
  max-height: 240px;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  object-fit: cover;
`;

export const WrapperButtonNavigate = styled(Space)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export const BodyCar = styled(Col)`
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin-bottom: 10px;
`;

export const LabelDescriptionProgram = styled.h2`
  color: rgb(71, 52, 46, 0.7);
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
`;

export const WrapperTotalInfoAreaProgram = styled.div`
  display: flex;
  padding-left: 20px;
  margin-bottom: 10px;
`;

const hashColorArea = (status) => {
  switch (status) {
    case "enrolled":
      return "#47342e";
    case "implemented":
      return "#1f9652";
    default:
      return "rgb(71, 52, 46, 0.8)";
  }
};

export const LabelInfoAreaProgram = styled.p`
  text-align: left;
  color: ${(props) => hashColorArea(props.status)};
  margin: 0 6px;
`;

export const TotalAreaProgram = styled.span`
  font-weight: bold;
  color: ${(props) => hashColorArea(props.status)};
`;
