import { Button, Card, Col, Row, Select, Switch, Table, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GiFarmer } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { checkParticipation, list } from "./service";

const { Meta } = Card;
const { Option } = Select;

export default function CalendarVerify() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [limit, setLimit] = useState(30);
  const [page, setyPage] = useState(1);
  const [events, setEvents] = useState([
    {
      _id: "",
      title: "",
      description: "",
      date: "",
      status: "",
      period: "",
      hour: "",
      type_event: {
        name: "",
      },
      destinations: [
        {
          farmer: {
            name: "Teste",
          },
        },
      ],
      owner: {
        email: "",
        name: "",
      },
    },
  ]);

  const [eventSelected, setEventSelected] = useState([
    {
      _id: "",
      title: "",
      description: "",
      date: "",
      status: "",
      period: "",
      hour: "",
      type_event: {
        name: "",
      },
      destinations: [
        {
          farmer: {
            name: "",
          },
        },
      ],
      owner: {
        email: "",
        name: "",
      },
    },
  ]);

  const findEvents = useCallback(async () => {
    const results = await list({ limit, page, verifyParticipation: true });
    setEvents(results);
  }, [limit, page]);

  useEffect(() => {
    findEvents();
  }, [findEvents]);

  const check = async () => {
    const destinationsPayload = [];
    eventSelected.destinations.forEach((d) => {
      destinationsPayload.push({
        user_id: d.destination_user_id,
        status: d.participated ? "ATTENDED" : "MISSED",
      });
    });

    const payload = {
      event_id: eventSelected._id,
      participants: destinationsPayload,
    };

    await checkParticipation(payload);
    navigate("/calendar");
  };

  const onChange = (value) => {
    const e = events.find((x) => x._id === value);
    e.destinations.map((x) => (x.participated = true));
    setEventSelected(e);
  };

  const handleSwitchChange = (checked, record) => {
    const updatedDestinations = eventSelected.destinations.map(
      (destination) => {
        if (destination === record) {
          return {
            ...destination,
            participated: checked,
          };
        }
        return destination;
      }
    );

    setEventSelected((prevState) => ({
      ...prevState,
      destinations: updatedDestinations,
    }));
  };

  console.log("AQUI", eventSelected);
  return (
    <div>
      <h1>{`${t("Calendar")} / ${t("Verify")}`}</h1>
      <Row style={{ marginTop: "15px", width: "100%", height: "100vh" }}>
        <Col span={12}>
          <Card style={{ height: "100%", borderRadius: 0 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <GiFarmer
                style={{
                  color: "rgb(79, 175, 73)",
                  fontSize: "25px",
                  marginRight: "5px",
                }}
              />
              <Meta title={t("Check_presence_of_Farmers")} />
            </div>
            <Row style={{ marginTop: "20px" }}>
              <Select
                style={{ width: "100%" }}
                onChange={onChange}
                placeholder={t("Choose_an_event")}
              >
                <Option value="" disabled>
                  {t("Choose_an_event")}
                </Option>
                {events.map((x) => (
                  <Option value={x._id} key={x._id}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {x.title} &nbsp;&nbsp;
                      <Tag color="success">
                        {new Date(x.date).toLocaleDateString()}
                      </Tag>
                      {x.type_event.name === "WORKSHOP" ? (
                        <Tag color="success">{t("WORKSHOP")}</Tag>
                      ) : (
                        <Tag color="warning">{t("TRAINING")}</Tag>
                      )}
                    </div>
                  </Option>
                ))}
              </Select>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Button
                onClick={() => check()}
                type="primary"
                className="confirm_button"
                htmlType="submit"
                shape="round"
                block
                style={{ width: "120px" }}
              >
                {t("Send")}
              </Button>
              <Table
                style={{ marginTop: "20px", width: "100%" }}
                dataSource={eventSelected.destinations}
                pagination={true}
              >
                <Table.Column
                  title={t("Name")}
                  align="start"
                  dataIndex={["farmer", "name"]}
                  render={(text, record) => {
                    return (
                      <span>
                        {record.farmer.name}
                        <br />
                        <Tag>{record.farmer.identifier}</Tag>
                      </span>
                    );
                  }}
                />
                <Table.Column
                  title={t("Participated")}
                  align="start"
                  dataIndex="participated"
                  key="participated"
                  render={(text, record) => (
                    <Switch
                      checked={text}
                      onChange={(checked) =>
                        handleSwitchChange(checked, record)
                      }
                      style={{
                        display: "flex",
                        backgroundColor: record.participated ? "green" : "",
                      }}
                      checkedChildren={t("Participated")}
                      unCheckedChildren={`${t("No")} ${t("Participated")}`}
                    />
                  )}
                />
              </Table>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f6f6f6",
              height: "100%",
              borderRadius: 0,
            }}
          ></Card>
        </Col>
      </Row>
    </div>
  );
}
