import { List } from "antd";
import styled, {css} from "styled-components";

export const Container = styled(List)`
  display: flex;
  flex:1;
  margin-top: 20px;
  overflow: auto;
  max-height: 500px;
  overflow-y: scroll;

 
  /* width */
  ::-webkit-scrollbar {
    width: 0px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #3338;
    border-radius: 10px;
  }

  ${css`
    &:hover {
      -webkit-mask-position: left top;

      ::-webkit-scrollbar {
        width: 10px;
        height: 8px;
      }
    }
  `}
`;
