export const chat = {
    closedIn: new Date(),
    createdAt: new Date(),
    destination_name: "",
    status: "",
    ticket_ref_number: "",
    title: "",
    user_origin_name: "",
    user_type: "",
    posts: [
      {
        message: "",
        file: "",
        createdAt: new Date(),
        read: false,
        sent_by_user_name: "",
        user_type: "",
        location: "",
        mimetype: "",
        originalname: "",
      },
    ]
}