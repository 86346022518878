import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { getChatsByFarmer } from "../../../service";

import * as S from "./CardTotalChatStyles";

export const CardTotalChat = ({ farm }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [chatCounts, setChatCounts] = useState({
    totalChat: 0,
    totalUnreadChat: 0,
  });

  const handleTotalChats = useCallback(async () => {
    try {
      if (!farm || !farm?._id) return;
      const { data } = await getChatsByFarmer({ farmerId: farm._id });

      const messages = data.result;

      if (messages.length > 0) {
        let countUnreadPosts = 0;

        messages.forEach((item) => {
          item.posts.forEach((post) => {
            if (!post.read) {
              countUnreadPosts++;
            }
          });
        });

        setChatCounts({
          totalChat: messages.length,
          totalUnreadChat: countUnreadPosts,
        });
      } else {
        setChatCounts({
          totalChat: "0",
          totalUnreadChat: "0",
        });
      }
    } catch (error) {}
  }, [farm]);

  useEffect(() => {
    handleTotalChats();
  }, [farm]);
  return (
    <S.Container
      hoverable
      onClick={() => navigate(`/messages/${farm._id}/farmer`)}
    >
      <S.HeaderCard>
        <S.Title>{t("Messages")}</S.Title>
        <S.IconChat />
      </S.HeaderCard>
      <S.ContentCard>
        <S.WrapperTotal>
          <S.TotalText>{chatCounts.totalChat}</S.TotalText>
          <S.LabelTotal>Total</S.LabelTotal>
        </S.WrapperTotal>
        <S.WrapperTotal>
          <S.TotalText style={{ color: "#1f9652" }}>
            {chatCounts.totalUnreadChat}
          </S.TotalText>
          <S.LabelTotal style={{ color: "#1f9652" }}>{t("New")}</S.LabelTotal>
        </S.WrapperTotal>
      </S.ContentCard>
    </S.Container>
  );
};
