import React from 'react'
import {
  CloseCircleOutlined,
  EyeOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Space, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { PROGRAM_AREA_MAP_STATUS } from "../../../Enums/ProgramAreaMapStatus";

export const ListAreaByFarmCollunmGrid = (translation, navigate) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("Name_area"),
      dataIndex: "farmer.name",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return (
          <span>
            <Tag>{data.identifier}</Tag>
          </span>
        );
      },
    },
    {
      title: t("Type_area"),
      dataIndex: "name",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return <span>{translation(data.area_type)}</span>;
      },
    },
    {
      title: t("Type_cultivation"),
      dataIndex: "type_cultivation",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return <span>{translation(data.type_cultivation)}</span>;
      },
    },
    {
      title: t("Code"),
      dataIndex: "name",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return (
          <span>
            <Tag>{data.identifier}</Tag>
          </span>
        );
      },
    },
    {
      title: t("Size"),
      dataIndex: "name",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return <span>{data.area_size}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "name",
      align: "start",
      sorter: true,
      render: (text, data) => {
        let statusTag;
        switch (data.status) {
          case PROGRAM_AREA_MAP_STATUS.PENDING:
            statusTag = (
              <Tag icon={<SyncOutlined spin />} color="processing">
                {translation("PENDING")}
              </Tag>
            );
            break;
          case PROGRAM_AREA_MAP_STATUS.REJECTED:
            statusTag = (
              <Tag icon={<CloseCircleOutlined />} color="error">
                {translation("REJECTED")}
              </Tag>
            );
            break;
          case PROGRAM_AREA_MAP_STATUS.REVIEW:
            statusTag = (
              <Tag icon={<SyncOutlined spin />} color="warning">
                {translation("REVIEW")}
              </Tag>
            );
            break;
          case PROGRAM_AREA_MAP_STATUS.APPROVED:
            statusTag = <Tag color="success">{translation("APPROVED")}</Tag>;
            break;
          case PROGRAM_AREA_MAP_STATUS.SIGNED:
            statusTag = <Tag color="success">{translation("SIGNED")}</Tag>;
            break;
          default:
            statusTag = <span>{data.status}</span>;
        }
        return statusTag;
      },
    },
  ];

  const actions = [
    {
      title: translation("Actions"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={() =>
              navigate(
                `/program-area/${record._id}/farmer/${record.identifier}/area`
              )
            }
            icon={<EyeOutlined />}
          >
            {translation("View")}
          </Button>
        </Space>
      ),
    },
  ];

  return {
    columns,
    actions,
  };
};
