import { AREA_MAP } from '../../../Enums/AreaMap.enum';
import {Colors} from './colors';

export const hashColor = (type) => {
    switch(type){
        case AREA_MAP.FARM_BOUNDARIES:
            return { lineColor: Colors.FARM_BOUNDARIES_LINE, areaColor: Colors.FARM_BOUNDARIES_AREA}
        case AREA_MAP.REFORESTATION:
            return { lineColor: Colors.PROGRAM_REFORESTATION_LINE, areaColor: Colors.PROGRAM_REFORESTATION_AREA}
        case AREA_MAP.AGROFORESTRY:
            return { lineColor: Colors.PROGRAM_AGROFORESTRY_LINE, areaColor: Colors.PROGRAM_AGROFORESTRY_AREA}
        case AREA_MAP.INDIGENOUS_AREA:
            return { lineColor: Colors.INDIGENOUS_AREA_LINE, areaColor: Colors.INDIGENOUS_AREA};
        case AREA_MAP.PRESERVATION_AREA:
            return { lineColor: Colors.PRESERVATION_AREA_LINE, areaColor: Colors.PRESERVATION_AREA}
        default:
            return { lineColor: Colors.FARM_BOUNDARIES_LINE, areaColor: Colors.FARM_BOUNDARIES_AREA}
    }
}
