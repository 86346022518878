import axios from "../../apis/axiosInterceptorCalendar";

export const list = async ({
  dateFinal = "",
  dateInitial = "",
  type = "",
  id = "",
  status = "",
  limit = "",
  page = "",
  verifyParticipation = "",
}) => {
  const result = await axios.get(
    `/v2/event/list?dateFinal=${dateFinal}&dateInitial=${dateInitial}&type=${type}&id=${id}&status=${status}&limit=${limit}&page=${page}&verifyParticipation=${verifyParticipation}`
  );
  return result.data.data.result;
};

export const create = async (event) => {
  await axios.post(`/v1/event`, event);
};

export const edit = async (id, event) => {
  await axios.put(`/v1/event/${id}`, event);
};

export const checkParticipation = async (payload) => {
  await axios.post(`/v1/event/participation`, payload);
};
