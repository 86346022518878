import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { Card, Col, Collapse, Divider, List, Row, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GiFarmer } from "react-icons/gi";
import { MdSupportAgent } from "react-icons/md";
import { useParams } from "react-router-dom";
import { CHECKLIST_STATUS } from "../../Enums/Checklist.enum";
import { listChecklist } from "./service";
const { Panel } = Collapse;

export default function ChecklistView() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [checklist, setChecklist] = useState({
    completedAt: new Date(),
    checklist_name: "",
    agent_name: "",
    farmer: {
      name: "",
      identifier: "",
    },
    farms: {
      city: "",
      state: "",
    },
    checklist: {
      questions_module: [
        {
          questions: [
            {
              available_responses: [],
            },
          ],
        },
      ],
    },
  });
  const [totals, setTotal] = useState({
    total: 0,
    validate: 0,
    pending: 0,
    notResponse: 0,
  });

  const findChecklist = useCallback(async () => {
    const result = await listChecklist({
      id: id,
    });

    let validate = 0;
    let pending = 0;
    let notResponse = 0;
    let total = 0;
    setChecklist(result.result[0]);

    result.result[0].checklist.questions_module.map((qm) => {
      total += qm.questions.length;
      // Se o status por pedente significa que o fazendeiro ainda não respondeu
      qm.questions.map((q) => {
        if (q.status === "PENDING") {
          notResponse += 1;
        }
        // Se o status por respondido e nao gerar action plan. esta validado
        if (q.status === "ANSWERED" && !q.generates_action_plan_item) {
          validate += 1;
        }

        // Se o status por respondido e gera action plan, eu preciso ver se todas as ações estavam diferente de RESOLVIDO
        if (q.status === "ANSWERED" && q.generates_action_plan_item) {
          switch (q.input_type) {
            case "DATE_PICKER":
            case "INPUT":
            case "INPUT_NUMBER":
            case "UPLOAD":
            case "SLIDER":
              let countPeding = 0;
              q.available_responses[0].action.forEach((a) => {
                if (a.status !== "RESOLVED") {
                  countPeding += 1;
                }
              });
              if (countPeding > 0) pending += 1;
              break;
            case "CHECKBOX":
            case "RADIO":
            case "DROPDOW":
            case "RATE":
            case "SELECT":
            case "SWITCH":
            case "TRANSFER":
              let countNotResolved = 0;
              q.responses.forEach((response) => {
                const resp = q.available_responses.find(
                  (x) => x.answer === response
                );
                if (!resp.action) countNotResolved += 1;
                else {
                  resp.action.forEach((a) => {
                    if (a.status !== "RESOLVED") {
                      countNotResolved += 1;
                    }
                  });
                }
              });
              if (countNotResolved > 0) pending += 1;
              break;
            default:
              break;
          }
        }
      });
    });

    setTotal({
      total: total,
      validate: validate,
      pending: pending,
      notResponse: notResponse,
    });
  }, [id]);

  useEffect(() => {
    findChecklist();
  }, [findChecklist]);

  const onChange = (key) => {
    console.log(key);
  };

  const statusTag = (() => {
    const style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "160px",
      height: "40px",
      borderRadius: "20px",
      fontSize: "20px",
    };
    switch (checklist.status) {
      case CHECKLIST_STATUS.COMPLETED:
        return (
          <Tag style={style} icon={<CheckCircleOutlined />} color="success">
            {t("COMPLETED")}
          </Tag>
        );
      case CHECKLIST_STATUS.EXPIRED:
        return (
          <Tag style={style} icon={<CloseCircleOutlined />} color="error">
            {t("EXPIRED")}
          </Tag>
        );
      case CHECKLIST_STATUS.PENDING:
        return (
          <Tag
            style={style}
            icon={<ClockCircleOutlined spin />}
            color="warning"
          >
            {t("PENDING")}
          </Tag>
        );
      default:
        return <span>Status Desconhecido</span>;
    }
  })();

  const getResponses = (item) => {
    if (item.status === "PENDING") {
      return <div>{t("Not_Response")}</div>;
    }
    // Se o status por respondido e gera action plan, eu preciso ver se todas as ações estavam diferente de RESOLVIDO
    else {
      switch (item.input_type) {
        case "DATE_PICKER":
          return (
            <div>
              {new Date(
                item.available_responses[0].answer
              ).toLocaleDateString()}
            </div>
          );
        case "INPUT":
        case "INPUT_NUMBER":
        case "SLIDER":
          return <div>{item.available_responses[0].answer}</div>;
        case "UPLOAD":
          return (
            <div>
              <a
                href={item.available_responses[0].answer}
                title="Link"
                target="_blank"
                rel="noreferrer"
              >
                {t("View_Document")} <FileTextOutlined />
              </a>
            </div>
          );
        case "CHECKBOX":
        case "RADIO":
        case "DROPDOW":
        case "RATE":
        case "SELECT":
        case "SWITCH":
        case "TRANSFER":
          return (
            <List
              dataSource={item.responses}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          );
        default:
          break;
      }
    }
  };

  const getMandatory = (item) => {
    if (item.mandatory) {
      return (
        <Tag icon={<ExclamationCircleOutlined />} color="red">
          {t("Required")}
        </Tag>
      );
    } else {
      return <Tag>{t("Not_Required")}</Tag>;
    }
  };

  const responseStatus = (item) => {
    let responseStatus = "";
    if (item.status === "PENDING") {
      return <Tag icon={<CloseCircleOutlined />}>{t("Not_Response")}</Tag>;
    }

    // Se o status por respondido e nao gerar action plan. esta validado
    if (item.status === "ANSWERED" && !item.generates_action_plan_item) {
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          {t("Validate")}
        </Tag>
      );
    }

    // Se o status por respondido e gera action plan, eu preciso ver se todas as ações estavam diferente de RESOLVIDO
    if (item.status === "ANSWERED" && item.generates_action_plan_item) {
      switch (item.input_type) {
        case "DATE_PICKER":
        case "INPUT":
        case "INPUT_NUMBER":
        case "UPLOAD":
        case "SLIDER":
          let countPeding = 0;
          item.available_responses[0].action.forEach((a) => {
            if (a.status !== "RESOLVED") {
              countPeding += 1;
            }
          });
          if (countPeding > 0)
            return (
              <Tag icon={<ClockCircleOutlined spin />} color="warning">
                {t("PENDING")}
              </Tag>
            );
          break;
        case "CHECKBOX":
        case "RADIO":
        case "DROPDOW":
        case "RATE":
        case "SELECT":
        case "SWITCH":
        case "TRANSFER":
          let countNotResolved = 0;
          item.responses.forEach((response) => {
            const resp = item.available_responses.find(
              (x) => x.answer === response
            );
            if (!resp.action) countNotResolved += 1;
            else {
              resp.action.forEach((a) => {
                if (a.status !== "RESOLVED") {
                  countNotResolved += 1;
                }
              });
            }
          });
          if (countNotResolved > 0)
            return (
              <Tag icon={<ClockCircleOutlined spin />} color="warning">
                {t("PENDING")}
              </Tag>
            );
          break;
        default:
          break;
      }
    }
    return <div>{responseStatus}</div>;
  };

  return (
    <div>
      <Row style={{ marginTop: "40px", width: "100%", height: "100vh" }}>
        <Col span={6}>
          <Card
            style={{
              backgroundColor: "#f6f6f6",
              height: "100%",
              borderRadius: 0,
            }}
          >
            <Row>
              <div>{statusTag}</div>
            </Row>
            <Row style={{ marginTop: "40px" }}>
              <div>
                {t("PerformedIn")}{" "}
                {checklist.completedAt
                  ? new Date(checklist.completedAt).toLocaleDateString()
                  : t("DateNotAvailable")}
                <h2>{checklist.checklist_name}</h2>
              </div>
            </Row>
            <Row style={{ marginTop: "40px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <GiFarmer
                  style={{
                    color: "rgb(79, 175, 73)",
                    fontSize: "25px",
                    marginRight: "5px",
                  }}
                />
                <div style={{ color: "rgb(79, 175, 73)", fontSize: "20px" }}>
                  {t("Farmer")}
                </div>
              </div>
            </Row>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: "15px" }}>
                {checklist.farmer.name.charAt(0).toUpperCase() +
                  checklist.farmer.name.slice(1)}
              </div>
              <div style={{ fontSize: "15px" }}>
                {checklist.farmer.identifier}
              </div>
              <div>
                {checklist.farms.city} - {checklist.farms.state}
              </div>
            </Row>

            <Row style={{ marginTop: "40px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <MdSupportAgent
                  style={{
                    color: "rgb(79, 175, 73)",
                    fontSize: "25px",
                    marginRight: "5px",
                  }}
                />
                <div style={{ color: "rgb(79, 175, 73)", fontSize: "20px" }}>
                  {t("Agent")}
                </div>
              </div>
            </Row>

            <Row
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "15px",
                }}
              >
                {checklist.agent_name.charAt(0).toUpperCase() +
                  checklist.agent_name.slice(1)}
              </div>
            </Row>
            <Divider />
            <Row>
              <Col span={12} style={{ textAlign: "start" }}>
                <h3>{t("Total_Questions")}</h3>
              </Col>
              <Col span={12}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Tag
                    style={{
                      display: "flex",
                      width: "40px",
                      borderRadius: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "15px",
                    }}
                    color="#47342e"
                  >
                    {totals.total}
                  </Tag>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ textAlign: "start", marginTop: "10px" }}>
                <span>{t("Validate")}</span>
              </Col>
              <Col span={12} style={{ marginTop: "10px" }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Tag
                    style={{
                      display: "flex",
                      width: "40px",
                      borderRadius: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "15px",
                    }}
                  >
                    {totals.validate}
                  </Tag>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ textAlign: "start", marginTop: "10px" }}>
                <span>{t("PENDING")}</span>
              </Col>
              <Col span={12} style={{ marginTop: "10px" }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Tag
                    style={{
                      display: "flex",
                      width: "40px",
                      borderRadius: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "15px",
                    }}
                  >
                    {totals.pending}
                  </Tag>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ textAlign: "start", marginTop: "10px" }}>
                <span>{t("Not_Response")}</span>
              </Col>
              <Col span={12} style={{ marginTop: "10px" }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Tag
                    style={{
                      display: "flex",
                      width: "40px",
                      borderRadius: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "15px",
                    }}
                  >
                    {totals.notResponse}
                  </Tag>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={18}>
          <Card style={{ height: "100%", borderRadius: 0 }}>
            <Collapse
              style={{ backgroundColor: "white" }}
              bordered={false}
              onChange={onChange}
            >
              {checklist.checklist.questions_module.map((a, index) => {
                return (
                  <Panel header={a.module_name} key={index}>
                    <List
                      dataSource={a.questions}
                      renderItem={(item) => (
                        <List.Item>
                          <Col
                            span={8}
                            style={{
                              display: "flex",
                              justifyContent: "start",
                            }}
                          >
                            {item.question_title}
                          </Col>

                          <Col span={16}>
                            <Row
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Col
                                span={16}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {getResponses(item)}
                              </Col>
                              <Col
                                span={2}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {getMandatory(item)}
                              </Col>
                              <Col
                                span={6}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {responseStatus(item)}
                              </Col>
                            </Row>
                          </Col>
                        </List.Item>
                      )}
                    />
                  </Panel>
                );
              })}
            </Collapse>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
