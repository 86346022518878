import { Button } from "antd";

// Esse botão é para ser usado para confirmação de formulários, ele já tem o stylo aplicado seguindo
// o site antigo do admin
function BCButtonConfirm({ buttonText, style, icon = null }) {
  return (
    <Button
      type="primary"
      className="confirm_button"
      htmlType="submit"
      shape="round"
      style={style}
      icon={icon}
      block
    >
      {buttonText}
    </Button>
  );
}

export default BCButtonConfirm;
