export const ORIGIN = Object.freeze({
  PACKAGE_PURCHASE: "PACKAGE_PURCHASE",
  PACKAGE_UPDATE: "PACKAGE_UPDATE",
  CREATION_OF_VENDOR: "CREATION_OF_VENDOR",
  MANUAL_CREDITS: "MANUAL_CREDITS",
  PURCHASE_FROM_INDEPENDENT_SERVICES: "PURCHASE_FROM_INDEPENDENT_SERVICES",
});

export const DEBIT_OR_CREDIT = Object.freeze({
  DEBITED: "DEBITED",
  CREDITED: "CREDITED",
});
