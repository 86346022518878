import styled from "styled-components";
import { Polygon } from "@react-google-maps/api";
import { hashColor } from "../BCThemeColorMaps/hash-color";

export const Container = styled(Polygon).attrs((props) => {
  return {
    options: {
      strokeColor: props.customColor
        ? props.customColor.lineColor
        : hashColor(props.type).lineColor,
      strokeWeight: 2,
      fillColor: props.customColor
        ? props.customColor.areaColor
        : hashColor(props.type).areaColor,
      fillOpacity: 0.2,
    },
  };
})``;
