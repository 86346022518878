import React from "react";
import BCTable from "../BCTable";
import { Button, Space, Tag } from "antd";

import { EyeOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";

import * as S from "./SelectFarmerStyles";
import BCListFarms from "../utils/BCListFarms";

function SelectFarmer({ title, navigateToFunc }) {
  const { t } = useTranslation();

  const actions = [
    {
      title: t("Actions"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={() => navigateToFunc(record._id)}
            icon={<EyeOutlined />}
          >
            {t("View")}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <S.Container>
      <S.Title>{title}</S.Title>

      <BCListFarms actionsColumn={actions}></BCListFarms>
    </S.Container>
  );
}

export default SelectFarmer;
