import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Form, Input, Row, Select } from "antd";
import { Formik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import BCButtonConfirm from "../../components/BCButtonConfirm";
import { AuthContext } from "../../contexts/auth";
import { phoneMaskBR } from "../../utils/mask";
import { editUser } from "../AdministrationUsers/service";
import { findSalesman, viewSuppliers } from "./service";

const { Option } = Select;
const { Meta } = Card;

function SuppliersEditSalesman() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { vendorId, id } = useParams();
  const [salesman, setSalesman] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [suppliers, setSuppliers] = useState({
    name: "",
  });

  const validationSchema = Yup.object().shape({
    salesmanName: Yup.string().required(t("Name_is_Required")),
    email: Yup.string()
      .email(t("Invalid_Email"))
      .required(t("Email_is_required")),
    phone: Yup.string().required(t("Phone_Is_Required")),
  });

  const findSalesmans = useCallback(async () => {
    const result = await findSalesman(id);
    setSalesman(result);
  }, [id]);

  const findSuppliers = useCallback(async () => {
    const result = await viewSuppliers({
      id: vendorId,
    });
    setSuppliers(result);
  }, [vendorId]);

  useEffect(() => {
    findSuppliers();
    findSalesmans();
  }, [findSuppliers, findSalesmans]);

  async function editSalesmans(values) {
    values.user_id = id;
    await editUser(values);
    navigate(`/suppliers/${vendorId}/view`);
  }

  return (
    <div>
      <Formik
        initialValues={{
          salesmanName: salesman.name,
          email: salesman.email,
          phone: salesman.phone,
        }}
        validationSchema={validationSchema}
        onSubmit={editSalesmans}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
        }) => (
          <Form layout="vertical" onFinish={handleSubmit}>
            <Meta
              avatar={
                <Avatar
                  style={{ backgroundColor: "green", color: "white" }}
                  icon={<UserOutlined />}
                />
              }
              title={t("Edit_Employee_Data")}
              description={suppliers.name}
            />
            <Row style={{ marginTop: "30px" }} gutter={16}>
              <Col span={7}>
                <Row gutter={16}>
                  <Col xs={24} sm={24}>
                    <Row gutter={16}>
                      <Col xs={24} sm={24}>
                        <Form.Item
                          help={
                            touched.salesmanName && errors.salesmanName
                              ? errors.salesmanName
                              : ""
                          }
                          validateStatus={
                            touched.salesmanName && errors.salesmanName
                              ? "error"
                              : ""
                          }
                        >
                          <Input
                            prefix={<MailOutlined />}
                            name="salesmanName"
                            className="input"
                            value={values.salesmanName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t("Employee_name")}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24}>
                        <Form.Item
                          help={
                            touched.email && errors.email ? errors.email : ""
                          }
                          validateStatus={
                            touched.email && errors.email ? "error" : ""
                          }
                        >
                          <Input
                            prefix={<MailOutlined />}
                            name="email"
                            className="input"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t("Employee_email")}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24}></Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24}>
                        <Form.Item
                          help={
                            touched.phone && errors.phone ? errors.phone : ""
                          }
                          validateStatus={
                            touched.phone && errors.phone ? "error" : ""
                          }
                        >
                          <Input
                            name="phone"
                            className="input"
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t("Employee_phone")}
                            onKeyUp={(event) => phoneMaskBR(event)}
                            prefix={<PhoneOutlined />}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={10}></Col>
            </Row>
            <Form.Item>
              <BCButtonConfirm
                style={{ width: "100%", maxWidth: "200px", marginTop: "20px" }}
                buttonText={t("Save_Updates")}
              ></BCButtonConfirm>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SuppliersEditSalesman;
