import {
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Rate, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit } from "react-icons/ai";
import { BsBuildingFill } from "react-icons/bs";
import { FaTools } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { SERVICE_STATUS } from "../../Enums/Service.enum";
import BCTable from "../../components/BCTable";
import { list } from "../../pages/Services/service";
import { CardCarbonWallet } from "../Farmers/DetailFarmer/Components/CardCarbonWallet";
import { getWallet, listSalesman, viewSuppliers } from "./service";
export default function SuppliersView() {
  const { id } = useParams();

  const { t } = useTranslation();
  const [servicesCount, setServicesCount] = useState([]);
  const [suppliers, setSuppliers] = useState({});
  const [salesmans, setSalesmans] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState(1);
  const [sortField, setSortField] = useState("name");
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [wallet, setWallet] = useState({});
  const [search, setSearch] = useState("");

  const findSuppliers = useCallback(async () => {
    setLoading(true);
    const result = await viewSuppliers({
      id,
    });

    setSuppliers(result);
  }, [id]);

  const findSalesmans = useCallback(async () => {
    setLoading(true);
    const result = await listSalesman(pageSize, currentPage, search, id);
    setSalesmans(result.data.result);
    setTotal(result.data.count);
    setLoading(false);
  }, [pageSize, currentPage, search, id]);

  const findWallet = useCallback(async () => {
    setLoading(true);
    const result = await getWallet(id);
    setWallet(result);
    setLoading(false);
  }, [id]);

  const listServices = useCallback(async () => {
    setLoading(true);

    const result = await list(
      pageSize,
      currentPage,
      search,
      sortOrder,
      sortField,
      SERVICE_STATUS.ACCEPTED,
      id
    );
    result.result.map((x) => (x.key = x._id));
    setServicesCount(result.result.length);
    setTotal(result.count);
    setLoading(false);
  }, [pageSize, currentPage, search, sortOrder, sortField, id]);

  useEffect(() => {
    findWallet();
    findSuppliers();
    findSalesmans();
    listServices();
  }, [findWallet, findSuppliers, findSalesmans, listServices]);

  const navigate = useNavigate();
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "start",
      width: 150,

      render: (text, data) => <span>{data.name}</span>,
    },
    {
      title: t("Email"),
      dataIndex: "email",
      align: "start",
      width: 150,

      render: (text, data) => <span>{data.email}</span>,
    },
    {
      title: t("Phone"),
      dataIndex: "phone",
      align: "start",
      width: 150,

      render: (text, data) => <span>{data.phone}</span>,
    },
  ];

  const actionsColumn = [
    {
      title: t("Actions"),
      key: "action",
      align: "center",
      width: 150,
      render: (text, record) => (
        <AiOutlineEdit
          onClick={() =>
            navigate(`/suppliers/${id}/salesman/${record._id}/edit`)
          }
        />
      ),
    },
  ];

  return (
    <div>
      <div>
        <Button
          onClick={() => navigate(`/suppliers/${id}/create/salesman`)}
          shape="round"
          className="confirm_button"
          type="dashed"
          icon={<PlusOutlined />}
        >
          {t("Add_New_Salesman")}
        </Button>

        <Button
          onClick={() => navigate(`/suppliers/${id}/edit`)}
          shape="round"
          className="confirm_button"
          type="dashed"
          icon={<PlusOutlined />}
        >
          {t("Edit_Supplier")}
        </Button>
      </div>
      <Row style={{ marginTop: "20px" }} gutter={[16, 16]} justify="start">
        <Col xs={24} sm={12} md={18}>
          <Col
            style={{ display: "flex", justifyContent: "space-between" }}
            className="custom-card"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <h1 style={{ fontSize: "2rem" }}>
                <BsBuildingFill size={48} />
              </h1>
              <h1>{suppliers.name}</h1>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  <UserOutlined size={24} />
                </h1>
                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  {suppliers.responsible_name}
                </h1>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    fontSize: "1.0rem",
                    color: "#555555",
                  }}
                >
                  <MailOutlined size={24} />
                </h1>
                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  {suppliers.email}
                </h1>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    fontSize: "1.0rem",
                    color: "#555555",
                  }}
                >
                  <PhoneOutlined size={24} />
                </h1>
                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  {suppliers.phone}
                </h1>
              </div>
            </div>
          </Col>
        </Col>
      </Row>

      <Row style={{ marginTop: "10px" }} gutter={[16, 16]} justify="start">
        <Col xs={24} sm={12} md={6}>
          <Card
            onClick={() => navigate("/services")}
            style={{ cursor: "pointer" }}
            className="custom-card"
          >
            <div className="card-content">
              <div
                className="centered-content"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <FaTools size={24} style={{ alignSelf: "flex-end" }} />
                <h1
                  style={{
                    fontSize: "3rem",
                    alignSelf: "flex-start",
                  }}
                >
                  {servicesCount}
                </h1>
                <h4 style={{ fontSize: "20px", color: "#555555" }}>
                  {t("Available_Services")}
                </h4>
              </div>
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={12} md={6}>
          <Card
            onClick={() => navigate(`/suppliers/${id}/rate`)}
            style={{ cursor: "pointer" }}
            className="custom-card"
          >
            <div className="card-content">
              <div
                className="centered-content"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h1 style={{ fontSize: "4rem" }}>
                  {<Rate disabled value={suppliers.rate} />}
                </h1>
                <h4 style={{ fontSize: "20px", color: "#555555" }}>
                  {t("Rating")}
                </h4>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <CardCarbonWallet
            wallet={wallet}
            block_use_of_credits={""}
            isEnableBlock={false}
          />
        </Col>
      </Row>
      <Card style={{ marginTop: "20px" }}>
        <Row gutter={[16, 16]}>
          <h1>{t("Salesmans")}</h1>
          <div style={{ marginTop: "20px" }}>
            <BCTable
              data={salesmans}
              total={total}
              pageSize={pageSize}
              currentPage={currentPage}
              placeholderSearch={`${t("Search")} ${t("Salesmans")}`}
              setSearch={setSearch}
              setCurrentPage={setCurrentPage}
              setPageSize={setPageSize}
              setSortField={false}
              setSortOrder={false}
              loading={loading}
              columns={columns}
              actionsColumn={actionsColumn}
              showCreateButton={false}
            />
          </div>
        </Row>
      </Card>
    </div>
  );
}
