import axiosCalendar from "../../apis/axiosInterceptorCalendar";

export const getNotificationList = async ({ page, limit, statusArray }) => {
  let body = {
    page,
    limit,
  };

  if (statusArray) {
    body.statusArray = statusArray;
  }

  const { data } = await axiosCalendar.post("v1/notifications/list", body);
  return data.data;
};

export const updateNotificationItem = async ({ status, id }) => {
  let body = {
    setStatus: [
      {
        notification_id: id,
        status: status,
      },
    ],
  };

  const response = await axiosCalendar.patch(
    "v1/notifications/update-status",
    body
  );

  return response;
};
