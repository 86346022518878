import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as S from "./OverlapStyles";
import { BCMaps } from "../../../components/Maps/BCMaps";
import { BCPolygon } from "../../../components/Maps/BCPolygon";
import { useParams } from "react-router-dom";

import { fetchIntersectedAreas } from "../service";

import { getRandomHexColor } from "../../../utils/getRandomColor";

import ReviewIntersections from "./Components/ReviewIntersections/ReviewIntersections";
import { Colors } from "../../../components/Maps/BCThemeColorMaps/colors";
import { AdapterAreas } from "../../../components/Maps/BCPolygon/AdapterMapArea";
import { Spin } from "antd";

export function OverlapPage() {
  const { farmerId } = useParams();
  const polygonRef = useRef(null);
  const mapRef = useRef();

  const [selectedArea, setSelectedArea] = useState("");

  const [loading, setLoading] = useState(false);

  const [intersections, setIntersections] = useState([]);

  const [intersectionsIdVisibles, setIntersectionsIdVisibles] = useState([]);

  const polygonCoordinatesOnLoad = useMemo(() => {
    if (intersections.length > 0) {
      try {
        const areas = AdapterAreas(intersections[0].areas[0]).execute();
        return areas[0].coordinates;
      } catch (error) {
        console.log({ error });
      }
    }

    return undefined;
  }, [intersections]);

  const fetchOverlapAreas = async () => {
    setLoading(true);
    const intersections = await fetchIntersectedAreas(farmerId);

    // Also add colors in intersections areas object
    intersections.map((i, interIndex) =>
      i.areas.map((a, index) => {
        const color =
          a.farm_id !== farmerId
            ? getRandomHexColor()
            : Colors.FARM_BOUNDARIES_AREA;

        if (!intersections[interIndex].areas[index].customColor) {
          intersections[interIndex].areas[index].customColor = {
            lineColor: color,
            areaColor: color,
          };
        }

        // Sanatize object for the AdapterAreas
        intersections[interIndex].areas[index].programAreas = [];
      })
    );

    setIntersections(intersections);
  };

  const handlePolygonClick = (area) => {
    mapRef.current?.zoomToPolygon(area.coordinates);
  };

  useEffect(() => {
    let areas = [];

    for (const intersection of intersections) {
      if (intersectionsIdVisibles.includes(intersection._id))
        areas.push(...intersection.areas);
    }

    const uniqueAreas = [];

    // Remove duplicated areas and add custom colors
    for (const area of areas) {
      if (!uniqueAreas.find((a) => a.farm_id === area.farm_id)) {
        uniqueAreas.push(area);
      }
    }

    polygonRef?.current?.handlePaths(uniqueAreas);
  }, [intersections, intersectionsIdVisibles]);

  useEffect(() => {
    if (farmerId) {
      fetchOverlapAreas().finally(() => setLoading(false));
    }
  }, [farmerId]);

  return (
    <S.ContainerProgramMap>
      <BCMaps
        polygonCoordinatesOnLoad={polygonCoordinatesOnLoad}
        center={selectedArea}
        ref={mapRef}
      >
        <BCPolygon
          onPolygonClick={handlePolygonClick}
          showIndigenousAreasDefault={false}
          showPreservationAreaDefault={false}
          defaultFilter={[
            {
              field: "area_type",
              value: ["FARM_BOUNDARIES"],
            },
            {
              field: "status",
              value: [
                "APPROVED",
                "REJECTED",
                "PENDING",
                "IN_PROGRESS",
                undefined,
              ],
            },
          ]}
          ref={polygonRef}
        />
      </BCMaps>

      {intersections && intersections.length > 0 && (
        <ReviewIntersections
          intersections={intersections}
          onIntersectionIdVisiblesChange={(ids) => {
            setIntersectionsIdVisibles(ids);
          }}
        ></ReviewIntersections>
      )}

      {loading && (
        <S.LoadingScreen>
          <Spin size="large"></Spin>
        </S.LoadingScreen>
      )}
    </S.ContainerProgramMap>
  );
}
