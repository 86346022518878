import React from 'react'
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import BaseLayout from "../../pages/BaseLayout";
import { AdditionalInformation } from "../../pages/Farmers/AdditionalInformation";
import { DetailFarmerPage } from "../../pages/Farmers/DetailFarmer";
import FarmersApplicationDenied from "../../pages/Farmers/FarmersApplicationDenied";
import FarmersDashboard from "../../pages/Farmers/FarmersDashboard";
import FarmersEnrolledToTheProgram from "../../pages/Farmers/FarmersEnrolledToTheProgram";
import FarmersFlaggedForReview from "../../pages/Farmers/FarmersFlaggedForReview";
import FarmersInProgress from "../../pages/Farmers/FarmersInProgress";
import { OverlapPage } from "../../pages/Farmers/Overlap/OverlapPage";
import Private from "../Private";

function FarmersRoutes() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Private>
            <BaseLayout breadcrumb={`${t("Farmers")}`}>
              <FarmersDashboard />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/detail/:id/farmer"
        element={
          <Private>
            <BaseLayout breadcrumb={`${t("Farmers")}`}>
              <DetailFarmerPage />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/overlap/:farmerId"
        element={
          <Private>
            <BaseLayout breadcrumb={`${t("Overlap Page")}`}>
              <OverlapPage></OverlapPage>
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/detail/:id/farmer/information-additional"
        element={
          <Private>
            <BaseLayout>
              <AdditionalInformation />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/farmer/in-progress"
        element={
          <Private>
            <BaseLayout>
              <FarmersInProgress />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/farmer/application-denied"
        element={
          <Private>
            <BaseLayout>
              <FarmersApplicationDenied />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/farmer/enrolled-to-the-program"
        element={
          <Private>
            <BaseLayout>
              <FarmersEnrolledToTheProgram />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/farmer/flagged-for-review"
        element={
          <Private>
            <BaseLayout>
              <FarmersFlaggedForReview />
            </BaseLayout>
          </Private>
        }
      />
    </Routes>
  );
}

export default FarmersRoutes;
