import React from 'react'
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import AdministrationAndUsers from "../../pages/AdministrationUsers/AdministrationAndUsers";
import AdministrationCreateAdmin from "../../pages/AdministrationUsers/AdministrationCreateAdmin";
import AdministrationCreateAgent from "../../pages/AdministrationUsers/AdministrationCreateAgent";
import { AdministrationEdit } from "../../pages/AdministrationUsers/AdministrationEdit/AdministrationEdit";
import AdministrationListAdmins from "../../pages/AdministrationUsers/AdministrationListAdmins";
import AdministrationListAgents from "../../pages/AdministrationUsers/AdministrationListAgents";
import AdministrationViewAdmins from "../../pages/AdministrationUsers/AdministrationViewAdmin";
import { AgentEdit } from "../../pages/AdministrationUsers/AgentEdit/AgentEdit";
import BaseLayout from "../../pages/BaseLayout";
import Private from "../Private";

function AdministrationUsers() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Private>
            <BaseLayout breadcrumb={t("Administration_and_users")}>
              <AdministrationAndUsers />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/list-agents"
        element={
          <Private>
            <BaseLayout
              breadcrumb={`${t("Administration_and_users")} / ${t("Agents")}`}
              gotoback="/administration-users"
            >
              <AdministrationListAgents />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/list-admins"
        element={
          <Private>
            <BaseLayout
              gotoback="/administration-users"
              breadcrumb={`${t("Administration_and_users")} / ${t(
                "Administrators"
              )}`}
            >
              <AdministrationListAdmins />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/create/admin"
        element={
          <Private>
            <BaseLayout
              gotoback="/administration-users"
              breadcrumb={`${t("Administration_and_users")} / ${t(
                "Creation"
              )} / ${t("Administrators")}`}
            >
              <AdministrationCreateAdmin />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/create/agent"
        element={
          <Private>
            <BaseLayout
              gotoback="/administration-users"
              breadcrumb={`${t("Administration_and_users")} / ${t(
                "Creation"
              )} / ${t("Agents")}`}
            >
              <AdministrationCreateAgent />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/:id/edit"
        element={
          <Private>
            <BaseLayout
              gotoback="/administration-users/list-agents"
              breadcrumb={`${t("Administration_and_users")} / ${t(
                "Edition"
              )} / ${t("Administrators")}`}
            >
              <AdministrationEdit></AdministrationEdit>
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/admin/:id/view"
        element={
          <Private>
            <BaseLayout
              breadcrumb={`${t("Administration_and_users")} / ${t(
                "Admin"
              )} / ${t("View")}`}
              gotoback="/administration-users/list-admins"
            >
              <AdministrationViewAdmins />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/:id/edit-agent"
        element={
          <Private>
            <BaseLayout
              gotoback="/administration-users/list-agents"
              breadcrumb={`${t("Administration_and_users")} / ${t(
                "Edition"
              )} / ${t("field_agent")}`}
            >
              <AgentEdit></AgentEdit>
            </BaseLayout>
          </Private>
        }
      />
    </Routes>
  );
}

export default AdministrationUsers;
