import { CloseOutlined, EyeOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Space, Tag } from "antd";
import { default as React, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTools } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import BCTable from "../../components/BCTable.jsx";
import { findServicePerStatus, list } from "./service.js";

export default function ServicesDashboard() {
  const navigate = useNavigate();

  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [vendorId, setVendorId] = useState("");
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [countServicesPerStatus, setCountServicePerStatus] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [sortField, setSortField] = useState("name");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const listServices = useCallback(async () => {
    setLoading(true);
    const result = await list(
      pageSize,
      currentPage,
      search,
      sortOrder,
      sortField,
      status,
      vendorId
    );
    result.result.map((x) => (x.key = x._id));
    setServices(result.result);
    setTotal(result.count);
    setLoading(false);
  }, [pageSize, currentPage, search, sortOrder, sortField, status, vendorId]);
  useEffect(() => {
    listServices();
  }, [listServices]);

  const onView = (key) => {
    navigate(`${key}/view`);
  };
  const getServicePerStatus = useCallback(async () => {
    setLoading(true);
    const resultCount = await findServicePerStatus("PENDING");
    setCountServicePerStatus(resultCount.count);
  }, []);
  useEffect(() => {
    getServicePerStatus();
  }, [getServicePerStatus]);
  const columns = [
    {
      title: t("Name"),
      dataIndex: "name",
      align: "center",
    },
    {
      title: t("Description"),
      dataIndex: "description",
      align: "center",
      render: (text, data) => data.description,
    },
    {
      title: t("Cost"),
      dataIndex: "cost",
      align: "center",
      render: (text, data) => data.cost,
    },
    {
      title: t("Suppliers"),
      dataIndex: "vendor_name",
      align: "center",
      render: (text, data) => data.vendor_name,
    },
    {
      title: t("Status"),
      dataIndex: "status",
      align: "center",
      render: (text, data) => {
        let statusTag;
        switch (data.status) {
          case "PENDING":
            statusTag = (
              <Tag icon={<SyncOutlined spin />} color="processing">
                {t("PENDING")}
              </Tag>
            );
            break;
          case "REJECTED":
            statusTag = (
              <Tag icon={<CloseOutlined />} color="error">
                {t("REJECTED")}
              </Tag>
            );
            break;

          case "ACCEPTED":
            statusTag = <Tag color="success">{t("ACCEPTED")}</Tag>;
            break;
          default:
            statusTag = <span>Status Desconhecido</span>;
        }
        return statusTag;
      },
    },
  ];
  const onAdd = () => {
    navigate("create/service");
  };
  const actionsColumn = [
    {
      title: t("Actions"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={() => onView(record.key)}
            icon={<EyeOutlined />}
          >
            {t("View")}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <BCTable
        data={services}
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        placeholderSearch={`${t("Search")} ${t("Services")}`}
        setSearch={setSearch}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        loading={loading}
        columns={columns}
        actionsColumn={actionsColumn}
        showCreateButton={true}
        textButtonAdd={t("Add_new_Service")}
        onView={onView}
        onAdd={onAdd}
      />
      <Row style={{ marginTop: "20px" }} gutter={[16, 16]} justify="start">
        <Col xs={24} sm={12} md={6}>
          <Card
            onClick={() => navigate("list-services-in-revision")}
            style={{
              cursor: "pointer",
              height: "150px",
              width: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="custom-card"
          >
            <div className="card-content">
              <div
                className="centered-content"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <h1
                  style={{
                    fontSize: "3rem",
                    alignSelf: "flex-start",
                    color: "#555555",
                  }}
                >
                  {countServicesPerStatus}
                </h1>
                <FaTools
                  size={24}
                  style={{ marginTop: "10", alignSelf: "flex-end" }}
                />
                <h4 style={{ fontSize: "20px", color: "#555555" }}>
                  {t("Services_Under_Review")}
                </h4>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
