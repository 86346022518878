import axiosChat from "../../apis/axiosInterceptorChat";
import axios from "../../apis/axiosInterceptorFarmer";

export const listFarmers = async ({
  currentPage = 1,
  pageSize = 10,
  search = "",
  sortOrder = 1,
  sortField = "name",
  id = "",
  withProgram = false,
  no_agents,
  only_with_agents,
  withChecklist = false,
  checklistType = "",
}) => {
  const result = await axios.get(
    `/v3/farm_admin?limit=${pageSize}&page=${currentPage}&search=${search}&sort=${sortOrder}&field=${sortField}&_id=${id}&withProgram=${withProgram}&no_agents=${no_agents}&only_with_agents=${only_with_agents}&withChecklist=${withChecklist}&checklist.checklist_type=${checklistType}`
  );
  return result.data.data;
};

export const listChats = async ({
  farmerId,
  pageSize = 10,
  currentPage = 1,
}) => {
  const result = await axiosChat.get(
    `/v1/chat?limit=${pageSize}&page=${currentPage}&farmerId=${farmerId}`
  );
  return result.data.data;
};

export const getChat = async ({ id }) => {
  const result = await axiosChat.get(`/v1/chat/${id}`);
  return result.data.data;
};

export const sendPost = async ({ chatId, formData, config }) => {
  await axiosChat.put(`v1/chat/post/${chatId}`, formData, config);
};

export const changeStatus = async ({ chatId, status }) => {
  await axiosChat.patch(`v1/chat/${chatId}`, { status });
};

export const getTitles = async () => {
  const result = await axiosChat.get("v1/titles_chats");
  return result.data.data;
};

export const createChat = async (formData) => {
  const result = await axiosChat.post("v1/chat", formData);
  return result.data.data;
};
