import { Select } from "antd";
import React from "react";
import brazilFlag from "../assets/br.svg";
import usaFlag from "../assets/us.svg";

const BCFlagSelector = ({ selected, onSelect }) => {
  return (
    <Select
      style={{
        width: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      value={selected}
      onChange={onSelect}
      dropdownMatchSelectWidth={false}
      className="bc-flag-selector"
    >
      <Select.Option value="+55">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={brazilFlag}
            alt="BR"
            style={{ width: "20px", marginRight: "4px" }}
          />{" "}
          +55
        </div>
      </Select.Option>
      <Select.Option value="+1">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={usaFlag}
            alt="US"
            style={{ width: "20px", marginRight: "4px" }}
          />{" "}
          +1
        </div>
      </Select.Option>
    </Select>
  );
};

export default BCFlagSelector;
