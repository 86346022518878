import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BCGraphic } from "../../../components/BCGraphic/BCGraphic";
import { getEnrolledVsImplementedPerYearID, getListUsers } from "../service";
import * as S from "./OverviewGraphicStyles";

import { Pagination } from "antd";

export const OverviewGraphic = () => {
  const [agents, setAgents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [countAgents, setCountAgents] = useState(0);
  const [agentSelected, setAgentSelected] = useState();
  const [dataGraphic, setDataGraphic] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    loadAgents();
  }, [currentPage]);

  useEffect(() => {
    loadDataGraphicByAgent();
  }, [agentSelected]);

  const loadAgents = useCallback(async () => {
    try {
      const responseAgents = await getListUsers(currentPage);
      const { result, count } = responseAgents;
      setAgents(result);
      setCountAgents(count);
      if (count === 0) return;
      setAgentSelected(result[0]);
    } catch (error) {}
  }, [currentPage]);

  const loadDataGraphicByAgent = useCallback(async () => {
    if (!agentSelected) return;
    const response = await getEnrolledVsImplementedPerYearID(agentSelected._id);

    const serializerDataGraphic = [];

    response.forEach((item) => {
      item.months.forEach((month) => {
        serializerDataGraphic.push({
          period: `${item.year}-${month.month}`,
          value: month.total_farms_enrolled,
          name: t("Enrolled_farmers"),
        });
      });
    });

    setDataGraphic(serializerDataGraphic);
  }, [agentSelected, agents]);

  return (
    <S.Container>
      <S.WrapperList>
        <S.HeaderList>
          <S.TitleList>
            {t("See_it_all")} ({countAgents})
          </S.TitleList>
        </S.HeaderList>
        <S.ListAgent>
          {agents.map((item, key) => (
            <S.ItemList
              isActive={item.name === agentSelected?.name}
              key={key}
              onClick={() => setAgentSelected(item)}
            >
              <S.TitleItemList>{item?.name}</S.TitleItemList>
              <S.IconArrow />
            </S.ItemList>
          ))}
        </S.ListAgent>
        <S.WrapperPagination>
          <Pagination
            defaultCurrent={1}
            total={countAgents}
            onChange={(e) => setCurrentPage(e)}
          />
        </S.WrapperPagination>
      </S.WrapperList>
      <S.ContentGraphic>
        <S.HeaderGraphic>
          <S.TitleNameSelected>
            {agentSelected ? agentSelected?.name : ""}
          </S.TitleNameSelected>
          <S.WrapperOrganization>
            <S.LabelOrganization>
              {t("Organization")}:{" "}
              <span>
                {agentSelected ? agentSelected?.organization?.name : ""}
              </span>
            </S.LabelOrganization>
            <S.LabelOrganization>
              {t("Participating")}:{" "}
              <span>
                {agentSelected?.regions ? agentSelected.regions[0]?.name : ""}
              </span>
            </S.LabelOrganization>
          </S.WrapperOrganization>
        </S.HeaderGraphic>
        <S.WrapperGraphic>
          <BCGraphic
            data={dataGraphic}
            title={`${t("Enrolled_vs_Deployed")}-${
              agentSelected ? agentSelected?.name : ""
            }`}
          />
        </S.WrapperGraphic>
      </S.ContentGraphic>
    </S.Container>
  );
};
