import { useEffect, useState } from "react";
import { signatureLink } from "../Farmers/service";

export const MessageFile = ({ url }) => {
  const [link, setLink] = useState("");

  useEffect(() => {
    handleSignatureLink();
  }, [])
  const handleSignatureLink= async () => {
    try {
      const response = await signatureLink(url);
      setLink(response);
    } catch (error) {}
  };
  return <img src={link} width={300} />;
};
