import React, { useEffect, useState } from "react";
import { Input, Select, Skeleton } from "antd";

import {
  AboutArea,
  ActionsIntersection,
  Container,
  ContentButtons,
  ContentForm,
  ContentItemDescription,
  DescriptionIntersection,
  Error,
  FarmColor,
  FormIntersection,
  SectionDescribeAreas,
  Arrow,
} from "./styles";

import { useTranslation } from "react-i18next";
import { fetchIntersectedAreas } from "../../../service";

export const ItemAreaIntersection = ({
  answerArea,
  setAnswerArea,
  area,
  intersection,
  intersectedFarmId,
}) => {
  const { t } = useTranslation();

  const [openArea, setOpenArea] = useState(false);
  const [openDescribeArea, setOpenDescribeArea] = useState(false);
  const [message, setMessage] = useState();
  const [status, setStatus] = useState();

  const renderMessage = () => {
    let message = answerArea.status ? t(answerArea.status) + " - " : "";
    let complement = answerArea.valid ? t("waiting_send") : t("evaluate_area");

    return message + complement;
  };

  const ItemDescription = ({ title, label }) => {
    return (
      <ContentItemDescription>
        <p>{title}</p>
        <span>{label}</span>
      </ContentItemDescription>
    );
  };

  const cancel = () => {
    setAnswerArea({
      description: "",
      status: "",
      valid: false,
      error: false,
    });
    setOpenArea(false);
  };

  const save = () => {
    const { description, status } = answerArea;
    const error = !description || !status;
    const valid = !error;

    setAnswerArea({
      description,
      status,
      error,
      valid,
    });

    if (!answerArea.error) setOpenArea(false);
  };

  const fetchIntersectionItemMessageStatus = async () => {
    let farmIntersectDetails = {};

    if (area.farm_id === intersection.farm_id) {
      farmIntersectDetails = intersection;
    } else {
      if (intersection.status === "PENDING")
        farmIntersectDetails.status = "PENDING";
      else
        farmIntersectDetails.status =
          intersection.status === "APPROVED" ? "REJECTED" : "APPROVED";

      farmIntersectDetails.description_main =
        intersection.description_secondary;
    }

    setStatus(farmIntersectDetails.status);
    setMessage(farmIntersectDetails.description_main);
  };

  useEffect(() => {
    fetchIntersectionItemMessageStatus();
  }, [intersection]);

  return (
    <Container>
      <AboutArea>
        <FarmColor color={area.customColor.lineColor} />
        <DescriptionIntersection>
          <p>{area.farm_name}</p>
          <span>{area.farm_identifier}</span>
          {message && status ? (
            <SectionDescribeAreas>
              <button onClick={() => setOpenDescribeArea(!openDescribeArea)}>
                <span>{t(status)}</span>
                <Arrow type={openDescribeArea ? "up" : "down"} />
              </button>
              {openDescribeArea && (
                <>
                  <ItemDescription
                    title={t("status")}
                    label={t(status).toLocaleUpperCase()}
                  />
                  <ItemDescription title={t("description")} label={message} />
                </>
              )}
            </SectionDescribeAreas>
          ) : (
            <ActionsIntersection>
              {openArea ? (
                <FormIntersection>
                  <ContentButtons>
                    <button onClick={cancel}>{t("btn_cancel")}</button>
                    <button onClick={save}>{t("btn_save")}</button>
                  </ContentButtons>
                </FormIntersection>
              ) : (
                <button
                  disabled={answerArea.valid}
                  onClick={() => setOpenArea(true)}
                >
                  {renderMessage()}
                </button>
              )}
            </ActionsIntersection>
          )}
        </DescriptionIntersection>
      </AboutArea>
      {openArea && (
        <ContentForm>
          {answerArea.error && <Error>{t("message_error")}</Error>}
          <Select
            defaultValue=""
            onChange={(value) =>
              setAnswerArea({
                ...answerArea,
                status: value,
              })
            }
          >
            <Select.Option value="">{t("status")}</Select.Option>
            <Select.Option value="approved">{t("approved")}</Select.Option>
            <Select.Option value="rejected">{t("rejected")}</Select.Option>
          </Select>
          <Input
            placeholder="Descrição"
            value={answerArea.description}
            onChange={(e) =>
              setAnswerArea({
                ...answerArea,
                description: e.target.value,
              })
            }
          />
        </ContentForm>
      )}
    </Container>
  );
};
