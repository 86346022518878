import React from 'react'
import { ConfigProvider } from "antd";
import ptBR from "antd/lib/locale/pt_BR";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthProvider from "./contexts/auth.jsx";
import { LanguageProvider } from "./contexts/languageContext.jsx";
import "./i18n.js";
import RoutesApp from "./routes/routes.jsx";
import { AppProvider } from "./hooks/index.jsx";

function App() {
  return (
    <BrowserRouter>
      <ConfigProvider locale={ptBR}>
        <LanguageProvider>
          <AuthProvider>
            <AppProvider>
              <ToastContainer autoClose={3000} />
              <RoutesApp />
            </AppProvider>
          </AuthProvider>
        </LanguageProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
