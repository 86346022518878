import React from 'react'
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import BaseLayout from "../../pages/BaseLayout";
import CenterNotificationCreate from "../../pages/CenterNotification/CenterNotificationCreate";
import CenterNotificationList from "../../pages/CenterNotification/CenterNotificationList";
import NotificationView from "../../pages/CenterNotification/CenterNotificationView";
import Private from "../Private";

function NotificationsRoutes() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Private>
            <BaseLayout breadcrumb={`${t("Notifications")}`}>
              <CenterNotificationList />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/create"
        element={
          <Private>
            <BaseLayout
              gotoback={"/notifications"}
              breadcrumb={`${t("Notifications")} / ${t("Creation")}`}
            >
              <CenterNotificationCreate />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/:id/view"
        element={
          <Private>
            <BaseLayout
              gotoback={"/notifications"}
              breadcrumb={`${t("Notifications")} / ${t("View")}`}
            >
              <NotificationView />
            </BaseLayout>
          </Private>
        }
      />
    </Routes>
  );
}

export default NotificationsRoutes;
