import { Card, Col, Divider, Row, Tag } from "antd";

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiMessageDetail } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { getNotification } from "../CenterNotification/service";

export default function NotificationView() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [notification, setNotification] = useState([]);
  const [count, setCount] = useState([]);
  const [loading, setLoading] = useState(false);

  const findNotification = useCallback(async () => {
    setLoading(true);
    const result = await getNotification(id);
    setNotification(result[0]);
    setCount(result.length);
  }, [id]);
  useEffect(() => {
    findNotification();
  }, [findNotification]);

  return (
    <div>
      <Row style={{ marginTop: "40px", width: "100%", height: "100vh" }}>
        <Col span={8}>
          <Card
            style={{
              backgroundColor: "#f6f6f6",
              height: "100%",
              borderRadius: 0,
            }}
          >
            <Row></Row>
            <Row style={{ marginTop: "20px" }}>
              <div>{new Date(notification.createdAt).toLocaleDateString()}</div>
            </Row>
            <Row style={{ marginTop: "1px" }}>
              <div></div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <BiMessageDetail
                  style={{
                    color: "rgb(79, 175, 73)",
                    fontSize: "25px",
                    marginRight: "5px",
                    marginTop: "13px",
                  }}
                />
                <div
                  style={{
                    color: "rgb(79, 175, 73)",
                    fontSize: "25px",
                    marginTop: "10px",
                  }}
                >
                  {t("Notifications")}
                </div>
              </div>
            </Row>

            <Row
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: "15px" }}></div>
              <div style={{ fontSize: "15px" }}></div>
              <div></div>
            </Row>

            <Row style={{ marginTop: "20px", fontSize: "20px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {t(notification.title)}
              </div>
            </Row>
            <Row>
              <div
                style={{
                  fontSize: "15px",
                  marginRight: "5px",
                  marginTop: "10px",
                }}
              >
                {notification.description}
              </div>
            </Row>

            <Row>
              <Col span={12} style={{ textAlign: "start" }}>
                <h3>{notification.user_origin_name}</h3>
              </Col>
              <Col span={12}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Tag
                    style={{
                      display: "flex",
                      width: "130px",
                      borderRadius: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "15px",
                    }}
                    color="#47342e"
                  >
                    {t(notification.user_origin_type)}
                  </Tag>
                </div>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={12} style={{ textAlign: "start" }}>
                <h3>{t("Total_Sent")}</h3>
              </Col>
              <Col span={12}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Tag
                    style={{
                      display: "flex",
                      width: "40px",
                      borderRadius: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "15px",
                    }}
                    color="#47342e"
                  >
                    {count}
                  </Tag>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={16}>
          <Card style={{ height: "100%", borderRadius: 0 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ fontSize: "25px" }}>{t("Destinations")}</div>
            </div>
            <Divider />
            <Row>
              <Col span={12} style={{ textAlign: "start" }}>
                <h3>{notification.user_destination_name}</h3>
              </Col>
              <Col span={12}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Tag
                    style={{
                      display: "flex",
                      width: "130px",
                      borderRadius: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "15px",
                    }}
                    color="#47342e"
                  >
                    {t(notification.user_destination_type)}
                  </Tag>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
