import styled, { css } from "styled-components";

export const Container = styled.div``;

const yellowColor = ["PENDING", "WAITING_TO_CALL", "CREATED", "SENT"];
const blueColor = ["PENDING_APPROVAL", "NEWPROPOSAL"];

export const Title = styled.h4`
  margin-left: 16px;
  color: #47342e;
  font-size: 18px;
`;

export const CountPendingAction = styled.span`
  color: rgba(71, 52, 46, 0.72);
  font-weight: 400;
  font-size: 16px;
`;

export const WrapperTable = styled.div`
  background-color: #fff;
  box-shadow: 1px 1px 10px #3331;
  border: 1px solid #3332;
  border-radius: 8px;
  margin-top: 12px;
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  padding: 0.5rem 0;
  border-radius: 20px;
  width: 130px;
  font-size: 0.875rem;

  ${(props) =>
    yellowColor.includes(props.status) &&
    css`
      color: #ffbc00;
    `}

  ${(props) =>
    blueColor.includes(props.status) &&
    css`
      color: #399ed8;
    `}
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  margin-right: 2rem;

  cursor: pointer;
`;
