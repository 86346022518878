export const isSafeURL = (url) => {
  const safeDomains = [
    "admin.beta.sabiaapp.com",
    "admin.gamma.sabiaapp.com",
    "admin.sabiaapp.com",
    "admin.dev-srafaels.sabiaapp.com",
    "localhost:3000",
  ];

  const isSafe = safeDomains.some((domain) => {
    if (domain == "localhost:3000") {
      return url.startsWith(`http://${domain}`);
    } else {
      return url.startsWith(`https://${domain}`);
    }
  });

  return isSafe;
};
