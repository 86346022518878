import axios from "../../apis/axiosInterceptorEntitlement";

export const ChangePassService = async (
  currentPassword,
  password,
  confirmePassword
) => {
  const payload = {
    current_password: currentPassword,
    password: password,
    confirm_password: confirmePassword,
  };
  await axios.patch(`v2/user/change-password`, payload);
};

export const getUser = async () => {
  const result = await axios.get(`v1/user/my-user`);
  return result.data;
};

export const editUserService = async (name, email, phone) => {
  const userLocal = localStorage.getItem("userLogged");
  const payload = {
    email: email,
    name: name,
    phone: phone,
    user_id: JSON.parse(userLocal).user._id,
  };
  await axios.put(`v2/user/edit`, payload);
};
