import axios from "../../apis/axiosInterceptorCarbon";

export const list = async (
  pageSize,
  currentPage,
  search,
  sortOrder,
  sortField,
  status,
  vendorId
) => {
  const result = await axios.get(
    `/v1/service?limit=${pageSize}&page=${currentPage}&name=${search}&status=${status}&vendorId=${vendorId}`
  );
  return result.data.data;
};

export const createService = async (name, description, cost) => {
  const payload = {
    name,
    description,
    cost,
  };
  await axios.post(`/v1/service`, payload);
};

export const findServicePerStatus = async (status) => {
  const result = await axios.get(`v1/service/count-status/${status}`);
  return result.data;
};
export const findServiceById = async (id) => {
  const result = await axios.get(`v1/service/${id}`);
  return result.data;
};
export const updateService = async (id, status, description) => {
  const payload = {
    status,
    description,
  };
  const result = await axios.patch(`v1/service/update-status/${id}`, payload);
  return result.data;
};
