import styled from "styled-components";

export const ContainerProgramMap = styled.div`
  display: flex;
  flex: 1;
  margin: -20px;
  border-radius: 6px;
`;

export const LoadingScreen = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;

  align-items: center;
`;
