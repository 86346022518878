import { SyncOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Form, Input, Row, Tag } from "antd";
import { Formik } from "formik";
import { default as React, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsBuildingFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { SERVICE_STATUS } from "../../Enums/Service.enum";
import { findServiceById, updateService } from "./service";

const { Meta } = Card;

export default function ServicesInRevisionView() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [service, setServices] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();

  const findService = useCallback(async () => {
    setLoading(false);
    const result = await findServiceById(id);
    setServices(result.data);
  }, [id]);

  useEffect(() => {
    findService();
  }, [findService]);

  const validationSchema = Yup.object().shape({
    status: Yup.string().required("Status_Is_Required"),
    description: Yup.string(),
  });

  async function updateStatusService(values) {
    try {
      await updateService(id, values.status, values.description);
      navigate("/services");
    } catch (error) {}
  }

  return (
    <Formik
      initialValues={{
        status: "",
        description: "",
      }}
      validationSchema={validationSchema}
      onSubmit={updateStatusService}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
      }) => (
        <Form layout="horizontal" onFinish={handleSubmit}>
          <Row>
            <Col span={8}>
              <Card
                style={{
                  backgroundColor: "#f6f6f6",
                  height: "100%",
                  borderRadius: 0,
                  marginBottom: "40px",
                }}
              >
                <Row>
                  <div>
                    {
                      <Tag
                        icon={<SyncOutlined spin />}
                        style={{ fontSize: "20px" }}
                        color="processing"
                      >
                        {service.status}
                      </Tag>
                    }
                  </div>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ fontSize: "20px" }}>{t("Supplier")}</div>
                  </div>
                </Row>
                <Row>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h1 style={{ fontSize: "2rem" }}>
                        <BsBuildingFill size={48} />
                      </h1>
                      <h1>{service.vendor_name}</h1>
                    </div>
                  </div>
                </Row>
                <Divider />
                <Row style={{ marginTop: "30px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ fontSize: "20px" }}>{t("Service")}</div>
                  </div>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ fontSize: "15px" }}>{service.name}</div>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ fontSize: "20px" }}>{t("Description")}</div>
                  </div>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ fontSize: "15px" }}>{service.description}</div>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      {t("Cost")}
                    </div>
                  </div>
                </Row>

                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "15px",
                    }}
                  >
                    R$ {service.cost}
                  </div>
                </Row>
                <Form.Item
                  help={
                    touched.description && errors.description
                      ? errors.description
                      : ""
                  }
                  validateStatus={
                    touched.description && errors.description ? "error" : ""
                  }
                >
                  <div style={{ marginTop: "20px" }}>
                    <Input.TextArea
                      name="description"
                      value={values.description}
                      className="input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t("Rejected_Description")}
                    />
                  </div>
                </Form.Item>

                <Divider />
                <Row>
                  <Col
                    span={12}
                    style={{
                      textAlign: "start",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      style={{
                        display: "flex",
                        width: "100px",
                        borderRadius: "20px",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "15px",
                        backgroundColor: "red",
                        color: "white",
                      }}
                      onClick={() =>
                        updateStatusService({
                          status: SERVICE_STATUS.REJECTED,
                          description: values.description,
                        })
                      }
                    >
                      {t("Reject")}
                    </Button>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        style={{
                          display: "flex",
                          width: "100px",
                          borderRadius: "20px",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "15px",
                          backgroundColor: "green",
                          color: "white",
                        }}
                        onClick={() =>
                          updateStatusService({
                            status: SERVICE_STATUS.ACCEPTED,
                          })
                        }
                      >
                        {t("Accepted")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
