import { CloseCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Collapse, Tag } from "antd";
import { default as React, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BCTable from "../../components/BCTable";
import { findServiceById } from "./service";

const { Panel } = Collapse;

export default function ServicesView() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [service, setServices] = useState([]);
  const { t } = useTranslation();

  const findService = useCallback(async () => {
    setLoading(false);
    const result = await findServiceById(id);
    setServices([result.data]);
  }, [id]);
  useEffect(() => {
    findService();
  }, [findService]);

  const columns = [
    {
      title: t("Name"),
      dataIndex: "name",
      align: "start",
      render: (text, data) => <span>{data.name}</span>,
    },

    {
      title: t("Description"),
      dataIndex: "description",
      align: "start",
      render: (text, data) => <span>{data.description}</span>,
    },

    {
      title: t("Cost"),
      dataIndex: "cost",
      align: "start",
      render: (text, data) => <span>{data.cost} </span>,
    },
    {
      title: t("Status"),
      dataIndex: "status",
      align: "start",
      render: (text, data) => {
        let statusTag;
        switch (data.status) {
          case "PENDING":
            statusTag = (
              <Tag icon={<SyncOutlined spin />} color="processing">
                {t("PENDING")}
              </Tag>
            );
            break;
          case "REJECTED":
            statusTag = (
              <Tag icon={<CloseCircleOutlined />} color="error">
                {t("REJECTED")}
              </Tag>
            );
            break;

          case "ACCEPTED":
            statusTag = <Tag color="success">{t("ACCEPTED")}</Tag>;
            break;
          default:
            statusTag = <span>Status Desconhecido</span>;
        }
        return statusTag;
      },
    },
    {
      title: t("Offers"),
      dataIndex: "offers",
      align: "start",
      render: (text, data) => (
        <span>
          {data.offers.length} {t("Offers")}
        </span>
      ),
    },
    {
      title: t("Date"),
      dataIndex: "date",
      align: "start",
      render: (text, data) => (
        <span>{new Date(data.createdAt).toLocaleDateString()}</span>
      ),
    },
  ];
  return (
    <div>
      <h1>{t("Service")}</h1>

      <BCTable
        data={service}
        total={false}
        pageSize={false}
        currentPage={false}
        placeholderSearch={false}
        setSearch={false}
        setCurrentPage={false}
        setPageSize={false}
        setSortField={false}
        setSortOrder={false}
        loading={loading}
        columns={columns}
        showCreateButton={false}
        showPlaceholderSearch={false}
        disablePagination={true}
      />
    </div>
  );
}
