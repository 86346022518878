import { useTranslation } from "react-i18next";
import { CardTotalArea } from "./Components/CardTotalArea";
import { CardTotalAreaAgroforestry } from "./Components/CardTotalAreaAgroforestry";
import { CardTotalAreaRestoration } from "./Components/CardTotalAreaRestoration";
import { CardGraphicArea } from "./Components/CardGraphicArea";

import * as S from "./OverviewOfProgramAreaStyles";
import { getEnrolledVsImplemented } from "../service";
import { useEffect, useState } from "react";

export const OverviewOfProgramArea = () => {
  const { t } = useTranslation();
  const [totalAreaEnrolled, setTotalAreaEnrolled] = useState(0);
  const [totalAreaImplemented, setTotalAreaImplemented] = useState(0);
  const [totalAgroforestryEnrolled, setTotalAgroforestryEnrolled] = useState(0);
  const [totalAgroforestryImplemented, setTotalAgroforestryImplemented] =
    useState(0);
  const [totalRestorationEnrolled, setTotalRestorationEnrolled] = useState(0);
  const [totalRestorationImplemented, setTotalRestorationImplemented] =
    useState(0);

  useEffect(() => {
    loadDataGraphRegionOverview()
  }, [])

  const loadDataGraphRegionOverview = async () => {
    try {
      const dataEnrolledVsImplemented = await getEnrolledVsImplemented();
      let totalAreaEnrolled = 0;
      let totalAreaImplemented = 0;
      let totalAgroforestryEnrolled = 0;
      let totalAgroforestryImplemented = 0;
      let totalRestorationEnrolled = 0;
      let totalRestorationImplemented = 0;

      dataEnrolledVsImplemented.map(async (region) => {
        totalAreaEnrolled += parseFloat(region.total_afs_enrolled_brown);
        totalAreaEnrolled += parseFloat(region.total_er_enrollled_blue);

        totalAreaImplemented += parseFloat(region.total_afs_implemented_green);
        totalAreaImplemented += parseFloat(region.total_er_implemented_orange);

        totalAgroforestryEnrolled += parseFloat(
          region.total_afs_enrolled_brown
        );
        totalAgroforestryImplemented += parseFloat(
          region.total_afs_implemented_green
        );

        totalRestorationEnrolled += parseFloat(region.total_er_enrollled_blue);
        totalRestorationImplemented += parseFloat(
          region.total_er_implemented_orange
        );
      });
      setTotalAreaEnrolled(totalAreaEnrolled);
      setTotalAreaImplemented(totalAreaImplemented);
      setTotalAgroforestryEnrolled(totalAgroforestryEnrolled);
      setTotalAgroforestryImplemented(totalAgroforestryImplemented);
      setTotalRestorationEnrolled(totalRestorationEnrolled);
      setTotalRestorationImplemented(totalRestorationImplemented);
    } catch (error) {}
  };

  return (
    <S.Container>
      <S.WrapperInfo>
        <CardTotalArea totalAreaEnrolled={totalAreaEnrolled} totalAreaImplemented={totalAreaImplemented}/>
        <S.WrapperBoxCountArea>
          <CardTotalAreaAgroforestry totalAgroforestryEnrolled={totalAgroforestryEnrolled} totalAgroforestryImplemented={totalAgroforestryImplemented}/>
          <CardTotalAreaRestoration totalRestorationEnrolled={totalRestorationEnrolled} totalRestorationImplemented={totalRestorationImplemented}/>
        </S.WrapperBoxCountArea>
      </S.WrapperInfo>
      <S.WrapperGraphic>
        <CardGraphicArea />
      </S.WrapperGraphic>
    </S.Container>
  );
};
