import styled from "styled-components";
import { GoogleMap } from "@react-google-maps/api";

export const ContainerProgramMap = styled.div`
  display: flex;
  flex: 1;
`;

export const Maps = styled(GoogleMap).attrs({
  mapContainerStyle: {
    display: "flex",
    flex: 1,
    borderRadius: 6,
  },
})`
  display: flex;
  flex: 1;
  border-radius: 6px;

`;

export const StyleZoonControl = () => {
  var divMain = document.querySelector(
    '[class="gmnoprint gm-bundled-control gm-bundled-control-on-bottom"]'
  );
  if (divMain) {
    divMain.style.position = "absolute";
    divMain.style.left = 0;
    divMain.style.flexDirection = "row";

    var divContent = divMain.querySelector('[class="gmnoprint"]');
    var divGroupButton = divContent.firstElementChild;
    divGroupButton.style.marginTop = "40px";
    divGroupButton.style.MinWidth = "80px";
    divGroupButton.style.height = "40px";
    divGroupButton.style.display = "flex";
    divGroupButton.style.flexDirection = "row";

    var button = divGroupButton.querySelectorAll('[class="gm-control-active"]');
    for (var x = 0; x <= 1; x++) {
      button[x].style.backgroundColor = "#fff";
      button[x].style.minWidth = "40px";
    }

    var divDivider = divGroupButton.querySelector("div");
    divDivider.style.margin = "1px";
  }
};
