import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Button, Col, Row, Typography, Form } from "antd";
import { UserOutlined, EyeFilled, EditFilled } from "@ant-design/icons";

import {
  Input,
  Container,
  RightSide,
  CheckboxContainer,
} from "./AgentEditStyles";
import { useNavigate, useParams } from "react-router-dom";

import { updateUser, getAgentById } from "../service";

import {
  phoneMaskBR,
  avoidSpaces,
  toLowerCase,
  insertMask,
} from "../../../utils/mask";

const { Title, Paragraph } = Typography;

export const AgentEdit = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [readOnly, setReadOnly] = useState(true);
  const [form] = Form.useForm();
  const [dataFieldAgent, setDataFieldAgent] = useState({});
  const labelsChecks = ["SE Pará", "NE Pará", "Transamazônica"];

  const onFinish = async (data) => {
    let originalPhone = data.phone;
    const newData = {
      email: data.email,
      name: data.name,
      phone: `+55${originalPhone.replace(/[()-]/g, "").replaceAll(/\s/g, "")}`,
      user_id: dataFieldAgent._id,
    };
    await updateUser(newData);
    navigate(`/administration-users/list-agents`);
  };

  const renderChecks = () => {
    if (dataFieldAgent?.regions?.length > 0) {
      return labelsChecks.map((item, key) => (
        <Container key={key}>
          <CheckboxContainer>
            <input
              checked={dataFieldAgent.regions.includes(item.toLowerCase())}
              type="checkbox"
            />
          </CheckboxContainer>

          <p>{item}</p>
        </Container>
      ));
    }
  };

  const fetchAgent = async (id) => {
    const { _id, name, email, phone, regions } = await getAgentById(id);
    const regionsFormatted = regions.map(({ name }) => name.toLowerCase());

    const data = {
      _id: _id,
      regions: regionsFormatted,
      name: name,
      email: email,
      phone: insertMask(phone, "phone"),
    };

    form.setFieldsValue(data);
    setDataFieldAgent(data);
  };

  useEffect(() => {
    fetchAgent(id);
  }, []);

  return (
    <>
      <Row>
        <Title level={3} style={{ color: "#47342e" }}>
          {t("agent_edit_page_title")}
        </Title>
      </Row>
      <Row
        style={{
          minHeight: "70%",
          overflow: "hidden",
        }}
      >
        <Col
          style={{
            backgroundColor: "#f6f6f6",
            padding: 30,

            borderTopLeftRadius: 25,
            borderBottomLeftRadius: 25,
          }}
          xl={6}
          md={24}
        >
          <Row justify={"space-between"}>
            <Row>
              <UserOutlined
                style={{ fontSize: 18, color: "#1f9652" }}
              ></UserOutlined>
              <Paragraph
                style={{ margin: 0, marginLeft: 10, color: "#1f9652" }}
              >
                {t("User_Profile")}
              </Paragraph>
            </Row>

            <Row>
              <span onClick={() => setReadOnly((prev) => !prev)}>
                {readOnly ? (
                  <EyeFilled style={{ fontSize: 20, color: "#b2bec3" }} />
                ) : (
                  <EditFilled style={{ fontSize: 20, color: "#b2bec3" }} />
                )}
              </span>
            </Row>
          </Row>

          <Row style={{ marginBottom: 30 }}></Row>

          <Row style={{ height: "100%" }}>
            <Form
              form={form}
              onFinish={onFinish}
              initialValues={dataFieldAgent}
            >
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("invalid_field_name"),
                  },
                ]}
              >
                <Input
                  readOnly={readOnly ? readOnly : ""}
                  placeholder={t("name")}
                ></Input>
              </Form.Item>
              <Row></Row>

              <Row>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: t("invalid_field_email"),
                    },
                  ]}
                  onKeyPress={(event) => {
                    avoidSpaces(event);
                    toLowerCase(event);
                  }}
                >
                  <Input
                    placeholder={t("email")}
                    readOnly={readOnly ? readOnly : ""}
                  ></Input>
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  name="phone"
                  onKeyPress={(event) => {
                    avoidSpaces(event);
                    phoneMaskBR(event);
                  }}
                  rules={[
                    {
                      required: true,
                      message: t("invalid_field_phone"),
                    },
                    () => ({
                      validator(_, value) {
                        if (value.length < 14) {
                          return Promise.reject(t("minimum_of_8_characters"));
                        }
                        if (value.length > 15) {
                          return Promise.reject(t("maximum_of_11_characters"));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder={t("phone")}
                    readOnly={readOnly ? readOnly : ""}
                  />
                </Form.Item>
              </Row>

              <Row style={{ flex: 1 }}>
                <Button
                  shape="round"
                  style={{
                    bottom: 0,
                    width: "83%",
                    position: "absolute",
                    left: 0,
                    margin: 30,
                    color: "white",
                    boxShadow: "rgb(0 0 0 / 16%) 0px 2px 2px 0px",
                  }}
                  className="confirm_button"
                  type="primary"
                  htmlType="submit"
                >
                  {t("btn_save")}
                </Button>
              </Row>
            </Form>
          </Row>
        </Col>
        <RightSide xl={6} md={24}>
          <Row>
            <Paragraph style={{ margin: 0 }}>{t("agent_areas")}</Paragraph>
          </Row>
          <Row style={{ marginBottom: 30 }}></Row>

          {renderChecks()}
        </RightSide>
      </Row>
    </>
  );
};
