import { useEffect, useState } from "react";
import * as S from "./CardCalendarStyles";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { getAllEventsByFarmer } from "../../../service";

export const CardCalendar = ({ farmerId }) => {
  const { t } = useTranslation();
  const [nextEvents, setNextEvents] = useState([]);

  const navigate = useNavigate();

  const fetchNextEvents = async () => {
    const response = await getAllEventsByFarmer(farmerId);

    const events = response.data.data;

    const nextEvents = events.filter(
      (e) => new Date(e.date) >= new Date() && e.status !== "VERIFIED"
    );

    setNextEvents(nextEvents);
  };

  useEffect(() => {
    if (farmerId) {
      fetchNextEvents();
    }
  }, [farmerId]);

  return (
    <S.Container hoverable onClick={() => navigate("/calendar/" + farmerId)}>
      <S.HeaderCard>
        <S.Title>{t("Calendar")}</S.Title>
        <S.IconCalendar />
      </S.HeaderCard>
      <S.ContentCard>
        <S.WrapperTotal>
          <S.TotalText>{nextEvents.length}</S.TotalText>
          <S.LabelTotal>{t("next_events")}</S.LabelTotal>
        </S.WrapperTotal>
      </S.ContentCard>
    </S.Container>
  );
};
