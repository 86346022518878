import { TbCloudUpload } from "react-icons/tb";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 16px 0;
`;

export const WrapperInput = styled.div`
  margin-top: 13px;
  display: flex;
  border-radius: 8px;
  width: 100%;
  height: 120px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed rgba(51, 37, 34, 0.3);
  cursor: pointer;
`;

export const LabelInput = styled.label`
  text-align: center;
`;

export const ButtonInput = styled.button``;

export const InputUpload = styled.input`
  display: block;
  height: 120px;
  width: 100%;
  position: absolute;
  top: 96px;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
`;

export const IconUpload = styled(TbCloudUpload)`
  width: 100%;
  font-size: 30px;
`;

export const Message = styled.p``;