import {
  Avatar,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  List,
  Row,
  Switch,
} from "antd";
import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCashCoin } from "react-icons/bs";
import { DEBIT_OR_CREDIT } from "../../../../../Enums/Wallet.enum";
import addCredits from "../../../../../assets/IconsSabia/addCredits.svg";
import removeCredits from "../../../../../assets/IconsSabia/removeCredits.svg";
import BCButtonConfirm from "../../../../../components/BCButtonConfirm";
import { addOrRemoveCreditsApi, blockCredits } from "../../../service";
import * as S from "./CardCarbonWalletStyles";

export const CardCarbonWallet = ({
  wallet,
  block_use_of_credits,
  isEnableBlock,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isListVisible, setListVisibility] = useState(true);
  const [isDebiting, setIsDebiting] = useState(block_use_of_credits);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const addOrRemoveCredits = async (values) => {
    const payload = {
      description: values.description,
      value: values.cost,
      debiting_or_crediting: values.debiting_or_crediting
        ? "DEBITED"
        : "CREDITED",
      currency: "REAL",
    };
    try {
      await addOrRemoveCreditsApi(wallet._id, payload);
    } catch (error) {}
  };

  const enableAddOrRemoveCredits = () => {
    setListVisibility(!isListVisible);
  };

  const blockCreditsUser = async (block) => {
    try {
      setIsDebiting(block);
      await blockCredits(wallet.onwer_id, block);
    } catch (error) {}
  };

  return (
    <S.Container hoverable>
      <S.Content>
        <S.Header>
          <S.IconWallet />
          <S.TitleWalletCard>Crédito de Carbono</S.TitleWalletCard>
        </S.Header>
        <S.ContentWallet>
          <S.WrapperTotal>
            <S.TotalText>{wallet?.value_carbon_issue}</S.TotalText>
            <S.TotalLabel active={false}>{t('Emitted')}</S.TotalLabel>
          </S.WrapperTotal>
          <S.WrapperTotal>
            <S.TotalText>{wallet?.value_carbon_current_effect}</S.TotalText>
            <S.TotalLabel active={false}>{t('Balance')}</S.TotalLabel>
          </S.WrapperTotal>
          <S.WrapperTotal>
            <S.TotalText>{wallet?.value_carbon_exchanged}</S.TotalText>
            <S.TotalLabel active={true}>{t('Exchanged')}</S.TotalLabel>
          </S.WrapperTotal>
          <S.WrapperActions>
            <S.ButtonActionWallet>
              <S.IconGraphic />
              <S.TitleAction onClick={() => showDrawer()}>
                {t("Historic")}
              </S.TitleAction>
              <Drawer
                maskClosable={true}
                width={520}
                style={{ backgroundColor: "#fffaf0" }}
                placement="right"
                onClose={onClose}
                open={open}
                closable={false}
                footer={
                  <Card>
                    <Row>
                      <Col span={12}>
                        <h3> {t("Exchanged")}</h3>{" "}
                      </Col>
                      <Col span={12} style={{ textAlign: "right" }}>
                        <h3>{wallet?.value_carbon_exchanged}</h3>
                      </Col>
                    </Row>
                  </Card>
                }
              >
                <S.Container hoverable>
                  <S.Content>
                    <S.Header>
                      <S.IconWallet />
                      <S.TitleWalletCard>
                        {t("Carbon_credit")}
                      </S.TitleWalletCard>
                    </S.Header>
                    <S.ContentWallet>
                      <S.WrapperTotal>
                        <S.TotalText>{wallet?.value_carbon_issue}</S.TotalText>
                        <S.TotalLabel active={false}>
                          {t("Emitted")}
                        </S.TotalLabel>
                      </S.WrapperTotal>
                      <S.WrapperTotal>
                        <S.TotalText>
                          {wallet?.value_carbon_current_effect}
                        </S.TotalText>
                        <S.TotalLabel active={false}>
                          {t("Balance")}
                        </S.TotalLabel>
                      </S.WrapperTotal>
                      <S.WrapperTotal>
                        <S.TotalText>
                          {wallet?.value_carbon_exchanged}
                        </S.TotalText>
                        <S.TotalLabel active={true}>
                          {t("Exchanged")}
                        </S.TotalLabel>
                      </S.WrapperTotal>
                      {wallet?._id && <S.WrapperActions>
                        <S.ButtonActionWallet>
                          <S.IconPriceChange />
                          <S.TitleAction
                            onClick={() => enableAddOrRemoveCredits()}
                          >
                            {t("Manage_credit")}
                          </S.TitleAction>
                        </S.ButtonActionWallet>
                      </S.WrapperActions>}
                    </S.ContentWallet>
                  </S.Content>
                </S.Container>

                {isListVisible ? (
                  <List
                    itemLayout="horizontal"
                    dataSource={wallet.banck_statement}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              src={
                                item.debit_or_credit ===
                                DEBIT_OR_CREDIT.CREDITED
                                  ? addCredits
                                  : removeCredits
                              }
                            />
                          }
                          title={`${item.value_carbon} ${
                            item.debit_or_credit === DEBIT_OR_CREDIT.CREDITED
                              ? t("CREDIT_ADDED")
                              : t("CREDIT_DEDECTED")
                          }`}
                          description={`${new Date(
                            item.entry_date
                          ).toLocaleDateString()} ${t(item.origin)}`}
                        />
                        <div>{item.value_carbon}</div>
                      </List.Item>
                    )}
                  />
                ) : (
                  <>
                    {isEnableBlock ? (
                      <div style={{ marginTop: "30px", width: "100%" }}>
                        {t("Restrict_farmer_carbon_credits")}
                        &nbsp;&nbsp;
                        <Switch
                          checked={isDebiting}
                          onChange={(checked) => {
                            blockCreditsUser(checked);
                          }}
                          checkedChildren="Bloqueado"
                          unCheckedChildren="Desbloqueado"
                        />
                      </div>
                    ) : null}

                    <Formik
                      initialValues={{
                        description: "",
                        cost: "",
                        debiting_or_crediting: "",
                        currency: "BR",
                      }}
                      // validationSchema={validationSchema}
                      onSubmit={addOrRemoveCredits}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        errors,
                        touched,
                        setFieldValue,
                      }) => (
                        <Form layout="vertical" onFinish={handleSubmit}>
                          <Row
                            style={{ marginTop: "30px", width: "100%" }}
                            gutter={16}
                          >
                            <Col span={24}>
                              <Form.Item>
                                <Switch
                                  name="debiting_or_crediting"
                                  checked={values.debiting_or_crediting}
                                  onChange={(checked) => {
                                    setFieldValue(
                                      "debiting_or_crediting",
                                      checked
                                    );
                                  }}
                                  checkedChildren={t("Deduct")}
                                  unCheckedChildren={t("Credit")}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row style={{ width: "100%" }} gutter={16}>
                            <Col span={24}>
                              <Form.Item
                                help={
                                  touched.cost && errors.cost ? errors.cost : ""
                                }
                                validateStatus={
                                  touched.cost && errors.cost ? "error" : ""
                                }
                              >
                                <Input
                                  prefix={<BsCashCoin />}
                                  name="cost"
                                  className="input"
                                  value={values.cost}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder={t("Value")}
                                  type="number"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row style={{ width: "100%" }} gutter={16}>
                            <Col span={24}>
                              <Form.Item
                                help={
                                  touched.description && errors.description
                                    ? errors.description
                                    : ""
                                }
                                validateStatus={
                                  touched.description && errors.description
                                    ? "error"
                                    : ""
                                }
                              >
                                <Input
                                  name="description"
                                  className="input"
                                  value={values.description}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder={t("Description")}
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Form.Item>
                            <BCButtonConfirm
                              style={{
                                width: "100%",
                                maxWidth: "200px",
                                marginTop: "20px",
                              }}
                              buttonText={t("Send")}
                            ></BCButtonConfirm>
                          </Form.Item>
                        </Form>
                      )}
                    </Formik>
                  </>
                )}
              </Drawer>
            </S.ButtonActionWallet>
            {wallet?._id && <S.ButtonActionWallet>
              <S.IconPriceChange />
              <S.TitleAction onClick={() => showDrawer()}>
                {t('Manage_credit')}
              </S.TitleAction>
            </S.ButtonActionWallet>}
          </S.WrapperActions>
        </S.ContentWallet>
      </S.Content>
    </S.Container>
  );
};
