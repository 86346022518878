import { format, parseISO } from "date-fns";
import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getVisitCallScheduling } from "../../../service";
import AssistanceModal from "../AssistanceModal/AssistanceModal";
import * as S from "./CardTotalAssistanceStyles";

import { enUS, ptBR } from "date-fns/locale";

export const CardTotalAssistance = ({ farmerId }) => {
  const { t, i18n } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [farmerAssistances, setFarmerAssistances] = useState([]);

  const fetchAssistances = async () => {
    const response = await getVisitCallScheduling({ id: farmerId });

    const { calls, scheduling, visits } = response;

    const dataLang = i18n.language === "pt" ? ptBR : enUS;

    const callsFarmersAssistances = calls.map((call) => ({
      created: format(parseISO(call.createdAt), "MMM dd', 'yyyy", {
        locale: dataLang,
      }),
      request_type: t("request_type_call"),
      request: "call",
      status: call.status,
    }));

    const schedulingsFiltered = scheduling.filter(
      (scheduling) => scheduling.status !== "ACCEPTED"
    );

    const schedulingsFarmersAssistances = schedulingsFiltered.map(
      (scheduling) => ({
        created: format(parseISO(scheduling.createdAt), "MMM dd', 'yyyy", {
          locale: dataLang,
        }),
        request_type: t("request_type_scheduling"),
        request: "scheduling",
        status: scheduling.status,
      })
    );

    const visitsFarmersAssistances = visits.map((visit) => ({
      created: format(parseISO(visit.createdAt), "MMM dd', 'yyyy", {
        locale: dataLang,
      }),
      request_type: t("request_type_visit"),
      request: "visit",
      status: visit.status,
    }));

    setFarmerAssistances([
      ...callsFarmersAssistances,
      ...schedulingsFarmersAssistances,
      ...visitsFarmersAssistances,
    ]);
  };

  useEffect(() => {
    if (farmerId) fetchAssistances();
  }, [farmerId]);

  return (
    <S.Container hoverable onClick={() => setIsModalOpen((prev) => !prev)}>
      <S.HeaderCard>
        <S.Title>{t("Assistance")}</S.Title>
        <S.IconChat />
      </S.HeaderCard>
      <S.ContentCard>
        <S.WrapperTotal>
          <S.TotalText>{farmerAssistances.length}</S.TotalText>
          <S.LabelTotal>Total</S.LabelTotal>
        </S.WrapperTotal>
      </S.ContentCard>

      <AssistanceModal
        assistances={farmerAssistances}
        isModalOpen={isModalOpen}
      ></AssistanceModal>
    </S.Container>
  );
};
