import { Card, Col, Space } from "antd";
import { MdSupportAgent, MdInfo } from "react-icons/md";
import styled from "styled-components";

export const Container = styled(Card)`
  margin-right: 10px;
  max-width: 340px;
  min-width: 340px;
  max-height: 440px;
  height: 440px;
  border-radius: 8px;
  margin-bottom: 16px;

  @media screen and (max-width: 1506px) {
    width: none;
    max-width: none;
    flex: 1;
  }

  @media screen and (max-width: 1006px) {
    width: 100%;
    max-width: none;
  }
`;

export const BackgroundImage = styled.div`
  border: 1px solid #3332;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  height: 240px;
`;

export const TitleBackground = styled.span`
  position: absolute;
  color: #fff;
  z-index: 1000;
  top: 35%;
  bottom: 0;
  left: 20px;
  right: 0;
  text-align: center;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #f90;
`;

export const ImageArea = styled.img`
  width: 100%;
  max-height: 240px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  object-fit: cover;
  z-index: 300;

  background-color: #cccccc;
`;

export const WrapperButtonNavigate = styled(Space)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export const BodyCar = styled(Col)`
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin-bottom: 10px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 6px;
`;

export const Title = styled.h4`
  margin-left: 10px;
  color: #1f9652;
  font-size: 18px;
  font-weight: 400;
`;

export const IconSupport = styled(MdSupportAgent)`
  color: #1f9652;
  font-size: 28px;
`;

export const Content = styled.div``;

export const NameAgent = styled.h4`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.1;
  letter-spacing: normal;
  text-align: left;
  color: #47342e;
`;

export const DescriptionOrganization = styled.p`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: rgba(71, 52, 46, 0.72);
`;

export const WrapperTagInfo = styled.div`
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 208px;
  height: 42px;
  padding: 9px 20px 9px 14px;
  border-radius: 21px;
  background-color: #fbe9c6;
`;

export const IconInfo = styled(MdInfo)`
  margin-right: 10px;
  color: rgba(51, 37, 34, 0.8);
  font-size: 24px;
`;

export const TitleTag = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #47342e;
  
`;
