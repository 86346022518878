import React, { useEffect, useState } from "react";

import * as S from "./CallsEventsStyles";
import { format, parseISO } from "date-fns";

import { ptBR, enUS } from "date-fns/locale";
import BCTable from "../BCTable";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BCProfilerFarmer } from "../BCProfileFarmer";
import translateStatus from "../../utils/translateStatus";

import { Space, Tag } from "antd";

import { getVisitCallScheduling, getFarm } from "../services";
import {
  statusWithBlueColor,
  statusWithGrayColor,
  statusWithGreenColor,
  statusWithRedColor,
  statusWithYellowColor,
} from "../../utils/statusWithColor";
import { CloseCircleOutlined, SyncOutlined } from "@ant-design/icons";

function CallsEvents() {
  const [callEvents, setCallEvents] = useState([]);
  const { t, i18n } = useTranslation();
  const dataLang = i18n.language === "pt" ? ptBR : enUS;

  const [currentPage, setPage] = useState(1);

  const { farmerId } = useParams();

  const [loading, setLoading] = useState(false);
  const [farm, setFarm] = useState();

  const fetchAssistances = async () => {
    setLoading(true);

    await fetchFarm();

    const response = await getVisitCallScheduling({ id: farmerId });

    const { calls, visits, scheduling } = response;

    const callsFarmersAssistances = calls.map((call) => ({
      created: format(parseISO(call.createdAt), "MMM dd', 'yyyy", {
        locale: dataLang,
      }),
      request_type: t("request_type_call"),
      request: "call",
      status: call.status,
      date: new Date(call.createdAt),
    }));

    const schedulings = scheduling
      .filter((scheduling) => scheduling.status !== "ACCEPTED")
      .map((scheduling, index) => ({
        id: `${index}-scheduling-${scheduling.status}`,
        created: format(parseISO(scheduling.createdAt), "MMM dd', 'yyyy", {
          locale: dataLang,
        }),
        request_type: t("request_type_scheduling"),
        request: "scheduling",
        status: scheduling.status,
        date: new Date(scheduling.createdAt),
      }));

    const visitsFarmersAssistances = visits.map((visit) => ({
      created: format(parseISO(visit.createdAt), "MMM dd', 'yyyy", {
        locale: dataLang,
      }),
      request_type: t("request_type_visit"),
      request: "visit",
      status: visit.status,
      date: new Date(visit.createdAt),
    }));

    setCallEvents(
      [
        ...callsFarmersAssistances,
        ...visitsFarmersAssistances,
        ...schedulings,
      ].sort((a, b) => b.date - a.date)
    );
  };

  const columnsFarmer = [
    {
      key: 1,
      title: t("created"),
      dataIndex: "created",
      render: (text, data) => {
        return <p style={{ width: "100%", textAlign: "start" }}>{text}</p>;
      },
    },
    {
      key: 2,
      title: t("request_type"),
      dataIndex: "request_type",
      render: (text, data) => {
        return <p style={{ width: "100%", textAlign: "start" }}>{text}</p>;
      },
    },
    {
      key: 3,
      title: t("Status"),
      dataIndex: "status",
      render: (text, { request, status }) => {
        console.log({ request, status });
        const statusTranslation = translateStatus(t)[request];
        const statusText = statusTranslation[status];

        let tag = <></>;

        if (statusWithBlueColor.includes(status)) {
          tag = (
            <Tag icon={<SyncOutlined spin />} color="warning">
              {statusText}
            </Tag>
          );
        } else if (
          statusWithGrayColor.includes(status) ||
          statusWithRedColor.includes(status)
        ) {
          tag = (
            <Tag icon={<CloseCircleOutlined />} color="error">
              {statusText}
            </Tag>
          );
        } else if (statusWithYellowColor.includes(status)) {
          tag = (
            <Tag icon={<SyncOutlined spin />} color="processing">
              {statusText}
            </Tag>
          );
        } else if (statusWithGreenColor.includes(status)) {
          tag = <Tag color="success">{statusText}</Tag>;
        } else tag = <span>{t("not_found")}</span>;

        return <div style={{ width: "100%", textAlign: "start" }}>{tag}</div>;
      },
      width: "30%",
    },
  ];

  const fetchFarm = async () => {
    const response = await getFarm({ _id: farmerId, withContract: true });

    if (response.data.data.result && response.data.data.result.length > 0) {
      setFarm(response.data.data.result[0]);
    }
  };

  useEffect(() => {
    if (farmerId) {
      fetchAssistances().finally(() => setLoading(false));
    }
  }, [farmerId]);

  return (
    <Space style={{ width: "82vw" }} direction="vertical" size={"middle"}>
      {farm && <BCProfilerFarmer farm={farm}></BCProfilerFarmer>}

      <S.WrapperTable>
        <BCTable
          showPlaceholderSearch={false}
          data={callEvents}
          total={callEvents.length}
          pageSize={10}
          currentPage={currentPage}
          loading={loading}
          columns={columnsFarmer}
          showCreateButton={false}
          setCurrentPage={setPage}
          setPageSize={() => {}}
        />
      </S.WrapperTable>
    </Space>
  );
}

export default CallsEvents;
