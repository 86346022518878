import { Avatar, Card, Col, Form, Input, Row, Select } from "antd";
import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCashCoin } from "react-icons/bs";
import { FaTools } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import BCButtonConfirm from "../../components/BCButtonConfirm";
import { createService } from "./service";

const { Option } = Select;
const { Meta } = Card;

function ServicesCreateService() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [organizations, setOrganizations] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("Name_is_Required")),
    description: Yup.string().required(t("Description_is_Required")),
    cost: Yup.number().required(t("Cost_is_Required")),
  });

  async function create(values) {
    await createService(values.name, values.description, values.cost);
    navigate("/services");
  }

  return (
    <Formik
      initialValues={{
        name: "",
        description: "",
        cost: "",
      }}
      validationSchema={validationSchema}
      onSubmit={create}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
      }) => (
        <Form layout="vertical" onFinish={handleSubmit}>
          <Meta
            avatar={
              <Avatar
                style={{ backgroundColor: "white", color: "green" }}
                icon={<FaTools />}
              />
            }
            title={t("Information_About_The_Service")}
            description={t("Text_new_service")}
          />
          <Row style={{ marginTop: "30px" }} gutter={16}>
            <Col span={7}>
              <Row gutter={16}>
                <Col xs={24} sm={24}>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={touched.name && errors.name ? errors.name : ""}
                        validateStatus={
                          touched.name && errors.name ? "error" : ""
                        }
                      >
                        <Input
                          name="name"
                          className="input"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Name")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={
                          touched.description && errors.description
                            ? errors.description
                            : ""
                        }
                        validateStatus={
                          touched.description && errors.description
                            ? "error"
                            : ""
                        }
                      >
                        <Input
                          name="description"
                          className="input"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Description")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        help={touched.cost && errors.cost ? errors.cost : ""}
                        validateStatus={
                          touched.cost && errors.cost ? "error" : ""
                        }
                      >
                        <Input
                          prefix={<BsCashCoin />}
                          name="cost"
                          className="input"
                          value={values.cost}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Cost")}
                          type="number"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Form.Item>
            <BCButtonConfirm
              style={{ width: "100%", maxWidth: "200px", marginTop: "10px" }}
              buttonText={t("Save_service")}
            ></BCButtonConfirm>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}

export default ServicesCreateService;
