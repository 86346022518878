import React, { useEffect, useState } from "react";
import { BellOutlined } from "@ant-design/icons";

import * as S from "./BellIconStyles";
import NotificationsModal from "../NotificationsModal/NotificationsModal";
import { getNotificationList } from "../../service";

function BellIcon() {
  const [isModalOpen, setModalOpen] = useState(false);

  const [unreadCount, setUnreadCount] = useState(0);

  const fetchUnreadCount = async () => {
    const response = await getNotificationList({
      limit: 1,
      page: 1,
      statusArray: [{ status: "CREATED" }],
    });
    const filterStatusReadAdmin = response.result.filter(item  => item.status_admin == 'CREATED')
    
    setUnreadCount(filterStatusReadAdmin.length);
  };

  useEffect(() => {
    fetchUnreadCount();
  }, [isModalOpen]);

  return (
    <S.Container
      onClick={() => {
        setModalOpen(true);
      }}
      style={{}}
    >
      <BellOutlined
        style={{ fontSize: "24px", marginRight: "16px" }}
      ></BellOutlined>

      {unreadCount > 0 && <S.UnreadCount>{unreadCount}</S.UnreadCount>}
      {isModalOpen && (
        <NotificationsModal
          onClose={() => setModalOpen(false)}
        ></NotificationsModal>
      )}
    </S.Container>
  );
}

export default BellIcon;
