import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { Col, Rate, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { FaMapMarkerAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import BCTable from "../../components/BCTable";
import { getAgent } from "../AdministrationUsers/service";

export default function AgentsRatingsView() {
  const { id } = useParams();
  const [rating, setRating] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [agent, setAgent] = useState({});
  const { t } = useTranslation();

  const findAgents = useCallback(async () => {
    setLoading(true);
    const result = await getAgent(id);
    setAgent(result);
    setRating(result.rating);
    setLoading(false);
  }, [id]);
  useEffect(() => {
    findAgents();
  }, [findAgents]);

  const columns = [
    {
      title: t("Author"),
      dataIndex: "autor",
      align: "center",
      sorter: false,
      render: (text, data) => {
        return <span>{data.name}</span>;
      },
    },
    {
      title: t("Date"),
      dataIndex: "createdAt",
      align: "center",
      sorter: false,
      width: 150,
      render: (text, record) => (
        <span key={record.createdAt}>
          {record.createdAt
            ? new Date(record.createdAt).toLocaleDateString()
            : t("No_Records")}
        </span>
      ),
    },
    {
      title: t("Rating"),
      dataIndex: "rate",
      align: "center",
      sorter: false,
      render: (text, data) => {
        return <span>{data.rate}</span>;
      },
    },
    {
      title: t("Comments"),
      dataIndex: "comments",
      align: "center",
      sorter: false,
      render: (text, data) => {
        return <span>{data.description}</span>;
      },
    },
  ];

  return (
    <div>
      <h1>
        {t("Ratings")} {t("Agent")}
      </h1>
      <Row style={{ marginTop: "20px" }} gutter={[16, 16]} justify="start">
        <Col xs={12} sm={12} md={21}>
          <Col
            style={{ display: "flex", justifyContent: "space-between" }}
            className="custom-card"
          >
            <div style={{ display: "flex", alignItems: "start" }}>
              <h1
                style={{
                  fontSize: "2rem",
                  marginTop: "1px",
                }}
              >
                <UserOutlined size={48} />
              </h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <h1
                  style={{
                    fontSize: "25px",
                    marginBottom: "4px",
                    marginTop: "10px",
                  }}
                >
                  {agent.name}
                </h1>
                <div
                  style={{
                    fontSize: "20px",
                    display: "flex",
                    gridTemplateColumns: "repeat(3)",
                    gap: "16px",
                    alignItems: "start",
                    justifyContent: "flex-start",
                    color: "rgb(79, 175, 73)",
                    marginLeft: "-30px",
                  }}
                >
                  {<Rate disabled value={agent.rate} />}
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  <MailOutlined size={24} />
                </h1>

                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  {agent.email}
                </h1>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  <PhoneOutlined size={24} />
                </h1>
                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  {agent.phone}
                </h1>
              </div>
            </div>
          </Col>
        </Col>
      </Row>
      <BCTable
        data={rating}
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        placeholderSearch={`${t("Search")} ${t("Message")}`}
        setSearch={() => {}}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        setSortField={() => {}}
        setSortOrder={() => {}}
        loading={loading}
        columns={columns}
        showCreateButton={false}
        showPlaceholderSearch={false}
      />
    </div>
  );
}
