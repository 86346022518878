import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UnreadCount = styled.p`
  position: absolute;

  height: 1.5rem;
  width: 1.5rem;

  font-size: 0.8rem;

  transform: translateY(-14px) translateX(6px);
  background-color: red;
  font-family: sans-serif;

  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 30px;
`;
