import { EyeOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Space, Tag } from "antd";
import { default as React, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BCTable from "../../components/BCTable.jsx";
import { list } from "./service.js";

export default function ServicesInRevision() {
  const navigate = useNavigate();

  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [vendorId, setVendorId] = useState("");
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [countServicesPerStatus, setCountServicePerStatus] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [sortField, setSortField] = useState("name");
  const [status, setStatus] = useState("PENDING");
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const listServices = useCallback(async () => {
    setLoading(true);
    const result = await list(
      pageSize,
      currentPage,
      search,
      sortOrder,
      sortField,
      status,
      vendorId
    );
    result.result.map((x) => (x.key = x._id));
    setServices(result.result);
    setTotal(result.count);
    setLoading(false);
  }, [pageSize, currentPage, search, sortOrder, sortField, status, vendorId]);
  useEffect(() => {
    listServices();
  }, [listServices]);

  const onView = (key) => {
    navigate(`/services/${key}/services-in-revision/view`);
  };
  const columns = [
    {
      title: t("Name"),
      dataIndex: "name",
      align: "center",
      sorter: true,
      width: "10%",
    },
    {
      title: t("Description"),
      dataIndex: "description",
      align: "center",
      sorter: false,
      width: "10%",
      render: (text, data) => data.description,
    },
    {
      title: t("Cost"),
      dataIndex: "cost",
      align: "center",
      sorter: true,
      render: (text, data) => data.cost,
    },
    {
      title: t("Suppliers"),
      dataIndex: "vendor_name",
      align: "center",
      sorter: true,
      render: (text, data) => data.vendor_name,
    },
    {
      title: t("Status"),
      dataIndex: "status",
      align: "center",
      sorter: true,
      render: (text, data) => {
        let statusTag;
        switch (data.status) {
          case "PENDING":
            statusTag = (
              <Tag icon={<SyncOutlined spin />} color="processing">
                {t("PENDING")}
              </Tag>
            );
            break;

          case "ACCEPTED":
            statusTag = <Tag color="success">{t("ACCEPTED")}</Tag>;
            break;
          default:
            statusTag = <span>Status Desconhecido</span>;
        }
        return statusTag;
      },
    },
  ];

  const actionsColumn = [
    {
      title: t("Actions"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={() => onView(record.key)}
            icon={<EyeOutlined />}
          >
            {t("View")}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <BCTable
        data={services}
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        placeholderSearch={`${t("Search")} ${t("Services")}`}
        setSearch={setSearch}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        loading={loading}
        columns={columns}
        actionsColumn={actionsColumn}
        showCreateButton={false}
        onView={onView}
      />
    </div>
  );
}
