import axiosCalendar from "../apis/axiosInterceptorCalendar";
import axiosFarmer from "../apis/axiosInterceptorFarmer";

export const getVisitCallScheduling = async ({ id }) => {
  const { data } = await axiosCalendar.get(
    `v1/user_events/visit-call-sheduling/${id}`
  );

  return data.data;
};

export const getFarm = async ({ _id, withContract = false }) => {
  return axiosFarmer.post(`v3/farm`, { _id, withContract });
};
