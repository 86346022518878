export const ACTION_PLANS = Object.freeze({
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
  EXPIRED: "EXPIRED",
  PENDING_APPROVAL: "PENDING_APPROVAL",
});

export const ITEMS_STATUS = Object.freeze({
  PENDING: "PENDING",
  REVIEW: "REVIEW",
  RESOLVED: "RESOLVED",
  REJECTED: "REJECTED",
});

export const ITEMS_HISTORY_STATUS = Object.freeze({
  APPROVED: "APPROVED",
  REVIEW: "REVIEW",
  REJECTED: "REJECTED",
});
