import React from "react";

const IconSabia = () => (
  <svg width="231.021" height="72" viewBox="0 0 231.021 72">
    <g>
      <g>
        <path
          d="M72.531 630.034a2.224 2.224 0 1 0-2.209-2.224 2.218 2.218 0 0 0 2.209 2.224"
          transform="translate(-36.522 -591.773)"
          fill="#ffdc00"
        />
        <path
          d="M72.613 622.908a2.8 2.8 0 1 0-2.779-2.8 2.788 2.788 0 0 0 2.779 2.8"
          transform="translate(-36.604 -593.16)"
          fill="#4fb04a"
        />
        <path
          d="M75.22 613.668a3.6 3.6 0 0 0 0-5.073 3.546 3.546 0 0 0-5.039 0 3.6 3.6 0 0 0 0 5.073 3.548 3.548 0 0 0 5.039 0"
          transform="translate(-36.72 -594.797)"
          fill="#f90"
        />
        <path
          d="M64.841 610.753a3.586 3.586 0 1 0-4.088-2.966 3.583 3.583 0 0 0 4.088 2.966"
          transform="translate(-38.134 -595.454)"
          fill="#4fb04a"
        />
        <path
          d="M52.779 622.788a3.586 3.586 0 1 0-4.287-2.667 3.589 3.589 0 0 0 4.287 2.667"
          transform="translate(-40.197 -593.429)"
          fill="#4fb04a"
        />
        <path
          d="M52.722 639.948a3.586 3.586 0 1 0-4.286-2.667 3.587 3.587 0 0 0 4.286 2.667"
          transform="translate(-40.206 -590.552)"
          fill="#4fb04a"
        />
        <path
          d="M64.019 652.255a3.587 3.587 0 1 0-3.564-3.587 3.575 3.575 0 0 0 3.564 3.587"
          transform="translate(-38.176 -588.505)"
          fill="#4fb04a"
        />
        <path
          d="M81.754 652.266a3.587 3.587 0 1 0-4.089-2.966 3.585 3.585 0 0 0 4.089 2.966"
          transform="translate(-35.299 -588.496)"
          fill="#4fb04a"
        />
        <path
          d="M90.931 634.141a3.7 3.7 0 0 0 0 5.093 3.512 3.512 0 0 0 5.06 0 3.567 3.567 0 0 0 0-5.093 3.512 3.512 0 0 0-5.06 0"
          transform="translate(-33.238 -590.52)"
          fill="#4fb04a"
        />
        <path
          d="M97.058 619.544a3.564 3.564 0 1 0-3.565 3.587 3.586 3.586 0 0 0 3.565-3.587"
          transform="translate(-33.235 -593.387)"
          fill="#4fb04a"
        />
        <path
          d="M83.938 609.8a3.569 3.569 0 0 0 0-5.093 3.514 3.514 0 0 0-5.062 0 3.569 3.569 0 0 0 0 5.093 3.514 3.514 0 0 0 5.062 0"
          transform="translate(-35.267 -595.453)"
          fill="#4fb04a"
        />
        <path
          d="M72.9 606.24a4.807 4.807 0 1 0-4.776-4.807 4.791 4.791 0 0 0 4.776 4.807"
          transform="translate(-36.89 -596.627)"
          fill="#1f9652"
        />
        <path
          d="M69.837 622.4a2.78 2.78 0 1 0-2.78 2.8 2.839 2.839 0 0 0 2.78-2.8"
          transform="translate(-37.535 -592.777)"
          fill="#f90"
        />
        <path
          d="M64.1 617.466a3.6 3.6 0 0 0-1.929-4.687 3.557 3.557 0 0 0-4.656 1.942 3.6 3.6 0 0 0 1.929 4.687 3.556 3.556 0 0 0 4.656-1.942"
          transform="translate(-38.714 -593.965)"
          fill="#4fb04a"
        />
        <path
          d="M58.245 611.056a4.819 4.819 0 0 0-2.585-6.281 4.763 4.763 0 0 0-6.239 2.6 4.816 4.816 0 0 0 2.584 6.279 4.764 4.764 0 0 0 6.24-2.6"
          transform="translate(-40.086 -595.323)"
          fill="#1f9652"
        />
        <path
          d="M64.835 630.689a2.8 2.8 0 1 0-2.78-2.8 2.789 2.789 0 0 0 2.78 2.8"
          transform="translate(-37.908 -591.855)"
          fill="#4fb04a"
        />
        <path
          d="M59.2 629.381a3.6 3.6 0 0 0-1.929-4.687 3.556 3.556 0 0 0-4.656 1.941 3.6 3.6 0 0 0 1.929 4.687 3.555 3.555 0 0 0 4.656-1.941"
          transform="translate(-39.535 -591.968)"
          fill="#ffdc00"
        />
        <path
          d="M46.148 632.986a4.807 4.807 0 1 0-4.774-4.806 4.791 4.791 0 0 0 4.774 4.806"
          transform="translate(-41.374 -592.144)"
          fill="#1f9652"
        />
        <path
          d="M67.057 636.185a2.8 2.8 0 1 0-2.78-2.8 2.789 2.789 0 0 0 2.78 2.8"
          transform="translate(-37.535 -590.934)"
          fill="#ffdc00"
        />
        <path
          d="M61.628 643.351a3.585 3.585 0 1 0-4.287-2.667 3.588 3.588 0 0 0 4.287 2.667"
          transform="translate(-38.714 -589.982)"
          fill="#4fb04a"
        />
        <path
          d="M57.241 650.534a4.83 4.83 0 0 0 0-6.8 4.753 4.753 0 0 0-6.754 0 4.827 4.827 0 0 0 0 6.8 4.755 4.755 0 0 0 6.754 0"
          transform="translate(-40.081 -588.967)"
          fill="#1f9652"
        />
        <path
          d="M72.613 638.408a2.8 2.8 0 1 0-2.779-2.8 2.789 2.789 0 0 0 2.779 2.8"
          transform="translate(-36.604 -590.562)"
          fill="#4fb04a"
        />
        <path
          d="M76.02 646.136a3.594 3.594 0 0 0-1.929-4.686 3.555 3.555 0 0 0-4.656 1.941 3.6 3.6 0 0 0 1.929 4.687 3.556 3.556 0 0 0 4.656-1.942"
          transform="translate(-36.716 -589.16)"
          fill="#f90"
        />
        <path
          d="M72.9 659.671a4.807 4.807 0 1 0-4.776-4.806 4.791 4.791 0 0 0 4.776 4.806"
          transform="translate(-36.89 -587.671)"
          fill="#1f9652"
        />
        <path
          d="M78.108 636.185a2.8 2.8 0 1 0-2.78-2.8 2.789 2.789 0 0 0 2.78 2.8"
          transform="translate(-35.683 -590.934)"
          fill="#f90"
        />
        <path
          d="M87.083 637.352a3.512 3.512 0 0 0-5.06 0 3.631 3.631 0 0 0 0 5.093 3.512 3.512 0 0 0 5.06 0 3.567 3.567 0 0 0 0-5.093"
          transform="translate(-34.735 -589.981)"
          fill="#4fb04a"
        />
        <path
          d="M91.9 651.929a4.807 4.807 0 1 0-4.776-4.806 4.791 4.791 0 0 0 4.776 4.806"
          transform="translate(-33.706 -588.969)"
          fill="#1f9652"
        />
        <path
          d="M80.33 630.689a2.8 2.8 0 1 0-2.78-2.8 2.789 2.789 0 0 0 2.78 2.8"
          transform="translate(-35.31 -591.855)"
          fill="#4fb04a"
        />
        <path
          d="M89.523 631.592a3.588 3.588 0 1 0-3.564-3.587 3.576 3.576 0 0 0 3.564 3.587"
          transform="translate(-33.901 -591.969)"
          fill="#ffdc00"
        />
        <path
          d="M104.045 630.023a4.818 4.818 0 0 0-2.585-6.28 4.765 4.765 0 0 0-6.24 2.6 4.818 4.818 0 0 0 2.585 6.28 4.764 4.764 0 0 0 6.24-2.6"
          transform="translate(-32.41 -592.143)"
          fill="#1f9652"
        />
        <path
          d="M78.108 625.193a2.8 2.8 0 1 0-2.78-2.8 2.789 2.789 0 0 0 2.78 2.8"
          transform="translate(-35.683 -592.777)"
          fill="#ffdc00"
        />
        <path
          d="M82.089 618.607a3.59 3.59 0 0 0 5.06-5.094 3.514 3.514 0 0 0-5.06 0 3.568 3.568 0 0 0 0 5.094"
          transform="translate(-34.729 -593.977)"
          fill="#4fb04a"
        />
        <path
          d="M95.242 612.6a4.784 4.784 0 0 0 0-6.743 4.709 4.709 0 0 0-6.7 0 4.784 4.784 0 0 0 0 6.743 4.709 4.709 0 0 0 6.7 0"
          transform="translate(-33.701 -595.314)"
          fill="#1f9652"
        />
        <g transform="translate(86.03 2.505)">
          <path
            d="m173.02 641.722-3.37-2.226a1.138 1.138 0 0 0-1.609.373c-.271.461-.5.461-.641.461a.55.55 0 0 1-.313-.058.946.946 0 0 1-.112-.546v-24.965a1.14 1.14 0 0 0-1.14-1.14s-6.139-.292-7.467-.388c-1.4-.1-2.6-.153-3.541-.153a18.875 18.875 0 0 0-4.7.562 14.682 14.682 0 0 0-9.62 7.9 19.772 19.772 0 0 0-1.859 8.677 21.014 21.014 0 0 0 1.625 8.266 14.647 14.647 0 0 0 4.827 6.215 12.6 12.6 0 0 0 7.62 2.356 13.356 13.356 0 0 0 5.783-1.254 9.915 9.915 0 0 0 1.73-1.038 1.15 1.15 0 0 1 1.525.114 6.974 6.974 0 0 0 5.218 2.178 7.254 7.254 0 0 0 3.739-1.031 7 7 0 0 0 2.685-2.825 1.139 1.139 0 0 0-.38-1.478zm-13.257-8.073a12.169 12.169 0 0 1-.082 1.483 4.162 4.162 0 0 1-.508 1.353l-.012.023a7.109 7.109 0 0 1-2.447 2.754 6.265 6.265 0 0 1-3.632 1.067 5.87 5.87 0 0 1-5.188-2.832 12.981 12.981 0 0 1-2.034-7.338 11.981 11.981 0 0 1 2.286-7.542 7.336 7.336 0 0 1 6.2-2.808c.649 0 4.36.267 4.36.267a1.138 1.138 0 0 1 1.058 1.136z"
            transform="translate(-111.099 -596.374)"
            fill="#47332e"
          />
          <path
            d="m220.022 611.289 2.767 2.467a1.139 1.139 0 0 0 1.6-.082l9.023-9.865a1.138 1.138 0 0 0-.13-1.658l-3.909-3.129a1.139 1.139 0 0 0-1.624.208l-7.88 10.526a1.14 1.14 0 0 0 .153 1.533z"
            transform="translate(-97.524 -598.772)"
            fill="#47332e"
          />
          <path
            d="M135.346 628.6a50.9 50.9 0 0 0-5.974-2.774 25.09 25.09 0 0 1-4.438-2.137 1.857 1.857 0 0 1-.861-1.678 1.806 1.806 0 0 1 .861-1.618 5.253 5.253 0 0 1 2.993-.731 9.2 9.2 0 0 1 5.642 1.764 1.139 1.139 0 0 0 1.653-.338l2.106-3.549a1.14 1.14 0 0 0-.23-1.438 12.356 12.356 0 0 0-4.338-2.3 17.186 17.186 0 0 0-5.315-.868 11.263 11.263 0 0 0-7.6 2.563 8.689 8.689 0 0 0-3.042 6.877 7.6 7.6 0 0 0 1.291 4.477 9.53 9.53 0 0 0 3.307 2.871 52.845 52.845 0 0 0 5.261 2.372c1.97.787 3.391 1.393 4.224 1.8a4.747 4.747 0 0 1 1.6 1.172 2.287 2.287 0 0 1 .433 1.5 3.089 3.089 0 0 1-1.261 2.67 6.521 6.521 0 0 1-3.976 1.063 9.251 9.251 0 0 1-3.859-.829 17.451 17.451 0 0 1-4.056-2.742 1.139 1.139 0 0 0-1.612.12l-2.828 3.309a1.138 1.138 0 0 0 .061 1.545 18.784 18.784 0 0 0 5.8 4.029 16.706 16.706 0 0 0 6.679 1.358 13.206 13.206 0 0 0 8.8-2.917 9.756 9.756 0 0 0 3.531-7.787 8.1 8.1 0 0 0-1.279-4.64 10.54 10.54 0 0 0-3.573-3.144z"
            transform="translate(-115.054 -596.4)"
            fill="#47332e"
          />
          <path
            d="M202.779 603.5a4.1 4.1 0 0 0-2.97 1.3 4.17 4.17 0 0 0-1.3 3.032 4.436 4.436 0 0 0 4.266 4.267 4.271 4.271 0 0 0 3.049-1.283 4.063 4.063 0 0 0 1.339-2.983 4.124 4.124 0 0 0-1.339-3.044 4.267 4.267 0 0 0-3.045-1.289z"
            transform="translate(-101.065 -597.98)"
            fill="#47332e"
          />
          <path
            d="m243.727 641.722-3.369-2.226a1.139 1.139 0 0 0-1.61.373c-.271.461-.5.461-.642.461a.541.541 0 0 1-.312-.058.934.934 0 0 1-.113-.546v-24.965a1.139 1.139 0 0 0-1.138-1.14s-6.141-.292-7.467-.388c-1.4-.1-2.6-.153-3.541-.153a18.861 18.861 0 0 0-4.7.562 14.68 14.68 0 0 0-9.621 7.9 19.772 19.772 0 0 0-1.858 8.677 21.014 21.014 0 0 0 1.624 8.266 14.666 14.666 0 0 0 4.827 6.218 12.6 12.6 0 0 0 7.621 2.356 13.347 13.347 0 0 0 5.782-1.254 9.925 9.925 0 0 0 1.732-1.038 1.149 1.149 0 0 1 1.524.114 6.973 6.973 0 0 0 5.219 2.178 7.246 7.246 0 0 0 3.738-1.031 7 7 0 0 0 2.687-2.832 1.14 1.14 0 0 0-.383-1.474zm-13.256-8.073a12.169 12.169 0 0 1-.082 1.483 4.2 4.2 0 0 1-.509 1.353l-.012.023a7.1 7.1 0 0 1-2.447 2.754 6.264 6.264 0 0 1-3.631 1.067 5.87 5.87 0 0 1-5.19-2.829 12.981 12.981 0 0 1-2.034-7.338 11.974 11.974 0 0 1 2.286-7.542 7.334 7.334 0 0 1 6.2-2.808c.648 0 4.36.267 4.36.267a1.139 1.139 0 0 1 1.058 1.136z"
            transform="translate(-99.247 -596.374)"
            fill="#47332e"
          />
          <path
            d="M192.658 617.139a12.948 12.948 0 0 0-7.473-2.244 14.206 14.206 0 0 0-4.675.785c-.225.078-.448.164-.669.253a1.139 1.139 0 0 1-1.577-1.051V603.4a1.139 1.139 0 0 0-1.138-1.14h-4.933a1.139 1.139 0 0 0-1.138 1.14v42.95a1.138 1.138 0 0 0 .75 1.071 19.864 19.864 0 0 0 2.31.613 24.879 24.879 0 0 0 2.82.471 44.7 44.7 0 0 0 5.846.369 21.343 21.343 0 0 0 5.109-.576 14.558 14.558 0 0 0 4.248-1.764 14.2 14.2 0 0 0 5.516-6.239 20.287 20.287 0 0 0 1.726-8.442 20.794 20.794 0 0 0-1.725-8.588 14.488 14.488 0 0 0-4.997-6.126zm-2.712 22.25c-1.409 1.788-3.841 2.694-7.226 2.694-1.317 0-2.371-.039-3.132-.114l-.3-.03a1.139 1.139 0 0 1-1.024-1.133v-13.285a1.2 1.2 0 0 1 .007-.13 5.686 5.686 0 0 1 2.35-4.18 7.586 7.586 0 0 1 4.384-1.588 5.868 5.868 0 0 1 3.518 1.142 8.042 8.042 0 0 1 2.624 3.463 14.415 14.415 0 0 1 1.019 5.685 11.788 11.788 0 0 1-2.22 7.476z"
            transform="translate(-105.668 -598.187)"
            fill="#47332e"
          />
          <rect
            width="7.211"
            height="33.017"
            rx=".976"
            transform="translate(98.169 17.669)"
            fill="#47332e"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default IconSabia;
