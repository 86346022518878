import styled from "styled-components";
import { Modal as AntDModal } from "antd";

export const Title = styled.h3`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.68;
  letter-spacing: normal;
  text-align: left;
  color: #47342e;

  margin-top: 2.5rem;
  margin-left: 1.25rem;
  margin-bottom: 2.5rem;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1.25rem;
  overflow-y: scroll;
`;

export const Content = styled.div`
  display: flex;

  width: 100%;

  height: 100%;
  background-color: #fffaf0;

  flex-direction: column;
`;

export const CloseButton = styled.button`
  background-color: black;
  border: none;
  width: 3rem;
  height: 3rem;
`;

export const Modal = styled(AntDModal)`
  position: absolute;

  right: 0;
  top: 4rem;

  margin-right: 3rem;

  max-width: 400px;

  height: 500px;
  padding-bottom: 0;

  .ant-modal-content {
    height: 100%;
    padding: 0;
  }
`;
