
import React, { useCallback, useEffect, useState } from "react";
import * as S from "./CardTotalActionPlanStyles";
import { ACTION_PLANS } from "../../../../../Enums/ActionsPlans.enum";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
export const CardTotalActionPlan = ({ farm }) => {
  const [countActionsPlan, setCountActionsPlan] = useState(0);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCountActionsPlan = () => {
    if (!farm?.actions_plan) return;

    let countAction = 0;
    farm?.actions_plan.forEach((act) => {
      act.items.forEach((item) => {
        const totalStatusPending = item.actions.filter(
          (action) => action.status === ACTION_PLANS.PENDING
        );
        countAction = countAction + totalStatusPending.length;
      });
    });

    setCountActionsPlan(countAction);
  };

  useEffect(() => handleCountActionsPlan(), [farm]);

  return (
    <S.Container
      hoverable
      onClick={() => navigate(`/action_plans/list/${farm._id}`)}
    >
      <S.HeaderCard>
        <S.Title>{t("ACTION_PLAN_ITEMS")}</S.Title>
        <S.IconClipboard />
      </S.HeaderCard>
      <S.ContentCard>
        <S.WrapperTotal>
          <S.TotalText>{countActionsPlan}</S.TotalText>
          <S.LabelTotal>{t("IN_PROGRESS")}</S.LabelTotal>
        </S.WrapperTotal>
      </S.ContentCard>
    </S.Container>
  );
};
