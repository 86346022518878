import { LockOutlined, MailOutlined, NumberOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import { Formik } from "formik";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import BCButtonConfirm from "../../components/BCButtonConfirm";
import { AuthContext } from "../../contexts/auth";
import BaseEntitlement from "../BaseEntitlement";

function FirstAccess() {
  const { t } = useTranslation();
  const { confirmeRegistration, loadingAuth } = useContext(AuthContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("Invalid_Email"))
      .required(t("Email_is_required")),
    password: Yup.string()
      .min(8, t("Pass_Least8"))
      .matches(/[A-Z]/, t("Pass_Should_Contain_At_Least_1_Uppercase"))
      .matches(/[a-z]/, t("Pass_Should_Contain_At_Least_1_Lowercase"))
      .matches(/[0-9]/, t("Pass_Should_Contain_At_Least_1_Number"))
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        t("Pass_Should_Contain_At_Least_1_Special_Character")
      )
      .required(t("Pass_is_required")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("Pass_must_match"))
      .required(t("Confirm_Pass_is_required")),
    numericCode: Yup.string()
      .matches(/^[0-9]*$/, t("Numeric_Code_Only_Digits"))
      .required(t("Numeric_Code_is_required")),
  });

  async function confirmeRegistrationInternal(values) {
    const payload = {
      confirm_password: values.confirmPassword,
      email: values.email,
      password: values.password,
    };
    await confirmeRegistration(payload, values.numericCode);
  }

  return (
    <BaseEntitlement
      title={t("Registration_Confirmation")}
      subtitle={t("SubTitle_First_Access")}
      to={"/login"}
    >
      <Formik
        initialValues={{}}
        validationSchema={validationSchema}
        onSubmit={confirmeRegistrationInternal}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
        }) => (
          <Form layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              help={touched.email && errors.email ? errors.email : ""}
              validateStatus={touched.email && errors.email ? "error" : ""}
            >
              <div style={{ marginTop: "20px" }}>
                <Input
                  prefix={<MailOutlined />}
                  name="email"
                  value={values.email}
                  className="input"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("Email")}
                />
              </div>
            </Form.Item>
            <Form.Item
              help={touched.password && errors.password ? errors.password : ""}
              validateStatus={
                touched.password && errors.password ? "error" : ""
              }
            >
              <Input.Password
                prefix={<LockOutlined />}
                name="password"
                className="input"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("Pass")}
              />
            </Form.Item>
            <Form.Item
              help={
                touched.confirmPassword && errors.confirmPassword
                  ? errors.confirmPassword
                  : ""
              }
              validateStatus={
                touched.confirmPassword && errors.confirmPassword ? "error" : ""
              }
            >
              <Input.Password
                prefix={<LockOutlined />}
                name="confirmPassword"
                className="input"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("Confirm_Pass")}
              />
            </Form.Item>
            <Form.Item
              help={
                touched.numericCode && errors.numericCode
                  ? errors.numericCode
                  : ""
              }
              validateStatus={
                touched.numericCode && errors.numericCode ? "error" : ""
              }
            >
              <Input
                prefix={<NumberOutlined />}
                name="numericCode"
                className="input"
                value={values.numericCode}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("Numeric_Code")}
              />
            </Form.Item>
            <Form.Item>
              <div style={{ marginTop: "20px" }}>
                <BCButtonConfirm
                  buttonText={!loadingAuth ? t("Send") : t("Sending")}
                ></BCButtonConfirm>
              </div>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </BaseEntitlement>
  );
}

export default FirstAccess;
