import { EyeOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Card, Row, Space, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BCTable from "../../components/BCTable";
import { getFarmsPerStatus } from "./service";

export default function FarmersEnrolledToTheProgram() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("ENROLLED_TO_THE_PROGRAM");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState(1);
  const [farm, setFarms] = useState([]);
  const [sortField, setSortField] = useState("name");
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);

  const listFarms = useCallback(async () => {
    setLoading(true);

    const result = await getFarmsPerStatus(
      pageSize,
      currentPage,
      search,
      sortOrder,
      sortField,
      status
    );
    result.result.map((x) => (x.key = x._id));
    setFarms(result.result);
    setTotal(result.count);
    setLoading(false);
  }, [pageSize, currentPage, search, sortOrder, sortField, status]);
  useEffect(() => {
    listFarms();
  }, [listFarms]);

  const columns = [
    {
      title: t("Farmers"),
      dataIndex: "farmers",
      align: "center",
      render: (text, data) => (
        <span>
          {data.farmer.name}
          <br />
          <Tag>{data.farmer.identifier}</Tag>
        </span>
      ),
    },
    {
      title: t("Property"),
      dataIndex: "property",
      align: "center",

      render: (text, data) => (
        <span>
          {data.name}
          <br />
          <Tag>{data.identifier}</Tag>
        </span>
      ),
    },
    {
      title: t("Localization"),
      dataIndex: "localization",
      align: "center",

      render: (text, data) => <span>{data.city}</span>,
    },
    {
      title: t("Status"),
      dataIndex: "status",
      align: "center",
      render: (text, data) => (
        <Tag icon={<SyncOutlined spin />} color="success">
          {t(data.status)}
        </Tag>
      ),
    },
  ];

  const actionsColumn = [
    {
      title: t("Actions"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={() => navigate(`/farmers/detail/${record._id}/farmer`)}
            icon={<EyeOutlined />}
          >
            {t("View")}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h1>{t("Farmers")}</h1>
      <Card style={{ marginTop: "20px" }}>
        <Row gutter={[16, 16]}>
          <div style={{ marginTop: "20px" }}>
            <BCTable
              data={farm}
              total={total}
              pageSize={pageSize}
              currentPage={currentPage}
              placeholderSearch={`${t("Search")} ${t("Property")}`}
              setSearch={setSearch}
              setCurrentPage={setCurrentPage}
              setPageSize={setPageSize}
              setSortField={false}
              setSortOrder={false}
              loading={loading}
              columns={columns}
              actionsColumn={actionsColumn}
              showCreateButton={false}
            />
          </div>
        </Row>
      </Card>
    </div>
  );
}
