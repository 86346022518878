import { useTranslation } from "react-i18next";
import * as S from "./CardTotalAreaStyles";

export const CardTotalArea = ({ totalAreaEnrolled, totalAreaImplemented }) => {
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.WrapperCountArea>
        <S.TitleCountArea>{Math.round(totalAreaEnrolled)} ha</S.TitleCountArea>
      </S.WrapperCountArea>
      <S.LabelDescription>
        {t("Total_area_of_registered_farms")}
      </S.LabelDescription>
      <S.TotalAreaImplemented>
        vs {Math.round(totalAreaEnrolled)} {t("ha_implemented")}
      </S.TotalAreaImplemented>
    </S.Container>
  );
};
