// Importe os componentes necessários do Ant Design e React
import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Table } from "antd";
import React from "react";

// Desestruture o componente Search do Input
const { Search } = Input;

// Defina o componente BCTable e suas propriedades
function BCTable({
  data, // Dados da tabela
  total, // Total de registros
  pageSize, // Tamanho da página
  currentPage, // Página atual
  columns, // Colunas da tabela
  placeholderSearch, // Texto do campo de pesquisa
  size = "small", // Tamanho da tabela (padrão: small)
  bordered = false, // Se a tabela deve ter bordas (padrão: false)
  loading, // Se a tabela está em carregamento
  showSizeChanger = false, // Se o seletor de tamanho de página deve ser exibido (padrão: false)
  actionsColumn = [], // Coluna de ações personalizada (padrão: vazio),
  setSearch, // Seta o valor da pesquisa
  setCurrentPage,
  setPageSize,
  setSortOrder,
  setSortField,
  showCreateButton, // Mostra o botão de adicionar
  textButtonAdd, // Nome do Botão de Adicioanr
  onAdd, // Função que é chamada quando o botão de adição é chamado
  showPlaceholderSearch = true,
  disablePagination = false,
  children,
}) {
  columns.forEach((column) => {
    if (!column.style) {
      column.style = { minWidth: 150 }; // Define o minWidth da coluna
    }
  });

  if (actionsColumn.length > 0) {
    columns.push(...actionsColumn);
  }

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      setSortOrder(sorter.order === "ascend" ? 1 : -1);
      setSortField(sorter.field);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Row gutter={16} style={{ marginBottom: showPlaceholderSearch ? 16: 0 }}>
          {showPlaceholderSearch && (
            <Col>
              {/* Campo de pesquisa */}
              <Search
                placeholder={placeholderSearch}
                onSearch={onSearch}
                style={{ width: 400 }}
              />
            </Col>
          )}
          <Col>
            {showCreateButton && (
              <Button
                shape="round"
                className="confirm_button"
                type="dashed"
                onClick={onAdd}
                icon={<PlusOutlined />}
              >
                {textButtonAdd}
              </Button>
            )}
          </Col>
        </Row>
        {children}
      </div>

      {disablePagination ? (
        <Table
          columns={columns}
          dataSource={data}
          onChange={onChange}
          sticky // Torna o cabeçalho da tabela fixo durante a rolagem
          bordered={bordered}
          loading={loading}
          size={size}
          pagination={{
            position: ["none"],
          }}
          scroll={{ x: "100%", scrollToFirstRowOnChange: true }}
        />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          onChange={onChange}
          sticky // Torna o cabeçalho da tabela fixo durante a rolagem
          bordered={bordered}
          loading={loading}
          size={size}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
            onChange: onChangePage, // Função para lidar com a mudança de página
            pageSizeOptions: ["10", "20", "30"],
            showSizeChanger: showSizeChanger, // Se o seletor de tamanho de página deve ser exibido
            defaultPageSize: 10,
          }}
          scroll={{ x: "100%", scrollToFirstRowOnChange: true }}
        />
      )}
    </div>
  );
}

export default BCTable;
