export const FieldsTemplateAdditionalInformation = () => {
  const InitialFields = [
    {
      title: "title_information_personal",
      fields: [
        {
          title: "label_name",
          field: "name",
          value: "",
          type: "text",
        },
        {
          title: "label_email",
          field: "email",
          value: "",
          type: "text",
        },
        {
          title: "label_phone",
          field: "phone_number",
          value: "",
          type: "text",
        },
        {
          title: "label_birth_date",
          field: "birth_date",
          value: "",
          type: "text",
        },
        {
          title: "label_cpf",
          field: "cpf",
          value: "",
          type: "text",
        },
        {
          title: "label_marital_status",
          field: "marital_status",
          value: "",
          type: "text",
        },
        {
          title: "label_level_of_education",
          field: "level_of_education",
          value: "",
          type: "text",
        },
        {
          title: "label_number_of_working_age_adults",
          field: "number_of_working_age_adults",
          value: "",
          type: "text",
        },
        {
          title: "label_number_of_children",
          field: "number_of_children",
          value: "",
          type: "text",
        },
      ],
    },
    {
      title: "title_information_property",
      fields: [
        {
          title: "label_name_farm",
          field: "farm_name",
          value: "",
          type: "text",
        },
        {
          title: "label_total_area",
          field: "total_area",
          value: "",
          type: "text",
        },
        {
          title: "label_area_to_be_enrolled_afs",
          field: "area_to_be_enrolled_afs",
          value: "",
          type: "text",
        },
        {
          title: "label_area_to_be_enrolled_er",
          field: "area_to_be_enrolled_er",
          value: "",
          type: "text",
        },
        {
          title: "label_organization_name",
          field: "organization_name",
          value: "",
          type: "text",
        },
        {
          title: "label_city",
          field: "city",
          value: "",
          type: "text",
        },
        {
          title: "label_state",
          field: "state",
          value: "",
          type: "text",
        },
        {
          title: "label_main_field_agent",
          field: "main_field_agent",
          value: "",
          type: "text",
        },
        {
          title: "label_has_beneficiary_registration",
          field: "has_beneficiary_registration",
          value: false,
          type: "checkbox",
        },
        {
          title: "label_has_land_title",
          field: "has_land_title",
          value: false,
          type: "checkbox",
        },
        {
          title: "label_has_possession",
          field: "has_possession",
          value: false,
          type: "checkbox",
        },
        {
          title: "label_multiple_owners",
          field: "multiple_owners",
          value: [{ name: "", cpf_cnpj: "" }],
          type: "list",
        },
      ],
    },
    {
      title: "title_information_production",
      fields: [
        {
          title: "label_primary_farm_activity",
          field: "primary_farm_activity",
          value: "",
          type: "text",
        },
        {
          title: "label_secondary_farm_activity",
          field: "secondary_farm_activity",
          value: "",
          type: "text",
        },

        {
          title: "label_agriculture_area",
          field: "agriculture_area",
          value: "",
          type: "text",
        },
        {
          title: "label_forest_area",
          field: "forest_area",
          value: "",
          type: "text",
        },
        {
          title: "label_pasture_area",
          field: "pasture_area",
          value: "",
          type: "text",
        },
        {
          title: "label_implementing_agroforestry",
          field: "implementing_agroforestry",
          value: false,
          type: "checkbox",
        },
        {
          title: "label_cacao_plantation",
          field: "cacao_plantation",
          value: false,
          type: "checkbox",
        },
      ],
    },
  ];

  const InitialSteps = [
    {
      id: 1,
      title: "label_step_1",
      subtitle: "label_step_1_subtitle",
      status: "APPROVAD",
    },
    {
      id: 2,
      title: "label_step_2",
      subtitle: "label_step_2_subtitle",
      status: "PENDING",
    },
    {
      id: 3,
      title: "label_step_3",
      subtitle: "label_step_3_subtitle",
      status: "PENDING",
    },
    {
      id: 4,
      title: "label_step_4",
      subtitle: "label_step_4_subtitle",
      status: "PENDING",
    },
    {
      id: 5,
      title: "label_step_5",
      subtitle: "label_step_5_subtitle",
      status: "PENDING",
    },
    {
      id: 6,
      title: "label_step_6",
      subtitle: "label_step_6_subtitle",
      status: "PENDING",
    },
  ];

  return {
    InitialFields,
    InitialSteps,
  };
};
