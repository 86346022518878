import React from 'react'
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import ActionPlanView from "../../pages/ActionPlans/ActionPlanView";
import ActionPlanViewItem from "../../pages/ActionPlans/ActionPlanViewItem";
import ActionsPlansDashBoard from "../../pages/ActionPlans/ActionsPlansDashBoard";
import ActionsPlansList from "../../pages/ActionPlans/ActionsPlansList";
import BaseLayout from "../../pages/BaseLayout";
import Private from "../Private";

function ActionsPlansRoutes() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Private>
            <BaseLayout breadcrumb={`${t("ActionsPlans")}}`}>
              <ActionsPlansDashBoard />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/:type/list/:farmerId?"
        element={
          <Private>
            <BaseLayout
              gotoback={"/action_plans"}
              breadcrumb={`${t("ActionsPlans")} / ${t("List")}`}
            >
              <ActionsPlansList />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/list/:farmerId?"
        element={
          <Private>
            <BaseLayout
              gotoback={"/action_plans"}
              breadcrumb={`${t("ActionsPlans")} / ${t("List")}`}
            >
              <ActionsPlansList />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/:id/view"
        element={
          <Private>
            <BaseLayout
              gotoback={"/action_plans"}
              breadcrumb={`${t("ActionsPlans")} / ${t("View")}`}
            >
              <ActionPlanView />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path=":id/view-item/:item_id"
        element={
          <Private>
            <BaseLayout breadcrumb={`${t("ActionsPlans")} / ${t("View_Item")}`}>
              <ActionPlanViewItem />
            </BaseLayout>
          </Private>
        }
      />
    </Routes>
  );
}

export default ActionsPlansRoutes;
