import React from 'react'
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import ImgCacau from "../../../../../assets/sabia_cacau_duo.png";
import * as S from "./CardFarmInformationStyles";

export const CardFarmInformation = ({ farm }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [myAgent, setMyAgent] = useState({});

  const loadAgent = () => {
    try {
      if (!farm) return;
      const agents = farm.agents;
      const agentMain = agents.find((agent) => agent.ordering === 1);
      setMyAgent(agentMain.info);
    } catch (error) {}
  };

  useEffect(() => {
    loadAgent();
  }, [farm]);

  return (
    <S.Container
      hoverable
      loading={!myAgent}
      onClick={() =>
        navigate(`/farmers/detail/${farm._id}/farmer/information-additional`)
      }
      cover={
        <S.BackgroundImage>
          <S.ImageArea src={ImgCacau} />
          <S.TitleBackground>{t("Name_APP")}</S.TitleBackground>
        </S.BackgroundImage>
      }
    >
      <S.BodyCar>
        <S.Header>
          <S.IconSupport />
          <S.Title>{t("Main_field_agent")}</S.Title>
        </S.Header>
        <S.Content>
          <S.NameAgent>{myAgent?.name}</S.NameAgent>
          <S.DescriptionOrganization>
            {farm?.organization_name}
          </S.DescriptionOrganization>

          <S.WrapperTagInfo>
            <S.IconInfo />
            <S.TitleTag>{t("Additional_information")}</S.TitleTag>
          </S.WrapperTagInfo>
        </S.Content>
      </S.BodyCar>
    </S.Container>
  );
};
