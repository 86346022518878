import axios from "../../apis/axiosInterceptorCalendar";

export const list = async ({
  dateFinal = "",
  dateInitial = "",
  status = "",
  id = "",
  limit = 10,
  page = 1,
  origin = "",
  search = "",
  field = "",
  onlyMy = "false",
  sort = "",
  group = "true",
}) => {
  const result = await axios.get(
    `/v2/notifications?group=${group}&dateFinal=${dateFinal}&dateInitial=${dateInitial}&status=${status}&id=${id}&limit=${limit}&page=${page}&origin=${origin}&search=${search}&field=${field}&onlyMy=${onlyMy}&sort=${sort}`
  );
  return result.data.data;
};

export const updateStatus = async (payload) => {
  const result = await axios.patch(`v1/notifications/update-status`, {
    setStatus: payload,
  });
  return result.data.data;
};

export const create = async (
  entityId,
  title,
  origin,
  description,
  destinations,
  send_by_app,
  send_by_sms
) => {
  const payload = {
    entityId,
    title,
    origin,
    description,
    destinations,
    send_by_app,
    send_by_sms,
  };
  const respsonse = await axios.post(`/v1/notifications/in-lote`, payload);
  return respsonse.data
};

export const getNotification = async (id) => {
  const result = await axios.get(`/v1/notifications/identifier/${id}`);
  return result.data.result.result;
};
