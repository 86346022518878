import React from 'react'
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import BaseLayout from "../../pages/BaseLayout";
import SuppliersCreateSalesman from "../../pages/Suppliers/SuppliersCreateSalesman";
import SuppliersCreateSuppliers from "../../pages/Suppliers/SuppliersCreateSuppliers";
import SuppliersDashboard from "../../pages/Suppliers/SuppliersDashboard";
import SuppliersEdit from "../../pages/Suppliers/SuppliersEdit";
import SuppliersEditSalesman from "../../pages/Suppliers/SuppliersEditSalesman";
import SuppliersRatingView from "../../pages/Suppliers/SuppliersRatingView";
import SuppliersView from "../../pages/Suppliers/SuppliersView";
import Private from "../Private";

function SuppliersRoutes() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Private>
            <BaseLayout breadcrumb={`${t("Suppliers")}`}>
              <SuppliersDashboard />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/create/suppliers"
        element={
          <Private>
            <BaseLayout
              gotoback="/suppliers"
              breadcrumb={`${t("Suppliers")} / ${t("Creation")}`}
            >
              <SuppliersCreateSuppliers />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/:id/view"
        element={
          <Private>
            <BaseLayout
              gotoback={"/suppliers"}
              breadcrumb={`${t("Suppliers")} / ${t("View")}`}
            >
              <SuppliersView />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/:id/create/salesman"
        element={
          <Private>
            <BaseLayout
              gotoback="/suppliers"
              breadcrumb={`${t("Suppliers")} / ${t("Creation")} / ${t(
                "Salesmans"
              )}`}
            >
              <SuppliersCreateSalesman />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/:id/edit"
        element={
          <Private>
            <BaseLayout
              gotoback={"/suppliers"}
              breadcrumb={`${t("Suppliers")} / ${t("Edit")}`}
            >
              <SuppliersEdit />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/:id/rate"
        element={
          <Private>
            <BaseLayout
              gotoback={"/suppliers"}
              breadcrumb={`${t("Suppliers")} / ${t("rating")}`}
            >
              <SuppliersRatingView />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path=":vendorId/salesman/:id/edit"
        element={
          <Private>
            <BaseLayout
              gotoback={"/suppliers"}
              breadcrumb={`${t("Salesman")} / ${t("edit")}`}
            >
              <SuppliersEditSalesman />
            </BaseLayout>
          </Private>
        }
      />
    </Routes>
  );
}

export default SuppliersRoutes;
