import React from 'react'
import { Route, Routes } from "react-router-dom";
import Private from "../Private";
import BaseLayout from "../../pages/BaseLayout";
import { ProgramMapPage } from "../../pages/ProgramMap/ProgramMapPage";
import { useTranslation } from "react-i18next";

function ProgramMapRoutes() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Private>
            <BaseLayout breadcrumb={t("Program_Area_title_page")}>
              <ProgramMapPage />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/:farm_id"
        element={
          <Private>
            <BaseLayout breadcrumb={t("Program_Area_title_page")}>
              <ProgramMapPage />
            </BaseLayout>
          </Private>
        }
      />
    </Routes>
  );
}

export default ProgramMapRoutes;
