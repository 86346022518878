import React from 'react'
import {
  CloseCircleOutlined,
  EyeOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Space, Tag } from "antd";
import { STATUS_FARM } from "../../../Enums/Farm.enum";

export const ProgramAreaCollunmGrid = (translation, navigate) => {
  const columns = [
    {
      title: translation("Farmer"),
      dataIndex: "name",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return (
          <span>
            {data.name}
            <br />
            <Tag>{data.identifier}</Tag>
          </span>
        );
      },
    },
    {
      title: translation("FARM_BOUNDARIES"),
      dataIndex: "total_area_farm",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return <span>{data.total_area_farm}</span>;
      },
    },
    {
      title: translation("AGROFORESTRY"),
      dataIndex: "total_area_agroforest",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return <span>{data.total_area_agroforest}</span>;
      },
    },
    {
      title: translation("REFORESTATION"),
      dataIndex: "total_area_reforestation",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return <span>{data.total_area_reforestation}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "start",
      sorter: true,
      render: (text, data) => {
        let statusTag;
        switch (data.status) {
          case STATUS_FARM.IN_PROGRESS:
            statusTag = (
              <Tag icon={<SyncOutlined spin />} color="processing">
                {translation("IN_PROGRESS")}
              </Tag>
            );
            break;
          case STATUS_FARM.APPLICATION_DENIED:
            statusTag = (
              <Tag icon={<CloseCircleOutlined />} color="error">
                {translation("APPLICATION_DENIED")}
              </Tag>
            );
            break;
          case STATUS_FARM.FLAGGED_FOR_REVIEW:
            statusTag = (
              <Tag icon={<SyncOutlined spin />} color="warning">
                {translation("FLAGGED_FOR_REVIEW")}
              </Tag>
            );
            break;
          case STATUS_FARM.ENROLLED_TO_THE_PROGRAM:
            statusTag = (
              <Tag color="success">
                {translation("ENROLLED_TO_THE_PROGRAM")}
              </Tag>
            );
            break;
          default:
            statusTag = <span>Status Desconhecido</span>;
        }
        return statusTag;
      },
    },
  ];

  const actions = [
    {
      title: translation("Actions"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={() =>
              navigate(
                `/program-area/${record._id ?? ''}/list-program-area-by-farm`
              )
            }
            icon={<EyeOutlined />}
          >
            {translation("View")}
          </Button>
        </Space>
      ),
    },
  ];

  return {
    columns,
    actions,
  };
};
