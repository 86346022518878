import { CloseCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { STATUS_FARM } from "../../Enums/Farm.enum";
import { TYPEUSER } from "../../Enums/Users.enum";
import BCTable from "../BCTable";
import { farm, farmByAgent, listFarms, listUsers } from "./service";

export default function BCListFarms({
  actionsColumn = [],
  needAgents = true,
  delete_items = [],
  no_agents = false,
  only_with_agents = false,
  withProgram = true,
  withChecklist = false,
  checklistType = "",
  agentId = ""
}) {
  const [farms, setFarms] = useState([]);
  const [agents, setAgents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [sortField, setSortField] = useState("name");
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  const findFarms = useCallback(async () => {
    setLoading(true);
    let result;
    
    if(!agentId){
      result = await listFarms({
        pageSize,
        currentPage,
        search,
        sortOrder,
        sortField,
        no_agents: no_agents,
        only_with_agents: only_with_agents,
        withProgram: withProgram,
        withChecklist: withChecklist,
        checklistType: checklistType,
      });
    }



    if(agentId){
      result = await farm(18,currentPage,agentId)
    }
    
    result.result.map((x) => (x.key = x._id));
    setFarms(result.result);
    setTotal(result.count);
    setLoading(false);
  }, [
    pageSize,
    currentPage,
    search,
    sortOrder,
    sortField,
    only_with_agents,
    withChecklist,
    checklistType,
    no_agents,
    withProgram,
  ]);

  const findUsers = useCallback(async () => {
    const result = await listUsers({
      limit: Number.MAX_SAFE_INTEGER,
      page: 1,
      type: TYPEUSER.AGENT,
      search: "",
      sort: 1,
      name: 1,
    });

    result.result.map((x) => (x.key = x._id));
    setAgents(result.result);
  }, []);

  useEffect(() => {
    findFarms();
    if (needAgents) findUsers();
  }, [findFarms, findUsers, needAgents]);

  const columns = [
    {
      title: t("Farmer"),
      dataIndex: "farmer.name",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return (
          <span>
            {data.farmer.name}
            <br />
            <Tag>{data.farmer.identifier}</Tag>
          </span>
        );
      },
    },
    {
      title: t("Farm"),
      dataIndex: "name",
      align: "start",
      sorter: true,
      render: (text, data) => {
        return (
          <span>
            {data.name}
            <br />
            <Tag>{data.identifier}</Tag>
          </span>
        );
      },
    },
    {
      title: t("Main_Field_Agent"),
      dataIndex: "agents",
      align: "start",
      sorter: true,
      render: (text, data) => {
        const main_agent = data.agents.find(agent => agent.ordering == 1)
        return (
          <span>
            {data.agents.length > 0
              ? agents.sort(function(a, b) {
                return a > b;
            }).find((x) => x._id === main_agent.agent_id)?.name
              : ""}
          </span>
        );
      },
    },
    {
      title: t("Location"),
      dataIndex: "location",
      align: "start",
      sorter: false,
      render: (text, data) => (
        <Tag color="success" key={data.city}>
          {data.city} - {data.state}
        </Tag>
      ),
    },
    {
      title: t("Status"),
      dataIndex: "status",
      align: "center",
      sorter: false,
      render: (text, data) => {
        let statusTag;
        switch (data.program[0]?.status) {
          case STATUS_FARM.IN_PROGRESS:
            statusTag = (
              <Tag icon={<SyncOutlined spin />} color="processing">
                {t("IN_PROGRESS")}
              </Tag>
            );
            break;
          case STATUS_FARM.APPLICATION_DENIED:
            statusTag = (
              <Tag icon={<CloseCircleOutlined />} color="error">
                {t("APPLICATION_DENIED")}
              </Tag>
            );
            break;
          case STATUS_FARM.FLAGGED_FOR_REVIEW:
            statusTag = (
              <Tag icon={<SyncOutlined spin />} color="warning">
                {t("FLAGGED_FOR_REVIEW")}
              </Tag>
            );
            break;
          case STATUS_FARM.ENROLLED_TO_THE_PROGRAM:
            statusTag = (
              <Tag color="success">{t("ENROLLED_TO_THE_PROGRAM")}</Tag>
            );
            break;
          default:
            statusTag = <span>Status Desconhecido</span>;
        }
        return statusTag;
      },
    },
  ];

  delete_items.map((field) => {
    columns.splice(
      columns.findIndex((x) => x.dataIndex === field),
      1
    );
  });

  return (
    <div>
      <BCTable
        data={farms}
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        placeholderSearch={`${t("Search")} ${t("Farms")}`}
        setSearch={setSearch}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        loading={loading}
        columns={columns}
        actionsColumn={actionsColumn}
        showCreateButton={false}
        showPlaceholderSearch={!agentId}
      />
    </div>
  );
}
