import { CloseCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Card, Col, Descriptions, Form, Row, Select, Tag } from "antd";
import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { STATUS_FARM } from "../../Enums/Farm.enum";
import { TYPEUSER } from "../../Enums/Users.enum";
import BCButtonConfirm from "../../components/BCButtonConfirm";
import { linkAgents, listFarms, listUsers } from "./service";
import { toast } from "react-toastify";
const { Option } = Select;

// Essa tela é exatamente igual a AgentsDefineFieldAgents, a unica diferenã é que buscando por uma fazenda
// Estou deixando separado para seguir a logica estabelecidade no inicio do projeto, que cada card seria uma page
export default function AgentsEditDefineFieldAgents() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    principalAgent: Yup.string().required(t("Main_Field_Agent_is_Required")),
    secondaryAgents: Yup.array().min(
      1,
      t("At_least_one_secondary_field_agent_is_mandatory")
    ),
  });

  const [initialValues, setInitialValues] = useState({
    principalAgent: "",
    secondaryAgents: [],
  });

  const [myAgents, setMyAgents] = useState([]);

  const [isEdit, setIsEdit] = useState(
    location.pathname.includes("/edit-field-agents")
  );

  const [sortOrder] = useState(1);
  const [sortField] = useState("name");
  const [agents, setAgents] = useState([]);
  const [farm, setFarm] = useState({
    identifier: "",
    name: "",
    farmer: {
      identifier: "",
      name: "",
      phone_number: "",
      email: "",
    },
    program: [
      {
        status: "",
      },
    ],
    city: "",
    state: "",
    total_area: 0,
  });

  const { id } = useParams();

  const findFarm = useCallback(async () => {
    const result = await listFarms({ id, sortOrder, sortField });
    if (location.pathname.includes("/edit-field-agents")) {
      setInitialValues({
        principalAgent: result.result[0].agents.find((x) => x.ordering === 1)
          .agent_id,
        secondaryAgents: result.result[0].agents
          .filter((agent) => agent.ordering !== 1)
          .map((agent) => agent.agent_id),
      });
    }
    setFarm(result.result[0]);
  }, [id, sortOrder, sortField, location.pathname]);

  const findAgents = useCallback(async () => {
    const result = await listUsers({
      limit: Number.MAX_SAFE_INTEGER,
      page: 1,
      type: TYPEUSER.AGENT,
      sort: sortOrder,
      field: sortField,
      search: "",
    });
    setAgents(result.result);
  }, [sortOrder, sortField]);

  useEffect(() => {
    findFarm();
    findAgents();
  }, [findFarm, findAgents]);

  useEffect(() => {
    handleMyAgent();
  }, [agents, initialValues]);

  const handleMyAgent = () => {
    const principalAgent = agents.find(
      (agent) => agent._id === initialValues.principalAgent
    );
    const secondaryAgents = agents.filter((agent) =>
      initialValues.secondaryAgents.includes(agent._id)
    );

    const _myAgents = [principalAgent, ...secondaryAgents];

    setMyAgents(_myAgents.map((item) => item?.name + "; "));
  };

  async function defineFieldAgent(values) {
    try {
      const ids = [];
      ids.push(values.principalAgent);
      ids.push(...values.secondaryAgents);
      const response = await linkAgents(id, ids);
      toast.success(response?.message);
      navigate("/agents");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div style={{ position: "relative" }}>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={6}>
              <img
                src="https://img.freepik.com/fotos-premium/imagem-rural-em-uma-fazenda-com-portao-de-madeira-estrada-de-terra-sol-e-colinas-ao-fundo_337748-36.jpg"
                alt="Imagem"
                style={{ width: "100%", height: "auto" }}
              />
            </Col>
            <Col xs={24} md={18}>
              <Descriptions title={t("Farm_details")}>
                <Descriptions.Item label={t("Farm")}>
                  <div>
                    {farm.name}
                    <br />
                    <Tag color="green">{farm.identifier}</Tag>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label={t("Farmer")}>
                  <div>
                    {farm.farmer.name}
                    <br />
                    <Tag color="blue">{farm.farmer.identifier}</Tag>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label={t("Location")}>
                  {farm.city} - {farm.state}
                </Descriptions.Item>
                <Descriptions.Item label={t("Phone")}>
                  {farm.farmer.phone_number
                    ? farm.farmer.phone_number
                    : t("Uninformed")}
                </Descriptions.Item>
                <Descriptions.Item label={t("Email")}>
                  {farm.farmer.email}
                </Descriptions.Item>
                <Descriptions.Item label={t("Area")}>
                  {farm.total_area} ha
                </Descriptions.Item>
                <Descriptions.Item label={"Status"}>
                  {(() => {
                    switch (farm.program[0].status) {
                      case STATUS_FARM.IN_PROGRESS:
                        return (
                          <Tag icon={<SyncOutlined spin />} color="processing">
                            {t("IN_PROGRESS")}
                          </Tag>
                        );
                      case STATUS_FARM.APPLICATION_DENIED:
                        return (
                          <Tag icon={<CloseCircleOutlined />} color="error">
                            {t("APPLICATION_DENIED")}
                          </Tag>
                        );
                      case STATUS_FARM.FLAGGED_FOR_REVIEW:
                        return (
                          <Tag icon={<SyncOutlined spin />} color="warning">
                            {t("FLAGGED_FOR_REVIEW")}
                          </Tag>
                        );
                      case STATUS_FARM.ENROLLED_TO_THE_PROGRAM:
                        return (
                          <Tag color="success">
                            {t("ENROLLED_TO_THE_PROGRAM")}
                          </Tag>
                        );
                      default:
                        return <span>Status Desconhecido</span>;
                    }
                  })()}
                </Descriptions.Item>

                <Descriptions.Item label={t("Agents")}>
                  {myAgents}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Col>
      </Row>

      <Card style={{ marginTop: "20px" }} title={t("Define_Field_Agent")}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={defineFieldAgent}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
          }) => (
            <Form layout="vertical" onFinish={handleSubmit}>
              <Row style={{ marginTop: "30px" }} gutter={16}>
                <Col xs={24} sm={6}>
                  <Form.Item
                    label={t("Main_Field_Agent")}
                    help={
                      touched.principalAgent &&
                      errors.principalAgent &&
                      errors.principalAgent
                        ? errors.principalAgent
                        : ""
                    }
                    validateStatus={
                      touched.principalAgent && errors.principalAgent
                        ? "error"
                        : ""
                    }
                  >
                    <Select
                      style={{ width: "100%" }}
                      name="principalAgent"
                      value={values.principalAgent}
                      onChange={(value) => {
                        handleChange({
                          target: { name: "principalAgent", value },
                        });
                        handleChange({
                          target: { name: "secondaryAgents", value: [] },
                        });
                      }}
                      onBlur={handleBlur}
                      placeholder={t("Agents")}
                    >
                      <Option value="" disabled>
                        {t("Agents")}
                      </Option>
                      {agents.map((x) => (
                        <Option value={x._id} key={x._id}>
                          {x.name} - {x.organization.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={18}>
                  <Form.Item
                    label={t("Secondary_Field_Agents")}
                    help={
                      touched.secondaryAgents &&
                      errors.secondaryAgents &&
                      errors.secondaryAgents
                        ? errors.secondaryAgents
                        : ""
                    }
                    validateStatus={
                      touched.secondaryAgents && errors.secondaryAgents
                        ? "error"
                        : ""
                    }
                  >
                    <Select
                      style={{ width: "100%" }}
                      name="secondaryAgents"
                      mode="multiple"
                      value={values.secondaryAgents}
                      onChange={(value) => {
                        handleChange({
                          target: { name: "secondaryAgents", value },
                        });
                      }}
                      onBlur={handleBlur}
                      placeholder={t("Agents")}
                      disabled={!values.principalAgent}
                    >
                      {values.principalAgent &&
                        agents
                          .filter(
                            (x) =>
                              x.organization._id ===
                                agents.find(
                                  (a) => a._id === values.principalAgent
                                ).organization._id &&
                              x._id !== values.principalAgent
                          )
                          .map((x) => (
                            <Option value={x._id} key={x._id}>
                              {`${x.name} - ${x.organization.name}`}
                            </Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <BCButtonConfirm
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    marginTop: "20px",
                  }}
                  buttonText={t("Send")}
                ></BCButtonConfirm>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
}
