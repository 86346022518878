import { MdPerson } from "react-icons/md";
import { TbDiscountCheckFilled } from "react-icons/tb";
import { Button, Card } from "antd";
import styled from "styled-components";
import React from "react";
import { PROGRAM_AREA_MAP_STATUS } from "../../../../../Enums/ProgramAreaMapStatus";

export const Container = styled(Card)`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 660px;
  /* max-height: 440px; */
  height: 440px;

  box-shadow: 1px 1px 10px #3331;
  border: 1px solid #3332;
`;

export const HeaderCar = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 0;
  align-items: center;
  margin-bottom: 10px;
`;

export const IconPerson = styled(MdPerson)`
  margin-right: 10px;
  font-size: 30px;
  color: rgb(71, 52, 46, 1);
`;

export const Title = styled.h2`
  font-size: 22px;
  color: rgb(71, 52, 46, 0.8);
  font-weight: 600;
`;

export const Divider = styled.div`
  min-width: 100%;
  height: 1px;
  border-top: 1px solid rgb(71, 52, 46, 0.2);
  margin-bottom: 10px;
`;

export const ContentStatuses = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const hashColor = (status) => {
  switch (status) {
    case PROGRAM_AREA_MAP_STATUS.APPROVED:
      return "#4faf49";
    case PROGRAM_AREA_MAP_STATUS.REVIEW:
      return "#49afaf";
    case PROGRAM_AREA_MAP_STATUS.REJECTED:
      return "#f54d4d";
    default:
      return "#332522";
  }
};

export const WrapperStatuses = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 0px;
  align-items: center;
`;

export const IconCheck = styled(TbDiscountCheckFilled)`
  margin-right: 10px;
  font-size: 30px;
  color: ${(props) =>
    props.status === PROGRAM_AREA_MAP_STATUS.APPROVED
      ? "#4faf49"
      : "transparent"};
`;

export const TitleStatusText = styled.h4`
  display: flex;
  width: 40%;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 400;
  color: rgb(71, 52, 46, 0.8);
  overflow-wrap: break-word;
`;

export const StatusText = styled.span`
  display: flex;
  width: 30%;
  color: ${(props) => hashColor(props.status)};
  font-weight: 300;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const Fragment = styled.div`
  display: flex;
  width: 20%;
  height: 10px;
`;

export const WrapperAction = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const ButtonAccept = styled(Button).attrs({
  style: {
    backgroundColor: "#4faf49",
  },
  shape: "round",
  type: "primary",
})``;

export const ButtonReject = styled(Button).attrs({
  style: {
    backgroundColor: "#f54d4d",
  },
  shape: "round",
  type: "primary",
})`
  margin-right: 10px;
`;
