import { LeftOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import IconSabia from "../assets/IconsSabia/IconSabia";
import BCLanguageSelector from "../components/BCLanguageSelector";
import "./BaseEntitlement.css";

//Edu, tem que mudar a Logo
function BaseEntitlement({ children, title, subtitle = null, to = null }) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 1340 });

  return (
    <div className="login-container">
      {!isMobile && (
        <div className="centered-image-text">
          <IconSabia />
          <h3 className="centered-text">{t("Welcome_to_the_Admin_Portal")}</h3>
        </div>
      )}
      <Row style={{ minHeight: "100vh" }} align="middle" justify="end">
        <Col xs={22} sm={16} md={12} lg={8} xl={6}>
          <Card className="login-card">
            {to && (
              <Col>
                {to && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Link to={to}>
                      <LeftOutlined />
                    </Link>
                  </div>
                )}
              </Col>
            )}
            <Row justify="space-between" align="middle" className="card-header">
              {!to && (
                <Col>
                  {to && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Link to={to}>
                        <LeftOutlined />
                      </Link>
                    </div>
                  )}
                </Col>
              )}
              <Col>
                <div style={{ textAlign: "center" }}>
                  <h2>{title}</h2>
                  {subtitle && (
                    <h4 style={{ fontWeight: "normal" }}>{subtitle}</h4>
                  )}
                </div>
              </Col>
              <Col />
            </Row>
            <div className="language-selector-container">
              <BCLanguageSelector />
            </div>
            {/* Nesse Ponto Entram os Formularios de toda a Parte do Entitlement */}
            {children}
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default BaseEntitlement;
