import React, { useEffect, useRef, useState } from "react";

import * as S from "./NotificationsModalStyles";
import NotificationItem from "./Components/NotificationItem";
import { getNotificationList } from "../../service";
import { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useOnClickAway } from "../../../../hooks/useOnClickAway";
import { useTranslation } from "react-i18next";
import { Skeleton, Divider } from "antd";

function NotificationsModal({ onClose }) {
  const [modalRoot, setModalRoot] = useState();

  const { t } = useTranslation();

  const limit = 10;
  const [notifications, setNotifications] = useState([]);

  const pageRef = useRef(1);
  const totalRef = useRef();

  const ref = useOnClickAway(() => {
    onClose?.call();
  });

  const fetchNotifications = async ({ limit, page }) => {
    const response = await getNotificationList({ limit, page, statusArray: [{ status: "CREATED" }] });
    setNotifications((prev) => [...prev, ...response.result]);

    totalRef.current = response.count;
  };

  const nextPage = () => {
    pageRef.current += 1;
    fetchNotifications({ limit, page: pageRef.current });
  };

  const end = useMemo(() => {
    if (!totalRef.current) return true;

    return totalRef.current && pageRef.current * limit >= totalRef.current;
  }, [pageRef.current, totalRef.current]);

  useEffect(() => {
    const modalRoot = document.getElementById("notifications-modal-container");

    setNotifications([]);
    setModalRoot(modalRoot);
    fetchNotifications({ limit, page: 1 });
  }, []);

  return (
    <>
      <div style={{}} id="notifications-modal-container"></div>
      {modalRoot && (
        <S.Modal
          getContainer={modalRoot}
          open={true}
          closable={false}
          footer={null}
          bodyStyle={{
            width: "100%",
            height: "100%",
            padding: 0,
          }}
          maskClosable={true}
        >
          <S.Container ref={ref} id="scrollableDiv">
            <InfiniteScroll
              dataLength={notifications.length}
              next={nextPage}
              hasMore={!end}
              loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
              endMessage={<Divider>{t("end_list")}</Divider>}
              scrollableTarget="scrollableDiv"
            >
              {notifications.map((item) => (
                <NotificationItem key={item._id} item={item}></NotificationItem>
              ))}
            </InfiniteScroll>
          </S.Container>
        </S.Modal>
      )}
    </>
  );
}

export default NotificationsModal;
