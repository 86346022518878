import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AboutArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0.5rem;
`;

export const FarmColor = styled.div`
  ${({ color }) => css`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-top: 0.125rem;
    background-color: ${color};
  `}
`;

export const DescriptionIntersection = styled.div`
  padding-left: 0.5rem;
  color: #47342e;

  > p {
    font-size: 1rem;
    margin: 0 !important;
  }

  > span {
    font-size: 0.75rem;
    opacity: 0.7;
    font-weight: 500;
  }
`;

export const Error = styled.span`
  color: #e74c3c;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
`;

export const ActionsIntersection = styled.div`
  margin-top: 0.5rem;

  > button {
    display: flex;
    border: 0;
    background-color: transparent;
    margin: 0;
    padding: 0;
    font-size: 0.75rem;
    opacity: 0.7;

    &::before {
      content: "|";
      display: block;
      padding-right: 0.5rem;
    }

    &::after {
      content: "|";
      display: block;
      padding-left: 0.5rem;
    }
  }
`;

export const FormIntersection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentButtons = styled.div`
  display: flex;
  margin-bottom: 1rem;

  button {
    display: flex;
    border: 0;
    background-color: transparent;
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    opacity: 0.7;
    color: #1f9652;

    &::before {
      content: "|";
      display: block;
      padding-right: 0.5rem;
    }

    &::after {
      content: "|";
      display: block;
      padding-left: 0.5rem;
    }

    & + button {
      margin-left: 2rem;
    }
  }
`;

export const ContentForm = styled.div`
  display: flex;
  flex-direction: column;

  .ant-select-selection-item {
    color: #47342e;
    opacity: 0.4;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-bottom: 2px solid rgba(51, 37, 34, 0.24) !important;
  }

  .ant-input {
    border-bottom: 2px solid rgba(51, 37, 34, 0.24) !important;
  }

  > input {
    margin-top: 0.75rem;
  }
`;

export const SectionDescribeAreas = styled.div`
  margin-top: 0.5rem;

  > button {
    display: flex;
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 0;

    > span {
      display: flex;
      font-size: 0.875rem;
      opacity: 0.7;

      &::before {
        content: "|";
        display: block;
        padding-right: 0.5rem;
      }

      &::after {
        content: "|";
        display: block;
        padding-left: 0.5rem;
      }
    }
  }
`;

export const ContentItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  > p {
    margin: 0 !important;
    font-size: 0.875rem;
    font-weight: bold;
  }

  > span {
    font-size: 0.75rem;
    font-weight: 400;
    padding-top: 0.25rem;
  }
`;

export const Arrow = styled.div`
  ${({ type }) => css`
    width: 10px;
    height: 10px;
    box-sizing: border-box;
    margin-left: 0.5rem;
    margin-top: ${type === "up" ? "0.25rem" : "0rem"};
    transform: ${type === "up" ? "rotate(-45deg)" : "rotate(135deg)"};

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border-width: 2px 2px 0 0;
      border-style: solid;
      border-color: #47342e;
      opacity: 0.7;
    }
  `}
`;
