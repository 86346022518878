import * as Style from "./BCListStyles";
import React from 'react'
export const BCList = ({
  dataSource,
  height,
  itemHeight,
  itemKey,
  onScroll,
  renderItem,
}) => {
  return <Style.Container 
    dataSource={dataSource}
    height={height}
    itemHeight={itemHeight}
    itemKey={itemKey}
    onScroll={onScroll}
    renderItem={renderItem}
  />;
};
