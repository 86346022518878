import React, { useMemo } from "react";

import * as S from "./NotificationItemStyles";
import { useTranslation } from "react-i18next";

import { format, parseISO } from "date-fns";

import { ptBR, enUS } from "date-fns/locale";

import { useNavigate } from "react-router-dom";

import { updateNotificationItem } from "../../../service";

function NotificationItem({ item }) {
  const { t, i18n } = useTranslation();

  const lang = i18n.language === "pt" ? ptBR : enUS;

  const navigate = useNavigate();

  const handleNavigation = useMemo(() => {
    if (item.origin === "CALL" && item.user_origin_type === "farmer") {
      return () => {
        navigate("/farmers/detail/" + item.user_origin_id + "/farmer");
      };
    }

    if (item.origin === "CHAT") {
      return () => {
        let id = null;
        if (item.user_origin_type === "farmer") {
          id = item.user_origin_id;
        } else if (item.user_destination_type === "farmer") {
          id = item.user_destination_id;
        }

        if (id) {
          navigate(`/messages/${id}/farmer/${item.entity_id}/chat`);
        }
      };
    }

    if (item.origin === "SCHEDULE_REQUEST") {
      let id = null;
      if (item.user_origin_type === "farmer") {
        id = item.user_origin_id;
      } else if (item.user_destination_type === "farmer") {
        id = item.user_destination_id;
      }

      return () => {
        if (id) navigate(`/farmers/detail/${id}/farmer`);
      };
    }

    if (item.origin === "AREA_WITH_INTERSECTION") {
      return () => {};
    }

    if (item.origin === "EVENT" || item.origin === "FIELD_VISIT_IN_GROUP") {
      let id = null;
      if (item.user_origin_type === "farmer") {
        id = item.user_origin_id;
      } else if (item.user_destination_type === "farmer") {
        id = item.user_destination_id;
      }

      return () => {
        navigate("/calendar/" + id);
      };
    }

    return () => {};
  }, [item.origin]);

  const handleOnClick = async () => {
    updateNotificationItem({
      id: item._id,
      status: "READ",
    });

    handleNavigation();
  };

  return (
    <S.NotificationCenterMessage
      hoverable
      key={item._id}
      onClick={handleOnClick}
    >
      <S.IconNotificationCenterOpen />
      <div>
        <h4>{item.description}</h4>
        <p>
          {format(parseISO(item.createdAt), "MMM dd, HH:MM", {
            locale: lang,
          })}
        </p>
      </div>

      {item.status_admin === "CREATED" && <S.RedDot></S.RedDot>}
    </S.NotificationCenterMessage>
  );
}

export default NotificationItem;
