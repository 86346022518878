import { Button, Modal, Select, Skeleton, Progress } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import * as S from "./DeleteDocumentModalStyles";
import {
  deleteDocument,
  fechAddDocument,
  fetchListTypeDocument,
  fetchUploadDocumentFarm,
} from "../../../service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const DeleteDocumentModal = (props, ref) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [farmId, setFarmId] = useState();
  const [identifier, setIdentifier] = useState();
  const [loading, setLoading] = useState(false);

  const onCancel = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const handleDeleteDocument = async () => {
    try {
      setLoading(true);
      await deleteDocument(farmId, identifier);
      setTimeout(() => {
        setLoading(false);
        props?.onLoad();
        onCancel();
        window.location.reload()
      }, 2000);
      toast.success(t('delete_document_feedback'));
    } catch (error) {
      console.log("erro upload document", error);
      setLoading(false);
      // toast.error("Arquivo deletado com sucesso!");
      toast.error("error_delete_document_feedback");
    }
  };

  useImperativeHandle(ref, () => ({
    handleOpen: (e) => {
      setIdentifier(e?.identifier);
      setFarmId(e?.farmId);
      handleOpen();
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={open}
      onCancel={onCancel}
      onOk={handleDeleteDocument}
      title={t("title_modal_delete_document")}
      footer={[
        <Button
          shape="round"
          className="confirm_button"
          type="primary"
          loading={loading}
          onClick={handleDeleteDocument}
        >
          {t("yes")}
        </Button>,
        <Button
          shape="round"
          type="primary"
          danger
          loading={loading}
          onClick={onCancel}
        >
          {t("not")}
        </Button>,
      ]}
    >
      <S.Container>
        <S.Message>{t("message_question_delete_document")}</S.Message>
      </S.Container>
    </Modal>
  );
};

export default forwardRef(DeleteDocumentModal);
