export const AREA_MAP = Object.freeze({
  FARM_BOUNDARIES: "FARM_BOUNDARIES",
  REFORESTATION: "REFORESTATION",
  AGROFORESTRY: "AGROFORESTRY",
  INDIGENOUS_AREA: "INDIGENOUS_AREA",
  PRESERVATION_AREA: "PRESERVATION_AREA",
  PRESERVATION: "PRESERVATION",
  SLAVE_LABOR: "SLAVE_LABOR",
  DEFORESTATION: "DEFORESTATION",
  INDIGENOUS: "INDIGENOUS",
  CHILD_SLAVE_LABOR: "CHILD_SLAVE_LABOR",
});
