import { Card, Col, List, Row, theme } from "antd";
import VirtualList from "rc-virtual-list";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowRight } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { listFarmers } from "./service";

const ContainerHeight = 650;

export default function MessageDashboard({ children }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [farmers, setFarmers] = useState([
    {
      _id: "",
      name: "",
      identifier: "",
      farmer: {
        _id: "",
        name: "",
        identifier: "",
      },
    },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sortOrder, setSortOrder] = useState(1);
  const [sortField, setSortField] = useState("farmer.name");
  const [farmSelected, setFarmSelect] = useState({
    _id: "",
    name: "",
    identifier: "",
    farmer: {
      _id: "",
      name: "",
      identifier: "",
    },
  });
  const [selectedItem, setSelectedItem] = useState(null); // Estado para controlar o item selecionado

  const findFarmers = useCallback(async () => {
    const result = await listFarmers({
      pageSize,
      currentPage,
      sortOrder,
      sortField,
    });
    result.result.map((x) => (x.key = x._id));
    setFarmers(result.result);
  }, [pageSize, currentPage, sortOrder, sortField]);

  useEffect(() => {
    findFarmers();
  }, [findFarmers]);

  const onScroll = async (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 10) {
      setPageSize((prevPageSize) => prevPageSize + 10);
      await findFarmers();
    }
  };

  async function viewChat(item) {
    setFarmSelect(item);
    navigate(`/messages/${item.farmer._id}/farmer`);
  }

  function handleItemClick(item) {
    if (id) setSelectedItem(null);
    else setSelectedItem(item);
    viewChat(item);
  }

  return (
    <div>
      <h1>{t("Messages")}</h1>
      <Row style={{ marginTop: "15px", width: "100%" }}>
        <Col span={6}>
          <Card style={{ height: "100%", borderRadius: "16px 0 0 16px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <BsFillPersonFill
                color="#4faf49"
                fontSize={25}
                style={{
                  marginRight: "16px",
                }}
              />
              <div style={{ color: "#1f9652", fontSize: "20px" }}>
                {t("Farmers")}
              </div>
            </div>
            <List style={{ marginTop: "20px" }}>
              <VirtualList
                data={farmers}
                height={ContainerHeight}
                itemHeight={120}
                itemKey={(item) => item.farmer.identifier}
                onScroll={onScroll}
              >
                {(item) => (
                  <List.Item
                    key={item.farmer.identifier}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleItemClick(item)}
                  >
                    <List.Item.Meta
                      title={
                        <h3
                          style={{
                            fontWeight:
                              item.farmer._id === id ? "bold" : "normal",
                            color: "#47342e",
                          }}
                        >
                          {item.farmer.name}
                        </h3>
                      }
                      description={
                        <h4 style={{ fontWeight: "normal", color: "#47342e" }}>
                          {item.farmer.identifier}
                        </h4>
                      }
                    />
                    <AiOutlineArrowRight
                      size={24}
                      style={{
                        color: item.farmer._id === id ? "#493630" : "#e5e1da",
                      }}
                    />
                  </List.Item>
                )}
              </VirtualList>
            </List>
          </Card>
        </Col>
        <Col span={18}>
          <Card
            style={{
              backgroundColor: "#f6f6f6",
              height: "100%",
              borderRadius: "0 16px 16px 0",
            }}
          >
            <Row style={{ flexGrow: 1, width: "100%" }}>
              <div style={{ flexGrow: 1, width: "100%" }}>{children}</div>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
