import { Card } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  min-height: 420px;
  min-width: 700px;
  display: flex;
  align-items: "center";
  box-shadow: 1px 1px 10px #3332;
  background-color: #ffffff;
  border-radius: 8px;
`;
