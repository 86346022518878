import styled, { css } from "styled-components";
import { ArrowRightOutlined } from "@ant-design/icons";

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 520px;
  max-height: 520px;
  box-shadow: 1px 1px 10px #3332;
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 20px;
`;

export const WrapperList = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  background-color: rgba(0, 0, 0, 0.08);
`;

export const HeaderList = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  padding-left: 30px;
  border-top-left-radius: 8px;
`;

export const TitleList = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #47342e;
`;

export const ListAgent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 450px;
  overflow-y: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }

  ${css`
    &:hover {
      -webkit-mask-position: left top;

      ::-webkit-scrollbar-thumb {
        background: #3338;
        border-radius: 10px;
      }
    }
  `}
`;

export const ItemList = styled.div`
  display: flex;
  width: 90%;
  height: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 10px;

  border-top: 1px solid #3333;

  background-color: ${(props) => (props.isActive ? "#3333" : "transparent")};

  ${css`
    &:hover {
      background-color: #3331;
      cursor: pointer;
    }
  `}
`;

export const TitleItemList = styled.p``;

export const IconArrow = styled(ArrowRightOutlined)``;

export const ContentGraphic = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

export const HeaderGraphic = styled.div`
  display: flex;
  width: 100%;
  min-height: 60px;
  padding: 0 30px;
  justify-content: space-between;
  align-items: center;
`;

export const TitleNameSelected = styled.h6`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #47342e;
`;

export const WrapperOrganization = styled.div`
  display: flex;
`;

export const LabelOrganization = styled.h6`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: right;
  color: #47342e;

  + h6 {
    margin-left: 10px;
  }

  span {
    font-weight: bold;
    font-weight: bold;
    color: #1f9652;
  }
`;

export const WrapperGraphic = styled.div`
  display: flex;
  width: 100%;
`;

export const WrapperPagination = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0 30px;
`;
