import axios from "../../apis/axiosInterceptorFarmer";

export const overviewChecklist = async () => {
  const result = await axios.get(`v1/checklist/overview`);
  return result.data.data;
};

export const listChecklist = async ({
  limit = 10,
  page = 1,
  search = "",
  sortOrder = 1,
  sortField = "checklist_name",
  checklistType = "",
  id = "",
  agentId = "",
}) => {
  const result = await axios.get(
    `v3/checklist?limit=${limit}&page=${page}&search=${search}&sort=${sortOrder}&field=${sortField}&checklist_type=${checklistType}&_id=${id}&agent_id=${agentId}`
  );
  return result.data.data;
};
