import { useEffect, useRef, useState } from "react";
import { signatureLink } from "../Farmers/service";
import ReactAudioPlayer from "react-audio-player";

export const MessageFileAudio = ({ url }) => {
  const [link, setLink] = useState("");

  useEffect(() => {
    handleSignatureLink();
  }, []);
  const handleSignatureLink = async () => {
    try {
      const response = await signatureLink(url);
      setLink(response);
    } catch (error) {}
  };
  return (
    <ReactAudioPlayer
      src={link}
      controls
    />
  );
};
