import { Button } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  border-top: 1px solid #e5e1da;

  padding: 0rem 1.5rem;
  padding-top: 0.5rem;

  .ant-form-item-control-input-content {
    border-bottom: unset !important;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px !important;
  }

  .ant-checkbox-inner {
    width: 20px !important;
    height: 20px !important;
    background-color: transparent !important;
    border: 2px solid #47342e !important;
  }

  .ant-checkbox-checked::after {
    border: 1px solid #47342e;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background: #47342e !important;
    border: unset !important;
  }

  .ant-checkbox-inner::after {
    top: 50% !important;
    left: 24% !important;
    width: 5px !important;
    height: 12px !important;
  }

  .ant-checkbox + span {
    font-size: 0.925rem;
    font-weight: 500;
    letter-spacing: normal;
    text-align: left;
    color: #47342e;
    padding-top: 6px;
    line-height: 1;
  }

  .ant-checkbox-wrapper {
    display: flex !important;
    align-items: center !important;
    margin: 5px 0 !important;
  }
`;

export const StatusIntersection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span {
    font-size: 0.875rem;
    opacity: 0.7;
  }
`;

export const ConfirmButton = styled(Button)`
  background: linear-gradient(to right, #47ab4b, #289b51);

  color: white;

  width: 100%;
  margin-top: 1rem;

`;
