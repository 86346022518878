import { Button, Modal } from "antd";
import { BoxArchive } from "../BoxArchive";
import * as S from "./BoxDocumentsStyles";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import UploadDocumentModal from "../UploadDocumentModal/UploadDocumentModal";
import { useTranslation } from "react-i18next";
import DeleteDocumentModal from "../DeleteDocumentModal/DeleteDocumentModal";

export const BoxDocuments = ({ farm, onLoad }) => {
  const refModal = useRef();
  const refDeleteModal = useRef();
  const { t } = useTranslation();
  const [documents, setDocuments] = useState([]);
  const [extraDocuments, setExtraDocuments] = useState([]);

  useEffect(() => {
    handleLoadDocuent();
    handleLoadExtraDocument();
  }, [farm]);

  const handleOpenModal = () => {
    refModal.current.handleOpen();
  };

  const handleLoadDocuent = () => {
    try {
      let documents = [
        {
          title: "possession_file",
          url: "",
        },
        {
          title: "land_title_file",
          url: "",
        },
        {
          title: "beneficiary_registration_file",
          url: "",
        },
        {
          title: "contract",
          url: "",
        },
      ];

      documents = documents.map((item) => {
        if (item.title == "possession_file") {
          return {
            ...item,
            url: farm?.possession_file,
          };
        } else if (item.title == "land_title_file") {
          return {
            ...item,
            url: farm?.land_title_file,
          };
        } else if (item.title == "beneficiary_registration_file") {
          return {
            ...item,
            url: farm?.beneficiary_registration_file,
          };
        } else if (item.title == "contract") {
          return {
            ...item,
            url: farm?.contract
              ? farm.contract[0]?.link_contract[0]?.Location
                ? farm.contract[0]?.link_contract[0]?.Location
                : farm.contract[0]?.link_contract[0]
              : "",
          };
        }
      });
      setDocuments(documents);
    } catch (error) {
      console.log("error load document =>", error);
    }
  };

  const handleLoadExtraDocument = () => {
    try {
      const documentsExtra = farm?.extra_documents?.map((item) => ({
        title: item?.type_document,
        url: item?.link,
        identifier: item?.identifier,
      }));

      setExtraDocuments(documentsExtra);
    } catch (error) {
      console.log("error load extra document ==>", error);
    }
  };

  const handleDelete = (identifier) => {
    try {
      refDeleteModal.current.handleOpen({ identifier, farmId: farm?._id });
    } catch (error) {
      console.log("error =>", error);
    }
  };

  return (
    <>
      <S.Container>
        <S.Title>{t("title_document")}</S.Title>
        <S.ListDocuments>
          {documents.map((item, key) => (
            <BoxArchive key={key} title={item?.title} url={item?.url} />
          ))}
        </S.ListDocuments>

        <S.WrapperTitle>
          <S.Title>{t("label_extra_document")}</S.Title>
          <Button
            style={{ marginLeft: 20 }}
            shape="round"
            className="confirm_button"
            type="dashed"
            onClick={handleOpenModal}
            icon={<PlusOutlined />}
          >
            {t("label_add_document")}
          </Button>
        </S.WrapperTitle>

        <S.ListDocuments>
          {extraDocuments?.map((item) => (
            <BoxArchive
              url={item?.url}
              title={item?.title}
              isDelete={true}
              onDelete={() => handleDelete(item?.identifier)}
            />
          ))}
        </S.ListDocuments>
      </S.Container>
      <UploadDocumentModal ref={refModal} farmId={farm?._id} onLoad={onLoad} />
      <DeleteDocumentModal ref={refDeleteModal} onLoad={onLoad}/>
    </>
  );
};
