import { useCallback, useEffect, useRef, useState } from "react";
import * as S from "./ProgramMapStyles";
import { listProgramAreas } from "../service";
import { BCMaps } from "../../../components/Maps/BCMaps";
import { BCPolygon } from "../../../components/Maps/BCPolygon";
import { BCBoxFilterArea } from "../../../components/Maps/BCBoxFilterArea/BCBoxFilterArea";
import { useParams } from "react-router-dom";

export function ProgramMapPage() {
  const { farm_id } = useParams();
  const polygonRef = useRef(null);

  const [selectedArea, setSelectedArea] = useState("");

  useEffect(() => {
    loadProgramArea();
  }, [farm_id]);

  const loadProgramArea = useCallback(async () => {
    try {
      const response = await listProgramAreas({ farmId: farm_id ?? "" });

      polygonRef.current.handlePaths(response);

      if (response.length === 0) return;

      if (!farm_id) {
        setSelectedArea("");
        return;
      }
      const coordinates = response[0].point.coordinates;

      setSelectedArea({ lat: coordinates[1], lng: coordinates[0] });
    } catch (error) {
      console.log(error);
    }
  }, [farm_id]);

  const handleFilter = (filter) => {
    polygonRef.current.handleApplyFilter(filter);
  };

  return (
    <S.ContainerProgramMap>
      <BCMaps center={selectedArea}>
        <BCPolygon ref={polygonRef} />
        <BCBoxFilterArea onFilter={(e) => handleFilter(e)} />
      </BCMaps>
    </S.ContainerProgramMap>
  );
}
