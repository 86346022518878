import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  max-height: 140px;
  max-width: 254px;
  margin-right: 10px;
  min-width: 254px;
  box-shadow: 1px 1px 10px #3331;
  background: linear-gradient(0.15turn, #90837b, #7a6b65, #4a3832);
  border-radius: 8px;
  padding: 18px;
  border: 1px solid #3332;
  justify-content: space-between;
  margin-top: 10px;

  :hover {
    box-shadow: 1px 1px 16px #3333;
    cursor: pointer;
  }

  @media screen and (max-width: 1506px) {
    width: none;
    max-width: none;
    flex:1;
  }

  @media screen and (max-width: 1006px) {
    width: 100%;
    max-width: none;
  }
`;

export const WrapperTotal = styled.div`
  display: flex;
`;

export const Chip = styled.div`
  display: flex;
  width: 48px;
  height: 22px;
  background-color: #2a9c51;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
`;
export const TitleChip = styled.p`
  color: #fffaf0;
  font-size: 12px;
`;

export const LabelTotal = styled.span`
  margin: 3px 0 5px 3px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
  color: #ffff;
`;
