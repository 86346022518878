export default function translateStatus(t) {
  return {
    call: {
      ["WAITING_TO_CALL"]: t("PENDING"),
      ["RECEIVED_THE_CALL"]: t("received_the_call"),
      ["DIDNT_RECEIVE_THE_CALL"]: t("didnt_received_the_call"),
    },
    scheduling: {
      ["SENT"]: t("PENDING"),
      ["REFUSED"]: t("refused"),
      ["EXPIRED"]: t("expired"),
      ["ACCEPTED"]: t("accepted"),
      ["NEWPROPOSAL"]: t("new_proposal"),
    },
    visit: {
      ["CREATED"]: t("created"),
      ["EXPIRED"]: t("expired"),
      ["DELETED"]: t("deleted"),
      ["VERIFIED"]: t("verified"),
    },
  };
}
