import { Select } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import BCTable from "../../components/BCTable";

const { Option } = Select;

export default function SuppliersRatingView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [rating, setRatings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: t("Date"),
      dataIndex: "date",
      align: "start",
      render: (text, data) => "",
    },

    {
      title: t("Author"),
      dataIndex: "Author",
      align: "start",
      render: (text, data) => "",
    },

    {
      title: t("Rating"),
      dataIndex: "Rating",
      align: "start",
      render: (text, data) => "",
    },
    {
      title: t("Comments"),
      dataIndex: "Comments",
      align: "start",
      render: (text, data) => "",
    },
  ];

  return (
    <div>
      <h1>{t("Ratings")}</h1>

      <BCTable
        data={""}
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        placeholderSearch={`${t("Search")} ${t("Message")}`}
        setSearch={() => {}}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        setSortField={() => {}}
        setSortOrder={() => {}}
        loading={loading}
        columns={columns}
        showCreateButton={false}
        showPlaceholderSearch={false}
      />
    </div>
  );
}
