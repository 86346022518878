import React from 'react'
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import BaseLayout from "../../pages/BaseLayout";
import MessageDashboard from "../../pages/Message/MessageDashboard";
import MessageListChatsFarmer from "../../pages/Message/MessageListChatsFarmer";
import MessageViewChatFarmer from "../../pages/Message/MessageViewChatFarmer";
import Private from "../Private";

function MessagesRoutes() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Private>
            <BaseLayout breadcrumb={`${t("Messages")}`}>
              <MessageDashboard />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/:id/farmer"
        element={
          <Private>
            <BaseLayout breadcrumb={`${t("Messages")}`}>
              <MessageDashboard children={<MessageListChatsFarmer />} />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/:id/farmer/:chatId/chat"
        element={
          <Private>
            <BaseLayout breadcrumb={`${t("Messages")}`}>
              <MessageDashboard children={<MessageViewChatFarmer />} />
            </BaseLayout>
          </Private>
        }
      />
    </Routes>
  );
}

export default MessagesRoutes;
