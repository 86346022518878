import {
  useCallback,
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import * as S from "./BCPolygonStyle";
import { AdapterAreas } from "./AdapterMapArea";
import { useReservationArea } from "../../../hooks/useAreaReservation";
import { AREA_MAP } from "../../../Enums/AreaMap.enum";

export const BCPolygon = forwardRef(function BCPolygon(
  {
    showIndigenousAreasDefault = true,
    showPreservationAreaDefault = true,
    defaultFilter = [],
    onPolygonClick = () => {},
  },
  ref
) {
  const { areaIndigenous, areaPreservation } = useReservationArea();
  const [filteredAreas, setFilteredAreas] = useState([]);
  const [areas, setAreas] = useState([]);

  const [showIndigenousAreas, setShowIndigenousAreas] = useState(
    showIndigenousAreasDefault
  );
  const [showPreservationArea, setShowPreservationArea] = useState(
    showPreservationAreaDefault
  );

  const handlePaths = useCallback((params) => {
    let _areas = [];

    params.forEach((farmAreas) => {
      AdapterAreas(farmAreas)
        .execute()
        .forEach((item) => _areas.push(item));
    });

    setAreas(_areas);
    setFilteredAreas(_areas);
  }, []);

  const handleApplyFilter = (filter) => {
    const filteredAreas = areas.filter((area) => {
      const isAreaTypeIncluded = filter
        .find((filterItem) => filterItem.field === "area_type")
        .value.includes(area.area_type);

      const isStatusIncluded = filter
        .find((filterItem) => filterItem.field === "status")
        .value.includes(area.status);

      return isAreaTypeIncluded && isStatusIncluded;
    });

    const isIndigenousAreaIncluded = filter
      .find((filterItem) => filterItem.field === "area_type")
      .value.includes(AREA_MAP.INDIGENOUS_AREA);
    setShowIndigenousAreas(isIndigenousAreaIncluded);

    const isPreservationAreaIncluded = filter
      .find((filterItem) => filterItem.field === "area_type")
      .value.includes(AREA_MAP.PRESERVATION_AREA);
    setShowPreservationArea(isPreservationAreaIncluded);

    setFilteredAreas(filteredAreas);
  };

  useImperativeHandle(ref, () => {
    return {
      handlePaths,
      handleApplyFilter,
    };
  });

  useEffect(() => {
    if (defaultFilter.length > 0) handleApplyFilter(defaultFilter);
  }, [defaultFilter, areas]);

  return (
    <>
      {filteredAreas.map((area, key) => (
        <>
          <S.Container
            onClick={() => onPolygonClick(area)}
            key={key}
            paths={area.coordinates}
            type={area.area_type}
            customColor={area.customColor}
          />
          {/* <BCMarker position={area.center_point} /> */}
        </>
      ))}
      {showIndigenousAreas &&
        areaIndigenous.map((areas, key) => (
          <S.Container
            key={key}
            paths={areas.coordinates}
            type={areas.area_type}
          />
        ))}

      {showPreservationArea &&
        areaPreservation.map((areas, key) => (
          <S.Container
            key={key}
            paths={areas.coordinates}
            type={areas.area_type}
          />
        ))}
    </>
  );
});
