export function getRandomHexColor() {
  
  const randomValue = () => crypto.getRandomValues(new Uint8Array(1))[0];

  const r = randomValue();
  const g = randomValue();
  const b = randomValue();

  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}
