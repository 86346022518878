import React from 'react'
import { Card, Checkbox, Col, Form, Input, List, Row, Select, Tag } from "antd";
import { Formik } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiMessageSquareAdd } from "react-icons/bi";
import { GiFarmer } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { TYPEUSER } from "../../Enums/Users.enum";
import BCButtonConfirm from "../../components/BCButtonConfirm";
import { BCList } from "../../components/BCList/BCList";
import { AuthContext } from "../../contexts/auth";
import { listUsers } from "../AdministrationUsers/service";
import { listFarmers } from "../Message/service";
import { create } from "./service";
import { toast } from 'react-toastify';

const { Meta } = Card;
const ContainerHeight = 800;
const { Option } = Select;
export default function CenterNotificationCreate() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [agents, setAgents] = useState([]);
  const [farmers, setFarmers] = useState([
    {
      _id: "",
      name: "",
      identifier: "",
      farmer: {
        _id: "",
        name: "",
        identifier: "",
      },
    },
  ]);
  const [initialFieldValues, setInitialFieldValues] = useState({
    entityId: "",
    title: "",
    origin: "",
    destinations: [],
    description: "",
    send_by_app: false,
    send_by_sms: false,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [sortField, setSortField] = useState("farmer.name");

  const [destinationSelected, setDestinationSelected] = useState([]);

  const [currentChecked, setCurrentChecked] = useState("all");

  const findFarmers = useCallback(async () => {
    setLoading(true);
    const result = await listFarmers({
      pageSize,
      currentPage,
      sortOrder,
      sortField,
    });
    result.result.map((x) => (x.key = x._id));
    setFarmers(result.result);
    setLoading(false);
  }, [pageSize, currentPage, sortOrder, sortField]);
  useEffect(() => {
    findFarmers();
  }, [findFarmers]);
  const onScroll = async (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 10) {
      setPageSize((prevPageSize) => prevPageSize + 10);
      await findFarmers();
      await findUsers();
    }
  };

  const findUsers = useCallback(async () => {
    setLoading(true);
    const result = await listUsers(
      pageSize,
      currentPage,
      TYPEUSER.AGENT,
      search,
      sortOrder,
      sortField
    );
    result.result.map((x) => (x.key = x._id));
    setAgents(result.result);
    setTotal(result.count);
    setLoading(false);
  }, [pageSize, currentPage, search, sortOrder, sortField]);

  useEffect(() => {
    findUsers();
  }, [findUsers]);

  const validationSchema = Yup.object().shape({
    entityId: Yup.string(),
    title: Yup.string().required(t("Title_is_Required")),
    origin: Yup.string().required(t("Origin_is_required")),
    description: Yup.string()
      .min(3, t("Description_min_3"))
      .required(t("Description_is_Required")),
    send_by_app: Yup.boolean().optional(true),
    send_by_sms: Yup.boolean().optional(false),
    destinations: Yup.array()
      .required(t("Destination_id_Is_Required"))
      .min(1, t("Destination_id_Is_Required")),
  });

  const typesOrigins = [
    {
      id: "1",
      name: t("EVENT"),
      type: "EVENT",
    },
    {
      id: "2",
      name: t("SCHEDULE_REQUEST"),
      type: "SCHEDULE_REQUEST",
    },
    {
      id: "3",
      name: t("CALL"),
      type: "CALL",
    },
    {
      id: "4",
      name: t("CHAT"),
      type: "CHAT",
    },
    {
      id: "5",
      name: t("OTHERS"),
      type: "OTHERS",
    },
  ];

  const getSelectedDestinations = (destinationsId) => {
    return destinationsId.map((destination) => {
      const _idDestination = farmers.find(
        (farmer) => farmer._id === destination
      );
      const _idDestinationFa = agents.find(agent => agent._id === destination);
      if (_idDestination)
        return { destination_id: destination, destination_type: "farmer" };
      if(_idDestinationFa) return {
        destination_id: destination, destination_type: "agent"
      }
    });
  };

  async function createNotification(values) {
    try{
      const response = await create(
        user.user._id,
        values.title,
        values.origin,
        values.description,
        getSelectedDestinations(values.destinations),
        values.send_by_app,
        values.send_by_sms
      );
      
      toast.success(response.message);
      navigate("/notifications");
    }catch(error){
      console.log(error);
    }
  }

  function getListDestinations() {
    const listDestinations = [...agents, ...farmers].map((destination) => {
      if (!destination.type)
        return {
          _id: destination.farmer._id,
          name: destination.farmer.name,
          type: "farmer",
        };
      return {
        _id: destination._id,
        name: destination.name,
        type: destination.type,
      };
    });
    return listDestinations;
  }

  function handleSelectedAllDestinations(type, checked) {
    setCurrentChecked(checked ? type : "");
    const destinations = getListDestinations()
      .filter((x) => {
        if (type != "all") {
          return x.type === type;
        } else if (type === "all") {
          return x.type !== null;
        }
      })
      .map((destination) => destination._id);

    if (!checked) {
      setInitialFieldValues({ ...initialFieldValues, destinations: [] });
      return;
    }

    setInitialFieldValues({ ...initialFieldValues, destinations });
  }

  return (
    <Formik
      initialValues={initialFieldValues}
      validationSchema={validationSchema}
      onSubmit={createNotification}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
      }) => (
        <Form layout="vertical" onFinish={handleSubmit}>
          <Row style={{ marginTop: "20px", width: "100%", height: "100vh" }}>
            <Col span={6}>
              <Card
                style={{
                  height: "100%",
                  borderRadius: 0,
                  backgroundColor: "#f6f6f6",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BiMessageSquareAdd
                    style={{
                      color: "rgb(79, 175, 73)",
                      fontSize: "20px",
                      marginRight: "5px",
                      marginTop: "10px",
                    }}
                  />
                  <Meta
                    title={t("Write_Your_Notification")}
                    style={{ marginTop: "10px" }}
                  />
                </div>

                <Row gutter={16}>
                  <Col xs={24} sm={24}>
                    <Row gutter={16}>
                      <Col xs={24} sm={24}>
                        <Form.Item
                          help={
                            touched.title && errors.title ? errors.title : ""
                          }
                          validateStatus={
                            touched.title && errors.title ? "error" : ""
                          }
                        >
                          <Input
                            name="title"
                            className="input"
                            value={values.title}
                            style={{ marginTop: "50px" }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t("Title")}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24}>
                        <Form.Item
                          help={
                            touched.description && errors.description
                              ? errors.description
                              : ""
                          }
                          validateStatus={
                            touched.description && errors.description
                              ? "error"
                              : ""
                          }
                        >
                          <Input.TextArea
                            name="description"
                            className="input"
                            value={values.description}
                            style={{ marginTop: "30px" }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t("Description")}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24}>
                        <Form.Item
                          style={{ marginTop: "30px" }}
                          help={
                            touched.origin && errors.origin && errors.origin
                              ? errors.origin
                              : ""
                          }
                          validateStatus={
                            touched.origin && errors.origin ? "error" : ""
                          }
                        >
                          <Select
                            style={{ width: "100%", marginTop: "10px" }}
                            name="origin"
                            value={values.origin}
                            onChange={(value) =>
                              handleChange({
                                target: { name: "origin", value },
                              })
                            }
                            onBlur={handleBlur}
                            placeholder={t("Select_type_notification")}
                            defaultValue=""
                          >
                            <Option value="" disabled>
                              {t("Select_type_notification")}
                            </Option>
                            {typesOrigins.map((x) => (
                              <Option value={x.type} key={x.id}>
                                {x.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ alignItems: "center", marginBottom: "20px" }}>
                      <Col span={12}>
                        <Checkbox
                          checked={values.send_by_sms}
                          style={{
                            marginTop: "20px",
                            "--checkbox-border-color": values.send_by_sms
                              ? "green"
                              : "",
                          }}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            handleChange({
                              target: { name: "send_by_sms", value: checked },
                            });
                          }}
                        >
                          {t("Send_by_sms")}
                        </Checkbox>
                      </Col>
                      <Col span={12}>
                        <Checkbox
                          checked={values.send_by_app}
                          style={{
                            marginTop: "20px",
                            "--checkbox-border-color": values.send_by_app
                              ? "green"
                              : "",
                          }}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            handleChange({
                              target: { name: "send_by_app", value: checked },
                            });
                          }}
                        >
                          {t("Send_by_app")}
                        </Checkbox>
                      </Col>
                    </Row>
                    <Form.Item>
                      <BCButtonConfirm
                        style={{
                          width: "100%",
                          maxWidth: "200px",
                          marginTop: "20px",
                        }}
                        buttonText={!loading ? t("Send") : t("Sending")}
                      ></BCButtonConfirm>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={14}>
              <Card style={{ height: "100%", borderRadius: 0 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <GiFarmer
                    style={{
                      color: "rgb(79, 175, 73)",
                      fontSize: "25px",
                      marginRight: "5px",
                    }}
                  />
                  <div style={{ color: "rgb(79, 175, 73)", fontSize: "20px" }}>
                    {t("Farmers")}
                  </div>
                </div>
                <Checkbox
                  checked={currentChecked === "all"}
                  style={{
                    marginTop: "20px",
                    "--checkbox-border-color":
                      currentChecked === "all" ? "green" : "",
                  }}
                  onChange={(e) =>
                    handleSelectedAllDestinations("all", e.target.checked)
                  }
                >
                  {t("Select_all")}
                </Checkbox>
                <Checkbox
                  checked={currentChecked === "farmer"}
                  name="all"
                  style={{
                    "--checkbox-border-color":
                      currentChecked === "farmer" ? "green" : "",
                  }}
                  indeterminate={""}
                  onChange={(e) =>
                    handleSelectedAllDestinations("farmer", e.target.checked)
                  }
                >
                  {t("Farmers")}
                </Checkbox>
                <Checkbox
                  checked={currentChecked === "agent"}
                  value={"all"}
                  style={{
                    "--checkbox-border-color":
                      currentChecked === "agent" ? "green" : "",
                  }}
                  indeterminate={""}
                  onChange={(e) =>
                    handleSelectedAllDestinations("agent", e.target.checked)
                  }
                >
                  {t("Agents")}
                </Checkbox>

                <Form.Item>
                  <Checkbox.Group
                    style={{ display: "flex" }}
                    name="regions"
                    value={values.destinations}
                    onChange={(checkedValues) =>
                      handleChange({
                        target: { name: "destinations", value: checkedValues },
                      })
                    }
                  >
                    <BCList
                      dataSource={getListDestinations()}
                      height={ContainerHeight}
                      itemHeight={47}
                      itemKey={(item) => item._id}
                      onScroll={onScroll}
                      renderItem={(destination) => (
                        <List.Item style={{ display: "flex", minWidth: 650 }}>
                          <Checkbox
                            style={{
                              "--checkbox-border-color":
                                values.destinations.includes(destination._id)
                                  ? "green"
                                  : "",
                            }}
                            value={destination._id}
                          >
                            {[
                              <span
                                style={{
                                  minWidth: 600,
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {destination.name}

                                <Tag
                                  style={{
                                    display: "center",
                                    justifyContent: "flex-end",
                                    backgroundColor:
                                      destination.type === "farmer"
                                        ? "#F6FFED"
                                        : "#E6F4FF",
                                  }}
                                >
                                  {t(destination.type)}
                                </Tag>
                              </span>,
                            ]}
                          </Checkbox>
                        </List.Item>
                      )}
                    ></BCList>
                  </Checkbox.Group>
                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
