import React from 'react'
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import BaseLayout from "../../pages/BaseLayout";
import ServicesCreateService from "../../pages/Services/ServicesCreateService";
import ServicesDashboard from "../../pages/Services/ServicesDashboard";
import ServicesInRevision from "../../pages/Services/ServicesInRevision";
import ServicesInRevisionView from "../../pages/Services/ServicesInRevisionView";
import ServicesView from "../../pages/Services/ServicesView";
import Private from "../Private";

function SuppliersRoutes() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Private>
            <BaseLayout breadcrumb={`${t("Services")}`}>
              <ServicesDashboard />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/create/service"
        element={
          <Private>
            <BaseLayout
              gotoback="/services"
              breadcrumb={`${t("Services")} / ${t("Creation")}`}
            >
              <ServicesCreateService />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/:id/view"
        element={
          <Private>
            <BaseLayout
              gotoback="/services"
              breadcrumb={`${t("Services")} / ${t("View")}}`}
            >
              <ServicesView />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/list-services-in-revision"
        element={
          <Private>
            <BaseLayout
              gotoback="/services"
              breadcrumb={`${t("Services")} / ${t("In_Revison")}`}
            >
              <ServicesInRevision />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/:id/services-in-revision/view"
        element={
          <Private>
            <BaseLayout
              gotoback="/services"
              breadcrumb={`${t("Services")} / ${t("In_Revison")} / ${t(
                "View"
              )}`}
            >
              <ServicesInRevisionView />
            </BaseLayout>
          </Private>
        }
      />
    </Routes>
  );
}

export default SuppliersRoutes;
