import React, { useState } from "react";
import { Button, Checkbox, Image, Skeleton } from "antd";
import { ItemAreaIntersection } from "../ItemAreaIntersection";

import {
  CheckCircleOutlined,
  CheckCircleFilled,
  CheckSquareFilled,
} from "@ant-design/icons";

import { useTranslation } from "react-i18next";

import * as S from "./IntersectionItemStyles";

import checkGreenIcon from "../../../../../assets/IconsSabia/check-green.svg";
import checkRedIcon from "../../../../../assets/IconsSabia/check-red.svg";

import { updateStatusIntersectedAreas } from "../../../service";

function IntersectionItem({ intersection, index, visible, onCheck }) {
  const [loading, setLoading] = useState(false);

  const [answerArea1, setAnswerArea1] = useState({
    description: "",
    status: "",
    valid: false,
    error: false,
  });
  const [answerArea2, setAnswerArea2] = useState({
    description: "",
    status: "",
    valid: false,
    error: false,
  });

  const { t } = useTranslation();

  const submitAnswers = async () => {
    setLoading(true);

    await updateStatusIntersectedAreas({
      a_id: intersection.areas[0].farm_id,
      a_status: answerArea1.status.toUpperCase(),
      a_description: answerArea1.description,
      b_id: intersection.areas[1].farm_id,
      b_status: answerArea2.status.toUpperCase(),
      b_description: answerArea2.description,
      identifier: intersection.identifier,
    });

    window.location.reload(false);
  };

  const isPending = intersection.status === "PENDING";

  const hasOnlyOneArea = intersection.areas.length === 1;

  if (loading) return <Skeleton active></Skeleton>;

  return (
    <S.Container>
      <S.StatusIntersection>
        <Checkbox
          onChange={(e) => {
            onCheck({
              visible: e.target.checked,
              intersectionId: intersection._id,
            });
          }}
          defaultChecked={visible}
          disabled={hasOnlyOneArea}
        >
          {t("overlap")} {index + 1}:
        </Checkbox>

        {isPending && <span>{t("PENDING")}</span>}

        {"APPROVED" === intersection.status && (
          <Image src={checkGreenIcon}></Image>
        )}
        {"REJECTED" === intersection.status && (
          <Image src={checkRedIcon}></Image>
        )}
      </S.StatusIntersection>

      {visible && !hasOnlyOneArea && (
        <>
          <ItemAreaIntersection
            answerArea={answerArea1}
            setAnswerArea={setAnswerArea1}
            area={intersection.areas[0]}
            intersection={intersection}
            intersectedFarmId={intersection.areas[1].farm_id}
          />
          <ItemAreaIntersection
            answerArea={answerArea2}
            setAnswerArea={setAnswerArea2}
            area={intersection.areas[1]}
            intersection={intersection}
            intersectedFarmId={intersection.areas[0].farm_id}
          />
          {answerArea1.valid && answerArea2.valid && (
            <S.ConfirmButton
              shape="round"
              onClick={() => submitAnswers().finally(() => setLoading(false))}
            >
              {t("btn_action")}
            </S.ConfirmButton>
          )}
        </>
      )}
    </S.Container>
  );
}

export default IntersectionItem;
