import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AREA_MAP } from "../../../../../Enums/AreaMap.enum";
import { PROGRAM_AREA_MAP_STATUS } from "../../../../../Enums/ProgramAreaMapStatus";
import { patchReviewStatus } from "../../../service";
import * as S from "./CardStatusesEligibiliteStyles";

export const CardStatusesEligibilite = ({ farm, onLoad }) => {
  const { t } = useTranslation();

  const [statuses, setStatuses] = useState([
    {
      title: t("No_child_labor"),
      status: PROGRAM_AREA_MAP_STATUS.PENDING,
      type: AREA_MAP.CHILD_SLAVE_LABOR,
      program_id: "",
    },
    {
      title: t("No_overlap_with_preservation_areas"),
      status: PROGRAM_AREA_MAP_STATUS.PENDING,
      type: AREA_MAP.PRESERVATION,
      program_id: "",
    },
    {
      title: t("No_slavery"),
      status: PROGRAM_AREA_MAP_STATUS.PENDING,
      type: AREA_MAP.SLAVE_LABOR,
      program_id: "",
    },
    {
      title: t("No_deforestation_in_the_last_5_years"),
      status: PROGRAM_AREA_MAP_STATUS.PENDING,
      type: AREA_MAP.DEFORESTATION,
      program_id: "",
    },
    {
      title: t("No_overlap_with_indigenous_lands"),
      status: PROGRAM_AREA_MAP_STATUS.PENDING,
      type: AREA_MAP.INDIGENOUS,
      program_id: "",
    },
  ]);

  const handleStatuses = () => {
    try {
      const programs = farm?.program;

      if (programs.length === 0) return;

      const { program } = programs[0];

      let _statuses = statuses.map((item) => {
        item.program_id = program._id;
        switch (item.type) {
          case AREA_MAP.CHILD_SLAVE_LABOR:
            item.status = program.rules[1].rules[1].status;
            return item;
          case AREA_MAP.PRESERVATION:
            item.status = program.rules[1].rules[1].status;
            return item;
          case AREA_MAP.SLAVE_LABOR:
            item.status = program.rules[1].rules[3].status;
            return item;
          case AREA_MAP.DEFORESTATION:
            item.status = program.rules[1].rules[2].status;
            return item;
          case AREA_MAP.INDIGENOUS:
            item.status = program.rules[1].rules[0].status;
            return item;
          default:
            return item;
        }
      });
      setStatuses(_statuses);
    } catch (error) {}
  };

  const handleApplyStatusInReview = async (typeArea, status, programId) => {
    try {
      await patchReviewStatus(typeArea, programId, farm._id, status);
      onLoad();
    } catch (error) {}
  };

  useEffect(() => {
    handleStatuses();
  }, [farm]);

  return (
    <S.Container>
      <S.HeaderCar>
        <S.IconPerson />
        <S.Title> {t("Status_of_eligibility_criteria")}</S.Title>
      </S.HeaderCar>
      <S.Divider />
      <S.ContentStatuses>
        {statuses.map((item, key) => (
          <S.WrapperStatuses key={key}>
            <S.TitleStatusText>{item.title}</S.TitleStatusText>
            <S.StatusText status={item.status}>
              <S.IconCheck status={item.status} />
              {t(item.status)}
            </S.StatusText>
            <S.Fragment>
              {item.status === PROGRAM_AREA_MAP_STATUS.REVIEW && (
                <S.WrapperAction>
                  <S.ButtonReject
                    onClick={() =>
                      handleApplyStatusInReview(
                        item.type,
                        PROGRAM_AREA_MAP_STATUS.REJECTED,
                        item.program_id
                      )
                    }
                  >
                    Rejeitar
                  </S.ButtonReject>
                  <S.ButtonAccept
                    onClick={() =>
                      handleApplyStatusInReview(
                        item.type,
                        PROGRAM_AREA_MAP_STATUS.APPROVED,
                        item.program_id
                      )
                    }
                  >
                    Aceitar
                  </S.ButtonAccept>
                </S.WrapperAction>
              )}
            </S.Fragment>
          </S.WrapperStatuses>
        ))}
      </S.ContentStatuses>
    </S.Container>
  );
};
