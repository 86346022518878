import React from "react";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default function FarmesVsImplementation({ data }) {
  const { t } = useTranslation();
  const arrayData = [];
  arrayData.push(data.first_segment);
  arrayData.push(data.second_segment);
  arrayData.push(data.third_segment);
  arrayData.push(data.fourth_segment);
  arrayData.push(data.fifth_segment);
  arrayData.push(data.sixth_segment);
  return (
    <BarChart
      width={600}
      height={300}
      data={arrayData}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="enrolled" name={t("Number_visits")} fill="#fdbc2c" />
      <Bar
        dataKey="implemented"
        name={`${t("Area_implemented")} %`}
        fill="#47342eb8"
      />
    </BarChart>
  );
}
