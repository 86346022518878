import { toast } from "react-toastify";
import { signatureLink } from "../../../service";
import * as S from "./BoxArchiveStyles";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import { useState } from "react";

export const BoxArchive = ({ title, url, isDelete, onDelete }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const handleSignatureLink = async () => {
    try {
      if (!url) return;
      setIsLoading(true);
      const documentSign = await signatureLink(url);
      setIsLoading(false);
      window.open(documentSign, "_blank");
    } catch (error) {
      toast.error("error_open_documento");
    }
  };

  if (!url) return <></>;
  return (
    <S.Container>
      <S.Icon />
      <S.Content>
        <S.Title>{t(title)}</S.Title>
      </S.Content>
      <S.WrapperIcons>
        {!isLoading && <S.DownloadIdcon onClick={handleSignatureLink} />}
        {isLoading && <Spin />}
        {isDelete && (
          <S.WrapperIconDelete onClick={onDelete}>
            <S.IconClose>X</S.IconClose>
          </S.WrapperIconDelete>
        )}
      </S.WrapperIcons>
    </S.Container>
  );
};
