import styled from "styled-components";
import { Typography, Row, Divider, Image } from "antd";

export const Title = styled(Typography.Title)`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #47342e;

  margin-left: 0.5rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;

  padding: 1.5rem;

  padding-bottom: 0rem;

  margin-bottom: 0rem;
`;

export const ListIntersections = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  border-radius: 20px;
  height: 95%;
  margin: 1rem;

  width: 25%;

  > div + div {
    margin-top: 1rem;
  }

  > button {
    margin-top: 1rem;
  }

  overflow-y: auto;

  padding-bottom: 1rem;
`;
