import { Link } from "react-router-dom";

function BCLinkReactRouterDom({ to, text }) {
  return (
    <Link className="link_react-router-dom" to={to}>
      {text}
    </Link>
  );
}

export default BCLinkReactRouterDom;
